import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { TopHeader } from "../../components/header/TopHeader";
import { CommonTitle } from "../../components/CommonTitle";
import { Col, Row, Button } from "react-bootstrap";
import "../css/channel/Channel.css";
import ChannelIcon from "../../assets/images/Group1787.png";
import { Formik, Form } from "formik";
import { InputField } from "../../components/formFields/InputField";
import { RadioButton } from "../../components/formFields/RadioButton";
import { TagsInput } from "../../components/formFields/TagsInput";
import { isUnlisted } from "../../helperFunction";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import apiInstance from "../../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";
import { accessToken } from "../../helperFunction";
import sochLogo from "../../assets/images/sochlogo.png";

export const ChannelEdit = () => {
  const [apiResponse, setApiResponse] = useState([]);
  const [tagsFromAPI, setTagsFromAPI] = useState([]);
  const [userInputTags, setUserInputTags] = useState([]);
  const [editorState, setEditorState] = useState();
  const [buttonDraftStatus, setButtonDraftStatus] = useState(false);
  const [buttonPublishStatus, setButtonPublishStatus] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    async function channelEdit() {
      await apiInstance
        .patch(
          `/channels/${id}/edit/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          }
        )
        .then((response) => {
          return response;
        })
        .then((data) => {
          if (data.status === 200) {
            setApiResponse(data.data.payload);
            let value = data.data.payload.description;

            const blocksFromHTML = convertFromHTML(`${value}`);
            const state = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            );
            const states = value
              ? EditorState.createWithContent(state)
              : EditorState.createEmpty();
            setEditorState(states);

            let temp = data.data.payload.tags;
            temp.forEach((item) => {
              delete item.id;
            });

            const finalsTags = temp.map((item) => item.name);
            setTagsFromAPI(finalsTags);
          }
        });
    }
    channelEdit();
  }, [id]);

  let unListed = "";
  if (apiResponse.is_unlisted === true) {
    unListed = "Yes";
  } else {
    unListed = "No";
  }

  const selectedTags = (tags) => {
    setUserInputTags(tags);
  };

  const initialValues = {
    channelName: `${apiResponse.name}`,
    isUnlisted: `${unListed}`,
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  const handleEditSubmit = (values) => {
    let unListed = "";
    let isPublished = false;

    if (values.isUnlisted === "Yes") {
      unListed = "True";
      isPublished = false;
    } else if (values.isUnlisted === "No") {
      unListed = "False";
      isPublished = true;
    }

    if (userInputTags.length < 1) {
      toast.error("Please enter minimum of three tags", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setButtonPublishStatus(true);
      let data = new FormData();
      data.append("name", values.channelName);
      data.append(
        "description",
        `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}`
      );
      data.append("is_unlisted", `${unListed}`);
      data.append("is_published", isPublished);
      for (var i = 0; i < userInputTags.length; i++) {
        data.append(`tags[${i}]`, userInputTags[i]);
      }
      apiInstance
        .patch(`/channels/${id}/edit/`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          history.push("/channel");
        })
        .catch((error) => {
          if (error.response.status === 500) {
            if (error.response.data.error_type === "DataError") {
              toast.error(`${error.response.data.errors[0].message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          } else if (error.response.status === 401) {
            if (error.response.data.error_type === "InvalidToken") {
              accessToken();
              handleEditSubmit();
            }
          } else {
            toast.warning(
              `Something went wrong, Please try submitting again.`,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        });
    }
  };

  const handleEditDraft = (values) => {
    let unListed = "";
    let isPublished = false;
    if (values.isUnlisted === "Yes") {
      unListed = "True";
    } else if (values.isUnlisted === "No") {
      unListed = "False";
    }

    if (userInputTags.length < 3) {
      toast.error("Please enter minimum of three tags", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setButtonDraftStatus(true);
      let data = new FormData();
      data.append("name", values.channelName);
      data.append(
        "description",
        `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}`
      );
      data.append("is_unlisted", `${unListed}`);
      data.append("is_published", isPublished);
      for (var i = 0; i < userInputTags.length; i++) {
        data.append(`tags[${i}]`, userInputTags[i]);
      }

      apiInstance
        .patch(`/channels/${id}/edit/`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            history.push("/channel");
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            if (error.response.data.error_type === "DataError") {
              toast.error(`${error.response.data.errors[0].message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          } else if (error.response.status === 401) {
            if (error.response.data.error_type === "InvalidToken") {
              accessToken();
              handleEditDraft();
            }
          } else {
            toast.warning(
              `Something went wrong, Please try submitting again.`,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        });
    }
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  return (
    <div className="app">
      <main>
        <TopHeader name="Manage Channel" />
        <div className="p-5 mainContainerAdd">
          <CommonTitle heading={`Edit Channel`} text="" icons={ChannelIcon} />
          <Row>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            {tagsFromAPI.length !== 0 ? (
              <Col xs={12} md={12} lg={12} xl={12} className="mt-5">
                <Formik initialValues={initialValues} enableReinitialize>
                  {(formik) => (
                    <Form onKeyDown={onKeyDown}>
                      <div className="channel-form">
                        <Row className="mx-4 py-5">
                          <Col xs={12} md={10} lg={10} xl={10}>
                            <InputField
                              label="Channel Name*"
                              name="channelName"
                              type="text"
                              data-testid="channel-name"
                              placeholder="Sochcast Title"
                            />
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10}>
                            <label
                              for="channelDescription"
                              className="formLabel mt-3"
                            >
                              Description*
                            </label>
                            <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="ChannelDesscription"
                              editorClassName="editorClassName"
                              toolbar={{
                                options: ["emoji", "link"],
                                emoji: { className: "emojiClassNAme" },
                                link: {
                                  className: "imageClassName",
                                  options: ["link"],
                                },
                              }}
                              editorState={editorState}
                              defaultEditorState={editorState}
                              onEditorStateChange={onEditorStateChange}
                            />
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <label className="formLabel">Tag*</label>
                            {tagsFromAPI?.length === 0 ? (
                              <TagsInput
                                selectedTags={selectedTags}
                                tags={[]}
                              />
                            ) : (
                              <TagsInput
                                selectedTags={selectedTags}
                                tags={tagsFromAPI}
                              />
                            )}
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <label className="formLabel">Is Unlisted</label>
                            <br />
                            <RadioButton
                              options={isUnlisted}
                              type="isUnlisted"
                            />
                          </Col>
                        </Row>
                      </div>
                      <Row>
                        <Col
                          xs={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="mt-4 chananelButton"
                        >
                          <Button
                            disabled={buttonDraftStatus}
                            variant="primary"
                            type="submit"
                            className="channelDraftButton rounded-2 mx-3"
                            title="ChannelDrafButton"
                            data-testid="signUpBtton"
                            // disabled={formik.isSubmitting}
                            onClick={() => handleEditDraft(formik.values)}
                          >
                            {buttonDraftStatus ? "Saving ..." : "Draft"}
                          </Button>
                          <Button
                            disabled={buttonPublishStatus}
                            variant="primary"
                            type="submit"
                            className="channelPublishButton rounded-2"
                            title="channelPublishButton"
                            data-testid="signUpBtton"
                            // disabled={formik.isSubmitting}
                            onClick={() => handleEditSubmit(formik.values)}
                          >
                            {buttonPublishStatus ? "Saving ..." : "Publish"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
            ) : (
              <div className="loading">
                <img src={sochLogo} alt="Loading" />{" "}
                <span>Loading Sochcasts...</span>
              </div>
            )}
          </Row>
        </div>
      </main>
    </div>
  );
};
