import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    color: #6781a9;
    font-family: "Poppins";
  }
  .app {
    display: flex;
    // position: relative;
  }
  .app main {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .pro-sidebar .overlay {
    background: none;
  }
  h3 {
    font-weight: bolder;
  }
  .buttonColor {
    background: #466594 !important;
    border: 0;
  }
  .formLabel {
    color: #0A1316;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    font-family: Poppins;
  }
  button.button-normalButton.btn.btn-primary {
    background: transparent linear-gradient(295deg, #ee6f00 0%, #ff9130 100%) 0%
    0% no-repeat padding-box;
    border: none;
    border-radius: 10px;
    width: 180px;
    height: 45px;
  }
  button.button-noBackgroundButton.btn.btn-primary {
    width: 160px;
    height: 50px;
    background: #ffffff00 0% 0% no-repeat padding-box;
    border: 2px solid #acacae;
    border-radius: 10px !important;
    color: #0a1316;
  }
  .main {
    background: #fafafa;
  }
  .customInput {
    box-shadow: 0px 1px 3px #0000000A !important;
    background: #FFFFFF1F 0% 0% no-repeat padding-box;
    border-radius: 10px !important;
  }
  .customInput:focus {
    border: 1px solid #38C3E0;
  }
`;

export default GlobalStyle;
