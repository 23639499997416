import React from "react";
import SignUpForm from "./SignUpForm";
import { Row, Col, Container } from "react-bootstrap";
import "../css/signup/signup.css";
import sochLogo from "../../assets/images/sochlogo.png";
import Image from "../../assets/images/MaskGroup20.png";
import { HelmetMetaData } from "../../components/HelmetMetaData";

const Signup = () => {
  return (
    <>
      <HelmetMetaData
        sTitle="Create Your Sochcast | Join the community created for women"
        sDescription="Sign up to create your Sochcast and share your soch to the community created for women"
      />
      <Container>
        <Row>
          <Col md={5} className="signup-left">
            <div className="sochlogo py-4 text-center">
              <img src={sochLogo} alt="SochCast Logo" className="img-fluid" />
            </div>
            <div className="my-3 text-center">
              <img src={Image} alt="SochCast Logo" className="img-fluid" />
            </div>
          </Col>
          <Col className="my-3 overflow-hidden" md={7}>
            <SignUpForm />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Signup;
