import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { TopHeaderNavigation } from "../components/listener/TopHeaderNavigation";
import { Banner } from "./banner/Banner";
import { Fethure } from "./fethure/Fethure";
import { TopEpisode } from "./topepisodes/TopEpisode";
import { TopChart } from "./topchart/TopChart";
import { NewRelease } from "./newrelease/NewRelease";
import { PopularShows } from "./popularshows/PopularShows";
import { SochcastOriginals } from "./sochcastoriginals/SochcastOriginals";
import { PopularCategories } from "./popularcategories/PopularCategories";
import { keepTheme } from "../utils/listenerTheme/Theme";
import moment from "moment";
import { FiFacebook, FiInstagram } from "react-icons/fi";
import { BsTwitter } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import apiInstance from "../api";
import LazyLoad from "react-lazyload";
import sochLogo from "../assets/images/sochlogo.png";
import { HelmetMetaData } from "../components/HelmetMetaData";
import { FaLinkedinIn } from 'react-icons/fa';

export const Listener = () => {
  const history = useHistory();
  const [themeStatus, setThemeStatus] = useState("theme-light");
  const [categoryShowsOne, setCategoryShowsOne] = useState([]);
  const [categoryShowsTwo, setCategoryShowsTwo] = useState([]);
  const [categoryShowsThree, setCategoryShowsThree] = useState([]);
  const [categoryShowsFour, setCategoryShowsFour] = useState([]);
  const [categoryShowsfive, setCategoryShowsFive] = useState([]);
  const [categoryShowsix, setCategoryShowsSix] = useState([]);
  const [categoryShowSeven, setCategoryShowsSeven] = useState([]);
  const [categoryShowEight, setCategoryShowsEight] = useState([]);
  const [categoryShowNine, setCategoryShowsNine] = useState([]);
  const [categoryShowTen, setCategoryShowsTen] = useState([]);

  useEffect(() => {
    keepTheme();
    if (localStorage.getItem("theme") === "theme-dark") {
      setThemeStatus("theme-dark");
    } else if (localStorage.getItem("theme") === "theme-light") {
      setThemeStatus("theme-light");
    }

    async function getAllCategoryShows() {
      let nextUrl = `/listener/categories/305`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catOne() {
      let temp1 = await getAllCategoryShows();
      setCategoryShowsOne(temp1);
    }

    async function getAllCategoryShows2() {
      let nextUrl = `/listener/categories/214`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catTwo() {
      let temp1 = await getAllCategoryShows2();
      setCategoryShowsTwo(temp1);
    }

    async function getAllCategoryShows3() {
      let nextUrl = `/listener/categories/203`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catThree() {
      let temp1 = await getAllCategoryShows3();
      setCategoryShowsThree(temp1);
    }

    async function getAllCategoryShows4() {
      let nextUrl = `/listener/categories/309`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catFour() {
      let temp1 = await getAllCategoryShows4();
      setCategoryShowsFour(temp1);
    }

    async function getAllCategoryShows5() {
      let nextUrl = `/listener/categories/307`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catFive() {
      let temp1 = await getAllCategoryShows5();
      setCategoryShowsFive(temp1);
    }

    async function getAllCategoryShows6() {
      let nextUrl = `/listener/categories/209`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catSix() {
      let temp1 = await getAllCategoryShows6();
      setCategoryShowsSix(temp1);
    }

    async function getAllCategoryShows7() {
      let nextUrl = `/listener/categories/218`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catSeven() {
      let temp1 = await getAllCategoryShows7();
      setCategoryShowsSeven(temp1);
    }

    async function getAllCategoryShows8() {
      let nextUrl = `/listener/categories/195`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catEight() {
      let temp1 = await getAllCategoryShows8();
      setCategoryShowsEight(temp1);
    }

    async function getAllCategoryShows9() {
      let nextUrl = `/listener/categories/308`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catNine() {
      let temp1 = await getAllCategoryShows9();
      setCategoryShowsNine(temp1);
    }

    async function getAllCategoryShows10() {
      let nextUrl = `/listener/categories/301`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catTen() {
      let temp1 = await getAllCategoryShows10();
      setCategoryShowsTen(temp1);
    }

    catOne();
    catTwo();
    catThree();
    catFour();
    catFive();
    catSix();
    catSeven();
    catEight();
    catNine();
    catTen();
  }, []);

  const year = moment().format("YYYY");

  const handleRecommededSochcast = (slug) => {
    history.push(`/show/${slug}`);
  };

  return (
    <>
      <HelmetMetaData
        sTitle="Listen to Sochcast - Available in your regional language for free"
        sDescription="Listen to sochcast in your regional language. Different shows available for 15 languages"
      />
      <div className={themeStatus}>
        <TopHeaderNavigation
          changeTheme={(themeStatus) => setThemeStatus(themeStatus)}
          upgrade="home"
        />
        <Banner />
        <Container>
          <Fethure />
          <NewRelease />
          <TopChart />
          <PopularShows />
          <PopularCategories />
          <TopEpisode />
          <div style={{ marginBottom: "92px" }}>
            <SochcastOriginals />
          </div>
          {/* <RecommendedHost /> */}

          {/* <LazyLoad height={100} offset={100} once>
            <div className="my-5">
              <Row>
                <Col xs={12} sm={12} md={9}>
                  <h3 className="listener-main-text">You may also like</h3>
                </Col>
              </Row>

              {allShows?.length !== 0 ? (
                <Row>
                  {allShows?.slice(minCount, count).map((item) => {
                    if (
                      item.hosts[0]?.first_name === undefined ||
                      item.hosts[0]?.first_name === null ||
                      item.hosts[0]?.last_name === undefined ||
                      item.hosts[0]?.last_name
                    ) {
                      return (
                        <Col md={2} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() => handleRecommededSochcast(item.id)}
                            >
                              <Card.Img
                                variant="top"
                                src={
                      item.show_compressed_image !== null
                        ? item.show_compressed_image
                        : item.show_image
                    }
                                className="f-listen-image-holder"
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    } else {
                      return (
                        <Col md={2} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() => handleRecommededSochcast(item.id)}
                            >
                              <Card.Img
                                variant="top"
                                src={
                      item.show_compressed_image !== null
                        ? item.show_compressed_image
                        : item.show_image
                    }
                                className="f-listen-image-holder"
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn">
                                  {item.hosts[0]?.first_name +
                                    " " +
                                    item.hosts[0]?.last_name}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    }
                  })}
                </Row>
              ) : (
                <div className="text-center">
                  <img src={sochLogo} alt="Loading" />
                  <span>Loading Sochcasts...</span>
                </div>
              )}
            </div>
          </LazyLoad> */}

          {categoryShowsOne?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Mind-Stimulating</h3>
                  </Col>
                </Row>
                {categoryShowsOne?.length !== 0 ? (
                  <Row>
                    {categoryShowsOne?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <>
                            <Card
                              className="playcard"
                              onClick={() =>
                                handleRecommededSochcast(item.slug)
                              }
                            >
                              <Card.Img
                                variant="top"
                                src={
                                  item.show_compressed_image !== null
                                    ? item.show_compressed_image
                                    : item.show_image
                                }
                                className="f-listen-image-holder"
                                alt={item?.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowsTwo?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Women-Led</h3>
                  </Col>
                </Row>
                {categoryShowsTwo?.length !== 0 ? (
                  <Row>
                    {categoryShowsTwo?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() =>
                                handleRecommededSochcast(item.slug)
                              }
                            >
                              <Card.Img
                                variant="top"
                                src={
                                  item.show_compressed_image !== null
                                    ? item.show_compressed_image
                                    : item.show_image
                                }
                                className="f-listen-image-holder"
                                alt={item?.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowsThree?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Kids & Family</h3>
                  </Col>
                </Row>
                {categoryShowsThree?.length !== 0 ? (
                  <Row>
                    {categoryShowsThree?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() =>
                                handleRecommededSochcast(item.slug)
                              }
                            >
                              <Card.Img
                                variant="top"
                                src={
                                  item.show_compressed_image !== null
                                    ? item.show_compressed_image
                                    : item.show_image
                                }
                                className="f-listen-image-holder"
                                alt={item?.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowsFour?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Success</h3>
                  </Col>
                </Row>
                {categoryShowsFour?.length !== 0 ? (
                  <Row>
                    {categoryShowsFour?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() =>
                                handleRecommededSochcast(item.slug)
                              }
                            >
                              <Card.Img
                                variant="top"
                                src={
                                  item.show_compressed_image !== null
                                    ? item.show_compressed_image
                                    : item.show_image
                                }
                                className="f-listen-image-holder"
                                alt={item?.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowsfive?.lenght >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Wisdom</h3>
                  </Col>
                </Row>
                {categoryShowsfive?.length !== 0 ? (
                  <Row>
                    {categoryShowsfive?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() =>
                                handleRecommededSochcast(item.slug)
                              }
                            >
                              <Card.Img
                                variant="top"
                                src={
                                  item.show_compressed_image !== null
                                    ? item.show_compressed_image
                                    : item.show_image
                                }
                                className="f-listen-image-holder"
                                alt={item?.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowsix?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Society & Culture</h3>
                  </Col>
                </Row>
                {categoryShowsix?.length !== 0 ? (
                  <Row>
                    {categoryShowsix?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() =>
                                handleRecommededSochcast(item.slug)
                              }
                            >
                              <Card.Img
                                variant="top"
                                src={
                                  item.show_compressed_image !== null
                                    ? item.show_compressed_image
                                    : item.show_image
                                }
                                className="f-listen-image-holder"
                                alt={item?.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowSeven?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Male Host</h3>
                  </Col>
                </Row>
                {categoryShowSeven?.length !== 0 ? (
                  <Row>
                    {categoryShowSeven?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() =>
                                handleRecommededSochcast(item.slug)
                              }
                            >
                              <Card.Img
                                variant="top"
                                src={
                                  item.show_compressed_image !== null
                                    ? item.show_compressed_image
                                    : item.show_image
                                }
                                className="f-listen-image-holder"
                                alt={item?.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowEight?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Arts</h3>
                  </Col>
                </Row>
                {categoryShowEight?.length !== 0 ? (
                  <Row>
                    {categoryShowEight?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() =>
                                handleRecommededSochcast(item.slug)
                              }
                            >
                              <Card.Img
                                variant="top"
                                src={
                                  item.show_compressed_image !== null
                                    ? item.show_compressed_image
                                    : item.show_image
                                }
                                className="f-listen-image-holder"
                                alt={item?.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowNine?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Path</h3>
                  </Col>
                </Row>
                {categoryShowNine?.length !== 0 ? (
                  <Row>
                    {categoryShowNine?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() =>
                                handleRecommededSochcast(item.slug)
                              }
                            >
                              <Card.Img
                                variant="top"
                                src={
                                  item.show_compressed_image !== null
                                    ? item.show_compressed_image
                                    : item.show_image
                                }
                                className="f-listen-image-holder"
                                alt={item?.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowTen?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">International</h3>
                  </Col>
                </Row>
                {categoryShowTen?.length !== 0 ? (
                  <Row>
                    {categoryShowTen?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() =>
                                handleRecommededSochcast(item.slug)
                              }
                            >
                              <Card.Img
                                variant="top"
                                src={
                                  item.show_compressed_image !== null
                                    ? item.show_compressed_image
                                    : item.show_image
                                }
                                className="f-listen-image-holder"
                                alt={item?.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}
        </Container>

        <div className="copy-right text-center p-4">
          <Row>
            <Col md={4}>
              <div className="legaltext">
                {/* <span>Legal</span>
                <span>About</span> */}
                <span onClick={() => history.push("/terms-and-conditions")}>
                  Terms and Conditions
                </span>
                <span onClick={() => history.push("/privacy-policy")}>
                  Privacy Policy
                </span>
              </div>
            </Col>
            <Col md={4}>
              <div className="social-icons-listener">
                <a
                  href="https://www.facebook.com/sochcast"
                  target="_blank"
                  rel="noreferrer"
                  className="sociallinksa"
                >
                  <FiFacebook className="mx-2" />
                </a>

                <a
                  href="https://www.instagram.com/sochcast/"
                  target="_blank"
                  rel="noreferrer"
                  className="sociallinksa"
                >
                  <FiInstagram className="mx-2" />
                </a>

                <a
                  href="https://twitter.com/sochcast"
                  target="_blank"
                  rel="noreferrer"
                  className="sociallinksa"
                >
                  <BsTwitter className="mx-2" />
                </a>
                <a
                  href="https://www.linkedin.com/company/sochcast"
                  target="_blank"
                  rel="noreferrer"
                  className="sociallinksa"
                >
                  <FaLinkedinIn className="mx-2" />
                </a>
              </div>
            </Col>
            <Col md={4}>
              <div className="listener-copyright-year">
                <span>&copy; {year} Sochcast.com. All rights reserved.</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
