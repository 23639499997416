import React from "react";
import { Header } from "../../components/header/Header";
import { TopHeader } from "../../components/header/TopHeader";
import { ProfileUpdate } from "./ProfileUpdate";

export const ProfileSettings = () => {
  return (
    <div className="app">
      <Header />
      <main>
        <TopHeader />
        <ProfileUpdate />
      </main>
    </div>
  );
};
