import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { keepTheme } from "../../utils/listenerTheme/Theme";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TopHeaderNavigation } from "../../components/listener/TopHeaderNavigation";
import { Container, Row, Col, Button, Dropdown } from "react-bootstrap";
import apiInstance from "../../api";
import EpisodePlay from "../../assets/images/episodeplay.png";
// import EpisodeFollow from "../../assets/images/add-circle-outline.png";
import sochLogo from "../../assets/images/sochlogo.png";
import DotsMenu from "../../assets/images/three-dots-vertical.svg";
import FollowMore from "../../assets/images/FollowMore.png";
import moment from "moment";
import { AiOutlineClose } from "react-icons/ai";
import { Trending } from "./Trending";
import { PlayListCustomAudioPlayer } from "./PlayListCustomAudioPlayer";
import { useHistory } from "react-router-dom";
import LikedHeart from "../../assets/images/heartLIked.svg";
import { accessToken } from "../../helperFunction";

export const MyPlayList = () => {
  const { playListID } = useParams();
  const history = useHistory();
  const [themeStatus, setThemeStatus] = useState("theme-light");
  const [playlistData, setPlayListData] = useState([]);
  const [playlistEpisodes, setPlaylistEpisodes] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  // const [likedIconStatus, setLikedIconStatus] = useState(true);
  const [sinlgeEpisodeStatus, setSinlgeEpisodeStatus] = useState(false);
  const [selectedSingleEpisodeToPlay, setSelectedSingleEpisodeToPlay] =
    useState([]);
  const [selectedEpisodeToPlay, setSelectedEpisodeToPlay] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [count, setCount] = useState("");
  const [episodeLikeStatus, setEpisodeLikeStatus] = useState(false);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    keepTheme();
    if (localStorage.getItem("theme") === "theme-dark") {
      setThemeStatus("theme-dark");
    } else if (localStorage.getItem("theme") === "theme-light") {
      setThemeStatus("theme-light");
    }

    apiInstance
      .get(`/listener/playlists/${playListID}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((res) => {
        setPlayListData(res.data.payload);
      })
      .catch((err) => {
        console.log(err);
      });

    apiInstance
      .get(`/listener/playlists/${playListID}/episodes/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((res) => {
        setPlaylistEpisodes(res.data.results);
        setCount(res.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh, episodeLikeStatus]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const handlePlayEpisode = (episodeIndex) => {
    setSinlgeEpisodeStatus(true);
    var arr = [];
    arr.push(playlistEpisodes[episodeIndex]);
    setSelectedSingleEpisodeToPlay(arr);
  };

  const handleDeleteEpisodeFromPlaylist = (playListId, episodeID, e) => {
    e.stopPropagation();
    setRefresh(false);
    apiInstance
      .delete(`/listener/playlists/${playListId}/episodes/${episodeID}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setSelectedSingleEpisodeToPlay([]);
          setRefresh(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeletePlayList = () => {
    apiInstance
      .delete(`/listener/playlists/${playListID}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          history.push("/your-library");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // calculation Duration Starts
  var total = 0;
  playlistEpisodes.forEach((item) => {
    total += item.playlist_episode.duration;
  });

  var date = new Date(0);
  date.setSeconds(total);
  var timeString = date.toISOString().substr(11, 8);
  // calculation Duration Ends

  const handlePlayNow = () => {
    setSinlgeEpisodeStatus(false);
    setSelectedEpisodeToPlay(playlistEpisodes);
  };

  useEffect(() => {
    setSelectedEpisodeToPlay(playlistEpisodes);
  }, [playlistEpisodes]);

  const handleLikedEpisode = (episodeID, e) => {
    e.stopPropagation();
    setEpisodeLikeStatus(false);

    apiInstance
      .post(
        "/listener/liked-episodes/",
        { liked_episode: episodeID },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.payload === "Episode liked") {
            setEpisodeLikeStatus(true);
            toast.success("Episode liked", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (response.data.payload === "Episode liked already") {
            setEpisodeLikeStatus(false);
            toast.warning("Episode liked already", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (error.response.data.error_type === "InvalidToken") {
            accessToken();
            setEpisodeLikeStatus(true);
            toast.success("Episode liked", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };

  const handleRemoveLikedEpisode = (episodeID, e) => {
    e.stopPropagation();
    setEpisodeLikeStatus(false);

    apiInstance
      .delete(`/listener/episode/${episodeID}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setEpisodeLikeStatus(false);
          if (res.data.payload.message === "Episode removed from likes.") {
            toast.warning("Episode removed from likes.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          setEpisodeLikeStatus(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className={themeStatus}>
        <TopHeaderNavigation
          changeTheme={(themeStatus) => setThemeStatus(themeStatus)}
          upgrade=""
        />

        {playlistData !== 0 ? (
          <Container className="my-5">
            <Row>
              <Col md={12} className="mt-5">
                <div className="episodelist-listener">
                  <h4>{playlistData?.playlist_name}</h4>
                  <p>{playlistData?.playlist_description}</p>
                  <p className="created-epiode-by text-capitalize">
                    <span>
                      {playlistData?.playlist_privacy +
                        " " +
                        ": " +
                        localStorage.getItem("first_name") +
                        " " +
                        localStorage.getItem("last_name")}
                    </span>
                    <span className="mx-4">{count} Episode</span>
                    <span className="mx-4">{timeString} Duration</span>
                  </p>
                </div>
              </Col>
              <Row>
                <Col md={2} sm={4} xs={4}>
                  <Button className="episode-playnow" onClick={handlePlayNow}>
                    <img
                      src={EpisodePlay}
                      alt="Play Now"
                      className="img-fluid playnowEpisode"
                    />
                    Play Now
                  </Button>
                </Col>
                <Col md={1} sm={2} xs={2}>
                  <div className="f-m">
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        variant="success"
                        id="dropdown-basic"
                        className="listener-drop-list"
                      >
                        <img
                          src={DotsMenu}
                          alt="Follow and More"
                          className="img-fluid like-heart"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        className="drop-listener-menu"
                        as={CustomMenu}
                      >
                        <Dropdown.Item href="#/action-1">
                          Play Now
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleDeletePlayList}>
                          Delete Playlist
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
              </Row>
              <h5 className="episode-all-title mt-5 mb-4">All Episodes</h5>
              {playlistEpisodes?.map((item, index) => {
                const minutes = Math.floor(item.playlist_episode.duration / 60);
                const returnedMinutes =
                  minutes < 10 ? `0${minutes}` : `${minutes}`;
                const seconds = Math.floor(item.playlist_episode.duration % 60);
                const returnedSeconds =
                  seconds < 10 ? `0${seconds}` : `${seconds}`;

                return (
                  <Row
                    className={`mb-3 episode-row-play-${
                      index === activeIndex ? "active" : "inactive"
                    }`}
                    key={item?.playlist_episode.id}
                    onClick={() => {
                      handlePlayEpisode(index);
                      setActiveIndex(index);
                    }}
                  >
                    <Col md={5} sm={12} xs={12}>
                      <div className="episode-list-all">
                        <p>
                          <span>
                            {item?.playlist_episode?.episode_number}.
                          </span>
                          <img
                            src={item?.playlist_episode?.episode_image}
                            alt="Episode"
                            className="img-fluid mx-3"
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "10px",
                            }}
                          />
                          <span>{item?.playlist_episode?.name}</span>
                        </p>
                      </div>
                    </Col>
                    <Col md={2} sm={6} xs={6} className="my-auto">
                      <div className="episode-list-all artist-name my-auto">
                        <>
                          By{" "}
                          {item?.playlist_episode?.shows[0]?.hosts?.map(
                            (item, index, array) => {
                              var hostName = "";
                              if (array.length - 1 === index) {
                                hostName = `${item?.first_name} ${item?.last_name}`;
                              } else {
                                hostName = `${item?.first_name} ${item?.last_name} & `;
                              }
                              return <>{hostName}</>;
                            }
                          )}
                        </>
                      </div>
                    </Col>
                    <Col md={2} sm={6} xs={6} className="my-auto">
                      <div className="episode-list-all artist-name my-auto">
                        <p>
                          {moment(
                            moment(item?.playlist_episode?.created_at).toDate()
                          ).format("D MMMM YYYY")}
                        </p>
                      </div>
                    </Col>
                    <Col md={3} sm={12} xs={12} className="my-auto">
                      <div className="episode-list-all artist-name my-auto float-end">
                        <p>
                          <span className="durationofepisode mx-5">
                            {returnedMinutes + ":" + returnedSeconds} min
                          </span>
                          <span className="mx-3">
                            {item.playlist_episode.is_liked === true ? (
                              <img
                                src={LikedHeart}
                                alt="Follow and More"
                                className="img-fluid like-heart"
                                onAnimationEnd={() => setClicked(false)}
                                onClick={(e) => {
                                  handleRemoveLikedEpisode(
                                    item.playlist_episode.id,
                                    e
                                  );
                                  setEpisodeLikeStatus(true);
                                  setClicked(true);
                                }}
                              />
                            ) : (
                              <img
                                src={FollowMore}
                                alt="Follow and More"
                                className="img-fluid like-heart"
                                onClick={(e) =>
                                  handleLikedEpisode(
                                    item.playlist_episode.id,
                                    e
                                  )
                                }
                              />
                            )}
                          </span>
                          <span
                            onClick={(e) =>
                              handleDeleteEpisodeFromPlaylist(
                                item.playlist.id,
                                item.id, e
                              )
                            }
                          >
                            <AiOutlineClose />
                          </span>
                        </p>
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </Row>

            <div className="my-5">
              <Trending />
            </div>
            <div className="audio-player-holder container">
              {sinlgeEpisodeStatus ? (
                <PlayListCustomAudioPlayer item={selectedSingleEpisodeToPlay} />
              ) : (
                <PlayListCustomAudioPlayer item={selectedEpisodeToPlay} />
              )}
            </div>
          </Container>
        ) : (
          <div className="loading">
            <img src={sochLogo} alt="Loading" />{" "}
            <span>Loading Sochcasts...</span>
          </div>
        )}
      </div>
    </>
  );
};
