import React, { useEffect, useState, useRef } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { InputField } from "../../components/formFields/InputField";
import { Formik, Form } from "formik";
import apiInstance from "../../api";
import { SelectDropdown } from "../../components/formFields/SelectDropdown";
import * as Yup from "yup";
import { accessToken } from "../../helperFunction";
import { FaUser } from "react-icons/fa";
import { RadioButton } from "../../components/formFields/RadioButton";
import { genderSelect } from "../../helperFunction";
import Multiselect from "multiselect-react-dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ProfileForm = () => {
  const fileElem = useRef(null);
  const [preferredLanguage, setPreferredLanguage] = useState([]);
  const [profile, setProfile] = useState([]);
  const [perferLanguage, setPreferLanguage] = useState([]);
  const [phone, setPhone] = useState("");
  const [outhLogin, setOauthLogin] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [profileCount, setProfileCount] = useState(false);
  const [categoryArray, setCategoryArray] = useState([]);
  const [genderSel, setGenderSel] = useState("");

  useEffect(() => {
    getLanguages();
    getProfileData();
    getAllCategories();
  }, [profileCount]);

  async function getAllCategories() {
    await apiInstance
      .get("/all-categories/")
      .then((response) => {
        setAllCategories(response.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getLanguages() {
    await apiInstance
      .get("/all-languages/")
      .then((response) => {
        return response;
      })
      .then((data) => {
        setPreferredLanguage(data.data.results);
      });
  }

  async function getProfileData() {
    await apiInstance
      .get("/profile/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        let ph = response.data.payload.phone_number;
        let ch = ph.replace("+91", "");
        setProfile(response.data.payload);
        setPreferLanguage(response.data.payload.preferred_languages[0]);
        setPhone(ch);
        if (response.data.payload.gender === "1") {
          setGenderSel("Man");
        } else if (response.data.payload.gender === "2") {
          setGenderSel("Woman");
        } else if (response.data.payload.gender === "3") {
          setGenderSel("Non-Binary");
        } else if (response.data.payload.gender === "4") {
          setGenderSel("Other");
        }
      })
      .catch((error) => {
        if (error.response.data.error_type === "InvalidToken") {
          accessToken();
          getProfileData();
        } else if (error.response.status === 404) {
          if (error.response.data.error_type === "Http404") {
            if (
              error.response.data.errors[0].message ===
              "No Profile matches the given query."
            ) {
              setOauthLogin(true);
              setProfile({
                age: "",
                first_name: `${localStorage.getItem("first_name")}`,
                last_name: `${localStorage.getItem("last_name")}`,
                email: `${localStorage.getItem("email")}`,
                profile_image: "",
                preferred_languages: [],
                phone_number: "",
                picked: "",
              });
            }
          }
        }
      });
  }

  console.log(genderSel);

  let selectedCategories = allCategories.filter((item) =>
    profile.categories?.includes(item.id)
  );

  const onCategorySelect = (selectedList, selectedItem) => {
    setCategoryArray(selectedList);
  };

  const onCategoryRemove = (selectedList, removedItem) => {
    setCategoryArray(selectedList);
  };

  const initialValues = {
    firstName: `${profile.first_name}`,
    lastName: `${profile.last_name}`,
    age: `${profile.age}`,
    phoneNumber: `${phone}`,
    email: `${profile.email}`,
    preferredLanguage: `${perferLanguage}`,
    profile_image: profile.profile_image,
    picked: `${genderSel}`,
  };

  const validate = Yup.object({
    firstName: Yup.string().max().required(),
    lastName: Yup.string().max().required(),
    email: Yup.string().email().required(),
    age: Yup.number().min(2).required(),
    preferredLanguage: Yup.string().required(),
    phonenumber: Yup.number().max(10).required(),
  });

  const handleSaveProfileChanges = async (values) => {
    let data = new FormData();

    let categoryList2 = [];
    if (categoryArray.length !== 0) {
      categoryList2 = categoryArray.map((e) => e.id);
    }

    var genderSel = "";
    if (values.picked === "Man") {
      genderSel = "1";
    } else if (values.picked === "Woman") {
      genderSel = "2";
    } else if (values.picked === "Non-Binary") {
      genderSel = "3";
    } else if (values.picked === "Other") {
      genderSel = "4";
    }

    data.append("user.first_name", values.firstName);
    data.append("user.last_name", values.lastName);
    data.append("phone_number", values.phoneNumber);
    data.append("age", values.age);
    data.append("preferred_languages", values.preferredLanguage);
    data.append("gender", genderSel);
    if (typeof values.profile_image === "object") {
      data.append("profile_image", values.profile_image);
    }
    for (var m = 0; m < categoryList2.length; m++) {
      data.append("categories", categoryList2[m]);
    }

    if (values.firstName === "") {
      toast.error("Please Enter First Name.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.lastName === "") {
      toast.error("Please Enter Last Name.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.phoneNumber === "") {
      toast.error("Please Enter Phone Number.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.age === "") {
      toast.error("Please Enter your Age.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // else if (categoryList2.length === 0) {
    //   toast.error("Please select Categories.", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
    else if (values.profile_image === null) {
      toast.error("Please upload your Profile Picture.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      apiInstance
        .patch("/profile/edit/", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setProfileCount(true);
            localStorage.setItem("first_name", values.firstName);
            localStorage.setItem("last_name", values.lastName);
            toast.success("Profile Saved.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          if (error.response.data.error_type === "InvalidToken") {
            accessToken();
            handleSaveProfileChanges();
          }
        });
    }
  };

  const handleSaveProfileChangesOauth = async (values) => {
    const categoryList = selectedCategories.map((e) => e.id);
    let categoryList2 = [];
    if (categoryArray.length !== 0) {
      categoryList2 = categoryArray.map((e) => e.id);
    }

    let finalCategoryList = categoryList.concat(categoryList2);
    var mySetCat = new Set(finalCategoryList);
    var filteredCatListArray = Array.from(mySetCat);

    var genderSel = "";
    if (values.picked === "Man") {
      genderSel = "1";
    } else if (values.picked === "Woman") {
      genderSel = "2";
    } else if (values.picked === "Non-Binary") {
      genderSel = "3";
    } else if (values.picked === "Other") {
      genderSel = "4";
    }

    let data = new FormData();

    data.append("user.first_name", values.firstName);
    data.append("user.last_name", values.lastName);
    data.append("phone_number", values.phoneNumber);
    data.append("age", values.age);
    data.append("preferred_languages", values.preferredLanguage);
    data.append("gender", genderSel);
    if (typeof values.profile_image === "object") {
      data.append("profile_image", values.profile_image);
    }
    for (var m = 0; m < filteredCatListArray.length; m++) {
      data.append("categories", filteredCatListArray[m]);
    }

    if (values.firstName === "") {
      toast.error("Please Enter First Name.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.lastName === "") {
      toast.error("Please Enter Last Name.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.phoneNumber === "") {
      toast.error("Please Enter Phone Number.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.age === "") {
      toast.error("Please Enter your Age.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (filteredCatListArray.length === 0) {
      toast.error("Please select Communities.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      apiInstance
        .post("/create/profile/", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setProfileCount(true);
            localStorage.setItem("first_name", values.firstName);
            localStorage.setItem("last_name", values.lastName);
            toast.success("Profile Saved.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          if (error.response.data.error_type === "InvalidToken") {
            accessToken();
            handleSaveProfileChangesOauth();
          } else {
            if (error.response.data.error_type === "ValidationError") {
              if (error.response.data.errors[0].field === "gender") {
                toast.error("Please pick your Gender.", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }
          }
        });
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            <Card className="ProfileEditTopCard">
              <Card.Body>
                <Row className="top-row">
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div
                      style={{ width: "120px", height: "120px" }}
                      className="profile-setting-p-image"
                    >
                      {profile.profile_image === null ? (
                        <div className="ico-react-rpfile">
                          <FaUser className="profile-cone-no-image" />
                        </div>
                      ) : (
                        <img
                          src={profile.profile_image}
                          alt="Profile"
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <Row className="userName">
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {profile && (
                          <p className="UserNAme">{`${profile.first_name} ${profile.last_name}`}</p>
                        )}
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className="UserNAmeEmail">{`${profile.email}`}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="text-center"
                  >
                    <input
                      type="file"
                      name="file"
                      ref={fileElem}
                      style={{ display: "none" }}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "profile_image",
                          event.target.files[0]
                        );
                      }}
                      accept="image/png"
                    />
                    <Button
                      className="upload-picture-button"
                      onClick={() => {
                        fileElem.current && fileElem.current.click();
                      }}
                    >
                      Upload Picture
                    </Button>
                  </Col>
                </Row>

                <Row className="bottom-row mt-5">
                  <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Row>
                      <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputField
                          label="Name"
                          name="firstName"
                          type="text"
                          data-testid="first-name"
                          styleClass="profile"
                        />
                      </Col>
                      <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputField
                          label="Last Name"
                          name="lastName"
                          type="text"
                          data-testid="first-name"
                          styleClass="profile"
                        />
                      </Col>
                      <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                        <InputField
                          label="Email"
                          name="email"
                          type="text"
                          data-testid="first-name"
                          readOnly
                          styleClass="profile"
                        />
                      </Col>
                      <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputField
                          label="Age"
                          name="age"
                          type="text"
                          data-testid="first-name"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          styleClass="profile"
                        />
                      </Col>
                      <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <SelectDropdown
                          control="select"
                          name="preferredLanguage"
                          data-testid="preferredLanguage"
                          id="preferredLanguage"
                          label="Preferred Language"
                          options={preferredLanguage}
                          styleClass="profile"
                          // preFillValue={profile.preferred_languages[0]}
                        />
                      </Col>
                      <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                        <Row>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <label className="formLabel">
                              What's your gender ?
                            </label>
                            <br />
                            <RadioButton options={genderSelect} type="gender" />
                          </Col>
                          <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                            <label className="formLabel">Communities</label>
                            <Multiselect
                              options={allCategories}
                              onSelect={onCategorySelect}
                              onRemove={onCategoryRemove}
                              displayValue="name"
                              selectedValues={selectedCategories}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                        <InputField
                          label="Phone"
                          name="phoneNumber"
                          type="text"
                          data-testid="first-name"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          styleClass="profile"
                        />
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={10}
                        lg={10}
                        xl={10}
                        className="mt-4"
                      >
                        {outhLogin ? (
                          <Button
                            variant="primary"
                            type="submit"
                            className="signupButton rounded-2"
                            title="SingUpButton"
                            data-testid="signUpBtton"
                            onClick={() =>
                              handleSaveProfileChangesOauth(formik.values)
                            }
                          >
                            Save Changes
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            type="submit"
                            className="signupButton rounded-2"
                            title="SingUpButton"
                            data-testid="signUpBtton"
                            onClick={() =>
                              handleSaveProfileChanges(formik.values)
                            }
                          >
                            Save Changes
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
};
