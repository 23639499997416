import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "../css/channel/Channel.css";
import DataTable from "react-data-table-component";
import apiInstance from "../../api";
import { accessToken } from "../../helperFunction";
import { useHistory } from "react-router";
import "../css/episode/Episode.css";
import { ReactComponent as PlayIcon } from "../../assets/images/episodePlayIcon.svg";
import { PopupPlayer } from "./PopupPlayer";
import moment from "moment";
import { Button } from "react-bootstrap";
import { ReactComponent as DeleteIcon } from "../../assets/images/DeleteIcon.svg";

export const EpisodeList = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [deletedRow, setDeletedRow] = useState(false);

  const [data, setData] = useState([]);

  const history = useHistory();

  useEffect(() => {
    accessToken();
    async function getAllEpisodes() {
      let nextUrl = "/showallepisodes";
      let temp = [];
      let config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      };

      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl, config);
        nextUrl = response.data.next;

        setData((prevDataFromAPI) => [
          ...prevDataFromAPI,
          ...response.data.results,
        ]);
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFunc() {
      let temp1 = await getAllEpisodes();
      setData(temp1);
    }

    tempFunc();
  }, [deletedRow]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "No.",
      cell: (row, index) => index + 1,
    },
    {
      name: "Episode",
      selector: (row) => {
        return (
          <>
            <div style={{ width: "42px", height: "42px" }}>
              <img
                style={{ width: "42px", height: "42px" }}
                src={row.episode_image}
                alt="episode"
              />
            </div>
            <p>{row.name}</p>
          </>
        );
      },
    },
    {
      name: "Date",
      selector: (row) => {
        const myMomentObject = moment(
          `${row.created_at}`,
          "YYYY-MM-DD"
        ).toDate();
        const data = moment(myMomentObject).format("MMM D, YYYY");
        return data;
      },
    },
    {
      name: "Status",
      selector: (row) => {
        if (row.is_published === true) {
          return (
            <div
              style={{
                background: "#D6DDE54A 0% 0% no-repeat padding-box",
                width: "71px",
                borderRadius: "10px",
              }}
            >
              <p style={{ margin: "auto", textAlign: "center" }}>Publish</p>
            </div>
          );
        } else {
          return (
            <div
              style={{ background: "#D6DDE54A 0% 0% no-repeat padding-box" }}
            >
              <p>Draft</p>
            </div>
          );
        }
      },
    },
    // {
    //   name: "Listener",
    //   selector: (row) => {
    //     if (row.is_published === true) {
    //       return (
    //         <div
    //           style={{
    //             background: "#D6DDE54A 0% 0% no-repeat padding-box",
    //             width: "71px",
    //             borderRadius: "10px",
    //           }}
    //         >
    //           <p style={{ margin: "auto", textAlign: "center" }}>05</p>
    //         </div>
    //       );
    //     }
    //   },
    // },
    // {
    //   name: "Listener Time",
    //   selector: (row) => {
    //     if (row.is_unlisted === true) {
    //       return "25.07 min";
    //     }
    //   },
    // },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div>
            <PlayIcon
              onClick={() => handlePlayEpisode(row)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div
            className="edit-show-icon mx-3 text-center"
            onClick={() => handlEpisodeEdit(row.shows[0], row.id)}
            style={{ cursor: "pointer" }}
          >
            <p className="icon-text mt-1">Edit Episode</p>
          </div>
          <div>
            <DeleteIcon
              onClick={() => handleDeleteEpisode(row.shows[0], row.id)}
              id={row.id}
              className="deleteIconChannelList"
            />
          </div>
        </>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <>
          <Button
            className="landing-signin"
            onClick={() =>
              handleViewAnalytics(row.id, row.name, row.episode_image)
            }
          >
            Analytics
          </Button>
        </>
      ),
    },
  ];

  const handleViewAnalytics = (episodeID, episodeName, episodeImage) => {
    history.push({
      pathname: "/episode-analytics",
      state: {
        id: episodeID,
        episodeName: episodeName,
        episodeImage: episodeImage,
      },
    });
  };

  const handlePlayEpisode = (name) => {
    setModalShow(true);
    setModalData(name);
  };

  const handlEpisodeEdit = (showID, episodeID) => {
    history.push(`/shows/${showID}/episodes/${episodeID}/`);
  };

  const handleDeleteEpisode = (showId, episodeID) => {
    apiInstance
      .delete(`/shows/${showId}/episodes/${episodeID}/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("access")}` },
      })
      .then((response) => {
        if (response.status === 200) {
          if (
            response.data.payload.message === "Episode deleted successfully."
          ) {
            setDeletedRow(true);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (error.response.data.error_type === "InvalidToken") {
            accessToken();
            handleDeleteEpisode();
          }
        }
      });
  };

  return (
    <Card className="ChannelListTable">
      <Card.Body>
        <DataTable
          pagination
          columns={columns}
          data={data}
          customStyles={customStyles}
        />

        <PopupPlayer
          show={modalShow}
          onHide={() => setModalShow(false)}
          data={modalData}
        />
      </Card.Body>
    </Card>
  );
};
