import axios from "axios";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const apiInstance = axios.create({
  baseURL: `${API_ENDPOINT}`,

  // transformRequest: [(data) => JSON.stringify(data.data)],
  // credentials: "same-origin",
  // headers: {
  //   Accept: "application/json",
  //   "Content-Type": "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // },
});

export default apiInstance;
