import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import apiInstance from "../../../api";
import { accessToken } from "../../../helperFunction";

export const ThisSochcast = (props) => {
  const [episodeAllListens, setEpisodeAllListens] = useState([]);
  const [perDateListens, setPerDateListens] = useState([]);

  useEffect(() => {
    async function getAllListen() {
      apiInstance
        .get(
          `/management/episode-analytics?time_delta=${props.dateNumber}&episode_id=${props.episodeID}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setEpisodeAllListens(res.data);
            setPerDateListens(res.data.per_date_listen_date_range);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            if (err.response.data.error_type === "InvalidToken") {
              accessToken();
              getAllListen();
            }
          }
        });
    }
    getAllListen();
  }, [props]);

  var finalArray = perDateListens.map(function (obj) {
    return obj.created_at__date;
  });

  var finalArrayCount = perDateListens.map(function (obj) {
    return obj.daily_count;
  });

  const options = {
    chart: {
      type: "spline",
    },
    style: {
      fontFamily: "Poppins",
      color: "#566777",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: finalArray,
    },
    yAxis: {
      // min: 0,
      title: {
        text: "No. of listen",
        style: {
          fontSize: "14",
          fontFamily: "Poppins",
          color: "#566777",
          fontWeight: "600",
        },
      },
      // labels: {
      //   overflow: "justify",
      // },
    },
    tooltip: {
      valueSuffix: " Listens",
      backgroundColor: "#FFFFFF",
      borderRadius: "15",
      borderColor: "#FFFFFF",
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    // legend: {
    //   layout: "vertical",
    //   align: "right",
    //   verticalAlign: "top",
    //   x: -40,
    //   y: 80,
    //   floating: true,
    //   borderWidth: 1,
    //   backgroundColor:
    //     Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
    //   shadow: true,
    // },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Listens",
        data: finalArrayCount,
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
