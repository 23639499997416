import React, { useState, useEffect } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { Row, Col } from "react-bootstrap";
import { ReactComponent as HeartIcon } from "../../assets/images/heart.svg";
import { ReactComponent as PlaylistCustomIcon } from "../../assets/images/playlist.svg";
import { PlaylistPopup } from "../episodelist/PlaylistPopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiInstance from "../../api";
import { ReactComponent as LikedHeart } from "../../assets/images/heartLIked.svg";
import { detect } from "detect-browser";
// import { useHistory } from "react-router-dom";

export const PlayListCustomAudioPlayer = (props) => {
  const browser = detect();

  const [episodesData, setEpisodesData] = useState(props.item);
  const [currentMusicIndex, setCurrentMusicIndex] = useState(0);
  const [playlistPopupModalShow, setPlaylistPopupModalShow] = useState(false);
  const [episodePlayListID, setEpisodePlayListID] = useState("");
  const [browserName, setBrowserName] = useState("");
  const [ipAddress, setIPAddress] = useState("");

  useEffect(() => {
    setEpisodesData(props.item);

    if (browser) {
      setBrowserName(browser.name);
    }

    apiInstance
      .get("https://api.ipify.org/?format=json")
      .then((res) => {
        setIPAddress(res.data.ip);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.item]);

  const handleOnPlay = (event) => {};

  const handleOnPause = (event) => {};

  const handleNextPlay = (value) => {
    setCurrentMusicIndex(currentMusicIndex + 1);
  };

  const handlePreviousPlay = (value) => {
    setCurrentMusicIndex(currentMusicIndex - 1);
  };

  const handleSingleEpisodeID = (playlistEpiosdeID) => {
    setEpisodePlayListID(playlistEpiosdeID);
  };

  const handleLikedEpisode = (episodeID) => {
    console.log(episodeID);
    apiInstance
      .post(
        "/listener/liked-episodes/",
        { liked_episode: episodeID },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.payload === "Episode liked") {
            toast.success("Episode liked", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (response.data.payload === "Episode liked already") {
            toast.warning("Episode liked already", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (error.response.data.error_type === "InvalidToken") {
            toast.error("Please Login to Like an Episode", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };

  // const history = useHistory();

  // const handleImageClick = (showID) => {
  //   history.push(`/show/${showID}`);
  // };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Row>
        <Col md={2} xs={3} className="my-auto text-center">
          <img
            src={
              episodesData[currentMusicIndex]?.playlist_episode?.episode_image
            }
            alt={episodesData[currentMusicIndex]?.playlist_episode?.name}
            className="img-fluid player-episode-image-listener"
            // onClick={() => handleImageClick(episodesData[currentMusicIndex]?.playlist_episode?.id)}
          />
        </Col>
        <Col md={10} xs={9}>
          {episodesData?.length !== 0 ? (
            <AudioPlayer
              autoPlay={true}
              src={episodesData[currentMusicIndex]?.playlist_episode?.file}
              showSkipControls={true}
              volume="0.2"
              customProgressBarSection={[
                RHAP_UI.PROGRESS_BAR,
                RHAP_UI.CURRENT_TIME,
                <div>/</div>,
                RHAP_UI.DURATION,
              ]}
              customAdditionalControls={[
                <button
                  className="like-audio-button"
                  onClick={() =>
                    handleLikedEpisode(
                      episodesData[currentMusicIndex]?.playlist_episode?.id
                    )
                  }
                >
                  <HeartIcon className="mx-2" />
                </button>,
                <button className="audio-playlist-button">
                  <PlaylistCustomIcon />
                </button>,
              ]}
              onPlay={(e) => {
                handleOnPlay(e, episodesData[currentMusicIndex]);
              }}
              onPause={(e) => {
                handleOnPause(e, episodesData[currentMusicIndex]);
              }}
              onClickNext={() => {
                handleNextPlay(episodesData);
              }}
              onClickPrevious={() => {
                handlePreviousPlay(episodesData);
              }}
              progressJumpSteps={{
                forward: 30000,
                backward: 10000,
              }}
              header={episodesData[currentMusicIndex]?.playlist_episode?.name}
              footer={
                episodesData[currentMusicIndex]?.playlist_episode?.shows[0]
                  ?.hosts[0]?.first_name +
                " " +
                episodesData[currentMusicIndex]?.playlist_episode?.shows[0]
                  ?.hosts[0]?.last_name
              }
            />
          ) : (
            // If there is no SRC
            <AudioPlayer
              autoPlay={false}
              src={episodesData[currentMusicIndex]?.file}
              showSkipControls={true}
              volume="0.2"
              customProgressBarSection={[
                RHAP_UI.PROGRESS_BAR,
                RHAP_UI.CURRENT_TIME,
                <div>/</div>,
                RHAP_UI.DURATION,
              ]}
              customAdditionalControls={[
                RHAP_UI.LOOP,
                <button className="like-audio-button">
                  <HeartIcon className="mx-2" />
                </button>,
                <button className="audio-playlist-button">
                  <PlaylistCustomIcon />
                </button>,
              ]}
            />
          )}
        </Col>
      </Row>

      <PlaylistPopup
        show={playlistPopupModalShow}
        onHide={() => setPlaylistPopupModalShow(false)}
        episodeID={episodePlayListID}
      />
    </>
  );
};
