import React, { useEffect, useState, useRef } from "react";
import { TopHeaderNavigation } from "../../components/listener/TopHeaderNavigation";
import { keepTheme } from "../../utils/listenerTheme/Theme";
import {
  Container,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Dropdown,
  Card,
} from "react-bootstrap";
import "../listener.css";
import EpisodePlay from "../../assets/images/episodeplay.png";
import EpisodeFollow from "../../assets/images/add-circle-outline.png";
import FollowMore from "../../assets/images/FollowMore.png";
import { useParams } from "react-router-dom";
import apiInstance from "../../api";
import moment from "moment";
import sochLogo from "../../assets/images/sochlogo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DotsMenuHorizontal from "../../assets/images/3-horizontal-dots.svg";
import { ReportAnIssue } from "./ReportAnIssue";
import { SocialShare } from "./SocialShare";
import { AddToPlaylist } from "./AddToPlaylist";
import { PlaylistPopup } from "./PlaylistPopup";
import { CustomAudioPlayer } from "../audioplayer/CustomAudioPlayer";
import { AboutSochcast } from "./AboutSochcast";
import { useHistory } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { BiChevronRight } from "react-icons/bi";
import parse from "html-react-parser";
import LazyLoad from "react-lazyload";
import { useLocation } from "react-router-dom";
import { ShowSocialShare } from "./ShowSocialShare";
import { AiOutlineShareAlt } from "react-icons/ai";
import Switch from "react-switch";
import LikedHeart from "../../assets/images/heartLIked.svg";
import { HelmetMetaData } from "../../components/HelmetMetaData";

export const EpisodeList = () => {
  const myRef = useRef(null);

  const [themeStatus, setThemeStatus] = useState("theme-light");
  const [episodeData, setEpisodeData] = useState([]);
  const [selectedEpisodeToPlay, setSelectedEpisodeToPlay] = useState([]);
  const [selectedSingleEpisodeToPlay, setSelectedSingleEpisodeToPlay] =
    useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [modalShow, setModalShow] = useState(false);
  const [socialModalShow, setSocialModalShow] = useState(false);
  const [socialModalOnlyShow, setSocialModalOnlyShow] = useState(false);
  const [playListModalShow, setPlayListModalShow] = useState(false);
  const [playlistPopupModalShow, setPlaylistPopupModalShow] = useState(false);
  const [episodeIDForPlayList, setEpisodeIDForPlayList] = useState("");
  const [episodePlayListID, setEpisodePlayListID] = useState("");
  const [sinlgeEpisodeStatus, setSinlgeEpisodeStatus] = useState(false);
  const [showFollowingStatus, setShowFollowingStatus] = useState(false);
  const [followedShows, setFollowedShows] = useState([]);
  const [orginalShows, setOriginalShows] = useState([]);
  const [popularShows, setPopularShows] = useState([]);
  const [categoryShowsOne, setCategoryShowsOne] = useState([]);
  const [categoryShowsTwo, setCategoryShowsTwo] = useState([]);
  const [categoryShowsThree, setCategoryShowsThree] = useState([]);
  const [categoryShowsFour, setCategoryShowsFour] = useState([]);
  const [categoryShowsfive, setCategoryShowsFive] = useState([]);
  const [categoryShowsix, setCategoryShowsSix] = useState([]);
  const [categoryShowSeven, setCategoryShowsSeven] = useState([]);
  const [categoryShowEight, setCategoryShowsEight] = useState([]);
  const [categoryShowNine, setCategoryShowsNine] = useState([]);
  const [categoryShowTen, setCategoryShowsTen] = useState([]);
  const [shareEpisodeData, setShareEpisodeData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [myEpisodesData, setMyEpisodesData] = useState([]);
  const [episodeFilterStatus, setEpisodeFilterStatus] = useState("");
  const [episodeLikeStatus, setEpisodeLikeStatus] = useState(false);
  const [clicked, setClicked] = useState(false);

  const history = useHistory();
  const { showId, episodeID } = useParams();
  let location = useLocation();

  useEffect(() => {
    apiInstance
      .get("/listener/sochcast-originals")
      .then((response) => {
        setOriginalShows(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    apiInstance
      .get("/listener/popular-shows")
      .then((response) => {
        setPopularShows(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setMyEpisodesData([
      ...episodeData.sort(
        // (a, b) => new Date(b.created_at) - new Date(a.created_at)
        (a, b) => parseFloat(b.episode_number) - parseFloat(a.episode_number)
      ),
    ]);
  }, [episodeData]);

  useEffect(() => {
    setEpisodeFilterStatus("Newest");
    // setChecked(true);
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
    smallDevices: {
      breakpoint: { max: 320, min: 0 },
      items: 1,
    },
  };

  const handleShowEpisode = (showID) => {
    setEpisodeData([]);
    setSelectedSingleEpisodeToPlay([]);
    setSelectedEpisodeToPlay([]);
    history.push(`/show/${showID}`);
  };

  const handleViewAllOriginals = () => {
    history.push("/all-originals");
  };

  const handleViewAllPopularShows = () => {
    history.push("/all-popular-shows");
  };

  const handleShowEpisodePopular = (slug) => {
    setEpisodeData([]);
    setSelectedSingleEpisodeToPlay([]);
    setSelectedEpisodeToPlay([]);
    history.push(`/show/${slug}`);
  };
  useEffect(() => {
    setSelectedSingleEpisodeToPlay((prev) => {
      return prev.map((e) => episodeData.find((d) => d.id === e.id));
    });
  }, [episodeData]);

  useEffect(() => {
    keepTheme();
    if (localStorage.getItem("theme") === "theme-dark") {
      setThemeStatus("theme-dark");
    } else if (localStorage.getItem("theme") === "theme-light") {
      setThemeStatus("theme-light");
    }

    async function getAllFollowedShows() {
      // accessToken();
      let nextUrl = `/listener/followed-shows/`;
      let config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      };
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl, config);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFunc2() {
      let temp2 = await getAllFollowedShows();
      setFollowedShows(temp2);
    }

    async function getAllEpisodeList() {
      let nextUrl = `/listener/show/${showId}`;

      if (localStorage.getItem("access") !== null) {
        var config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        };
      }

      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl, config);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFunc() {
      let temp1 = await getAllEpisodeList();
      setEpisodeData(temp1);
    }

    async function getAllCategoryShows() {
      let nextUrl = `/listener/categories/305`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catOne() {
      let temp1 = await getAllCategoryShows();
      setCategoryShowsOne(temp1);
    }

    async function getAllCategoryShows2() {
      let nextUrl = `/listener/categories/214`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catTwo() {
      let temp1 = await getAllCategoryShows2();
      setCategoryShowsTwo(temp1);
    }

    async function getAllCategoryShows3() {
      let nextUrl = `/listener/categories/203`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catThree() {
      let temp1 = await getAllCategoryShows3();
      setCategoryShowsThree(temp1);
    }

    async function getAllCategoryShows4() {
      let nextUrl = `/listener/categories/309`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catFour() {
      let temp1 = await getAllCategoryShows4();
      setCategoryShowsFour(temp1);
    }

    async function getAllCategoryShows5() {
      let nextUrl = `/listener/categories/307`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catFive() {
      let temp1 = await getAllCategoryShows5();
      setCategoryShowsFive(temp1);
    }

    async function getAllCategoryShows6() {
      let nextUrl = `/listener/categories/209`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catSix() {
      let temp1 = await getAllCategoryShows6();
      setCategoryShowsSix(temp1);
    }

    async function getAllCategoryShows7() {
      let nextUrl = `/listener/categories/218`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catSeven() {
      let temp1 = await getAllCategoryShows7();
      setCategoryShowsSeven(temp1);
    }

    async function getAllCategoryShows8() {
      let nextUrl = `/listener/categories/195`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catEight() {
      let temp1 = await getAllCategoryShows8();
      setCategoryShowsEight(temp1);
    }

    async function getAllCategoryShows9() {
      let nextUrl = `/listener/categories/308`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catNine() {
      let temp1 = await getAllCategoryShows9();
      setCategoryShowsNine(temp1);
    }

    async function getAllCategoryShows10() {
      let nextUrl = `/listener/categories/301`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catTen() {
      let temp1 = await getAllCategoryShows10();
      setCategoryShowsTen(temp1);
    }

    catOne();
    catTwo();
    catThree();
    catFour();
    catFive();
    catSix();
    catSeven();
    catEight();
    catNine();
    catTen();
    tempFunc2();
    tempFunc();
  }, [showId, episodeLikeStatus]);

  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    setSelectedEpisodeToPlay(episodeData);
  }, [episodeData]);

  useEffect(() => {
    if (location.state !== undefined) {
      var filteredArray = episodeData
        .filter(function (obj) {
          return obj.episode_number === location.state.episodeNumber;
        })
        .map(function (obj) {
          return obj;
        });

      setSelectedSingleEpisodeToPlay(filteredArray);
      setSelectedEpisodeToPlay(filteredArray);
    } else if (episodeID !== undefined) {
      var filteredArrayEpisode = episodeData
        .filter(function (obj) {
          return obj.id === episodeID;
        })
        .map(function (obj) {
          return obj;
        });

      setSelectedSingleEpisodeToPlay(filteredArrayEpisode);
      setSelectedEpisodeToPlay(filteredArrayEpisode);
    }
  }, [episodeData, episodeID]);

  // var followingShowData = followedShows.filter((obj) => {
  //   return obj.following_show === showId;
  // });

  // if (followingShowData?.length !== 0) {
  //   setShowFollowingStatus(true);
  // }

  // sort in ascending order
  // setMyEpisodesData([
  //   ...episodeData.sort(
  //     (a, b) => parseFloat(a.episode_number) - parseFloat(b.episode_number)
  //   ),
  // ]);
  // const myEpisodesData = episodeData.sort(
  //   (a, b) => parseFloat(a.episode_number) - parseFloat(b.episode_number)
  // );

  // handle single episode play click
  const handleSinglePlayEpisode = (episodeIndex) => {
    setSinlgeEpisodeStatus(true);
    var arr = [];
    arr.push(myEpisodesData[episodeIndex]);
    setSelectedSingleEpisodeToPlay(arr);
  };

  // handle Like button
  const handleLikedEpisode = (episodeID, e) => {
    if (e !== null) {
      e.stopPropagation();
    }
    setEpisodeLikeStatus(false);

    console.log(episodeID);
    console.log(e);

    apiInstance
      .post(
        "/listener/liked-episodes/",
        { liked_episode: episodeID },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.payload === "Episode liked") {
            setEpisodeLikeStatus(true);
            toast.success("Episode liked", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (response.data.payload === "Episode liked already") {
            setEpisodeLikeStatus(false);
            toast.warning("Episode liked already", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (error.response.data.error_type === "InvalidToken") {
            setEpisodeLikeStatus(false);
            toast.error("Please Login to Like an Episode", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };

  // handle Remove Like
  const handleRemoveLikedEpisode = (episodeID, e) => {
    e.stopPropagation();
    setEpisodeLikeStatus(false);

    apiInstance
      .delete(`/listener/episode/${episodeID}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setEpisodeLikeStatus(false);
          if (res.data.payload.message === "Episode removed from likes.") {
            toast.warning("Episode removed from likes.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          setEpisodeLikeStatus(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // calculation Duration Starts
  var total = 0;
  myEpisodesData.forEach((item) => {
    total += item.duration;
  });

  var date = new Date(0);
  date.setSeconds(total);
  var timeString = date.toISOString().substr(11, 8);
  // calculation Duration Ends

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  // Drop Down Add to playlist
  const handlePlaylistData = (episodeID, e) => {
    // e.stopPropagation();
    // if (localStorage.getItem("access") !== null) {
    setPlayListModalShow(true);
    // }
    setEpisodeIDForPlayList(episodeID);
  };

  // Play List
  const handleSingleEpisodeID = (playlistEpiosdeID, e) => {
    e.stopPropagation();
    if (localStorage.getItem("access") !== null) {
      setPlaylistPopupModalShow(true);
    } else {
      toast.warning("Please Login to add to playlist.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setEpisodePlayListID(playlistEpiosdeID);
  };

  // Play Now
  const handlePlayNow = () => {
    setSinlgeEpisodeStatus(false);
    setSelectedEpisodeToPlay(myEpisodesData);
  };

  useEffect(() => {
    function handleFollowShowEffect() {
      apiInstance
        .post(
          `/listener/followed-shows/`,
          {
            following_show: showId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.payload === "Already following") {
              setShowFollowingStatus(true);
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            apiInstance
              .post(`/token/refresh/`, {
                refresh: `${localStorage.getItem("refresh")}`,
              })
              .then((res) => {
                localStorage.removeItem("access");
                localStorage.setItem("access", res.data.access);
                handleFollowShowEffect();
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    }
    handleFollowShowEffect();
    executeScroll();
  }, [showId]);

  const handleFollowShow = () => {
    if (localStorage.getItem("access") !== null) {
      apiInstance
        .post(
          `/listener/followed-shows/`,
          {
            following_show: episodeData[0]?.shows[0]?.id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setShowFollowingStatus(true);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            apiInstance
              .post(`/token/refresh/`, {
                refresh: `${localStorage.getItem("refresh")}`,
              })
              .then((res) => {
                localStorage.removeItem("access");
                localStorage.setItem("access", res.data.access);
                handleFollowShow();
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    } else {
      toast.warning("Please Login to Follow the Show.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleRecommededSochcast = (slug) => {
    history.push(`/show/${slug}`);
  };

  const handleShareEpisodeData = (episodeIndex, e) => {
    e.stopPropagation();
    setSocialModalShow(true);
    var arr = [];
    arr.push(myEpisodesData[episodeIndex]);
    setShareEpisodeData(arr);
  };

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);

    if (checked === true) {
      setMyEpisodesData([
        ...myEpisodesData.sort(
          // (a, b) => new Date(b.created_at) - new Date(a.created_at)
          (a, b) => parseFloat(b.episode_number) - parseFloat(a.episode_number)
        ),
      ]);
      setEpisodeFilterStatus("Newest");
    } else if (checked === false) {
      setMyEpisodesData([
        ...myEpisodesData.sort(
          // (a, b) => new Date(a.created_at) - new Date(b.created_at)
          (a, b) => parseFloat(a.episode_number) - parseFloat(b.episode_number)
        ),
      ]);
      setEpisodeFilterStatus("Oldest");
    }
  };

  return (
    <>
      {episodeData[0]?.shows[0]?.meta?.title ||
      episodeData[0]?.shows[0]?.meta?.description === null ? (
        <HelmetMetaData
          sTitle={episodeData[0]?.shows[0]?.name}
          sDescription={episodeData[0]?.shows[0]?.description}
        />
      ) : (
        <HelmetMetaData
          sTitle={episodeData[0]?.shows[0]?.meta?.title}
          sDescription={episodeData[0]?.shows[0]?.meta?.description}
        />
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className={themeStatus} ref={myRef}>
        <TopHeaderNavigation
          changeTheme={(themeStatus) => setThemeStatus(themeStatus)}
          upgrade=""
        />
        {episodeData.length !== 0 && myEpisodesData !== 0 ? (
          <Container className="my-5">
            <Row>
              <Col
                md={{ span: 3, offset: 0 }}
                xs={{ span: 6, offset: 3 }}
                className="pb-5"
              >
                <div>
                  <img
                    src={
                      episodeData[0]?.shows[0].show_compressed_image !== null
                        ? episodeData[0]?.shows[0].show_compressed_image
                        : episodeData[0]?.shows[0].show_image
                    }
                    alt="epsidoe"
                    className="img-fluid"
                    style={{ borderRadius: "25px" }}
                  />
                </div>
              </Col>
              <Col md={9} className="">
                <div className="episodelist-listener">
                  <h4>{episodeData[0]?.shows[0].name}</h4>
                  <p>{parse(`${episodeData[0]?.shows[0].description}`)}</p>
                  <p className="created-epiode-by">
                    <span>
                      By{" "}
                      {episodeData[0]?.shows[0]?.hosts?.map(
                        (item, index, array) => {
                          var hostName = "";
                          if (array.length - 1 === index) {
                            hostName = `${item?.first_name} ${item?.last_name}`;
                          } else {
                            hostName = `${item?.first_name} ${item?.last_name} & `;
                          }

                          // let string = hostName;
                          return (
                            // <>{hostName.slice(0, hostName.lastIndexOf(" & "))}</>
                            <>{hostName}</>
                          );
                        }
                      )}
                    </span>
                    <span className="mx-4">{episodeData.length} Episode</span>
                    <span>{timeString} Duration</span>
                  </p>
                </div>
                <Row>
                  <Col md={3} sm={4} xs={4}>
                    <Button className="episode-playnow" onClick={handlePlayNow}>
                      <img
                        src={EpisodePlay}
                        alt="Play Now"
                        className="img-fluid playnowEpisode"
                      />
                      Play Now
                    </Button>
                  </Col>
                  <Col md={3} sm={4} xs={4}>
                    {showFollowingStatus ? (
                      <Button
                        className="episode-playnow following"
                        onClick={handleFollowShow}
                      >
                        <img
                          src={EpisodeFollow}
                          alt="Play Now"
                          className="img-fluid playnowEpisode"
                        />
                        Following
                      </Button>
                    ) : (
                      <Button
                        className="episode-playnow follow"
                        onClick={handleFollowShow}
                      >
                        <img
                          src={EpisodeFollow}
                          alt="Play Now"
                          className="img-fluid playnowEpisode"
                        />
                        Follow
                      </Button>
                    )}
                  </Col>
                  <Col md={2} sm={3} xs={2} className="shareshow-main">
                    <div className="show-0-share">
                      <AiOutlineShareAlt
                        onClick={() => setSocialModalOnlyShow(true)}
                      />
                    </div>
                  </Col>
                  {/* <Col md={1} sm={2} xs={2}>
                    <div className="f-m">
                      <Dropdown>
                        <Dropdown.Toggle
                          as={CustomToggle}
                          variant="success"
                          id="dropdown-basic"
                          className="listener-drop-list"
                        >
                          <img
                            src={DotsMenu}
                            alt="Follow and More"
                            className="img-fluid like-heart"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          className="drop-listener-menu"
                          as={CustomMenu}
                        >
                          <Dropdown.Item onClick={handlePlayNow}>
                            Play Now
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setSocialModalOnlyShow(true)}
                          >
                            Share
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col> */}
                </Row>
              </Col>
            </Row>

            <div className="filter-new-old">
              {episodeFilterStatus}
              <Switch
                onChange={handleChange}
                checked={checked}
                className="react-switch"
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#38c3e0"
              />
            </div>

            <Tabs
              defaultActiveKey="episode"
              id="uncontrolled-tab-example"
              className="mb-3 cutsom-tab"
            >
              <Tab eventKey="episode" title="Episode">
                <h5 className="episode-all-title mt-5 mb-4">All Episode</h5>

                {myEpisodesData?.map((item, index) => {
                  const minutes = Math.floor(item.duration / 60);
                  const returnedMinutes =
                    minutes < 10 ? `0${minutes}` : `${minutes}`;
                  const seconds = Math.floor(item.duration % 60);
                  const returnedSeconds =
                    seconds < 10 ? `0${seconds}` : `${seconds}`;

                  return (
                    <Row
                      className={`mb-3 episode-row-play-${
                        index === activeIndex ? "active" : "inactive"
                      }`}
                      key={item.id}
                      onClick={() => {
                        handleSinglePlayEpisode(index);
                        setActiveIndex(index);
                      }}
                    >
                      <Col md={4} sm={12} xs={12} className="my-auto">
                        <div className="episode-list-all">
                          <p>
                            <span>{item.episode_number}.</span>
                            <img
                              src={item.episode_image}
                              alt="Episode"
                              className="img-fluid mx-3"
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "10px",
                              }}
                            />
                            <span>{item.name}</span>
                          </p>
                        </div>
                      </Col>
                      <Col md={2} sm={4} xs={4} className="my-auto">
                        <div className="episode-list-all artist-name my-auto">
                          By{" "}
                          {episodeData[0]?.shows[0]?.hosts?.map(
                            (item, index, array) => {
                              var hostName = "";
                              if (array.length - 1 === index) {
                                hostName = `${item?.first_name} ${item?.last_name}`;
                              } else {
                                hostName = `${item?.first_name} ${item?.last_name} & `;
                              }

                              // let string = hostName;
                              return (
                                // <>{hostName.slice(0, hostName.lastIndexOf(" & "))}</>
                                <>{hostName}</>
                              );
                            }
                          )}
                        </div>
                      </Col>
                      <Col md={2} sm={4} xs={4} className="my-auto">
                        <div className="episode-list-all artist-name my-auto">
                          <p>
                            {moment(moment(item.created_at).toDate()).format(
                              "D MMMM YYYY"
                            )}
                          </p>
                        </div>
                      </Col>

                      <Col md={1} sm={4} xs={4} className="my-auto">
                        <span className="durationofepisode">
                          {returnedMinutes + ":" + returnedSeconds} min
                        </span>
                      </Col>
                      <Col md={3} sm={12} xs={12} className="my-auto">
                        <Row>
                          <Col md={3} xs={3}>
                            <span>
                              <Dropdown
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                  className="listener-drop-list activeRowofEpisodeList"
                                >
                                  <img
                                    src={DotsMenuHorizontal}
                                    alt="Follow and More"
                                    className="img-fluid"
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="drop-listener-menu">
                                  <Dropdown.Item
                                    onClick={() => {
                                      handleSinglePlayEpisode(index);
                                      setActiveIndex(index);
                                    }}
                                  >
                                    Play Episode Now
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item>
                                    Save To Your Episode
                                  </Dropdown.Item> */}
                                  <Dropdown.Item
                                    onClick={() => {
                                      handlePlaylistData(item.id);
                                    }}
                                  >
                                    Add To PlayList
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item
                                    onClick={() => {
                                      setSocialModalShow(true);
                                      handleShareEpisodeData(index);
                                    }}
                                  >
                                    Share
                                  </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </span>
                          </Col>
                          <Col md={3} xs={3}>
                            <div className="show-0-share">
                              <AiOutlineShareAlt
                                onClick={(e) => {
                                  handleShareEpisodeData(index, e);
                                }}
                              />
                            </div>
                          </Col>

                          {item?.is_liked === true ? (
                            <Col md={3} xs={3}>
                              <span
                                className=""
                                onClick={(e) => {
                                  handleRemoveLikedEpisode(item.id, e);
                                  setEpisodeLikeStatus(true);
                                  setClicked(true);
                                }}
                                onAnimationEnd={() => setClicked(false)}
                              >
                                <img
                                  src={LikedHeart}
                                  alt="Follow and More"
                                  className="img-fluid like-heart"
                                />
                              </span>
                            </Col>
                          ) : (
                            <Col md={3} xs={3}>
                              <span
                                className=""
                                onClick={(e) => handleLikedEpisode(item.id, e)}
                              >
                                <img
                                  src={FollowMore}
                                  alt="Follow and More"
                                  className="img-fluid like-heart"
                                />
                              </span>
                            </Col>
                          )}

                          <Col md={3} xs={3}>
                            <span
                              onClick={(e) => {
                                handleSingleEpisodeID(item.id, e);
                              }}
                            >
                              <img
                                src={EpisodeFollow}
                                alt="Follow and More"
                                className="img-fluid like-heart"
                              />
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })}
              </Tab>
              <Tab eventKey="about" title="About Sochcast">
                <div className="episode-all-title mt-5 mb-4">
                  <h4 className="episode-all-title mt-5 mb-4">
                    About Sochcast
                  </h4>
                  <AboutSochcast
                    data={selectedSingleEpisodeToPlay}
                    hostData={episodeData}
                  />
                </div>
              </Tab>
            </Tabs>

            <div className="episodeshow-popular">
              {/* Sochcast Originals Starts */}
              <p className="sub-main-text">For you</p>
              <Row>
                <Col xs={12} sm={12} md={9}>
                  <h3 className="listener-main-text">Sochcast Originals</h3>
                </Col>
                <Col xs={12} sm={12} md={3}>
                  <p className="view-all" onClick={handleViewAllOriginals}>
                    View all <BiChevronRight />
                  </p>
                </Col>
              </Row>

              <Carousel responsive={responsive}>
                {orginalShows?.map((item) => {
                  return (
                    <div key={item.id}>
                      <Card
                        className="playcard"
                        onClick={() => handleShowEpisode(item.id)}
                      >
                        <Card.Img
                          variant="top"
                          src={
                            item.show_compressed_image !== null
                              ? item.show_compressed_image
                              : item.show_image
                          }
                          className="f-listen-image-holder"
                          alt={item?.name}
                        />
                        <Card.Body className="card-b-listen">
                          <Card.Title className="l-title-albumn">
                            {item.name}
                          </Card.Title>
                          <Card.Text className="l-author-albumn text-capitalize">
                            By{" "}
                            {item.hosts[0]?.first_name +
                              " " +
                              item.hosts[0]?.last_name}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </Carousel>
              {/* Sochcast Originals Ends */}

              {/* Popular Shows Starts */}
              <p className="sub-main-text mt-5">For you</p>
              <Row>
                <Col xs={12} sm={12} md={9}>
                  <h3 className="listener-main-text">Popular Shows</h3>
                </Col>
                <Col xs={12} sm={12} md={3}>
                  <p className="view-all" onClick={handleViewAllPopularShows}>
                    View all <BiChevronRight />
                  </p>
                </Col>
              </Row>

              <Carousel responsive={responsive}>
                {popularShows?.map((item) => {
                  return (
                    <div key={item.id}>
                      <Card
                        className="playcard"
                        onClick={() => handleShowEpisodePopular(item.slug)}
                      >
                        <Card.Img
                          variant="top"
                          src={
                            item.show_compressed_image !== null
                              ? item.show_compressed_image
                              : item.show_image
                          }
                          className="f-listen-image-holder"
                          alt={item?.name}
                        />
                        <Card.Body className="card-b-listen">
                          <Card.Title className="l-title-albumn">
                            {item.name}
                          </Card.Title>
                          <Card.Text className="l-author-albumn text-capitalize">
                            {item.hosts[0]?.first_name +
                              " " +
                              item.hosts[0]?.last_name}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </Carousel>
              {/* Popular Shows Ends */}

              {/* All Shows Starts */}

              {categoryShowsOne?.length >= 5 ? (
                <LazyLoad height={100} offset={100} once>
                  <div className="my-5">
                    <p className="sub-main-text">You may also like</p>
                    <Row>
                      <Col xs={12} sm={12} md={9}>
                        <h3 className="listener-main-text">Mind-Stimulating</h3>
                      </Col>
                    </Row>
                    {categoryShowsOne?.length !== 0 ? (
                      <Row>
                        {categoryShowsOne?.map((item) => {
                          return (
                            <Col md={2} xs={6} key={item.id}>
                              <>
                                <Card
                                  className="playcard"
                                  onClick={() =>
                                    handleRecommededSochcast(item.slug)
                                  }
                                >
                                  <Card.Img
                                    variant="top"
                                    src={
                                      item.show_compressed_image !== null
                                        ? item.show_compressed_image
                                        : item.show_image
                                    }
                                    className="f-listen-image-holder"
                                    alt={item?.name}
                                  />
                                  <Card.Body className="card-b-listen">
                                    <Card.Title className="l-title-albumn">
                                      {item.name}
                                    </Card.Title>
                                    <Card.Text className="l-author-albumn"></Card.Text>
                                  </Card.Body>
                                </Card>
                              </>
                            </Col>
                          );
                        })}
                      </Row>
                    ) : (
                      <div className="text-center">
                        <img src={sochLogo} alt="Loading" />
                        <span>Loading Sochcasts...</span>
                      </div>
                    )}
                  </div>
                </LazyLoad>
              ) : null}

              {categoryShowsTwo?.length >= 5 ? (
                <LazyLoad height={100} offset={100} once>
                  <div className="my-5">
                    <p className="sub-main-text">You may also like</p>
                    <Row>
                      <Col xs={12} sm={12} md={9}>
                        <h3 className="listener-main-text">Women-Led</h3>
                      </Col>
                    </Row>
                    {categoryShowsTwo?.length !== 0 ? (
                      <Row>
                        {categoryShowsTwo?.map((item) => {
                          return (
                            <Col md={2} xs={6} key={item.id}>
                              <div>
                                <Card
                                  className="playcard"
                                  onClick={() =>
                                    handleRecommededSochcast(item.slug)
                                  }
                                >
                                  <Card.Img
                                    variant="top"
                                    src={
                                      item.show_compressed_image !== null
                                        ? item.show_compressed_image
                                        : item.show_image
                                    }
                                    className="f-listen-image-holder"
                                    alt={item?.name}
                                  />
                                  <Card.Body className="card-b-listen">
                                    <Card.Title className="l-title-albumn">
                                      {item.name}
                                    </Card.Title>
                                    <Card.Text className="l-author-albumn"></Card.Text>
                                  </Card.Body>
                                </Card>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    ) : (
                      <div className="text-center">
                        <img src={sochLogo} alt="Loading" />
                        <span>Loading Sochcasts...</span>
                      </div>
                    )}
                  </div>
                </LazyLoad>
              ) : null}

              {categoryShowsThree?.length >= 5 ? (
                <LazyLoad height={100} offset={100} once>
                  <div className="my-5">
                    <p className="sub-main-text">You may also like</p>
                    <Row>
                      <Col xs={12} sm={12} md={9}>
                        <h3 className="listener-main-text">Kids & Family</h3>
                      </Col>
                    </Row>
                    {categoryShowsThree?.length !== 0 ? (
                      <Row>
                        {categoryShowsThree?.map((item) => {
                          return (
                            <Col md={2} xs={6} key={item.id}>
                              <div>
                                <Card
                                  className="playcard"
                                  onClick={() =>
                                    handleRecommededSochcast(item.slug)
                                  }
                                >
                                  <Card.Img
                                    variant="top"
                                    src={
                                      item.show_compressed_image !== null
                                        ? item.show_compressed_image
                                        : item.show_image
                                    }
                                    className="f-listen-image-holder"
                                    alt={item?.name}
                                  />
                                  <Card.Body className="card-b-listen">
                                    <Card.Title className="l-title-albumn">
                                      {item.name}
                                    </Card.Title>
                                    <Card.Text className="l-author-albumn"></Card.Text>
                                  </Card.Body>
                                </Card>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    ) : (
                      <div className="text-center">
                        <img src={sochLogo} alt="Loading" />
                        <span>Loading Sochcasts...</span>
                      </div>
                    )}
                  </div>
                </LazyLoad>
              ) : null}

              {categoryShowsFour?.length >= 5 ? (
                <LazyLoad height={100} offset={100} once>
                  <div className="my-5">
                    <p className="sub-main-text">You may also like</p>
                    <Row>
                      <Col xs={12} sm={12} md={9}>
                        <h3 className="listener-main-text">Success</h3>
                      </Col>
                    </Row>
                    {categoryShowsFour?.length !== 0 ? (
                      <Row>
                        {categoryShowsFour?.map((item) => {
                          return (
                            <Col md={2} xs={6} key={item.id}>
                              <div>
                                <Card
                                  className="playcard"
                                  onClick={() =>
                                    handleRecommededSochcast(item.slug)
                                  }
                                >
                                  <Card.Img
                                    variant="top"
                                    src={
                                      item.show_compressed_image !== null
                                        ? item.show_compressed_image
                                        : item.show_image
                                    }
                                    className="f-listen-image-holder"
                                    alt={item?.name}
                                  />
                                  <Card.Body className="card-b-listen">
                                    <Card.Title className="l-title-albumn">
                                      {item.name}
                                    </Card.Title>
                                    <Card.Text className="l-author-albumn"></Card.Text>
                                  </Card.Body>
                                </Card>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    ) : (
                      <div className="text-center">
                        <img src={sochLogo} alt="Loading" />
                        <span>Loading Sochcasts...</span>
                      </div>
                    )}
                  </div>
                </LazyLoad>
              ) : null}

              {categoryShowsfive?.lenght >= 5 ? (
                <LazyLoad height={100} offset={100} once>
                  <div className="my-5">
                    <p className="sub-main-text">You may also like</p>
                    <Row>
                      <Col xs={12} sm={12} md={9}>
                        <h3 className="listener-main-text">Wisdom</h3>
                      </Col>
                    </Row>
                    {categoryShowsfive?.length !== 0 ? (
                      <Row>
                        {categoryShowsfive?.map((item) => {
                          return (
                            <Col md={2} xs={6} key={item.id}>
                              <div>
                                <Card
                                  className="playcard"
                                  onClick={() =>
                                    handleRecommededSochcast(item.slug)
                                  }
                                >
                                  <Card.Img
                                    variant="top"
                                    src={
                                      item.show_compressed_image !== null
                                        ? item.show_compressed_image
                                        : item.show_image
                                    }
                                    className="f-listen-image-holder"
                                    alt={item?.name}
                                  />
                                  <Card.Body className="card-b-listen">
                                    <Card.Title className="l-title-albumn">
                                      {item.name}
                                    </Card.Title>
                                    <Card.Text className="l-author-albumn"></Card.Text>
                                  </Card.Body>
                                </Card>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    ) : (
                      <div className="text-center">
                        <img src={sochLogo} alt="Loading" />
                        <span>Loading Sochcasts...</span>
                      </div>
                    )}
                  </div>
                </LazyLoad>
              ) : null}

              {categoryShowsix?.length >= 5 ? (
                <LazyLoad height={100} offset={100} once>
                  <div className="my-5">
                    <p className="sub-main-text">You may also like</p>
                    <Row>
                      <Col xs={12} sm={12} md={9}>
                        <h3 className="listener-main-text">
                          Society & Culture
                        </h3>
                      </Col>
                    </Row>
                    {categoryShowsix?.length !== 0 ? (
                      <Row>
                        {categoryShowsix?.map((item) => {
                          return (
                            <Col md={2} xs={6} key={item.id}>
                              <div>
                                <Card
                                  className="playcard"
                                  onClick={() =>
                                    handleRecommededSochcast(item.slug)
                                  }
                                >
                                  <Card.Img
                                    variant="top"
                                    src={
                                      item.show_compressed_image !== null
                                        ? item.show_compressed_image
                                        : item.show_image
                                    }
                                    className="f-listen-image-holder"
                                    alt={item?.name}
                                  />
                                  <Card.Body className="card-b-listen">
                                    <Card.Title className="l-title-albumn">
                                      {item.name}
                                    </Card.Title>
                                    <Card.Text className="l-author-albumn"></Card.Text>
                                  </Card.Body>
                                </Card>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    ) : (
                      <div className="text-center">
                        <img src={sochLogo} alt="Loading" />
                        <span>Loading Sochcasts...</span>
                      </div>
                    )}
                  </div>
                </LazyLoad>
              ) : null}

              {categoryShowSeven?.length >= 5 ? (
                <LazyLoad height={100} offset={100} once>
                  <div className="my-5">
                    <p className="sub-main-text">You may also like</p>
                    <Row>
                      <Col xs={12} sm={12} md={9}>
                        <h3 className="listener-main-text">Male Host</h3>
                      </Col>
                    </Row>
                    {categoryShowSeven?.length !== 0 ? (
                      <Row>
                        {categoryShowSeven?.map((item) => {
                          return (
                            <Col md={2} xs={6} key={item.id}>
                              <div>
                                <Card
                                  className="playcard"
                                  onClick={() =>
                                    handleRecommededSochcast(item.slug)
                                  }
                                >
                                  <Card.Img
                                    variant="top"
                                    src={
                                      item.show_compressed_image !== null
                                        ? item.show_compressed_image
                                        : item.show_image
                                    }
                                    className="f-listen-image-holder"
                                    alt={item?.name}
                                  />
                                  <Card.Body className="card-b-listen">
                                    <Card.Title className="l-title-albumn">
                                      {item.name}
                                    </Card.Title>
                                    <Card.Text className="l-author-albumn"></Card.Text>
                                  </Card.Body>
                                </Card>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    ) : (
                      <div className="text-center">
                        <img src={sochLogo} alt="Loading" />
                        <span>Loading Sochcasts...</span>
                      </div>
                    )}
                  </div>
                </LazyLoad>
              ) : null}

              {categoryShowEight?.length >= 5 ? (
                <LazyLoad height={100} offset={100} once>
                  <div className="my-5">
                    <p className="sub-main-text">You may also like</p>
                    <Row>
                      <Col xs={12} sm={12} md={9}>
                        <h3 className="listener-main-text">Arts</h3>
                      </Col>
                    </Row>
                    {categoryShowEight?.length !== 0 ? (
                      <Row>
                        {categoryShowEight?.map((item) => {
                          return (
                            <Col md={2} xs={6} key={item.id}>
                              <div>
                                <Card
                                  className="playcard"
                                  onClick={() =>
                                    handleRecommededSochcast(item.slug)
                                  }
                                >
                                  <Card.Img
                                    variant="top"
                                    src={
                                      item.show_compressed_image !== null
                                        ? item.show_compressed_image
                                        : item.show_image
                                    }
                                    className="f-listen-image-holder"
                                    alt={item?.name}
                                  />
                                  <Card.Body className="card-b-listen">
                                    <Card.Title className="l-title-albumn">
                                      {item.name}
                                    </Card.Title>
                                    <Card.Text className="l-author-albumn"></Card.Text>
                                  </Card.Body>
                                </Card>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    ) : (
                      <div className="text-center">
                        <img src={sochLogo} alt="Loading" />
                        <span>Loading Sochcasts...</span>
                      </div>
                    )}
                  </div>
                </LazyLoad>
              ) : null}

              {categoryShowNine?.length >= 5 ? (
                <LazyLoad height={100} offset={100} once>
                  <div className="my-5">
                    <p className="sub-main-text">You may also like</p>
                    <Row>
                      <Col xs={12} sm={12} md={9}>
                        <h3 className="listener-main-text">Path</h3>
                      </Col>
                    </Row>
                    {categoryShowNine?.length !== 0 ? (
                      <Row>
                        {categoryShowNine?.map((item) => {
                          return (
                            <Col md={2} xs={6} key={item.id}>
                              <div>
                                <Card
                                  className="playcard"
                                  onClick={() =>
                                    handleRecommededSochcast(item.slug)
                                  }
                                >
                                  <Card.Img
                                    variant="top"
                                    src={
                                      item.show_compressed_image !== null
                                        ? item.show_compressed_image
                                        : item.show_image
                                    }
                                    className="f-listen-image-holder"
                                    alt={item?.name}
                                  />
                                  <Card.Body className="card-b-listen">
                                    <Card.Title className="l-title-albumn">
                                      {item.name}
                                    </Card.Title>
                                    <Card.Text className="l-author-albumn"></Card.Text>
                                  </Card.Body>
                                </Card>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    ) : (
                      <div className="text-center">
                        <img src={sochLogo} alt="Loading" />
                        <span>Loading Sochcasts...</span>
                      </div>
                    )}
                  </div>
                </LazyLoad>
              ) : null}

              {categoryShowTen?.length >= 5 ? (
                <LazyLoad height={100} offset={100} once>
                  <div className="my-5">
                    <p className="sub-main-text">You may also like</p>
                    <Row>
                      <Col xs={12} sm={12} md={9}>
                        <h3 className="listener-main-text">International</h3>
                      </Col>
                    </Row>
                    {categoryShowTen?.length !== 0 ? (
                      <Row>
                        {categoryShowTen?.map((item) => {
                          return (
                            <Col md={2} xs={6} key={item.id}>
                              <div>
                                <Card
                                  className="playcard"
                                  onClick={() =>
                                    handleRecommededSochcast(item.slug)
                                  }
                                >
                                  <Card.Img
                                    variant="top"
                                    src={
                                      item.show_compressed_image !== null
                                        ? item.show_compressed_image
                                        : item.show_image
                                    }
                                    className="f-listen-image-holder"
                                    alt={item?.name}
                                  />
                                  <Card.Body className="card-b-listen">
                                    <Card.Title className="l-title-albumn">
                                      {item.name}
                                    </Card.Title>
                                    <Card.Text className="l-author-albumn"></Card.Text>
                                  </Card.Body>
                                </Card>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    ) : (
                      <div className="text-center">
                        <img src={sochLogo} alt="Loading" />
                        <span>Loading Sochcasts...</span>
                      </div>
                    )}
                  </div>
                </LazyLoad>
              ) : null}

              {/* All shows ends */}
            </div>
            <div className="audio-player-holder container">
              {sinlgeEpisodeStatus ? (
                <CustomAudioPlayer
                  item={selectedSingleEpisodeToPlay}
                  handleLikedEpisode={handleLikedEpisode}
                  handleRemoveLikedEpisode={handleRemoveLikedEpisode}
                />
              ) : (
                <CustomAudioPlayer
                  item={selectedEpisodeToPlay}
                  handleLikedEpisode={handleLikedEpisode}
                  handleRemoveLikedEpisode={handleRemoveLikedEpisode}
                />
              )}
            </div>
          </Container>
        ) : (
          <div className="loading">
            <img src={sochLogo} alt="Loading" />
            <span>Loading Sochcasts...</span>
          </div>
        )}

        <ReportAnIssue show={modalShow} onHide={() => setModalShow(false)} />
        <SocialShare
          show={socialModalShow}
          onHide={() => setSocialModalShow(false)}
          socialShareData={shareEpisodeData}
        />

        <ShowSocialShare
          show={socialModalOnlyShow}
          onHide={() => setSocialModalOnlyShow(false)}
          socialShareData={episodeData}
        />

        <AddToPlaylist
          show={playListModalShow}
          onHide={() => setPlayListModalShow(false)}
          episodeID={episodeIDForPlayList}
        />

        <PlaylistPopup
          show={playlistPopupModalShow}
          onHide={() => setPlaylistPopupModalShow(false)}
          episodeID={episodePlayListID}
        />
      </div>
    </>
  );
};
