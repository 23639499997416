import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TopHeader } from "../../../components/header/TopHeader";
import { CommonTitle } from "../../../components/CommonTitle";
import ChannelIcon from "../../../assets/images/Group1787.png";
import { Row, Col } from "react-bootstrap";
import apiInstance from "../../../api";
import { accessToken } from "../../../helperFunction";
import "./reports.css";
import sochLogo from "../../../assets/images/sochlogo.png";
import { HiTrendingDown, HiTrendingUp } from "react-icons/hi";
import Select from "react-select";
// import { EpisodeListens } from "../../episode/reports/EpisodeListens";
import { Header } from "../../../components/header/Header";
import ReactTooltip from "react-tooltip";

export const ShowAnalytics = () => {
  const { showID } = useParams();
  const [showData, setShowData] = useState([]);
  const [showAllListens, setShowAllListens] = useState([]);
  const [filterDateNumber, setFilterDateNumber] = useState(7);

  useEffect(() => {
    async function getShowData() {
      await apiInstance
        .get(`/shows/${showID}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setShowData(res.data.payload);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            if (err.response.data.error_type === "InvalidToken") {
              accessToken();
              getShowData();
            }
          }
        });
    }

    async function getAllListen() {
      apiInstance
        .get(
          `/management/show-analytics?time_delta=${filterDateNumber}&show_id=${showID}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setShowAllListens(res.data);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            if (err.response.data.error_type === "InvalidToken") {
              accessToken();
              getAllListen();
            }
          }
        });
    }
    getShowData();
    getAllListen();
  }, [filterDateNumber]);

  const handleChange = (selectedOption) => {
    setFilterDateNumber(selectedOption.value);
  };

  const options = [
    { value: "7", label: "7 Days" },
    { value: "15", label: "15 Days" },
    { value: "30", label: "30 Days" },
    { value: "60", label: "60 Days" },
  ];

  return (
    <div className="app">
      <Header />
      <main>
        <TopHeader name="Shows Analytics" />
        <div className="p-5 mainContainerAdd">
          <CommonTitle
            heading={`Analytics`}
            text="Understand Performance And Growth So You Can Improve As You Go"
            icons={ChannelIcon}
          />

          {Object.keys(showData).length !== 0 ? (
            <>
              <Row>
                <Col md={8}>
                  <div className="channel-form mt-5">
                    <Row className="mx-4 py-5" style={{ height: "170px" }}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div>
                          <img
                            src={showData.show_image}
                            alt="episode"
                            className="img-fluid pi-analuy-iomg"
                          />
                          <div className="ep-anlyutics-title">
                            <h3>{showData.name}</h3>
                            <p>{showAllListens?.show_rss_link}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="channel-form mt-5">
                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="py-5"
                      >
                        <div
                          className="text-center"
                          data-tip
                          data-for="alltimeListen"
                        >
                          <div>
                            <span className="alllistennumber">
                              {showAllListens.show_all_listens}
                            </span>
                          </div>
                          <p className="yourlistenstext">
                            Your all time listens are displayed here
                          </p>
                          <ReactTooltip
                            id="alltimeListen"
                            place="top"
                            effect="solid"
                          >
                            Promote more to increase this number
                          </ReactTooltip>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <div className="channel-form mt-5">
                <Row className="mx-4 py-5">
                  <Col xs={6} sm={3} md={3} lg={3} xl={3}>
                    <Row>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className="color-box listems">
                          <HiTrendingUp />
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        data-tip
                        data-for="totalL"
                      >
                        <div className="count-analytis">
                          <p>{showAllListens.show_all_listens}</p>
                          <span>Total listens</span>
                        </div>
                        <ReactTooltip id="totalL" place="top" effect="solid">
                          Here's how many listened to your content
                        </ReactTooltip>
                      </Col>
                    </Row>
                  </Col>

                  {showAllListens.listen_growth > 0 ? (
                    <Col
                      xs={6}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      data-tip
                      data-for="gLis"
                    >
                      <Row>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="color-box listems">
                            <HiTrendingUp />
                          </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="count-analytis">
                            <p>{showAllListens.listen_growth}</p>
                            <span>Growth listens</span>
                          </div>
                        </Col>
                      </Row>
                      <ReactTooltip id="gLis" place="top" effect="solid">
                        How much your listens have grown since the last time
                      </ReactTooltip>
                    </Col>
                  ) : (
                    <Col
                      xs={6}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      data-tip
                      data-for="gLis"
                    >
                      <Row>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="color-box growth">
                            <HiTrendingDown />
                          </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="count-analytis">
                            <p>{showAllListens.listen_growth}</p>
                            <span>Growth listens</span>
                          </div>
                        </Col>
                      </Row>
                      <ReactTooltip id="gLis" place="top" effect="solid">
                        How much your listens have grown since the last time
                      </ReactTooltip>
                    </Col>
                  )}

                  <Col
                    xs={6}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    data-tip
                    data-for="uLIs"
                  >
                    <Row>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className="color-box unique">
                          <HiTrendingUp />
                        </div>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className="count-analytis">
                          <p>{showAllListens.show_unique_listener_count}</p>
                          <span>Unique listeners</span>
                        </div>
                      </Col>
                    </Row>
                    <ReactTooltip id="uLIs" place="top" effect="solid">
                      Unique individuals who listened to your content
                    </ReactTooltip>
                  </Col>

                  {showAllListens.listen_growth_prct > 0 ? (
                    <Col
                      xs={6}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      data-tip
                      data-for="guLIs"
                    >
                      <Row>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="color-box listems">
                            <HiTrendingUp />
                          </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="count-analytis">
                            <p>{showAllListens.listen_growth_prct}</p>
                            <span>Growth Unique listens</span>
                          </div>
                        </Col>
                      </Row>
                      <ReactTooltip id="guLIs" place="top" effect="solid">
                        How much your listeners have grown since the last time
                      </ReactTooltip>
                    </Col>
                  ) : (
                    <Col
                      xs={6}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      data-tip
                      data-for="guLIs"
                    >
                      <Row>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="color-box growth-4">
                            <HiTrendingDown />
                          </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="count-analytis">
                            <p>{showAllListens.listen_growth_prct}</p>
                            <span>Growth Unique listens</span>
                          </div>
                        </Col>
                      </Row>
                      <ReactTooltip id="guLIs" place="top" effect="solid">
                        How much your listeners have grown since the last time
                      </ReactTooltip>
                    </Col>
                  )}
                </Row>
              </div>

              <div className="channel-form mt-5">
                <Row className="mx-4 py-5">
                  <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <p className="time-rang">
                      Select time range to display analytics
                    </p>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    className="drop-filter-react"
                  >
                    <Select
                      defaultValue={{ label: "7 Days", value: 7 }}
                      onChange={handleChange}
                      options={options}
                      isSearchable={false}
                      isRtl={false}
                    />
                  </Col>
                </Row>
              </div>

              {/* <div className="channel-form mt-5">
                <Row className="mx-4 py-5">
                  <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <p className="time-rang">Episode Listens</p>
                    <p className="time-rang submto">
                      The number of listens (episode wise)
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
                  <Col>
                    <div className="mt-3">
                      <EpisodeListens />
                    </div>
                  </Col>
                </Row>
              </div> */}
            </>
          ) : (
            <div className="loading">
              <img src={sochLogo} alt="Loading" />{" "}
              <span>Loading Sochcasts...</span>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};
