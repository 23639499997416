import React, { useState, useEffect } from "react";
import { keepTheme } from "../../utils/listenerTheme/Theme";
import { TopHeaderNavigation } from "../../components/listener/TopHeaderNavigation";
import { Col, Row, Card, Container } from "react-bootstrap";
import apiInstance from "../../api";
import { useHistory } from "react-router-dom";
import LazyLoad from "react-lazyload";
import sochLogo from "../../assets/images/sochlogo.png";

export const AllCategories = () => {
  const [themeStatus, setThemeStatus] = useState("theme-light");
  const [categories, setCategories] = useState([]);
  // const [allShows, setAllShows] = useState([]);
  // const [count, setCount] = useState(0);
  // const [minCount, setMinCount] = useState(0);

  const [categoryShowsOne, setCategoryShowsOne] = useState([]);
  const [categoryShowsTwo, setCategoryShowsTwo] = useState([]);
  const [categoryShowsThree, setCategoryShowsThree] = useState([]);
  const [categoryShowsFour, setCategoryShowsFour] = useState([]);
  const [categoryShowsfive, setCategoryShowsFive] = useState([]);
  const [categoryShowsix, setCategoryShowsSix] = useState([]);
  const [categoryShowSeven, setCategoryShowsSeven] = useState([]);
  const [categoryShowEight, setCategoryShowsEight] = useState([]);
  const [categoryShowNine, setCategoryShowsNine] = useState([]);
  const [categoryShowTen, setCategoryShowsTen] = useState([]);
  // const [categoryShowEleven, setCategoryShowsEleven] = useState([]);
  // const [categoryShowTwelve, setCategoryShowsTwelve] = useState([]);
  // const [categoryShowThirteen, setCategoryShowsThirteen] = useState([]);
  // const [categoryShowFourteen, setCategoryShowsFourteen] = useState([]);

  const history = useHistory();

  useEffect(() => {
    keepTheme();
    if (localStorage.getItem("theme") === "theme-dark") {
      setThemeStatus("theme-dark");
    } else if (localStorage.getItem("theme") === "theme-light") {
      setThemeStatus("theme-light");
    }

    async function getAllCategories() {
      let nextUrl = "/listener/categories";
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFunc() {
      let temp1 = await getAllCategories();
      setCategories(temp1);
    }

    // apiInstance.get("/listener/shows").then((res) => {
    //   if (res.status === 200) {
    //     // setCount(Math.floor(Math.random() * (res.data.count - 0) + 1) + 0);
    //     setCount(res.data.count);
    //     if (res.data.count > 0 && res.data.count > 81) {
    //       if (
    //         res.data.count ===
    //         Math.floor(Math.random() * (res.data.count - 0) + 1) + 0
    //       ) {
    //         setMinCount(
    //           Math.floor(Math.random() * (res.data.count - 0) + 1) + 0 - 30
    //         );
    //       } else {
    //         setMinCount(
    //           Math.floor(Math.random() * (res.data.count - 0) + 1) + 0 + 30
    //         );
    //       }
    //     }
    //   }
    // });

    // async function getAllShows() {
    //   let nextUrl = "/listener/shows";
    //   let temp = [];
    //   while (nextUrl !== null) {
    //     const response = await apiInstance.get(nextUrl);
    //     nextUrl = response.data.next;
    //     temp.push(...response.data.results);
    //   }
    //   return temp;
    // }

    // async function tempFunc3() {
    //   let temp1 = await getAllShows();
    //   setAllShows(temp1);
    // }

    // tempFunc3();

    tempFunc();

    async function getAllCategoryShows() {
      let nextUrl = `/listener/categories/305`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catOne() {
      let temp1 = await getAllCategoryShows();
      setCategoryShowsOne(temp1);
    }

    async function getAllCategoryShows2() {
      let nextUrl = `/listener/categories/214`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catTwo() {
      let temp1 = await getAllCategoryShows2();
      setCategoryShowsTwo(temp1);
    }

    async function getAllCategoryShows3() {
      let nextUrl = `/listener/categories/203`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catThree() {
      let temp1 = await getAllCategoryShows3();
      setCategoryShowsThree(temp1);
    }

    async function getAllCategoryShows4() {
      let nextUrl = `/listener/categories/309`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catFour() {
      let temp1 = await getAllCategoryShows4();
      setCategoryShowsFour(temp1);
    }

    async function getAllCategoryShows5() {
      let nextUrl = `/listener/categories/307`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catFive() {
      let temp1 = await getAllCategoryShows5();
      setCategoryShowsFive(temp1);
    }

    async function getAllCategoryShows6() {
      let nextUrl = `/listener/categories/209`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catSix() {
      let temp1 = await getAllCategoryShows6();
      setCategoryShowsSix(temp1);
    }

    async function getAllCategoryShows7() {
      let nextUrl = `/listener/categories/218`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catSeven() {
      let temp1 = await getAllCategoryShows7();
      setCategoryShowsSeven(temp1);
    }

    async function getAllCategoryShows8() {
      let nextUrl = `/listener/categories/195`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catEight() {
      let temp1 = await getAllCategoryShows8();
      setCategoryShowsEight(temp1);
    }

    async function getAllCategoryShows9() {
      let nextUrl = `/listener/categories/308`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catNine() {
      let temp1 = await getAllCategoryShows9();
      setCategoryShowsNine(temp1);
    }

    async function getAllCategoryShows10() {
      let nextUrl = `/listener/categories/301`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function catTen() {
      let temp1 = await getAllCategoryShows10();
      setCategoryShowsTen(temp1);
    }

    // async function getAllCategoryShows11() {
    //   let nextUrl = `/listener/categories/88`;
    //   let temp = [];
    //   while (nextUrl !== null) {
    //     const response = await apiInstance.get(nextUrl);
    //     nextUrl = response.data.next;
    //     temp.push(...response.data.results);
    //   }
    //   return temp;
    // }

    // async function catEleven() {
    //   let temp1 = await getAllCategoryShows11();
    //   setCategoryShowsEleven(temp1);
    // }

    // async function getAllCategoryShows12() {
    //   let nextUrl = `/listener/categories/72`;
    //   let temp = [];
    //   while (nextUrl !== null) {
    //     const response = await apiInstance.get(nextUrl);
    //     nextUrl = response.data.next;
    //     temp.push(...response.data.results);
    //   }
    //   return temp;
    // }

    // async function catTwelve() {
    //   let temp1 = await getAllCategoryShows12();
    //   setCategoryShowsTwelve(temp1);
    // }

    // async function getAllCategoryShows13() {
    //   let nextUrl = `/listener/categories/31`;
    //   let temp = [];
    //   while (nextUrl !== null) {
    //     const response = await apiInstance.get(nextUrl);
    //     nextUrl = response.data.next;
    //     temp.push(...response.data.results);
    //   }
    //   return temp;
    // }

    // async function catThirteen() {
    //   let temp1 = await getAllCategoryShows13();
    //   setCategoryShowsThirteen(temp1);
    // }

    // async function getAllCategoryShows14() {
    //   let nextUrl = `/listener/categories/20`;
    //   let temp = [];
    //   while (nextUrl !== null) {
    //     const response = await apiInstance.get(nextUrl);
    //     nextUrl = response.data.next;
    //     temp.push(...response.data.results);
    //   }
    //   return temp;
    // }

    // async function catFourteen() {
    //   let temp1 = await getAllCategoryShows14();
    //   setCategoryShowsFourteen(temp1);
    // }

    catOne();
    catTwo();
    catThree();
    catFour();
    catFive();
    catSix();
    catSeven();
    catEight();
    catNine();
    catTen();
    // catEleven();
    // catTwelve();
    // catThirteen();
    // catFourteen();
  }, []);

  const handleCategoryShow = (categoryId) => {
    history.push(`/category/${categoryId}`);
  };

  const handleRecommededSochcast = (id) => {
    history.push(`/show/${id}`);
  };

  return (
    <>
      <div className={themeStatus}>
        <TopHeaderNavigation
          changeTheme={(themeStatus) => setThemeStatus(themeStatus)}
          upgrade="home"
        />
        <Container>
          <div className="my-5">
            <p className="sub-main-text">For you</p>
            <Row>
              <Col xs={12} sm={12} md={9}>
                <h3 className="listener-main-text">Popular Communities</h3>
              </Col>
            </Row>

            <Row>
              {categories?.map((item) => {
                return (
                  <Col
                    className="mb-3"
                    key={item.id}
                    onClick={() => handleCategoryShow(item.id)}
                    xs={6}
                    md={2}
                  >
                    <div
                      className={`common-cat-listener pop-cat-${item.id} all`}
                    >
                      <h4 className="cat-text py-4 px-3">{item.name}</h4>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>

          {categoryShowsOne?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Mind-Stimulating</h3>
                  </Col>
                </Row>
                {categoryShowsOne?.length !== 0 ? (
                  <Row>
                    {categoryShowsOne?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <>
                            <Card
                              className="playcard"
                              onClick={() => handleRecommededSochcast(item.id)}
                            >
                              <Card.Img
                                variant="top"
                                src={item.show_image}
                                className="f-listen-image-holder"
                                alt={item.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowsTwo?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Women-Led</h3>
                  </Col>
                </Row>
                {categoryShowsTwo?.length !== 0 ? (
                  <Row>
                    {categoryShowsTwo?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() => handleRecommededSochcast(item.id)}
                            >
                              <Card.Img
                                variant="top"
                                src={item.show_image}
                                className="f-listen-image-holder"
                                alt={item.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowsThree?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Kids & Family</h3>
                  </Col>
                </Row>
                {categoryShowsThree?.length !== 0 ? (
                  <Row>
                    {categoryShowsThree?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() => handleRecommededSochcast(item.id)}
                            >
                              <Card.Img
                                variant="top"
                                src={item.show_image}
                                className="f-listen-image-holder"
                                alt={item.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowsFour?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Success</h3>
                  </Col>
                </Row>
                {categoryShowsFour?.length !== 0 ? (
                  <Row>
                    {categoryShowsFour?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() => handleRecommededSochcast(item.id)}
                            >
                              <Card.Img
                                variant="top"
                                src={item.show_image}
                                className="f-listen-image-holder"
                                alt={item.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowsfive?.lenght >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Wisdom</h3>
                  </Col>
                </Row>
                {categoryShowsfive?.length !== 0 ? (
                  <Row>
                    {categoryShowsfive?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() => handleRecommededSochcast(item.id)}
                            >
                              <Card.Img
                                variant="top"
                                src={item.show_image}
                                className="f-listen-image-holder"
                                alt={item.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowsix?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Society & Culture</h3>
                  </Col>
                </Row>
                {categoryShowsix?.length !== 0 ? (
                  <Row>
                    {categoryShowsix?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() => handleRecommededSochcast(item.id)}
                            >
                              <Card.Img
                                variant="top"
                                src={item.show_image}
                                className="f-listen-image-holder"
                                alt={item.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowSeven?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Male Host</h3>
                  </Col>
                </Row>
                {categoryShowSeven?.length !== 0 ? (
                  <Row>
                    {categoryShowSeven?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() => handleRecommededSochcast(item.id)}
                            >
                              <Card.Img
                                variant="top"
                                src={item.show_image}
                                className="f-listen-image-holder"
                                alt={item.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowEight?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Arts</h3>
                  </Col>
                </Row>
                {categoryShowEight?.length !== 0 ? (
                  <Row>
                    {categoryShowEight?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() => handleRecommededSochcast(item.id)}
                            >
                              <Card.Img
                                variant="top"
                                src={item.show_image}
                                className="f-listen-image-holder"
                                alt={item.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowNine?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">Path</h3>
                  </Col>
                </Row>
                {categoryShowNine?.length !== 0 ? (
                  <Row>
                    {categoryShowNine?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() => handleRecommededSochcast(item.id)}
                            >
                              <Card.Img
                                variant="top"
                                src={item.show_image}
                                className="f-listen-image-holder"
                                alt={item.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}

          {categoryShowTen?.length >= 5 ? (
            <LazyLoad height={100} offset={100} once>
              <div className="my-5">
                <p className="sub-main-text">You may also like</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">International</h3>
                  </Col>
                </Row>
                {categoryShowTen?.length !== 0 ? (
                  <Row>
                    {categoryShowTen?.map((item) => {
                      return (
                        <Col md={2} xs={6} key={item.id}>
                          <div>
                            <Card
                              className="playcard"
                              onClick={() => handleRecommededSochcast(item.id)}
                            >
                              <Card.Img
                                variant="top"
                                src={item.show_image}
                                className="f-listen-image-holder"
                                alt={item.name}
                              />
                              <Card.Body className="card-b-listen">
                                <Card.Title className="l-title-albumn">
                                  {item.name}
                                </Card.Title>
                                <Card.Text className="l-author-albumn"></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="text-center">
                    <img src={sochLogo} alt="Loading" />
                    <span>Loading Sochcasts...</span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ) : null}
        </Container>
      </div>
    </>
  );
};
