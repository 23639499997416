import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../listener.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useHistory } from "react-router-dom";
import apiInstance from "../../api";

export const Banner = () => {
  const handleDragStart = (e) => e.preventDefault();
  const [orginalShows, setOriginalShows] = useState([]);

  const history = useHistory();

  useEffect(() => {
    apiInstance
      .get("/listener/sochcast-originals")
      .then((response) => {
        setOriginalShows(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let items = [];

  const handleBannerClick = (slug) => {
    history.push(`/show/${slug}`);
  };

  for (let i = 0; i <= orginalShows?.length - 1; i++) {
    let imgTag = (
      <img
        src={orginalShows[i]?.show_image}
        onDragStart={handleDragStart}
        alt={orginalShows[i]?.name}
        className="img-fluid banner-image-slide-home-page"
        onClick={() => handleBannerClick(orginalShows[i]?.slug)}
        style={{ cursor: "pointer" }}
      />
    );
    items.push(imgTag);
  }

  return (
    <>
      <Row className="m-5 p-5 banner-main-row">
        <Col xs={12} sm={12} md={5} lg={5} xl={5} className="banner-text-col">
          <div className="banner-text">
            <h3>
              <span>Thousands of hours </span>of 'Soch' across 9+ Indian
              languages.
            </h3>
            <h5>Apni Soch Duniya ko Sunao aur Suno Duniya ki Soch</h5>
          </div>
        </Col>
        <Col xs={12} sm={12} md={{ span: 5, offset: 2 }} lg={{ span: 4, offset: 2 }} xl={4}>
          <div className="text-center">
            <AliceCarousel
              mouseTracking
              items={items}
              autoPlay="true"
              animationDuration="1000"
              autoPlayInterval="2000"
              infinite="true"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
