import React, { useState, Suspense, lazy } from "react";
import { LandingPageNav } from "../components/landingpage/LandingPageNav";
import { Banner } from "./banner/Banner";
// import { Library } from "./library/Library";
// import { Category } from "./category/Category";
// import { Footer } from "./footer/Footer";
// import { CopyRight } from "./copyright/CopyRight";
// import { BestSochcastService } from "./bestsochcastservice/BestSochcastSerivce";
// import { GetAllAccess } from "./getallaccess/GetAllAccess";
// import { Testimonial } from "./testimonial/Testimonial";
// import GetStarted from "./footer/GetStarted";
import { Row, Col, Button } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import SochLogo from "../assets/images/sochlogo.png";
import { HelmetMetaData } from "../components/HelmetMetaData";

const Library = lazy(() => import("./library/Library"));
const BestSochcastService = lazy(() =>
  import("./bestsochcastservice/BestSochcastSerivce")
);
const Category = lazy(() => import("./category/Category"));
const Testimonial = lazy(() => import("./testimonial/Testimonial"));
const GetAllAccess = lazy(() => import("./getallaccess/GetAllAccess"));
const GetStarted = lazy(() => import("./footer/GetStarted"));
const Footer = lazy(() => import("./footer/Footer"));
const CopyRight = lazy(() => import("./copyright/CopyRight"));

export const LandingPage = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <HelmetMetaData
        sTitle="Sochcast | The Voice, Thought, Space for Women"
        sDescription="Sochcast is a leading Audio content  (sochcast) distribution, creation and hosting platform for Women"
      />
      <LandingPageNav />
      <Banner />
      <Suspense fallback={<div>Loading</div>}>
        <Library />
        <BestSochcastService />
        <Category />
        <Testimonial />
        <GetAllAccess />
        <GetStarted />
        <Footer />
        <CopyRight />
      </Suspense>

      {visible === true ? null : (
        <div className="d-block d-sm-none mobile-holder-app">
          <Row>
            <Col xs={2} className="close-icon">
              <AiFillCloseCircle
                className="mt-2"
                onClick={() => setVisible(true)}
              />
            </Col>
            <Col xs={8}>
              <p className="m-0">
                Download our <b>Mobile App</b> for a better experience
              </p>
            </Col>
            <Col xs={2}>
              <img src={SochLogo} alt="Sochcast" className="img-fluid" />
            </Col>
            <Col xs={{ span: 6, offset: 4 }} className="p-1">
              <Button className="download-app-button">
                <a
                  href="https://play.google.com/store/apps/details?id=com.sochcast.app.sochcast"
                  without
                  rel="noreferrer"
                  target="_blank"
                  className="link-down-app"
                >
                  Download Now
                </a>
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
