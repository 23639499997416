import React from "react";
import { Button } from "react-bootstrap";

export const CommonButton = ({ onClick, type, name, ...props }) => {
  const myClass = `button-${type}`;

  return (
    <Button className={myClass} onClick={onClick}>
      {name}
    </Button>
  );
};
