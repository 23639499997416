import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import "../css/dashboard/dashboard.css";
import { ReactComponent as ChannelSVG } from "../../assets/images/Group643.svg";
import { ReactComponent as ShowsSVG } from "../../assets/images/albums-outline.svg";
import { ReactComponent as EpisodesSVG } from "../../assets/images/musical-notes-outline.svg";
// import { SochCastPro } from "../../components/sochcastPro/SochCastPro";
import { Link } from "react-router-dom";
import { accessToken } from "../../helperFunction";
import apiInstance from "../../api";

export const Dashboard = () => {
  const [channelFlag, setChannelFlag] = useState(false);
  const [channelCount, setChannelCount] = useState(0);
  const [showFlag, setShowFlag] = useState(false);
  const [showCount, setShowCount] = useState(0);
  const [episodeFlag, setEpisodeFlag] = useState(false);
  const [episodeCount, setEpisodeCount] = useState(0);

  useEffect(() => {
    async function getAllCount() {
      await apiInstance
        .get("/shows/dashboard", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((res) => {
          if (res.data.payload.channels >= 1) {
            setChannelFlag(true);
            setChannelCount(res.data.payload.channels);
          }

          if (res.data.payload.shows >= 1) {
            setShowFlag(true);
            setShowCount(res.data.payload.shows);
          }

          if (res.data.payload.episodes >= 1) {
            setEpisodeFlag(true);
            setEpisodeCount(res.data.payload.episodes);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            if (err.response.data.error_type === "InvalidToken") {
              accessToken();
              getAllCount();
            }
          }
        });
    }

    getAllCount();
  }, []);

  return (
    <>
      {/* <SochCastPro /> */}

      <Row>
        {channelFlag ? (
          <Col xs={12} md={6} lg={4} xl={4}>
            <Link to="/channel" className="count-channel">
              <h1 className="dash-text">Total Channel</h1>
              <Card className="dash-card count">
                <Card.Body>
                  <Card.Title>
                    <div className="card-content-icon text-center m-auto">
                      <ChannelSVG className="m-3 text-center" />
                    </div>
                  </Card.Title>
                  <p className="mt-5 channel-count-number">{channelCount}</p>
                  <p className="mt-1 count-text">Channels</p>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ) : (
          <Col xs={12} md={6} lg={4} xl={4}>
            <h1 className="dash-text">Channel</h1>
            <Card className="dash-card">
              <Card.Body>
                <Card.Title>
                  <div className="card-content-icon text-center m-auto">
                    <ChannelSVG className="m-3 text-center" />
                  </div>
                  <p className="mt-2">Channel</p>
                </Card.Title>
                <Card.Text className="cardText">
                  Build your own dream, or someone else will hire you to build
                  theirs.
                </Card.Text>
              </Card.Body>
              <Card.Footer className="commonButtonBackground card">
                <Link to="/channel">Create Channel</Link>
              </Card.Footer>
            </Card>
          </Col>
        )}

        {showFlag ? (
          <Col xs={12} md={6} lg={4} xl={4}>
            <Link to="/show" className="count-channel">
              <h1 className="dash-text">Total Show</h1>
              <Card className="dash-card show count count-show">
                <Card.Body>
                  <Card.Title>
                    <div className="card-content-icon text-center m-auto">
                      <ShowsSVG className="m-3 text-center" />
                    </div>
                  </Card.Title>
                  <p className="mt-5 channel-count-number">{showCount}</p>
                  <p className="mt-1 count-text">Shows</p>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ) : (
          <Col xs={12} md={6} lg={4} xl={4}>
            <h1 className="dash-text">Show</h1>
            <Card className="dash-card show">
              <Card.Body>
                <Card.Title>
                  <div className="card-content-icon text-center m-auto">
                    <ShowsSVG className="m-3 text-center" />
                  </div>
                  <p className="mt-2">Show</p>
                </Card.Title>
                <Card.Text className="cardText">
                  Build your own dream, or someone else will hire you to build
                  theirs.
                </Card.Text>
              </Card.Body>
              <Card.Footer className="commonButtonBackground card">
                <Link to="/show">Create Show</Link>
              </Card.Footer>
            </Card>
          </Col>
        )}

        {episodeFlag ? (
          <Col xs={12} md={6} lg={4} xl={4}>
            <Link to="/episode" className="count-channel">
              <h1 className="dash-text">Total Episode</h1>
              <Card className="dash-card show count count-show">
                <Card.Body>
                  <Card.Title>
                    <div className="card-content-icon text-center m-auto">
                      <ShowsSVG className="m-3 text-center" />
                    </div>
                  </Card.Title>
                  <p className="mt-5 channel-count-number">{episodeCount}</p>
                  <p className="mt-1 count-text">Episodes</p>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ) : (
          <Col xs={12} md={6} lg={4} xl={4}>
            <h1 className="dash-text">Episode</h1>
            <Card className="dash-card episode">
              <Card.Body>
                <Card.Title>
                  <div className="card-content-icon text-center m-auto">
                    <EpisodesSVG className="m-3 text-center" />
                  </div>
                  <p className="mt-2">Episode</p>
                </Card.Title>
                <Card.Text className="cardText">
                  Build your own dream, or someone else will hire you to build
                  theirs.
                </Card.Text>
              </Card.Body>
              <Card.Footer className="commonButtonBackground card">
                
                <Link to="/episode">Create Episode</Link>
                
              </Card.Footer>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};
