import apiInstance from "./api";

export const isUnlisted = [
  { key: "true", value: "Yes" },
  { key: "false", value: "No" },
];

export const isPublicPrivate = [
  { key: "public", value: "Public" },
  { key: "private", value: "Private" },
];

export const genderSelect = [
  { key: "1", value: "Man" },
  { key: "2", value: "Woman" },
  { key: "3", value: "Non-Binary" },
  { key: "4", value: "Other" },
];

export const showRatings = [
  { key: "u", value: "U" },
  { key: "u/a7+", value: "U/A 7+" },
  { key: "u/a13+", value: "U/A 13+" },
  { key: "u/a16+", value: "U/A 16+" },
  { key: "a", value: "A" },
];

export const episodeTypeDropDown = [
  { id: 0, name: "Full", title: "The complete content of your show." },
  {
    id: 1,
    name: "Bonus",
    title:
      "Extra content for your show (for example, behind-the-scenes information or interviews with the cast) or cross-promotional content for another show. Bonus episodes can be available to anyone or only to paid subscribers.",
  },
  {
    id: 2,
    name: "Trailer",
    title:
      "A short, promotional piece of content that represents a preview of your current show",
  },
];

export const accessToken = async function getAccessToken() {
  await apiInstance
    .post("/token/refresh/", {
      refresh: `${localStorage.getItem("refresh")}`,
    })
    .then((response) => {
      localStorage.removeItem("access");
      localStorage.setItem("access", response.data.access);
    })
    .catch((error) => {
      if (error.response.status === 401) {
        if (error.response.data.error_type === "InvalidToken") {
          if (
            error.response.data.errors[0].message ===
            "Token is invalid or expired"
          ) {
            // const history = useHistory();
            localStorage.clear();
            window.location = "/signin";
            // history.push("/signin");
          }
        }
      }
    });
};
