import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../listener.css";
import { BiChevronRight } from "react-icons/bi";
import apiInstance from "../../api";
import { useHistory } from "react-router-dom";

export const TopChart = () => {
  const [topCharts, setTopCharts] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function getTopCharts() {
      let nextUrl = "/listener/top-charts";
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFunc() {
      let temp1 = await getTopCharts();
      setTopCharts(temp1);
    }

    tempFunc();
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
    smallDevices: {
      breakpoint: { max: 320, min: 0 },
      items: 1,
    },
  };

  const handleViewAllTopChart = () => {
    history.push("/top-charts");
  };

  const handleShowEpisode = (slug) => {
    history.push(`/show/${slug}`);
    // console.log(id)
  };

  return (
    <>
      <div className="my-5">
        <p className="sub-main-text">For you</p>
        <Row>
          <Col xs={12} sm={12} md={9}>
            <h3 className="listener-main-text">Top Chart</h3>
          </Col>
          <Col xs={12} sm={12} md={3}>
            <p className="view-all" onClick={handleViewAllTopChart}>
              View all
              <BiChevronRight />
            </p>
          </Col>
        </Row>

        <Carousel responsive={responsive}>
          {topCharts?.map((item) => {
            return (
              <div key={item.id}>
                <Card
                  className="playcard"
                  onClick={() => handleShowEpisode(item.slug)}
                >
                  <Card.Img
                    variant="top"
                    src={
                      item.show_compressed_image !== null
                        ? item.show_compressed_image
                        : item.show_image
                    }
                    className="f-listen-image-holder"
                    alt={item.name}
                  />
                  <Card.Body className="card-b-listen">
                    <Card.Title className="l-title-albumn">
                      {item.name}
                    </Card.Title>
                    <Card.Text className="l-author-albumn text-capitalize">
                      By{" "}
                      {item.hosts[0]?.first_name +
                        " " +
                        item.hosts[0]?.last_name}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </Carousel>

        {/* <Carousel responsive={responsive}>
          {topCharts?.map((item) => {
            return (
              <div>
                <Card className="playcard topChart">
                  <div></div>
                </Card>
              </div>
            );
          })}
        </Carousel> */}
      </div>
    </>
  );
};
