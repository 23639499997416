import React, { useEffect, useState } from "react";
import { TopHeader } from "../../components/header/TopHeader";
import { Header } from "../../components/header/Header";
import { CommonTitle } from "../../components/CommonTitle";
import ChannelIcon from "../../assets/images/Group1787.png";
import { Row, Col, Button } from "react-bootstrap";
// import { EpisodeListens } from "./reports/EpisodeListens";
import { ThisSochcast } from "./reports/ThisSochcast";
// import { DayListen } from "./reports/DayListen";
// import { TopHours } from "./reports/TopHours";
import { EpisodePerformance } from "./reports/EpisodePerformance";
import apiInstance from "../../api";
import { accessToken } from "../../helperFunction";
import { useLocation } from "react-router-dom";
import sochLogo from "../../assets/images/sochlogo.png";
import "./reports/report.css";
import Select from "react-select";
import { HiTrendingDown, HiTrendingUp } from "react-icons/hi";
import { FiDownload } from "react-icons/fi";
import ReactTooltip from "react-tooltip";

export const Analytics = () => {
  let location = useLocation();

  const [episodeAllListens, setEpisodeAllListens] = useState([]);
  const [filterDateNumber, setFilterDateNumber] = useState(7);

  useEffect(() => {
    async function getAllListen() {
      apiInstance
        .get(
          `/management/episode-analytics?time_delta=${filterDateNumber}&episode_id=${location.state.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setEpisodeAllListens(res.data);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            if (err.response.data.error_type === "InvalidToken") {
              accessToken();
              getAllListen();
            }
          }
        });
    }

    getAllListen();
  }, [filterDateNumber, location.state.id]);

  const options = [
    { value: "7", label: "7 Days" },
    { value: "15", label: "15 Days" },
    { value: "30", label: "30 Days" },
    { value: "60", label: "60 Days" },
  ];

  const handleChange = (selectedOption) => {
    setFilterDateNumber(selectedOption.value);
  };

  return (
    <div className="app">
      <Header />
      <main>
        <TopHeader name="Episode Analytics" />
        <div className="p-5 mainContainerAdd">
          <CommonTitle
            heading={`Analytics`}
            text="Understand Performance And Growth So You Can Improve As You Go"
            icons={ChannelIcon}
          />

          {Object.keys(episodeAllListens).length !== 0 ? (
            <>
              <Row>
                <Col md={8}>
                  <div className="channel-form mt-5">
                    <Row className="mx-4 py-5" style={{ height: "170px" }}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div>
                          <img
                            src={location.state.episodeImage}
                            alt="episode"
                            className="img-fluid pi-analuy-iomg"
                          />
                          <div className="ep-anlyutics-title">
                            <h3>{location.state.episodeName}</h3>
                            {/* <p>Education</p> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={4} data-tip data-for="ealltime">
                  <div className="channel-form mt-5">
                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="py-5"
                      >
                        <div className="text-center">
                          <div>
                            <span className="alllistennumber">
                              {episodeAllListens.episode_all_listens}
                            </span>
                          </div>
                          <p className="yourlistenstext">
                            Your all time listens are displayed here
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <ReactTooltip id="ealltime" place="top" effect="solid">
                    Promote more to increase this number
                  </ReactTooltip>
                </Col>
              </Row>

              <div className="channel-form mt-5">
                <Row className="mx-4 py-5">
                  <Col
                    xs={6}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    data-tip
                    data-for="etl"
                  >
                    <Row>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className="color-box listems">
                          <HiTrendingUp />
                        </div>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className="count-analytis">
                          <p>{episodeAllListens.episode_all_listens}</p>
                          <span>Total listens</span>
                        </div>
                      </Col>
                    </Row>
                    <ReactTooltip id="etl" place="top" effect="solid">
                      Here's how many listened to your content
                    </ReactTooltip>
                  </Col>

                  {episodeAllListens.listen_growth > 0 ? (
                    <Col
                      xs={6}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      data-tip
                      data-for="egl"
                    >
                      <Row>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="color-box listems">
                            <HiTrendingUp />
                          </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="count-analytis">
                            <p>{episodeAllListens.listen_growth}</p>
                            <span>Growth listens</span>
                          </div>
                        </Col>
                      </Row>
                      <ReactTooltip id="egl" place="top" effect="solid">
                        How much your listens have grown since the last time
                      </ReactTooltip>
                    </Col>
                  ) : (
                    <Col
                      xs={6}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      data-tip
                      data-for="egl"
                    >
                      <Row>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="color-box growth">
                            <HiTrendingDown />
                          </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="count-analytis">
                            <p>{episodeAllListens.listen_growth}</p>
                            <span>Growth listens</span>
                          </div>
                        </Col>
                      </Row>
                      <ReactTooltip id="egl" place="top" effect="solid">
                        How much your listens have grown since the last time
                      </ReactTooltip>
                    </Col>
                  )}

                  <Col
                    xs={6}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    data-tip
                    data-for="eul"
                  >
                    <Row>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className="color-box unique">
                          <HiTrendingUp />
                        </div>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className="count-analytis">
                          <p>
                            {episodeAllListens.episode_unique_listener_count}
                          </p>
                          <span>Unique listeners</span>
                        </div>
                      </Col>
                    </Row>
                    <ReactTooltip id="eul" place="top" effect="solid">
                      Unique individuals who listened to your content
                    </ReactTooltip>
                  </Col>

                  {episodeAllListens.listen_growth_prct > 0 ? (
                    <Col
                      xs={6}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      data-tip
                      data-for="egulll"
                    >
                      <Row>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="color-box listems">
                            <HiTrendingUp />
                          </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="count-analytis">
                            <p>
                              {episodeAllListens.listen_growth_prct.toFixed(2)}
                            </p>
                            <span>Growth Unique listensers</span>
                          </div>
                        </Col>
                      </Row>
                      <ReactTooltip id="egulll" place="top" effect="solid">
                        How much your listeners have grown since the last time
                      </ReactTooltip>
                    </Col>
                  ) : (
                    <Col
                      xs={6}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      data-tip
                      data-for="egulll"
                    >
                      <Row>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="color-box growth-4">
                            <HiTrendingDown />
                          </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="count-analytis">
                            <p>
                              {episodeAllListens.listen_growth_prct.toFixed(2)}
                            </p>
                            <span>Growth Unique listensers</span>
                          </div>
                        </Col>
                      </Row>
                      <ReactTooltip id="egulll" place="top" effect="solid">
                        How much your listeners have grown since the last time
                      </ReactTooltip>
                    </Col>
                  )}
                </Row>
              </div>

              <div className="channel-form mt-5">
                <Row className="mx-4 py-5">
                  <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <p className="time-rang">
                      Select time range to display analytics
                    </p>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    className="drop-filter-react"
                  >
                    <Select
                      defaultValue={{ label: "7 Days", value: 7 }}
                      onChange={handleChange}
                      options={options}
                      isSearchable={false}
                      isRtl={false}
                    />
                  </Col>
                </Row>
              </div>

              <div className="channel-form mt-5">
                <Row className="mx-4 py-5">
                  <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <p className="time-rang">This sochcast</p>
                    <p className="time-rang submto">
                      How your sochcast has performed in the last{" "}
                      {filterDateNumber ? filterDateNumber : 0} days.
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
                  <Col>
                    <div className="mt-3">
                      <ThisSochcast
                        dateNumber={filterDateNumber}
                        episodeID={location.state.id}
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              {/* <div className="channel-form mt-5">
                <Row className="mx-4 py-5">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p className="time-rang">Top Episode</p>
                    <p className="time-rang submto">
                      Top Episode listens and listeners data point
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <div className="mt-3">
                      <img
                        src={ChannelIcon}
                        alt="episode"
                        className="img-fluid pi-analuy-iomg"
                      />
                      <div className="ep-anlyutics-title">
                        <h3>Break Down</h3>
                        <p>Education</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4} className="mt-3">
                    <Row>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className="color-box listems"></div>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className="count-analytis">
                          <p>29</p>
                          <span>Total listens</span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4} className="mt-3">
                    <Row>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className="color-box listems"></div>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className="count-analytis">
                          <p>29</p>
                          <span>Total listens</span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div> */}

              {/* <div className="mt-5">
                <Row className="py-5">
                  <Col
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="channel-form pt-5"
                  >
                    <p className="time-rang">Daily listens</p>
                    <p className="time-rang submto">
                      The number of listens from 04-08-2021 to 02-09-2021
                    </p>
                    <div className="my-5">
                      <DayListen />
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="channel-form pt-5"
                  >
                    <p className="time-rang">Top Hours</p>
                    <p className="time-rang submto">Episode Wise</p>
                    <div className="my-5">
                      <TopHours />
                    </div>
                  </Col>
                </Row>
              </div> */}

              <div className="channel-form mt-5">
                <Row className="mx-4 py-5">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p className="time-rang">Episode Performance</p>
                    <p className="time-rang submto">
                      Sochcast Time Performance
                    </p>
                  </Col>

                  <Col>
                    <div className="mt-3">
                      <EpisodePerformance
                        dateNumber={filterDateNumber}
                        episodeID={location.state.id}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div className="loading">
              <img src={sochLogo} alt="Loading" />{" "}
              <span>Loading Sochcasts...</span>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};
