import React from "react";
import GoogleLogin from "react-google-login";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router";
import apiInstance from "../../api";
import { useDispatch } from "react-redux";
import { setUserName } from "../../redux/actions/setUserNameAction";

export const GoogleSignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const responseGoogle = (response) => {
    apiInstance
      .post("/google/auth/", { token: response.tokenObj.access_token })
      .then((response) => {
        if (response.status === 200) {
          getNames(
            response.data.payload.access,
            response.data.payload.first_name,
            response.data.payload.last_name
          );
          localStorage.setItem("isAuthenticated", "true");
          localStorage.setItem("access", response.data.payload.access);
          localStorage.setItem("refresh", response.data.payload.refresh);
          localStorage.setItem("first_name", response.data.payload.first_name);
          localStorage.setItem("last_name", response.data.payload.last_name);
          localStorage.setItem("email", response.data.payload.email);
          localStorage.setItem("user_id", response.data.payload.user_id);
          history.push("/listener");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function getNames(accessToken, fName, lName) {
    let first_letter = fName;
    let last_letter = lName;
    first_letter = first_letter.charAt(0);
    last_letter = last_letter.charAt(0);
    dispatch(setUserName(first_letter + last_letter));
  }

  return (
    <Row className="signinWithGoogle">
      <Col xs={12} md={12} lg={12} xl={12} className="text-center">
        <GoogleLogin
          clientId="421955354900-tnvsne0ap2hqlih6r6t97jggqp3vbloh.apps.googleusercontent.com"
          buttonText="Sign in with Google"
          cookiePolicy={"single_host_origin"}
          className="outline-primary mt-3 signupButtonWithGoogle"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
        />
      </Col>
    </Row>
  );
};
