import React from "react";
import { Navbar, Button, Container, Nav } from "react-bootstrap";
import SochLogo from "../../assets/images/sochlogo.png";
import "./landingpage.css";

export const LandingPageNav = () => {
  return (
    <>
      <Navbar bg="light" expand="lg" className="landingpage nav" sticky="top">
        <Container>
          <Navbar.Brand href="/">
            <img
              src={SochLogo}
              alt="Soch Logo"
              className="img-fluid landingpagelogo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto navtexts">
              {/* <Nav.Link
                href="/home"
                className="my-auto"
                style={{ color: "#fff" }}
              >
                Create
              </Nav.Link>
              <Nav.Link
                href="/listener"
                className="my-auto"
                style={{ color: "#fff" }}
              >
                Listen
              </Nav.Link> */}
              {/* <Nav.Link href="/" className="my-auto" style={{ color: "#fff" }}>
                Curate
              </Nav.Link>
              <Nav.Link href="/" className="my-auto" style={{ color: "#fff" }}>
                Brands & Enterprise
              </Nav.Link>
              <Nav.Link href="/" className="my-auto" style={{ color: "#fff" }}>
                Blog
              </Nav.Link> */}
              <Nav.Link href="/signin" className="my-auto">
                <Button className="landing-signin">Sign in</Button>
              </Nav.Link>
              <Nav.Link href="/signup">
                <Button className="landing-signup">Sign Up</Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
