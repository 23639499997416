import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import "../css/upgrade/upgrade.css";
import { CommonButton } from "../../components/formFields/CommonButton";

export const UpgradePlans = () => {
  return (
    <div className="mx-3 p-5">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
          <h1 className="upgradeText">Choose Your Plan</h1>
          <p className="upgradeSubText">Upgrade To Sochcast Pro</p>
        </Col>
      </Row>

      <Row>
        {/* Lite */}
        <Col xs={12} sm={12} md={4} lg={4} xl={4} className="text-center">
          <Card className="upgradePrcingCard">
            <Card.Body className="my-4">
              <Card.Title class="upgradePlanTitle">Lite</Card.Title>
              <p className="upgradePrice">
                $29<span>/ Month</span>
              </p>
              <p className="upgradePlanText">
                This is our most affordable plan to get started!
              </p>
              <ol className="upgradeOL my-5">
                <li>Unlimited sending</li>
                <li>Email marketing</li>
                <li>Send newsletters</li>
                <li>Up to 5 users</li>
              </ol>
              <CommonButton name="Get Started" type="upgradeButton" />
            </Card.Body>
          </Card>
        </Col>

        {/* Pro */}
        <Col xs={12} sm={12} md={4} lg={4} xl={4} className="text-center">
          <Card className="upgradePrcingCard pro">
            <Card.Body className="my-4">
              <Card.Title class="upgradePlanTitle">Lite</Card.Title>
              <p className="upgradePrice">
                $29<span>/ Month</span>
              </p>
              <p className="upgradePlanText">
                This is our most affordable plan to get started!
              </p>
              <ol className="upgradeOL my-5">
                <li>Unlimited sending</li>
                <li>Email marketing</li>
                <li>Send newsletters</li>
                <li>Up to 25 users</li>
              </ol>
              <CommonButton name="Get Started" type="upgradeButtonPro" />
            </Card.Body>
          </Card>
        </Col>

        {/* Ultimate */}
        <Col xs={12} sm={12} md={4} lg={4} xl={4} className="text-center">
          <Card className="upgradePrcingCard">
            <Card.Body className="my-4">
              <Card.Title class="upgradePlanTitle">Lite</Card.Title>
              <p className="upgradePrice">
                $29<span>/ Month</span>
              </p>
              <p className="upgradePlanText">
                This is our most affordable plan to get started!
              </p>
              <ol className="upgradeOL my-5">
                <li>Unlimited sending</li>
                <li>Email marketing</li>
                <li>Send newsletters</li>
                <li>Up to 5 users</li>
              </ol>
              <CommonButton name="Get Started" type="upgradeButton" />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
