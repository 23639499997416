import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { FiArrowLeftCircle } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import "./Header.css";
import { GetHelp } from "./GetHelp";
import { ReactComponent as ChannelSVG } from "../../assets/images/Group643.svg";
import { ReactComponent as DashboardSVG } from "../../assets/images/grid-outline.svg";
import { ReactComponent as ShowsSVG } from "../../assets/images/albums-outline.svg";
import { ReactComponent as EpisodesSVG } from "../../assets/images/musical-notes-outline.svg";
import { ReactComponent as HostsSVG } from "../../assets/images/people-outline.svg";
import { ReactComponent as MoniSVG } from "../../assets/images/Group1773.svg";
// import { ReactComponent as CustomizeSVG } from "../../assets/images/color-wand-outline.svg";
// import { ReactComponent as SochRoomSVG } from "../../assets/images/Group746.svg";
import { ReactComponent as LogoutSVG } from "../../assets/images/log-out.svg";
import { NavLink } from "react-router-dom";
import sochLogo from "../../assets/images/sochlogo.png";
import { useHistory } from "react-router";

export const Header = ({ toggled, handleToggleSidebar }) => {
  // const [menuCollapse, setMenuCollapse] = useState(false);
  // const [toggled, setToggled] = useState(false);

  // const menuIconClick = () => {
  //   menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  // };

  // const handleToggleSidebar = () => {
  //   handleToggleSidebar ? setToggled(false) : setToggled(true);
  // };

  const menuIconClick = () => {
    history.goBack();
  };

  const history = useHistory();
  const handleLogOut = () => {
    localStorage.clear();
    history.push("/signin");
  };

  const openSochroomTab = () => {
    window.open("https://sochroom.sochcast.com/");
  };

  return (
    <div /* style={{ position: "fixed" }} */>
      {/* <ProSidebar
        breakPoint="md"
        collapsed={menuCollapse}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
      > */}
      <ProSidebar
        breakPoint="md"
        toggled={toggled}
        onToggle={handleToggleSidebar}
      >
        <SidebarHeader>
          <div className="logotext text-center">
            {/* {menuCollapse ? (
              <img
                src={sochLogo}
                alt="Soch Logo"
                className="img-fluid collapsed"
              />
            ) : ( */}
            <img
              src={sochLogo}
              alt="Soch Logo"
              className="img-fluid expanded"
            />
            {/* )} */}
          </div>
          {/* <div className="closemenu" onClick={menuIconClick}>
            {menuCollapse ? <FiArrowRightCircle /> : <FiArrowLeftCircle />}
          </div> */}
          <div className="closemenu" onClick={menuIconClick}>
            <FiArrowLeftCircle />
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <MenuItem icon={<DashboardSVG />}>
              <NavLink to="/home" />
              Dashboard
            </MenuItem>
            <MenuItem icon={<ChannelSVG />}>
              <NavLink to="/channel" />
              Channel
            </MenuItem>
            <MenuItem icon={<ShowsSVG />}>
              <NavLink to="/show" />
              Shows
            </MenuItem>
            <MenuItem icon={<EpisodesSVG />}>
              <NavLink to="/episode" />
              Episodes
            </MenuItem>
            <MenuItem icon={<HostsSVG />}>
              <NavLink to="/host" />
              Hosts
            </MenuItem>
            <MenuItem icon={<MoniSVG />}>
              <NavLink to="/monetization" />
              Monetization
            </MenuItem>
            {/* <MenuItem icon={<SochRoomSVG />} onClick={openSochroomTab}>
              <NavLink  />
              Sochroom
            </MenuItem> */}
            {/* <MenuItem icon={<CustomizeSVG />}>Customize</MenuItem> */}
          </Menu>
          <GetHelp />
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape="square">
            <MenuItem icon={<LogoutSVG />} onClick={handleLogOut}>
              Logout
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
};
