import React from "react";
import { Field } from "formik";

export const RadioButton = (props) => {
  return (
    <>
      {props.options.map((option) => {
        return (
          <label
            style={{ cursor: "pointer" }}
            key={option.key}
            className={`${props.type === "isUnlistedPP" ? "mx-4" : "mx-2"} ${
              option.key
            }-signup formLabel ${
              props.type === "isUnlisted" ||
              props.type === "explicitContent" ||
              props.type === "abusiveContent" ||
              props.type === "isUnlistedPP"
                ? "isUnlisted"
                : ""
            }`}
          >
            <Field
              type="radio"
              name={`${
                props.type === "isUnlisted" ||
                props.type === "explicitContent" ||
                (props.type === "abusiveContent") |
                  (props.type === "isUnlistedPP")
                  ? props.type
                  : "picked"
              }`}
              value={option.value}
              defaultChecked
            />{" "}
            {option.value}
          </label>
        );
      })}
      {/* <ErrorMessage name={field.name} component="div" className="error" /> */}
    </>
  );
};
