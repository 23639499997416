import React, { useState, useContext, useCallback, useRef } from "react";
import { Col, Row, Button } from "react-bootstrap";
import "../css/channel/Channel.css";
import ChannelIcon from "../../assets/images/Group1787.png";
import { Formik, Form } from "formik";
import { InputField } from "../../components/formFields/InputField";
import * as Yup from "yup";
import { CommonTitle } from "../../components/CommonTitle";
import apiInstance from "../../api";
import { RadioButton } from "../../components/formFields/RadioButton";
import { isUnlisted } from "../../helperFunction";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { LoginContext } from "./Helper/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { accessToken } from "../../helperFunction";
import { TagsInput } from "../../components/formFields/TagsInput";
import ReactTooltip from "react-tooltip";
import { Header } from "../../components/header/Header";

export const AddNewChannel = (props) => {
  const { setFormStatus } = useContext(LoginContext);
  const [userInputTags, setUserInputTags] = useState([]);
  const [buttonDraftStatus, setButtonDraftStatus] = useState(false);
  const [buttonPublishStatus, setButtonPublishStatus] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [toggled, setToggled] = useState(false);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const initialValues = {
    isUnlisted: "",
    channelName: "",
  };

  const validate = Yup.object({
    channelName: Yup.string().required(),
  });

  const selectedTags = (tags) => {
    setUserInputTags(tags);
  };

  const handleDraft = async (values) => {
    let unListed = "";
    let isPublished = false;
    if (values.isUnlisted === "Yes") {
      unListed = "True";
    } else if (values.isUnlisted === "No") {
      unListed = "False";
    }

    if (unListed === "" || unListed === null) {
      toast.error("Please select an option for listed or unlisted", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInputTags.length < 3) {
      toast.error("Please enter atleast a minimum of 3 tags", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setButtonDraftStatus(true);
      let data = new FormData();
      data.append("name", values.channelName);
      data.append(
        "description",
        `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}`
      );
      data.append("is_unlisted", `${unListed}`);
      for (var i = 0; i < userInputTags.length; i++) {
        data.append(`tags[${i}]`, userInputTags[i]);
      }
      data.append("is_published", isPublished);

      await apiInstance
        .post(`/channels/create/`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setFormStatus(false);
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            if (err.response.data.error_type === "ValidationError") {
              toast.error(`${err.response.data.errors[0].message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          } else if (err.response.status === 401) {
            if (err.response.data.error_type === "InvalidToken") {
              accessToken();
              handleDraft();
            }
          } else {
            toast.warning(
              `Something went wrong, Please try submitting again.`,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        });
    }
  };

  const handlePublish = async (values) => {
    let unListed = "";
    let isPublished = false;
    if (values.isUnlisted === "Yes") {
      unListed = "True";
      isPublished = false;
    } else if (values.isUnlisted === "No") {
      unListed = "False";
      isPublished = true;
    }

    if (unListed === "" || unListed === null) {
      toast.error("Please select an option for listed or unlisted", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInputTags.length < 3) {
      toast.error("Please enter atleast a minimum of 3 tags", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setButtonPublishStatus(true);
      let data = new FormData();
      data.append("name", values.channelName);
      data.append(
        "description",
        `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}`
      );
      data.append("is_unlisted", `${unListed}`);
      for (var i = 0; i < userInputTags.length; i++) {
        data.append(`tags[${i}]`, userInputTags[i]);
      }
      data.append("is_published", isPublished);

      await apiInstance
        .post(`/channels/create/`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setFormStatus(false);
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            if (err.response.data.error_type === "ValidationError") {
              toast.error(`${err.response.data.errors[0].message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          } else if (err.response.status === 401) {
            if (err.response.data.error_type === "InvalidToken") {
              accessToken();
              handlePublish();
            }
          } else {
            toast.warning(
              `Something went wrong, Please try submitting again.`,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        });
    }
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  return (
    <div className="p-5 mainContainerAdd">
      <CommonTitle heading="Create An Channel" text="" icons={ChannelIcon} />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Row>
        <Col xs={12} md={12} lg={12} xl={12} className="mt-5">
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            // onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form onKeyDown={onKeyDown}>
                <div className="channel-form">
                  <Row className="mx-4 py-5">
                    <Col
                      xs={12}
                      md={10}
                      lg={10}
                      xl={10}
                      data-tip
                      data-for="channelName"
                    >
                      <InputField
                        label="Channel Name*"
                        name="channelName"
                        type="text"
                        data-testid="channel-name"
                        placeholder="Sochcast Title"
                      />
                      <ReactTooltip id="channelName" place="top" effect="solid">
                        You can have multiple channels for different subjects.
                      </ReactTooltip>
                    </Col>
                    <Col
                      xs={12}
                      md={10}
                      lg={10}
                      xl={10}
                      data-tip
                      data-for="channelDescription"
                    >
                      <label
                        for="channelDescription"
                        className="formLabel mt-3"
                      >
                        Description*
                      </label>
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="ChannelDesscription"
                        editorClassName="editorClassName"
                        toolbar={{
                          options: ["emoji", "link"],
                          emoji: { className: "emojiClassNAme" },
                          link: {
                            className: "imageClassName",
                            options: ["link"],
                          },
                        }}
                        editorState={editorState}
                        defaultEditorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                      />
                      <ReactTooltip
                        id="channelDescription"
                        place="top"
                        effect="solid"
                      >
                        Describe the channel. Add popular keywords, be accurate.
                        Get discovered
                      </ReactTooltip>
                    </Col>
                    <Col
                      xs={12}
                      md={10}
                      lg={10}
                      xl={10}
                      className="mt-3"
                      data-tip
                      data-for="cTag"
                    >
                      <label className="formLabel">
                        Tag*{" "}
                        <p className="text-muted">
                          Please enter minimum 3 tags
                        </p>
                      </label>
                      <TagsInput selectedTags={selectedTags} tags={[]} />
                      <ReactTooltip id="cTag" place="top" effect="solid">
                        Use relevant tags to get discovered
                      </ReactTooltip>
                    </Col>
                    <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                      <label className="formLabel">Is Unlisted</label>
                      <br />
                      <RadioButton options={isUnlisted} type="isUnlisted" />
                    </Col>
                    <Col xs={12} md={10} lg={10} xl={10}>
                      {values.isUnlisted === "Yes"
                        ? "The episode is not published and will not be show in the RSS."
                        : values.isUnlisted === "No"
                        ? "The episode is published and will be show in the RSS."
                        : null}
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="mt-4 chananelButton"
                  >
                    <Button
                      disabled={buttonDraftStatus}
                      variant="primary"
                      className="channelDraftButton rounded-2 mx-3"
                      title="ChannelDrafButton"
                      data-testid="signUpBtton"
                      type="submit"
                      onClick={() => handleDraft(values)}
                    >
                      {buttonDraftStatus ? "Saving ..." : "Draft"}
                    </Button>

                    <Button
                      disabled={buttonPublishStatus}
                      variant="primary"
                      className="channelPublishButton rounded-2"
                      title="channelPublishButton"
                      data-testid="signUpBtton"
                      type="submit"
                      onClick={() => handlePublish(values)}
                    >
                      {buttonPublishStatus ? "Saving ..." : "Publish"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
