import React, { useEffect, useState } from "react";
import { Header } from "../../components/header/Header";
import { TopHeader } from "../../components/header/TopHeader";
import { CommonTitle } from "../../components/CommonTitle";
import DistributionIcon from "../../assets/images/Group9834.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";
import Gmail from "../../assets/images/gmail.png";
import Globle from "../../assets/images/globe.png";
import Download from "../../assets/images/download.png";
import Stitcher from "../../assets/images/Image721.png";
import Apple from "../../assets/images/apple.png";
import Google from "../../assets/images/google.png";
import Soch from "../../assets/images/sochlogodi.png";
import Select from "react-select";
import { accessToken } from "../../helperFunction";
import apiInstance from "../../api";

import { ReactComponent as Copy } from "../../assets/images/copy-outline.svg";
import { ReactComponent as Outline } from "../../assets/images/link-outline.svg";

import "../css/channel/Channel.css";

export const ShowDistribution = () => {
  const [shows, setShows] = useState([]);
  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    async function getAllShowsList() {
      await apiInstance
        .get("/shows/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setShows(response.data.results);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (error.response.data.error_type === "InvalidToken") {
              accessToken();
              getAllShowsList();
            }
          }
        });
    }

    getAllShowsList();
  }, []);

  shows.forEach((item) => {
    delete item.abusive_content;
    delete item.categories;
    delete item.channels;
    delete item.created_at;
    delete item.description;
    delete item.explicit_content;
    delete item.hosts;
    delete item.intro;
    delete item.is_published;
    delete item.is_unlisted;
    delete item.language;
    delete item.pre_roll;
    delete item.rating;
    delete item.show_image;
    delete item.tags;
    delete item.trailer;
    delete item.updated_at;
    delete item.user;
    delete item.banner_image;
  });

  const resultShows = shows.map((elm) => ({ value: elm.id, label: elm.name }));

  const handleChangeShow = (selectedOption) => {
    // setSelectedShow(selectedOption);
    console.log(selectedOption);
    apiInstance
      .get(
        `/distribution/all-distributed-platforms?show_id=${selectedOption.value}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setPlatforms(response.data.payload);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="app">
      <Header />
      <main>
        <TopHeader name="Distribution" />
        <div className="mx-3 p-5">
          <CommonTitle
            heading={`Distribution & Social Channels`}
            text="Your Sochcast Is Available On These Platforms Find Your Sochcast Live On"
            icons={DistributionIcon}
          />

          <Row>
            <Col xs={12} md={12} lg={12} xl={12}>
              <h3 className="m-s-t">Select a Show</h3>
              <Select
                className="basic-single"
                isSearchable={true}
                options={resultShows}
                onChange={handleChangeShow}
              />
            </Col>
          </Row>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <Row className="my-5">
            <div className="channel-form">
              <div className="dis-col my-4">
                <Col xs={12} md={12} lg={12} xl={12} className="my-0">
                  <Row className="my-3">
                    <Col xs={12} md={1} lg={1} xl={1}>
                      <div className="gmail-holder ml-3">
                        <img
                          src={Gmail}
                          alt="Gmail"
                          className="img-fluid mt-2"
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={3} lg={3} xl={3}>
                      <div className="text-icon-name p-3">
                        <h3>Email</h3>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      md={8}
                      lg={8}
                      xl={8}
                      className="custonimput-distirbuition"
                    >
                      <div>
                        <InputGroup className="mb-3">
                          <InputGroup.Text>
                            <Outline />
                          </InputGroup.Text>
                          <FormControl aria-label="Amount (to the nearest dollar)" />
                          <InputGroup.Text>
                            <Copy />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>

              <div className="dis-col my-4">
                <Col xs={12} md={12} lg={12} xl={12} className="my-0">
                  <Row className="my-3">
                    <Col xs={12} md={1} lg={1} xl={1}>
                      <div className="gmail-holder ml-3">
                        <img
                          src={Globle}
                          alt="Gmail"
                          className="img-fluid mt-2"
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={3} lg={3} xl={3}>
                      <div className="text-icon-name p-3">
                        <h3>Website</h3>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      md={8}
                      lg={8}
                      xl={8}
                      className="custonimput-distirbuition"
                    >
                      <div>
                        <InputGroup className="mb-3">
                          <InputGroup.Text>
                            <Outline />
                          </InputGroup.Text>
                          <FormControl aria-label="Amount (to the nearest dollar)" />
                          <InputGroup.Text>
                            <Copy />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
            </div>
          </Row>

          {platforms?.length !== 0 ? (
            <>
              <Row>
                <Col
                  xs={12}
                  md={9}
                  lg={9}
                  xl={9}
                  className="createChannelTitle"
                >
                  <h1>Connect to Sochcast Listening Platforms & Apps</h1>
                  <p>
                    Please Enter The Sochcast Urls For Each Listening Platform
                    So That We Can Share Your Sochcast With Your Audience
                    Wherever They Prefer To Listen.
                  </p>
                </Col>
              </Row>

              <Row className="my-5">
                <div className="channel-form">
                  {Object.entries(platforms).map(([key, value], i) => (
                    <>
                      <div className="dis-col my-4">
                        <Col xs={12} md={12} lg={12} xl={12} className="my-0">
                          <Row className="my-3">
                            <Col xs={12} md={1} lg={1} xl={1}>
                              <div className="gmail-holder ml-3">
                                <img
                                  src={Soch}
                                  alt="Gmail"
                                  className="img-fluid mt-2"
                                />
                              </div>
                            </Col>
                            <Col xs={12} md={3} lg={3} xl={3}>
                              <div className="text-icon-name p-3">
                                <h3>{key}</h3>
                              </div>
                            </Col>
                            <Col
                              xs={12}
                              md={8}
                              lg={8}
                              xl={8}
                              className="custonimput-distirbuition"
                            >
                              <div>
                                <InputGroup className="mb-3">
                                  <InputGroup.Text>
                                    <Outline />
                                  </InputGroup.Text>
                                  <FormControl aria-label="Amount (to the nearest dollar)" />
                                  <InputGroup.Text>
                                    <Copy />
                                  </InputGroup.Text>
                                </InputGroup>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </div>
                    </>
                  ))}
                </div>
              </Row>
            </>
          ) : null}
        </div>
      </main>
    </div>
  );
};
