import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { BiDownload } from "react-icons/bi";
import apiInstance from "../../api";
import { accessToken } from "../../helperFunction";

export const PopupPlayer = (props) => {
  const [deletedRow, setDeletedRow] = useState(false);
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data, deletedRow]);

  const handleDownload = () => {
    console.log("download");
  };

  // const handleDeleteEpisode = (showId, episodeID) => {
  //   apiInstance
  //     .delete(`/shows/${showId}/episodes/${episodeID}/`, {
  //       headers: { Authorization: `Bearer ${localStorage.getItem("access")}` },
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         if (
  //           response.data.payload.message === "Episode deleted successfully."
  //         ) {
  //           props.onHide(false);
  //           setDeletedRow(true);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response.status === 401) {
  //         if (error.response.data.error_type === "InvalidToken") {
  //           accessToken();
  //           handleDeleteEpisode();
  //         }
  //       }
  //     });
  // };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // scrollable={true}
      dialogClassName="episode-popupplayer"
      style={{ innerWidth: 100 }}
    >
      <Modal.Header closeButton className="termsModalHeader">
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="episode-player-image mt-2 text-center">
          <div className="episode-image-holder">
            <img
              src={data.episode_image}
              alt="episode"
              className="img-fluid banner-image"
            />
          </div>
          <p className="episode-play-name">{data.name}</p>
          <p className="episode-play-name subcategorytext">{data.name}</p>
        </div>
        <div className="player">
          <AudioPlayer
            autoPlay
            src={data.file}
            // onPlay={(e) => console.log("onPlay")}
            customVolumeControls={[]}
            customAdditionalControls={[]}
          />
        </div>
      </Modal.Body>
      <Modal.Footer
        className="justify-content-center"
        style={{ display: "initial" }}
      >
        <Row>
          {/* <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <div className="delete-pop">
              <div className="inner mt-2">
                <EditIcon />
              </div>
            </div>
          </Col> */}
          {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div
              className="download-popup"
              style={{ cursor: "pointer" }}
              onclick={handleDownload}
            >
              <div className="demoasdfh akjhsdf">
                <div className="downloadICON">
                  <BiDownload
                    style={{ textAlign: "center", color: "#ffffff" }}
                  />
                </div>
                <span className="semi-download-text mt-5">Download</span>
              </div>
            </div>
          </Col> */}
          {/* <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <div className="delete-pop">
              <div className="inner mt-2">
                <DeleteIcon
                  onClick={() => handleDeleteEpisode(data.shows[0], data.id)}
                />
              </div>
            </div>
          </Col> */}
        </Row>
      </Modal.Footer>
    </Modal>
  );
};
