import React from "react";
import { Button } from "react-bootstrap";

export const GetHelp = () => {
  return (
    <div className="side-nav-get-help text-center m-4 p-4">
      <p>Go to Sochcast help center</p>
      <h3>How can We help You?</h3>
      <Button href="mailto:support@sochcast.com">Get Help</Button>
    </div>
  );
};
