import React from "react";
import "../css/landingpagemain.css";
import { Row, Col, Button } from "react-bootstrap";
import Human from "../../assets/images/women.png";
import { useHistory } from "react-router-dom";

export const Banner = () => {
  const history = useHistory();
  return (
    <>
      <section>
        <Row>
          <Col xs={12} sm={12} md={6}>
            {/* <div className="wrapper"> */}
            <img
              src={Human}
              alt="Apni Soch Duniya Ko Sunao aur Suno Duniya Ki Soch"
              className="img-fluid mt-5 human"
            />
            {/* </div> */}
          </Col>
          <Col xs={12} sm={12} md={6} className="text-button-right">
            <div className="ban-text">
              <h2 className="text-banner">
                Apni Soch Duniya Ko <br /> Sunao aur Suno <br />
                Duniya Ki Soch
              </h2>
              <p className="ban-sub-text">
                Explore and share an extensive collection of high-quality audio
                for women by creators around the world.
              </p>

              <div className="mt-4">
                <Button
                  className="start-creatingbutton"
                  onClick={() => history.push("/signup")}
                >
                  Start Creating
                </Button>
                <Button
                  className="start-listeningbutton"
                  onClick={() => history.push("/listener")}
                >
                  Start Listening
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};
