import React, { useState, useEffect } from "react";
import { TopHeader } from "../../components/header/TopHeader";
import { Header } from "../../components/header/Header";
import { Card, Row, Col, Button } from "react-bootstrap";
import "../css/channel/Channel.css";
import "../css/monetization/monetization.css";
import Switch from "react-switch";
import DollarSign from "../../assets/images/Group9536.png";
import { DisableMonetization } from "./DisableMonetization";
import Select from "react-select";
import apiInstance from "../../api";
import { accessToken } from "../../helperFunction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";
import DataTable from "react-data-table-component";
import { ReactComponent as DeleteIcon } from "../../assets/images/DeleteIcon.svg";
import ReactTooltip from "react-tooltip";

export const Monetization = () => {
  const [deletedRow, setDeletedRow] = useState(false);
  useEffect(() => {
    getAllShowsList();
    getAllMonetizationBrands();
    getAllMonetization();
  }, [deletedRow]);

  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [disableMonetizationStatus, setDisableMonetizationStatus] =
    useState(true);
  const [addnewMonetizationCardStatus, setAddnewMonetizationCardStatus] =
    useState(false);
  const [addMonetizationStatus, setAddMonetizationStatus] = useState(false);
  const [shows, setShows] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedShow, setSelectedShow] = useState([]);
  const [mone, setMone] = useState([]);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    setAddnewMonetizationCardStatus(true);

    if (checked === true) {
      setDisableMonetizationStatus(false);
      setAddnewMonetizationCardStatus(false);
      setAddMonetizationStatus(false);
      if (disableMonetizationStatus === false) {
        setModalShow(true);
      }
    }
  };

  const handleAddMonetization = () => {
    setAddnewMonetizationCardStatus(false);
    setAddMonetizationStatus(true);
  };

  async function getAllShowsList() {
    await apiInstance
      .get("/shows/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        setShows(response.data.results);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (error.response.data.error_type === "InvalidToken") {
            accessToken();
            getAllShowsList();
          }
        }
      });
  }

  async function getAllMonetizationBrands() {
    apiInstance
      .get("/monetization/brands")
      .then((response) => {
        if (response.status === 200) {
          setBrands(response.data.results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllMonetization() {
    await apiInstance
      .get("/monetization/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.count >= 1) {
            setChecked(true);
            setAddnewMonetizationCardStatus(true);
            setMone(response.data.results);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  shows.forEach((item) => {
    delete item.abusive_content;
    delete item.categories;
    delete item.channels;
    delete item.created_at;
    delete item.description;
    delete item.explicit_content;
    delete item.hosts;
    delete item.intro;
    delete item.is_published;
    delete item.is_unlisted;
    delete item.language;
    delete item.pre_roll;
    delete item.rating;
    delete item.show_image;
    delete item.tags;
    delete item.trailer;
    delete item.updated_at;
    delete item.user;
    delete item.banner_image;
  });

  const resultShows = shows.map((elm) => ({ value: elm.id, label: elm.name }));

  const handleChangeShow = (selectedOption) => {
    setSelectedShow(selectedOption);
  };

  const handleBrandClick = (brandID) => {
    if (selectedShow.length === 0) {
      toast.error("Please pick a show to Monetize", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      history.push(`/brand/${brandID}/show/${selectedShow.value}`);
    }
  };

  const columns = [
    {
      name: "No.",
      // selector: (row) => row.title,
      cell: (row, index) => index + 1,
    },
    {
      name: "Shows",
      selector: (row) => row.monetized_show,
    },
    {
      name: "brand",
      selector: (row) => row.chosen_sponsor,
    },
    {
      name: "Proposal status",
      selector: (row) => row.proposal_status,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <DeleteIcon
            onClick={() => handleMoneDelete(row.id)}
            id={row.id}
            className="deleteIconChannelList mx-3"
          />
        </>
      ),
    },
  ];

  const handleMoneDelete = (monetizationId) => {
    setDeletedRow(false);
    apiInstance
      .delete(`/monetization/${monetizationId}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        setDeletedRow(true);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (error.response.data.error_type === "InvalidToken") {
            accessToken();
            handleMoneDelete();
          }
        }
      });
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const data = mone;

  return (
    <div className="app">
      <Header />
      <main>
        <TopHeader name="Monetization" />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div className="p-5 mainContainerAdd">
          <Card className="monetization-card-toggle">
            <Card.Body className="my-3">
              <Row>
                <Col xs={9} sm={12} md={9} lg={9} xl={9}>
                  <Card.Title>Monetization</Card.Title>
                  <Card.Text>
                    Leverage Your Niche By Offering Pre-Roll, Mid-Roll, Or
                    Post-Roll Ad Breaks
                    <br />
                    In Your Sochcast. Just Getting Started?
                  </Card.Text>
                </Col>
                <Col xs={3} sm={12} md={3} lg={3} xl={3} my-auto>
                  <div className="monit-main-logo">
                    <img
                      src={DollarSign}
                      alt="Leverage Your Niche By Offering Pre-Roll, Mid-Roll, Or
                    Post-Roll Ad Breaks"
                      className="img-fluid monit-img-icon"
                    ></img>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label>
                    <Switch
                      onChange={handleChange}
                      checked={checked}
                      className="react-switch"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor="#38c3e0"
                    />
                    <span className="switch-text mx-3">
                      Enable Monetization
                    </span>
                  </label>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {addMonetizationStatus ? (
            <Row>
              <Col xs={12} md={12} lg={12} xl={12} className="mt-5">
                <div className="channel-form">
                  <Row className="mx-3 py-5">
                    <Col xs={10} md={10} lg={10} xl={10}>
                      <h3 className="m-s-t">Select Show for Monetization</h3>
                      <label for="show" className="formLabel show">
                        Show
                      </label>
                      <Select
                        className="basic-single"
                        isSearchable={true}
                        options={resultShows}
                        onChange={handleChangeShow}
                      />
                    </Col>
                    <hr className="my-5" />
                    <Col
                      xs={10}
                      md={10}
                      lg={10}
                      xl={10}
                      data-tip
                      data-for="mspon"
                    >
                      <h3 className="m-s-t">Sochcast Sponsor</h3>
                      <Row>
                        {brands?.map((item) => {
                          return (
                            <Col md={3} className="mx-1">
                              <Card
                                className="brand-sponsor"
                                onClick={() => handleBrandClick(item.id)}
                                style={{ cursor: "pointer" }}
                              >
                                <Card.Img
                                  variant="top"
                                  src={item.brand_image}
                                  style={{
                                    width: "74px",
                                    height: "74px",
                                    margin: "auto",
                                    marginTop: "30px",
                                  }}
                                  alt={item.brand_name}
                                />
                                <Card.Body className="brand-name-footer text-center">
                                  <p className="m-0">{item.brand_name}</p>
                                </Card.Body>
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                      <ReactTooltip id="mspon" place="top" effect="solid">
                        Here's who sponsored your content
                      </ReactTooltip>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          ) : null}

          {addnewMonetizationCardStatus ? (
            // Add Monetization
            <div className="my-5">
              <Card className="monetization-add-card">
                <Card.Body className="p-5">
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={9} xl={9}>
                      <Card.Title>Add Monetization</Card.Title>
                      <Card.Text>
                        Leverage Your Niche By Offering Pre-Roll, Mid-Roll, Or
                        Post-Roll Ad Breaks
                        <br />
                        In Your Podcast. Just Getting Started?
                      </Card.Text>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={3} xl={3} className="mt-3">
                      <Button
                        variant="primary"
                        type="submit"
                        className="addmonetization rounded-2"
                        title="Add Monetization"
                        data-testid="add-monetization"
                        onClick={handleAddMonetization}
                      >
                        Add Monetization
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="ChannelListTable my-5">
                <Card.Body>
                  <DataTable
                    pagination
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                  />
                </Card.Body>
              </Card>
            </div>
          ) : null}
        </div>
      </main>

      {disableMonetizationStatus === false && (
        <DisableMonetization
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
    </div>
  );
};
