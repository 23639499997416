import React, { useState, useEffect, useContext, useRef } from "react";
import { CommonTitle } from "../../components/CommonTitle";
import ShowIcon from "../../assets/images/Group1837.svg";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { InputField } from "../../components/formFields/InputField";
import { ImportExistingShow } from "../../components/showsImport/ImportExistingShow";
import { TagsInput } from "../../components/formFields/TagsInput";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { RadioButton } from "../../components/formFields/RadioButton";
import { showRatings } from "../../helperFunction";
import "../css/shows/show.css";
import { isUnlisted } from "../../helperFunction";
import { SelectDropdown } from "../../components/formFields/SelectDropdown";
import apiInstance from "../../api";
import { accessToken } from "../../helperFunction";
import Multiselect from "multiselect-react-dropdown";
import { LoginContext } from "../channel/Helper/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/channel/Channel.css";
import ReactTooltip from "react-tooltip";

export const AddNewShow = () => {
  const bannerImage = useRef(null);
  const showImage = useRef(null);
  const { setFormStatus } = useContext(LoginContext);
  const [buttonDraftStatus, setButtonDraftStatus] = useState(false);
  const [buttonPublishStatus, setButtonPublishStatus] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    apiInstance
      .get("/all-languages/")
      .then((response) => {
        setPreferredLanguage(response.data.results);
      })
      .catch((err) => {
        console.log(err);
      });

    apiInstance
      .get("/main-categories/")
      .then((response) => {
        setAllCategories(response.data.results);
      })
      .catch((err) => {
        console.log(err);
      });

    async function getAllChannels() {
      await apiInstance
        .get("/channels/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setChannels(response.data.results);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (error.response.data.error_type === "InvalidToken") {
              accessToken();
              getAllChannels();
            }
          }
        });
    }

    var config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    };

    async function getAllHosts() {
      let nextUrl = "/host/";
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl, config);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFuncGetHosts() {
      let temp1 = await getAllHosts();
      setHosts(temp1);
    }

    getAllChannels();
    tempFuncGetHosts();
  }, []);

  const [hosts, setHosts] = useState([]);
  const [filePreviewBannerImage, setFilePreviewBannerImage] = useState(null);
  const [filePreviewShowImage, setFilePreviewShowImage] = useState(null);
  const [channels, setChannels] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  const [preferredLanguage, setPreferredLanguage] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [userInputTags, setUserInputTags] = useState([]);
  const [channelArray, setChannelArray] = useState([]);
  const [hostArray, setHostArray] = useState([]);
  const [categoryArray, setCategoryArray] = useState([]);
  const selectedTags = (tags) => {
    setUserInputTags(tags);
  };

  const initialValues = {
    ShowName: "",
    explicitContent: "",
    abusiveContent: "",
    selectChannel: "",
    selectLanguage: "",
    selectCategories: "",
    selectHost: "",
    isUnlisted: "",
    banner_image: "",
    show_image: "",
    picked: "",
    intro: "",
    trailer: "",
  };

  const validate = Yup.object({
    ShowName: Yup.string().required(),
    selectChannel: Yup.string().required(),
    selectLanguage: Yup.string().required(),
    selectCategories: Yup.string().required(),
    selectHost: Yup.string().required(),
  });

  const handleDraft = async (values) => {
    let unListed = "";
    let explicitContent = "";
    let abusiveContent = "";
    let isPublished = false;

    if (values.picked === "Yes") {
      unListed = "True";
    } else {
      unListed = "False";
    }

    if (values.explicitContent === "Yes") {
      explicitContent = "True";
    } else {
      explicitContent = "False";
    }

    if (values.abusiveContent === "Yes") {
      abusiveContent = "True";
    } else {
      abusiveContent = "False";
    }

    const hostList = hostArray.map((e) => e.id);
    const ChannelList = channelArray.map((e) => e.id);
    const categoryList = categoryArray.map((e) => e.id);

    let data = new FormData();
    data.append("name", values.ShowName);
    data.append(
      "description",
      `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}`
    );
    data.append("show_image", values.show_image);
    data.append("banner_image", values.banner_image);
    data.append("language", values.selectLanguage);
    for (var i = 0; i < categoryList.length; i++) {
      data.append("categories", categoryList[i]);
    }
    for (var i = 0; i < ChannelList.length; i++) {
      data.append("channels", ChannelList[i]);
    }
    for (var i = 0; i < hostList.length; i++) {
      data.append("hosts", hostList[i]);
    }
    if (typeof values.intro !== "string") {
      data.append("intro", values.intro);
    }
    if (typeof values.trailer !== "string") {
      data.append("trailer", values.trailer);
    }
    data.append("rating", values.picked);
    data.append("abusive_content", `${abusiveContent}`);
    data.append("explicit_content", `${explicitContent}`);
    data.append("is_unlisted", `${unListed}`);
    data.append("is_published", isPublished);
    for (var i = 0; i < userInputTags.length; i++) {
      data.append(`tags[${i}]`, userInputTags[i]);
    }

    if (userInputTags.length < 3) {
      toast.error("Please enter atleast a minimum of 3 tags", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.picked === undefined || values.picked === "") {
      toast.error("Please pick an rating", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.explicitContent === "") {
      toast.error("Please select Explicit Content type", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.abusiveContent === "") {
      toast.error("Please select Abusive Content type", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.banner_image === null || values.banner_image === "") {
      toast.error("Please upload an Banner image", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.show_image === null || values.show_image === "") {
      toast.error("Please upload an Show image", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (ChannelList.length === 0) {
      toast.error("Please select the Channels", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (hostList.length === 0) {
      toast.error("Please select the Hosts", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (unListed === "") {
      toast.error("Please select an option for listed or unlisted", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.explicitContent === "Yes" &&
      values.abusiveContent === "No" &&
      values.picked !== "A"
    ) {
      toast.error("Please change your rating to A - Adult", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.explicitContent === "No" &&
      values.abusiveContent === "Yes" &&
      values.picked !== "A"
    ) {
      toast.error("Please change your rating to A - Adult", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.explicitContent === "Yes" &&
      values.abusiveContent === "Yes" &&
      values.picked !== "A"
    ) {
      toast.error("Please change your rating to A - Adult", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (categoryList.length === 0) {
      toast.error("Please select the Communities.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.selectLanguage === "" || values.selectLanguage === null) {
      toast.error("Please select the Language.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      handleShow();
      setButtonDraftStatus(true);
      apiInstance
        .post("/shows/", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setFormStatus(false);
        })
        .catch((error) => {
          if (error.response.data.error_type === "ValidationError") {
            if (error.response.data.errors[0].field === "show_image") {
              handleClose();
              setButtonDraftStatus(false);
              toast.error(
                `Show Image - ${error.response.data.errors[0].message}`,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }
          }
        });
    }
  };

  channels.forEach((item) => {
    delete item.created_at;
    delete item.description;
    delete item.is_published;
    delete item.is_unlisted;
    delete item.tags;
    delete item.updated_at;
  });

  hosts.forEach((item) => {
    delete item.created_at;
    delete item.description;
    delete item.email;
    delete item.last_name;
    delete item.gender;
    delete item.host_image;
    delete item.is_unlisted;
    delete item.joined_platform;
    delete item.last_name;
    delete item.tags;
    delete item.updated_at;
  });

  const handlePublish = async (values) => {
    let unListed = "";
    let explicitContent = "";
    let abusiveContent = "";
    let isPublished = false;

    if (values.picked === "Yes") {
      unListed = "True";
      isPublished = false;
    } else {
      unListed = "False";
      isPublished = true;
    }

    if (values.explicitContent === "Yes") {
      explicitContent = "True";
    } else {
      explicitContent = "False";
    }

    if (values.abusiveContent === "Yes") {
      abusiveContent = "True";
    } else {
      abusiveContent = "False";
    }

    const hostList = hostArray.map((e) => e.id);
    const ChannelList = channelArray.map((e) => e.id);
    const categoryList = categoryArray.map((e) => e.id);

    let data = new FormData();
    data.append("name", values.ShowName);
    data.append(
      "description",
      `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}`
    );
    data.append("show_image", values.show_image);
    data.append("banner_image", values.banner_image);
    data.append("language", values.selectLanguage);
    for (var i = 0; i < ChannelList.length; i++) {
      data.append("channels", ChannelList[i]);
    }
    for (var i = 0; i < hostList.length; i++) {
      data.append("hosts", hostList[i]);
    }

    for (var i = 0; i < categoryList.length; i++) {
      data.append("categories", categoryList[i]);
    }

    if (typeof values.intro !== "string") {
      data.append("intro", values.intro);
    }
    if (typeof values.trailer !== "string") {
      data.append("trailer", values.trailer);
    }
    data.append("rating", values.picked);
    data.append("abusive_content", `${abusiveContent}`);
    data.append("explicit_content", `${explicitContent}`);
    data.append("is_unlisted", `${unListed}`);
    data.append("is_published", isPublished);
    for (var i = 0; i < userInputTags.length; i++) {
      data.append(`tags[${i}]`, userInputTags[i]);
    }

    if (userInputTags.length < 3) {
      toast.error("Please enter atleast a minimum of 3 tags", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.picked === undefined || values.picked === "") {
      toast.error("Please pick an rating", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.explicitContent === "") {
      toast.error("Please select Explicit Content type", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.abusiveContent === "") {
      toast.error("Please select Abusive Content type", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.banner_image === null || values.banner_image === "") {
      toast.error("Please upload an Banner image", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.show_image === null || values.show_image === "") {
      toast.error("Please upload an Show image", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (ChannelList.length === 0) {
      toast.error("Please select the Channels", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (hostList.length === 0) {
      toast.error("Please select the Hosts", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (unListed === "") {
      toast.error("Please select an option for listed or unlisted", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.explicitContent === "Yes" &&
      values.abusiveContent === "No" &&
      values.picked !== "A"
    ) {
      toast.error("Please change your rating to A - Adult", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.explicitContent === "No" &&
      values.abusiveContent === "Yes" &&
      values.picked !== "A"
    ) {
      toast.error("Please change your rating to A - Adult", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.explicitContent === "Yes" &&
      values.abusiveContent === "Yes" &&
      values.picked !== "A"
    ) {
      toast.error("Please change your rating to A - Adult", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (categoryList.length === 0) {
      toast.error("Please select the Communities.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.selectLanguage === "" || values.selectLanguage === null) {
      toast.error("Please select the Language.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      handleShow();
      setButtonPublishStatus(true);
      apiInstance
        .post("/shows/", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setFormStatus(false);
        })
        .catch((error) => {
          if (error.response.data.error_type === "ValidationError") {
            if (error.response.data.errors[0].field === "show_image") {
              handleClose();
              setButtonPublishStatus(false);
              toast.error(
                `Show Image - ${error.response.data.errors[0].message}`,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }
          }
        });
    }
  };

  const onChannelSelect = (selectedList, selectedItem) => {
    setChannelArray(selectedList);
  };

  const onHostSelect = (selectedList, selectedItem) => {
    setHostArray(selectedList);
  };

  const onCategorySelect = (selectedList, selectedItem) => {
    setCategoryArray(selectedList);
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const handleRemoveShowImage = () => {
    setFilePreviewShowImage(null);
  };

  const handleRemoveBannerImage = () => {
    setFilePreviewBannerImage(null);
  };

  return (
    <div className="p-5 mainContainerAdd">
      <ImportExistingShow />
      <CommonTitle heading="Create An new Sochcast" text="" icons={ShowIcon} />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Row>
        <Col xs={12} md={12} lg={12} xl={12} className="mt-5 px-3">
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            // onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form onKeyDown={onKeyDown}>
                <div className="channel-form">
                  <Row className="py-5 px-3">
                    <Col
                      xs={12}
                      md={10}
                      lg={10}
                      xl={10}
                      data-tip
                      data-for="showName"
                    >
                      <InputField
                        label="Show Name*"
                        name="ShowName"
                        type="text"
                        data-testid="show-name"
                        placeholder="Sochcast Title"
                      />
                      <ReactTooltip id="showName" place="top" effect="solid">
                        Pick a great name for your show
                      </ReactTooltip>
                    </Col>
                    <Col
                      xs={12}
                      md={10}
                      lg={10}
                      xl={10}
                      data-tip
                      data-for="showDescriptions"
                    >
                      <label for="showDescription" className="formLabel mt-3">
                        Description*
                      </label>
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="ChannelDesscription"
                        editorClassName="editorClassName"
                        toolbar={{
                          options: ["emoji", "link"],
                          emoji: { className: "emojiClassNAme" },
                          link: {
                            className: "imageClassName",
                            options: ["link"],
                          },
                        }}
                        editorState={editorState}
                        defaultEditorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                      />
                      <ReactTooltip
                        id="showDescriptions"
                        place="top"
                        effect="solid"
                      >
                        Add popular keywords, be accurate. Get discovered
                      </ReactTooltip>
                    </Col>
                    <Col
                      xs={12}
                      md={10}
                      lg={10}
                      xl={10}
                      className="mt-3"
                      data-tip
                      data-for="sTag"
                    >
                      <label className="formLabel">
                        Tag*
                        <p className="text-muted">
                          Please enter minimum 3 tags
                        </p>
                      </label>

                      <TagsInput selectedTags={selectedTags} tags={[]} />
                      <ReactTooltip id="sTag" place="top" effect="solid">
                        Use relevant tags to get discovered
                      </ReactTooltip>
                    </Col>
                    <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                      <label className="formLabel">Rating</label>
                      <br />
                      <RadioButton options={showRatings} type="rating" />
                    </Col>
                    <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                      <Row>
                        <Col xs={12} md={6} lg={6} xl={6}>
                          <label className="formLabel">Explicit content</label>
                          <br />
                          <RadioButton
                            options={isUnlisted}
                            type="explicitContent"
                          />
                        </Col>
                        <Col xs={12} md={6} lg={6} xl={6}>
                          <label className="formLabel">Abusive content</label>
                          <br />
                          <RadioButton
                            options={isUnlisted}
                            type="abusiveContent"
                          />
                        </Col>
                      </Row>
                    </Col>

                    {/* Banner Image Starts */}
                    <Row>
                      <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                        <label className="formLabel">
                          Choose Banner Image:{" "}
                          <p className="text-muted">Only *.png accepted</p>
                        </label>
                        <br />
                      </Col>
                      <>
                        <Col
                          xs={12}
                          md={3}
                          lg={3}
                          xl={3}
                          className="img-mobile-holder"
                        >
                          <div className="banner-image-holder">
                            {filePreviewBannerImage !== null ? (
                              <img
                                src={filePreviewBannerImage}
                                alt="Profile"
                                className="img-fluid banner-image"
                              />
                            ) : (
                              <div className="upload-image-text-holder">
                                <p>Add Image</p>
                                <Button
                                  className="add-image-icon"
                                  onClick={() => {
                                    bannerImage.current &&
                                      bannerImage.current.click();
                                  }}
                                >
                                  +
                                </Button>
                              </div>
                            )}
                          </div>
                        </Col>
                        <>
                          <Col xs={6} md={3} lg={3} xl={3} className="mt-2">
                            <input
                              type="file"
                              name="file"
                              accept="image/png"
                              ref={bannerImage}
                              style={{ display: "none" }}
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "banner_image",
                                  event.target.files[0]
                                );
                                setFilePreviewBannerImage(
                                  URL.createObjectURL(event.target.files[0])
                                );
                              }}
                            />
                            <Button
                              className="upload-picture-button"
                              onClick={() => {
                                bannerImage.current &&
                                  bannerImage.current.click();
                              }}
                            >
                              Upload Picture
                            </Button>
                          </Col>
                          <Col xs={6} md={6} lg={6} xl={6} className="mt-2">
                            <Button
                              className="upload-picture-button remove"
                              onClick={handleRemoveBannerImage}
                            >
                              Remove
                            </Button>
                          </Col>
                        </>
                      </>
                    </Row>
                    {/* Banner Image Ends */}

                    {/* Show Image Starts */}
                    <Row>
                      <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                        <label className="formLabel">
                          Choose Show Image:{" "}
                          <p className="text-muted">
                            Only *.png accepted (Image pixels must be between
                            1400 and 3000.)
                          </p>
                        </label>
                        <br />
                      </Col>
                      <>
                        <Col
                          xs={12}
                          md={3}
                          lg={3}
                          xl={3}
                          className="img-mobile-holder"
                        >
                          <div className="banner-image-holder">
                            {filePreviewShowImage !== null ? (
                              <img
                                src={filePreviewShowImage}
                                alt="Profile"
                                className="img-fluid banner-image"
                              />
                            ) : (
                              <div className="upload-image-text-holder">
                                <p>Add Image</p>
                                <Button
                                  className="add-image-icon"
                                  onClick={() => {
                                    showImage.current &&
                                      showImage.current.click();
                                  }}
                                >
                                  +
                                </Button>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xs={6} md={3} lg={3} xl={3} className="mt-2">
                          <input
                            type="file"
                            name="file"
                            accept="image/png"
                            ref={showImage}
                            style={{ display: "none" }}
                            onChange={(event) => {
                              formik.setFieldValue(
                                "show_image",
                                event.target.files[0]
                              );
                              setFilePreviewShowImage(
                                URL.createObjectURL(event.target.files[0])
                              );
                            }}
                          />
                          <Button
                            className="upload-picture-button"
                            onClick={() => {
                              showImage.current && showImage.current.click();
                            }}
                          >
                            Upload Picture
                          </Button>
                        </Col>
                        <Col xs={6} md={6} lg={6} xl={6} className="mt-2">
                          <Button
                            className="upload-picture-button remove"
                            onClick={handleRemoveShowImage}
                          >
                            Remove
                          </Button>
                        </Col>
                      </>
                    </Row>
                    {/* Show Image Ends */}

                    <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                      <label className="formLabel">
                        Choose an Intro Audio File
                      </label>
                      <br />
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => {
                          formik.setFieldValue("intro", event.target.files[0]);
                        }}
                        accept=".mp3,audio/*"
                      />
                    </Col>
                    <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                      <label className="formLabel">
                        Choose an Trailer Audio File
                      </label>
                      <br />
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "trailer",
                            event.target.files[0]
                          );
                        }}
                        accept=".mp3,audio/*"
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={10}
                      lg={10}
                      xl={10}
                      className="mt-3"
                      data-tip
                      data-for="showChannelSelect"
                    >
                      <label className="formLabel">Channel</label>
                      <Multiselect
                        options={channels}
                        onSelect={onChannelSelect}
                        displayValue="name"
                      />
                      <ReactTooltip
                        id="showChannelSelect"
                        place="top"
                        effect="solid"
                      >
                        you can have multiple channels with different shows
                        under them
                      </ReactTooltip>
                    </Col>
                    <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                      <Row>
                        <Col
                          xs={12}
                          md={6}
                          lg={6}
                          xl={6}
                          data-tip
                          data-for="showLanguageSelect"
                        >
                          <SelectDropdown
                            control="select"
                            name="selectLanguage"
                            data-testid="selectLanguage"
                            id="selectLanguage"
                            label="Select Language"
                            options={preferredLanguage}
                          />
                          <ReactTooltip
                            id="showLanguageSelect"
                            place="top"
                            effect="solid"
                          >
                            This is important. People can find you easily
                          </ReactTooltip>
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          lg={6}
                          xl={6}
                          data-tip
                          data-for="pickCommunity"
                        >
                          <label className="formLabel">Communities</label>
                          <Multiselect
                            options={allCategories} // Options to display in the dropdown
                            onSelect={onCategorySelect} // Function will trigger on select event
                            // onRemove={onRemove} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                          />
                          <ReactTooltip
                            id="pickCommunity"
                            place="top"
                            effect="solid"
                          >
                            Pick a community or two
                          </ReactTooltip>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      md={10}
                      lg={10}
                      xl={10}
                      className="mt-3"
                      data-tip
                      data-for="sHost"
                    >
                      <label className="formLabel">Host</label>
                      <Multiselect
                        options={hosts} // Options to display in the dropdown
                        onSelect={onHostSelect} // Function will trigger on select event
                        // onRemove={onRemove} // Function will trigger on remove event
                        displayValue="first_name" // Property name to display in the dropdown options
                      />
                      <ReactTooltip id="sHost" place="top" effect="solid">
                        Who is hosting
                      </ReactTooltip>
                    </Col>
                    <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                      <label className="formLabel">Is Unlisted</label>
                      <br />
                      <RadioButton options={isUnlisted} type="isUnlisted" />
                    </Col>
                    <Col xs={12} md={10} lg={10} xl={10}>
                      {formik.values.isUnlisted === "Yes"
                        ? "The episode is not published and will not be show in the RSS."
                        : formik.values.isUnlisted === "No"
                        ? "The episode is published and will be show in the RSS."
                        : null}
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="mt-4 chananelButton"
                  >
                    <Button
                      disabled={buttonDraftStatus}
                      variant="primary"
                      type="submit"
                      className="channelDraftButton rounded-2 mx-3"
                      title="ChannelDrafButton"
                      data-testid="signUpBtton"
                      onClick={() => handleDraft(formik.values)}
                    >
                      {buttonDraftStatus ? "Saving ..." : "Draft"}
                    </Button>
                    <Button
                      disabled={buttonPublishStatus}
                      variant="primary"
                      type="submit"
                      className="channelPublishButton rounded-2"
                      title="channelPublishButton"
                      data-testid="signUpBtton"
                      // disabled={formik.isSubmitting}
                      onClick={() => handlePublish(formik.values)}
                    >
                      {buttonDraftStatus ? "Saving ..." : "Publish"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header>
          <Modal.Title>Please wait</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p>We are creating your Sochcast. Thank you for your patience.</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center"></Modal.Footer>
      </Modal>
    </div>
  );
};
