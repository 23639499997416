import React, { useState } from "react";
import { Header } from "../../components/header/Header";
import { TopHeader } from "../../components/header/TopHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Button, Modal } from "react-bootstrap";
import "../css/shows/show.css";
import { useHistory } from "react-router-dom";
import apiInstance from "../../api";

export const ImportExistingShow = () => {
  const [feedUrl, setFeedUrl] = useState("");
  const [checked, setChecked] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);
  const [rssId, setRssID] = useState("");
  const [show, setShow] = useState(false);
  const [otp, setOTP] = useState("");
  const [buttonValidateStatus, setButtonValidateStatus] = useState(false);
  const [buttonOTPStatus, setButtonOTPStatus] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [toggled, setToggled] = useState(false);

  const history = useHistory();

  const setHandleTerms = () => {
    setChecked(!checked);
  };

  const handleValidate = () => {
    if (feedUrl !== "" && feedUrl !== null) {
      if (checked) {
        setButtonValidateStatus(true);
        apiInstance
          .post(
            "/ingested-rss",
            {
              rss_link: feedUrl,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access")}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              toast.success(response.data.payload.message, {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setImportSuccess(true);
              setRssID(response.data.payload.rss_id);
            }
          })
          .catch((error) => {
            setButtonValidateStatus(false);
            if (error.response.status === 400) {
              toast.error(error.response.data.errors[0].message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
      } else {
        setButtonValidateStatus(false);
        toast.error("Please accept Terms and Conditions.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      setButtonValidateStatus(false);
      toast.error("Please enter your RSS feed URL.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleOTPVerification = () => {
    if (otp !== "" || otp !== null) {
      setButtonOTPStatus(true);
      apiInstance
        .patch(
          `/ingested-rss/${rssId}`,
          {
            otp: otp,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            handleClose();
            history.push("/show");
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            toast.error(error.response.data.errors[0].message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    } else {
      toast.error("Please enter OTP.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <div className="app">
      <Header handleToggleSidebar={handleToggleSidebar} toggled={toggled} />
      <main>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <TopHeader name="Manage Show" />
        {importSuccess ? (
          <div className="p-5 mainContainerAdd">
            <Row>
              <Col xs={12} md={12} lg={12} xl={12}>
                <div className="channel-form">
                  <Row className="mx-4 py-5">
                    <Col md={12}>
                      <div className="import">
                        <h3>Search For Your Sochcast</h3>
                        <p>we'll search apple sochcast to find your rss feed</p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="import pt-3">
                        <input
                          type="text"
                          className="search-rss"
                          placeholder="Enter An Name your Sochcast"
                          name="rsssearch"
                          readOnly
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col xs={12} md={12} lg={12} xl={12}>
                <div className="channel-form">
                  <Row className="mx-4 py-5">
                    <Col md={9}>
                      <div className="import-succes-true">
                        <h3>Your feed is valid and ready to be imported</h3>
                        <p>
                          Click Continue to enter OTP and start importing your
                          Feed.
                        </p>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="import-successimage"></div>
                    </Col>
                  </Row>

                  <Row className="pb-5">
                    <Col>
                      <div className="import">
                        <Button className="try-again">Try Again</Button>
                        <Button className="continue-rss" onClick={handleShow}>
                          Continue
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="p-5 mainContainerAdd">
            <Row>
              <Col xs={12} md={12} lg={12} xl={12}>
                <div className="channel-form">
                  {/* <Row className="mx-4 pt-5">
                    <Col md={12}>
                      <div className="import">
                        <h3>Search For Your Sochcast</h3>
                        <p>we'll search apple sochcast to find your rss feed</p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="import pt-3">
                        <input
                          type="text"
                          className="search-rss"
                          placeholder="Enter An Name your Sochcast"
                          name="rsssearch"
                          readOnly
                        />
                      </div>
                    </Col>
                  </Row> */}

                  <Row className="py-5">
                    <Col>
                      <div className="import">
                        <h3>Enter Your Sochcast's Rss Feed Url</h3>
                        <p>If You Know the Url Type it Below</p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="import pt-3">
                        <input
                          type="text"
                          className="search-rss feed-url"
                          placeholder="Enter Url of Rss feed"
                          name="rssurl"
                          onChange={(e) => setFeedUrl(e.target.value)}
                          value={feedUrl}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="pb-5">
                    <Col>
                      <div className="import">
                        <input
                          type="checkbox"
                          id="agree"
                          data-testid="tnc"
                          onChange={setHandleTerms}
                        />
                        <label htmlFor="agree" className="tnccheckbox import">
                          I accept all the given{" "}
                          <b>
                            <Button className="termsButton import">
                              Terms and Conditions
                            </Button>
                          </b>
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row className="pb-5">
                    <Col>
                      <div className="import">
                        <Button
                          className="importvalidatebutton"
                          onClick={handleValidate}
                          disabled={buttonValidateStatus}
                        >
                          {buttonValidateStatus ? "Verfiying ..." : "Validate"}
                        </Button>
                        <Button
                          className="importcancelbutton"
                          onClick={() => history.push("/show")}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </main>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        data-keyboard="false"
        centered
      >
        <Modal.Header>
          <Modal.Title>Email Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder="Enter OTP"
            name="otp"
            onChange={(e) => setOTP(e.target.value)}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            className="customInput-otp"
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          {/* <Button
            variant="secondary"
            onClick={handleClose}
            className="playlist-cancel"
          >
            Cancel
          </Button> */}
          <Button
            variant="primary"
            className="playlist-save"
            onClick={handleOTPVerification}
            disabled={buttonOTPStatus}
          >
            {buttonOTPStatus ? "Verfiying ..." : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
