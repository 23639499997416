import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Card, Container } from "react-bootstrap";
import "../listener.css";
import apiInstance from "../../api";
import { keepTheme } from "../../utils/listenerTheme/Theme";
import { TopHeaderNavigation } from "../../components/listener/TopHeaderNavigation";
import "react-multi-carousel/lib/styles.css";
import { useHistory } from "react-router-dom";
import { BsMusicNoteBeamed } from "react-icons/bs";
import { HiTrendingUp } from "react-icons/hi";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { AiFillCheckCircle } from "react-icons/ai";

export const Explore = () => {
  const [themeStatus, setThemeStatus] = useState("theme-light");
  const [categories, setCategories] = useState([]);
  const [categoryShows, setCategoryShows] = useState([]);
  const [catClassName, setCatClassName] = useState("");
  const [catArray, setCatArray] = useState([]);
  const [catNames, setCatName] = useState("");
  const [activeIndicies, setActiveIndicies] = useState(0);

  const myRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    keepTheme();
    if (localStorage.getItem("theme") === "theme-dark") {
      setThemeStatus("theme-dark");
    } else if (localStorage.getItem("theme") === "theme-light") {
      setThemeStatus("theme-light");
    }

    async function getAllCategories() {
      let nextUrl = "/listener/categories";
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFunc() {
      let temp1 = await getAllCategories();
      setCategories(temp1);
      setActiveIndicies(temp1);
    }

    async function getAllCategoryShows() {
      let nextUrl = `/listener/categories/201`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFunc2() {
      let temp2 = await getAllCategoryShows();
      setCategoryShows(temp2);
      // setCatArray((catArray) => [...catArray, { id: 1, name: "History" }]);
    }

    tempFunc();
    tempFunc2();
  }, []);

  useEffect(() => {
    if (catArray.length > 0) {
      setCatClassName("selected");
    }
  }, [catArray]);

  const handleCategoryShow = (catID, i, catName) => {
    if (catArray.filter((e) => e.id === catID).length > 0) {
      /* catArray contains the element (ID) we're looking for */
      for (var ci = 0; ci < catArray.length; ci++) {
        if (catArray[ci].id === catID) {
          catArray.splice(ci, 1);
          // break;
        }
      }
    } else {
      setCatArray((catArray) => [...catArray, { id: catID, name: catName }]);
    }
    executeScroll();

    if (catArray.length === 0) {
      setCatName(catName);
      async function getAllCategoryShows() {
        let nextUrl = `/listener/shows/categories?cat_id=${catID}`;
        let temp = [];
        while (nextUrl !== null) {
          const response = await apiInstance.get(nextUrl);
          nextUrl = response.data.next;
          temp.push(...response.data.results);
        }
        return temp;
      }

      async function tempFunc() {
        let temp1 = await getAllCategoryShows();
        setCategoryShows(temp1);
      }

      tempFunc();
    } else {
      async function getAllCategoryShows() {
        var arr = catArray.map((i) => "cat_id=" + i.id + "&");
        var apiURL = arr.join("").slice(0, -1);

        let nextUrl = `/listener/shows/categories?${apiURL}`;
        let temp = [];
        while (nextUrl !== null) {
          const response = await apiInstance.get(nextUrl);
          nextUrl = response.data.next;
          temp.push(...response.data.results);
        }
        return temp;
      }

      async function tempFunc() {
        let temp1 = await getAllCategoryShows();
        setCategoryShows(temp1);
      }

      tempFunc();
    }
  };

  const handleShowEpisode = (slug) => {
    history.push(`/show/${slug}`);
  };

  const executeScroll = () => myRef.current.scrollIntoView();

  const handleNewReleases = () => {
    history.push("/all-new-release");
  };

  const handleTopCharts = () => {
    history.push("/top-charts");
  };

  const handleTopEpisodes = () => {
    history.push("/top-episodes");
  };

  // const handleClearCommunities = () => {
  //   setCatArray([]);
  //   // setActiveIndicies(false);
  //   setCategoryShows([]);
  // };

  return (
    <>
      <div className={themeStatus}>
        <TopHeaderNavigation
          changeTheme={(themeStatus) => setThemeStatus(themeStatus)}
          upgrade="explore"
        />

        <Container>
          <div className="my-5">
            <div className="mb-4">
              <Row>
                <Col className="card-top-explore" onClick={handleNewReleases}>
                  <BsMusicNoteBeamed className="mx-3" />
                  New Releases
                </Col>
                <Col className="card-top-explore" onClick={handleTopCharts}>
                  <HiTrendingUp className="mx-3" />
                  Top Chart
                </Col>
                <Col className="card-top-explore" onClick={handleTopEpisodes}>
                  <AiOutlinePlayCircle className="mx-3" />
                  Top Episodes
                </Col>
              </Row>
            </div>

            <p className="sub-main-text">For you</p>
            <Row>
              <Col xs={12} sm={12} md={9}>
                <h3 className="listener-main-text">Popular Communities</h3>
              </Col>
            </Row>

            <Row>
              {categories?.map((item, index) => {
                return (
                  <Col
                    className={`mb-3 main-cat-tick ${
                      activeIndicies[index] === true
                        ? "selected"
                        : "notselected"
                    }`}
                    key={item.id}
                    onClick={() => {
                      handleCategoryShow(item.id, index, item.name);
                      setActiveIndicies(
                        activeIndicies?.map((bool, j) =>
                          j === index ? (bool === true ? false : true) : bool
                        )
                      );
                    }}
                    xs={6}
                    md={2}
                  >
                    <div
                      className={`common-cat-listener pop-cat-${item.id} all`}
                    >
                      <h4 className="cat-text py-4 px-3">{item.name}</h4>
                      <div className="tick">
                        <span>
                          <AiFillCheckCircle />
                        </span>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>

          <div className="my-5" ref={myRef}>
            <p className="sub-main-text">For you</p>
            <Row>
              <Col xs={12} sm={12} md={9}>
                <h3 className="listener-main-text">{`Top Shows in ${catArray.map(
                  (item) => {
                    return " " + item.name;
                  }
                )}`}</h3>
              </Col>
              {/* <Col xs={12} sm={12} md={3} style={{ textAlign: "right" }}>
                <button
                  className="landing-signin listener become-sochcaster btn btn-primary clear-communities"
                  onClick={handleClearCommunities}
                >
                  Clear
                </button>
              </Col> */}
            </Row>

            <Row>
              {categoryShows.length === 0 ? (
                <p className="text-center fs-2 fw-normal">
                  No shows found, please select a Community
                </p>
              ) : (
                <>
                  {categoryShows?.map((item) => {
                    return (
                      <Col md={2} xs={6} key={item.id}>
                        <Card
                          className="playcard"
                          onClick={() => handleShowEpisode(item.slug)}
                        >
                          <Card.Img
                            variant="top"
                            src={item.show_image}
                            className="f-listen-image-holder"
                            alt={item.name}
                          />
                          <Card.Body className="card-b-listen">
                            <Card.Title className="l-title-albumn text-capitalize">
                              {item.name}
                            </Card.Title>
                            <Card.Text className="l-author-albumn text-capitalize">
                              By{" "}
                              {item.hosts[0]?.first_name +
                                " " +
                                item.hosts[0]?.last_name}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
