import React, { useState } from "react";
import { Header } from "../../components/header/Header";
import { Container } from "react-bootstrap";
import { TopHeader } from "../../components/header/TopHeader";
import { ShowMain } from "./ShowMain";
import { AddNewShow } from "./AddNewShow";
import { LoginContext } from "../channel/Helper/Context";
import { FaBars } from "react-icons/fa";

export const Show = () => {
  const [formStatus, setFormStatus] = useState(false);

  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <LoginContext.Provider value={{ formStatus, setFormStatus }}>
      <div className="app">
      <Header handleToggleSidebar={handleToggleSidebar} toggled={toggled} />
        <main>
          <TopHeader name="Manage Show" />
          <div
          className="btn-toggle mobilemnnu"
          onClick={() => handleToggleSidebar(true)}
        >
          <FaBars />
        </div>
          {formStatus ? (
            <AddNewShow />
          ) : (
            <ShowMain
              handleAddNewChannel={(formStatus) => setFormStatus(true)}
            />
          )}
        </main>
      </div>
    </LoginContext.Provider>
  );
};
