import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "../sochcastPro/pro.css";
import { BsChevronRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";

export const Distribution = () => {
  const history = useHistory();
  const handleDistribution = () => {
    history.push("/show-distribution");
  };
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Card className="distributionSochacast">
          <Card.Body className="mx-auto">
            <Row className="dis-ro mx-auto mt-5">
              <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                <h3 className="dis-title">Sochcast Distribution</h3>
                <p className="dis-sub-text">
                  See where your sochcast is distributed, manage your RSS and
                  distribute to more platforms.
                </p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <Button className="dis-button" onClick={handleDistribution}>
                  Go To Distribute{" "}
                  <BsChevronRight style={{ fontWeight: "600" }} />
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
