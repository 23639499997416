import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
// import { HelmetProvider } from "react-helmet-async";

ReactDOM.render(
  <React.StrictMode>
    {/* <HelmetProvider> */}
      <Provider store={store}>
        <App />
      </Provider>
    {/* </HelmetProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
);
