import React, { useState } from "react";
import { Modal, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { ReactComponent as Copy } from "../../assets/images/copy-outline.svg";
import { ReactComponent as Outline } from "../../assets/images/link-outline.svg";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import {
  FacebookButton,
  LinkedInButton,
  TwitterButton,
  EmailButton,
} from "react-social";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import TwitterIconD from "../../assets/socialIcons/twitter.png";
import FacebookIcon from "../../assets/socialIcons/facebook.png";
import EmailIcon from "../../assets/socialIcons/gmail.png";
import TwitterIcon from "../../assets/socialIcons/twitter.png";
import LinkIN from "../../assets/socialIcons/linkd.png";

export const ShowSocialShare = (props) => {
  const [copySuccess, setCopySuccess] = useState("");
  const handleOnCopyLink = (copyLink) => {
    navigator.clipboard.writeText(copyLink);
    setCopySuccess("copied");
  };

  let appId = "2569710076497393";
  let url = `https://app.sochcast.com/show/${props?.socialShareData[0]?.shows[0]?.slug}`;

  return (
    <>
      {/* Share modal STARTS */}

      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Sochcast - ${props?.socialShareData[0]?.shows[0]?.name}`}</title>

        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={props?.socialShareData[0]?.shows[0]?.name}
        />
        <meta
          property="og:description"
          content={props?.socialShareData[0]?.shows[0]?.description}
        />
        <meta
          property="og:image"
          content={props?.socialShareData[0]?.shows[0]?.show_image}
        />

        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={props?.socialShareData[0]?.shows[0]?.name}
        />
        <meta
          property="og:description"
          content={props?.socialShareData[0]?.shows[0]?.description}
        />
        <meta
          property="og:image"
          itemprop="image"
          content={props?.socialShareData[0]?.shows[0]?.show_image}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="https://app.sochcast.com/" />
        <meta property="twitter:url" content="https://app.sochcast.com/" />
        <meta
          name="twitter:title"
          content={props?.socialShareData[0]?.shows[0]?.name}
        />
        <meta
          name="twitter:description"
          content={props?.socialShareData[0]?.shows[0]?.description}
        />
        <meta
          name="twitter:image"
          content={props?.socialShareData[0]?.shows[0]?.show_image}
        />
      </Helmet>

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="termsTitle justify-content-center w-100"
          >
            Share
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="share-mod-listener">
            <Row>
              <Col md={2} xs={4}>
                <div className="share-img-holder">
                  <WhatsappShareButton
                    url={`https://app.sochcast.com/show/${props?.socialShareData[0]?.shows[0]?.slug}`}
                    title={`Hi there, Here’s an amazing show for you to listen now!`}
                    separator={` - `}
                    media={props.socialShareData[0]?.shows?.[0]?.show_image}
                  >
                    <WhatsappIcon size={45} round={true} />
                  </WhatsappShareButton>
                </div>
                <p className="share-text">Whatsapp</p>
              </Col>

              <Col md={2} xs={4}>
                <div className="share-img-holder">
                  <FacebookButton
                    url={url}
                    appId={appId}
                    style={{ background: "none", border: "none" }}
                  >
                    <img src={FacebookIcon} alt="Facebook" />
                  </FacebookButton>
                </div>
                <p className="share-text">Facebook</p>
              </Col>

              <Col md={2} xs={4}>
                <div className="share-img-holder">
                  <EmailButton
                    url={url}
                    style={{ background: "none", border: "none" }}
                  >
                    <img src={EmailIcon} alt="LinkedIn" />
                  </EmailButton>
                </div>
                <p className="share-text">Email</p>
              </Col>

              <Col md={2} xs={4}>
                <div className="share-img-holder">
                  <LinkedInButton
                    url={url}
                    title={`Hi there, Here’s an amazing show for you to listen now!`}
                    summary={props.socialShareData[0]?.shows[0]?.description}
                    source="Sochcast"
                    style={{ background: "none", border: "none" }}
                  >
                    <img src={LinkIN} alt="LinkedIn" />
                  </LinkedInButton>
                </div>
                <p className="share-text">LinkedIn</p>
              </Col>

              <Col md={2} xs={4}>
                <div className="share-img-holder">
                  <TwitterButton
                    url={url}
                    style={{ background: "none", border: "none" }}
                  >
                    <img src={TwitterIconD} alt="Twitter" />
                  </TwitterButton>
                </div>
                <p className="share-text">Twitter</p>
              </Col>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <div>
            <label className="formLabel text-center">Episode Link</label>
            <InputGroup className="mb-3">
              <InputGroup.Text>
                <Outline />
              </InputGroup.Text>
              <FormControl type="text" value={window.location.href} />
              <InputGroup.Text
                onClick={() => {
                  handleOnCopyLink(window.location.href);
                }}
                style={{ cursor: "pointer" }}
              >
                <Copy />
              </InputGroup.Text>
            </InputGroup>
            <div className="text-center">{copySuccess}</div>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Share modal Ends */}
    </>
  );
};
