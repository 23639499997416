import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router";
import { Header } from "../../components/header/Header";
import { TopHeader } from "../../components/header/TopHeader";
import { CommonTitle } from "../../components/CommonTitle";
import { Col, Row, Button, Modal } from "react-bootstrap";
import "../css/channel/Channel.css";
import { Formik, Form } from "formik";
import { InputField } from "../../components/formFields/InputField";
import { RadioButton } from "../../components/formFields/RadioButton";
import { TagsInput } from "../../components/formFields/TagsInput";
import { isUnlisted } from "../../helperFunction";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import apiInstance from "../../api";
import ShowIcon from "../../assets/images/Group1837.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showRatings } from "../../helperFunction";
import Multiselect from "multiselect-react-dropdown";
import { SelectDropdown } from "../../components/formFields/SelectDropdown";
import { accessToken } from "../../helperFunction";
import { useHistory } from "react-router";
import sochLogo from "../../assets/images/sochlogo.png";

export const ShowEdit = () => {
  const { id } = useParams();
  const history = useHistory();
  const bannerImage = useRef(null);
  const showImage = useRef(null);

  const [tagsFromAPI, setTagsFromAPI] = useState([]);
  const [editorState, setEditorState] = useState();
  const [apiResponse, setApiResponse] = useState([]);
  const [userInputTags, setUserInputTags] = useState([]);
  const [preferredLanguage, setPreferredLanguage] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [channels, setChannels] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [channelArray, setChannelArray] = useState([]);
  const [categoryArray, setCategoryArray] = useState([]);
  const [hostArray, setHostArray] = useState([]);
  const [filePreviewBannerImage, setFilePreviewBannerImage] = useState(null);
  const [filePreviewShowImage, setFilePreviewShowImage] = useState(null);
  const [buttonDraftStatus, setButtonDraftStatus] = useState(false);
  const [buttonPublishStatus, setButtonPublishStatus] = useState(false);

  const [bannerImageRemoveStatus, setBannerImageRemoveStatus] = useState(false);
  const [showImageRemoveStatus, setShowImageRemoveStatus] = useState(false);

  const [intorRemoveStatus, setIntroRemoveStatus] = useState(false);
  const [trailerRemoveStatus, setTrailerRemoveStatus] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    async function getAllLanguages() {
      await apiInstance
        .get("/all-languages/")
        .then((response) => {
          setPreferredLanguage(response.data.results);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function getAllCategories() {
      await apiInstance
        .get("/main-categories/")
        .then((response) => {
          setAllCategories(response.data.results);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function showEdit() {
      await apiInstance
        .patch(
          `/shows/${id}/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          }
        )
        .then((response) => {
          return response;
        })
        .then((data) => {
          if (data.status === 200) {
            setApiResponse(data.data.payload);
            let value = data.data.payload.description;

            const blocksFromHTML = convertFromHTML(`${value}`);
            const state = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            );
            const states = value
              ? EditorState.createWithContent(state)
              : EditorState.createEmpty();
            setEditorState(states);

            let temp = data.data.payload.tags;
            temp.forEach((item) => {
              delete item.id;
            });

            const finalsTags = temp.map((item) => item.name);
            setTagsFromAPI(finalsTags);
            setUserInputTags(finalsTags);
          }
        });
    }

    async function getAllChannels() {
      await apiInstance
        .get("/channels/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setChannels(response.data.results);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (error.response.data.error_type === "InvalidToken") {
              accessToken();
              getAllChannels();
            }
          }
        });
    }

    var config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    };

    async function getAllHosts() {
      let nextUrl = "/host/";
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl, config);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFuncGetHosts() {
      let temp1 = await getAllHosts();
      setHosts(temp1);
    }

    getAllChannels();
    getAllHosts();
    showEdit();
    getAllLanguages();
    getAllCategories();
    tempFuncGetHosts();
  }, [id]);

  const selectedTags = (tags) => {
    setUserInputTags(tags);
  };

  let unListed = "";
  let abusiveContent = "";
  let explicitContent = "";
  if (apiResponse.is_unlisted === true) {
    unListed = "Yes";
  } else if (apiResponse.is_unlisted === false) {
    unListed = "No";
  }

  if (apiResponse.abusive_content === true) {
    abusiveContent = "Yes";
  } else if (apiResponse.abusive_content === false) {
    abusiveContent = "No";
  }

  if (apiResponse.explicit_content === true) {
    explicitContent = "Yes";
  } else if (apiResponse.explicit_content === false) {
    explicitContent = "No";
  }

  const initialValues = {
    ShowName: `${apiResponse.name}`,
    explicitContent: `${explicitContent}`,
    abusiveContent: `${abusiveContent}`,
    selectChannel: "",
    selectLanguage: `${apiResponse.language}`,
    selectHost: "",
    isUnlisted: `${unListed}`,
    banner_image: ``,
    show_image: ``,
    picked: `${apiResponse.rating}`,
    intro: `${apiResponse.intro}`,
    trailer: `${apiResponse.trailer}`,
  };

  let selectedChannels = channels.filter((item) =>
    apiResponse.channels?.includes(item.id)
  );

  let selectedCategories = allCategories.filter((item) =>
    apiResponse.categories?.includes(item.id)
  );

  let selecteHosts = hosts.filter((item) =>
    apiResponse.hosts?.includes(item.id)
  );

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  const handleEditSubmit = (values) => {
    let unListed = "";
    let explicitContent = "";
    let abusiveContent = "";
    let isPublished = false;

    if (values.isUnlisted === "Yes") {
      unListed = "True";
      isPublished = false;
    } else if (values.isUnlisted === "No") {
      unListed = "False";
      isPublished = true;
    }

    if (values.explicitContent === "Yes") {
      explicitContent = "True";
    } else if (values.explicitContent === "No") {
      explicitContent = "False";
    }

    if (values.abusiveContent === "Yes") {
      abusiveContent = "True";
    } else if (values.abusiveContent) {
      abusiveContent = "False";
    }

    let categoryList2 = [];
    let hostList2 = [];
    let channelList2 = [];
    if (categoryArray.length !== 0) {
      categoryList2 = categoryArray.map((e) => e.id);
    }

    if (hostArray.length !== 0) {
      hostList2 = hostArray.map((e) => e.id);
    }

    if (channelArray.length !== 0) {
      channelList2 = channelArray.map((e) => e.id);
    }

    let data = new FormData();
    data.append("name", values.ShowName);
    data.append(
      "description",
      `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}`
    );

    if (typeof values.show_image !== "string") {
      data.append("show_image", values.show_image);
    }

    if (typeof values.banner_image === "object") {
      data.append("banner_image", values.banner_image);
    }

    if (typeof values.intro !== "string") {
      data.append("intro", values.intro);
    }

    if (intorRemoveStatus === true) {
      data.append("intro", "");
    }

    if (typeof values.trailer !== "string") {
      data.append("trailer", values.trailer);
    }

    if (trailerRemoveStatus === true) {
      data.append("trailer", "");
    }

    data.append("language", values.selectLanguage);

    for (var i = 0; i < categoryList2.length; i++) {
      data.append("categories", categoryList2[i]);
    }
    for (var m = 0; m < channelList2.length; m++) {
      data.append("channels", channelList2[m]);
    }
    for (var n = 0; n < hostList2.length; n++) {
      data.append("hosts", hostList2[n]);
    }
    data.append("rating", values.picked);
    data.append("abusive_content", `${abusiveContent}`);
    data.append("explicit_content", `${explicitContent}`);
    data.append("is_unlisted", `${unListed}`);
    data.append("is_published", isPublished);
    for (var j = 0; j < userInputTags.length; j++) {
      data.append(`tags[${j}]`, userInputTags[j]);
    }

    if (userInputTags.length < 1) {
      toast.error("Please enter atleast a minimum of 1 tags", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.explicitContent === "Yes" &&
      values.abusiveContent === "No" &&
      values.picked !== "A"
    ) {
      toast.error("Please change your rating to A - Adult", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.explicitContent === "No" &&
      values.abusiveContent === "Yes" &&
      values.picked !== "A"
    ) {
      toast.error("Please change your rating to A - Adult", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.explicitContent === "Yes" &&
      values.abusiveContent === "Yes" &&
      values.picked !== "A"
    ) {
      toast.error("Please change your rating to A - Adult", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (bannerImageRemoveStatus === true) {
      toast.error("Please upload Banner Image.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (showImageRemoveStatus === true) {
      toast.error("Please upload Show Image.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // else if (categoryList2.length === 0) {
    //   toast.error("Please select an Category", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // } else if (channelList2.length === 0) {
    //   toast.error("Please select an Channel", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // } else if (hostList2.length === 0) {
    //   toast.error("Please select an Host", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
    else {
      handleShow();
      setButtonPublishStatus(true);
      apiInstance
        .patch(`/shows/${id}/`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          history.push("/show");
        })
        .catch((error) => {
          if (error.response.data.error_type === "ValidationError") {
            if (error.response.data.errors[0].field === "show_image") {
              handleClose();
              setButtonPublishStatus(false);
              toast.error(
                `Show Image - ${error.response.data.errors[0].message}`,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }
          }
        });
    }
  };

  const handleEditDraft = (values) => {
    let unListed = "";
    let explicitContent = "";
    let abusiveContent = "";
    let isPublished = false;
    if (values.isUnlisted === "Yes") {
      unListed = "True";
    } else if (values.isUnlisted === "No") {
      unListed = "False";
    }

    if (values.explicitContent === "Yes") {
      explicitContent = "True";
    } else if (values.explicitContent === "No") {
      explicitContent = "False";
    }

    if (values.abusiveContent === "Yes") {
      abusiveContent = "True";
    } else if (values.abusiveContent) {
      abusiveContent = "False";
    }

    let categoryList2 = [];
    let hostList2 = [];
    let channelList2 = [];
    if (categoryArray.length !== 0) {
      categoryList2 = categoryArray.map((e) => e.id);
    }

    if (hostArray.length !== 0) {
      hostList2 = hostArray.map((e) => e.id);
    }

    if (channelArray.length !== 0) {
      channelList2 = channelArray.map((e) => e.id);
    }

    let data = new FormData();
    data.append("name", values.ShowName);
    data.append(
      "description",
      `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}`
    );

    if (typeof values.show_image !== "string") {
      data.append("show_image", values.show_image);
    }

    if (typeof values.show_image !== "string") {
      data.append("banner_image", values.banner_image);
    }

    if (typeof values.intro !== "string") {
      data.append("intro", values.intro);
    }

    if (intorRemoveStatus === true) {
      data.append("intro", "");
    }

    if (typeof values.trailer !== "string") {
      data.append("trailer", values.trailer);
    }

    if (trailerRemoveStatus === true) {
      data.append("trailer", "");
    }

    data.append("language", values.selectLanguage);
    for (var i = 0; i < categoryList2.length; i++) {
      data.append("categories", categoryList2[i]);
    }
    for (var c = 0; c < channelList2.length; c++) {
      data.append("channels", channelList2[c]);
    }
    for (var h = 0; h < hostList2.length; h++) {
      data.append("hosts", hostList2[h]);
    }
    data.append("rating", values.picked);
    data.append("abusive_content", `${abusiveContent}`);
    data.append("explicit_content", `${explicitContent}`);
    data.append("is_unlisted", `${unListed}`);
    data.append("is_published", isPublished);
    for (var shodraft = 0; shodraft < userInputTags.length; shodraft++) {
      data.append(`tags[${shodraft}]`, userInputTags[shodraft]);
    }

    if (userInputTags.length < 1) {
      toast.error("Please enter atleast a minimum of 1 tags", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.explicitContent === "Yes" &&
      values.abusiveContent === "No" &&
      values.picked !== "A"
    ) {
      toast.error("Please change your rating to A - Adult", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.explicitContent === "No" &&
      values.abusiveContent === "Yes" &&
      values.picked !== "A"
    ) {
      toast.error("Please change your rating to A - Adult", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.explicitContent === "Yes" &&
      values.abusiveContent === "Yes" &&
      values.picked !== "A"
    ) {
      toast.error("Please change your rating to A - Adult", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (bannerImageRemoveStatus === true) {
      toast.error("Please upload Banner Image.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (showImageRemoveStatus === true) {
      toast.error("Please upload Show Image.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // else if (categoryList2.length === 0) {
    //   toast.error("Please select an Category", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // } else if (channelList2.length === 0) {
    //   toast.error("Please select an Channel", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // } else if (hostList2.length === 0) {
    //   toast.error("Please select an Host", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
    else {
      handleShow();
      setButtonDraftStatus(true);
      apiInstance
        .patch(`/shows/${id}/`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          history.push("/show");
        })
        .catch((error) => {
          if (error.response.data.error_type === "ValidationError") {
            if (error.response.data.errors[0].field === "show_image") {
              handleClose();
              setButtonDraftStatus(false);
              toast.error(
                `Show Image - ${error.response.data.errors[0].message}`,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }
          }
        });
    }
  };

  const onHostSelect = (selectedList, selectedItem) => {
    setHostArray(selectedList);
  };

  const onChannelSelect = (selectedList, selectedItem) => {
    setChannelArray(selectedList);
  };

  const onCategorySelect = (selectedList, selectedItem) => {
    setCategoryArray(selectedList);
  };

  const onCategoryRemove = (selectedList, removedItem) => {
    setCategoryArray(selectedList);
  };

  const onChannelRemove = (selectedList, removedItem) => {
    setCategoryArray(selectedList);
  };

  const onHostRemove = (selectedList, removedItem) => {
    setHostArray(selectedList);
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const handleRemoveBannerImage = () => {
    setFilePreviewBannerImage("");
    setBannerImageRemoveStatus(true);
  };

  const handleRemoveShowImage = () => {
    setFilePreviewShowImage("");
    setShowImageRemoveStatus(true);
  };

  return (
    <div className="app">
      <Header />
      <main>
        <TopHeader name="Manage Show" />
        <div className="mt-5 p-3">
          <CommonTitle heading={`Edit Show`} text="" icons={ShowIcon} />

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <Row>
            {tagsFromAPI.length !== 0 ? (
              <Col xs={12} md={12} lg={12} xl={12} className="mt-5">
                <Formik initialValues={initialValues} enableReinitialize>
                  {(formik) => (
                    <Form onKeyDown={onKeyDown}>
                      <div className="channel-form">
                        <Row className="px-2 py-5">
                          <Col xs={12} md={10} lg={10} xl={10}>
                            <InputField
                              label="Show Name*"
                              name="ShowName"
                              type="text"
                              data-testid="show-name"
                              placeholder="Sochcast Title"
                            />
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10}>
                            <label
                              for="showDescription"
                              className="formLabel mt-3"
                            >
                              Description*
                            </label>
                            <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="ChannelDesscription"
                              editorClassName="editorClassName"
                              toolbar={{
                                options: ["emoji", "link"],
                                emoji: { className: "emojiClassNAme" },
                                link: {
                                  className: "imageClassName",
                                  options: ["link"],
                                },
                              }}
                              editorState={editorState}
                              defaultEditorState={editorState}
                              onEditorStateChange={onEditorStateChange}
                            />
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <label className="formLabel">Tag* </label>
                            {tagsFromAPI.length === 0 ? (
                              <TagsInput
                                selectedTags={selectedTags}
                                tags={[]}
                              />
                            ) : (
                              <TagsInput
                                selectedTags={selectedTags}
                                tags={tagsFromAPI}
                              />
                            )}
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <label className="formLabel">Rating</label>
                            <br />
                            <RadioButton options={showRatings} type="rating" />
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <Row>
                              <Col xs={12} md={6} lg={6} xl={6}>
                                <label className="formLabel">
                                  Explicit content
                                </label>
                                <br />
                                <RadioButton
                                  options={isUnlisted}
                                  type="explicitContent"
                                />
                              </Col>
                              <Col xs={12} md={6} lg={6} xl={6}>
                                <label className="formLabel">
                                  Abusive content
                                </label>
                                <br />
                                <RadioButton
                                  options={isUnlisted}
                                  type="abusiveContent"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <label className="formLabel">
                              Choose Banner Image:
                              <p className="text-muted">Only *.png accepted</p>
                            </label>

                            <br />
                            <Row>
                              <Col
                                xs={12}
                                md={3}
                                lg={3}
                                xl={3}
                                className="img-mobile-holder"
                              >
                                <div className="banner-image-holder">
                                  {filePreviewBannerImage !== null ? (
                                    <img
                                      src={filePreviewBannerImage}
                                      alt="Profile"
                                      className="img-fluid banner-image"
                                    />
                                  ) : (
                                    <img
                                      src={apiResponse.banner_image}
                                      alt="Profile"
                                      className="img-fluid banner-image"
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col xs={6} md={3} lg={3} xl={3} className="mt-2">
                                <input
                                  type="file"
                                  name="file"
                                  className="testadfasdfd"
                                  accept="image/png"
                                  ref={bannerImage}
                                  style={{ display: "none" }}
                                  onChange={(event) => {
                                    formik.setFieldValue(
                                      "banner_image",
                                      event.target.files[0]
                                    );
                                    setFilePreviewBannerImage(
                                      URL.createObjectURL(event.target.files[0])
                                    );
                                    setBannerImageRemoveStatus(false);
                                  }}
                                />
                                <Button
                                  className="upload-picture-button"
                                  onClick={() => {
                                    bannerImage.current &&
                                      bannerImage.current.click();
                                  }}
                                >
                                  Upload Picture
                                </Button>
                              </Col>
                              <Col xs={6} md={6} lg={6} xl={6} className="mt-2">
                                <Button
                                  className="upload-picture-button remove"
                                  onClick={handleRemoveBannerImage}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <label className="formLabel">
                              Choose Show Image:
                              <p className="text-muted">
                                Only *.png accepted (Image pixels must be
                                between 1400 and 3000.)
                              </p>
                            </label>
                            <br />
                            <Row>
                              <Col
                                xs={12}
                                md={3}
                                lg={3}
                                xl={3}
                                className="img-mobile-holder"
                              >
                                <div className="banner-image-holder">
                                  {filePreviewShowImage !== null ? (
                                    <img
                                      src={filePreviewShowImage}
                                      alt="Profile"
                                      className="img-fluid banner-image"
                                    />
                                  ) : (
                                    <img
                                      src={apiResponse.show_image}
                                      alt="Profile"
                                      className="img-fluid banner-image"
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col xs={6} md={3} lg={3} xl={3} className="mt-2">
                                <input
                                  type="file"
                                  name="file"
                                  accept="image/png"
                                  ref={showImage}
                                  style={{ display: "none" }}
                                  onChange={(event) => {
                                    formik.setFieldValue(
                                      "show_image",
                                      event.target.files[0]
                                    );
                                    setFilePreviewShowImage(
                                      URL.createObjectURL(event.target.files[0])
                                    );
                                    setShowImageRemoveStatus(false);
                                  }}
                                />
                                <Button
                                  className="upload-picture-button"
                                  onClick={() => {
                                    showImage.current &&
                                      showImage.current.click();
                                  }}
                                >
                                  Upload Picture
                                </Button>
                              </Col>
                              <Col xs={6} md={6} lg={6} xl={6} className="mt-2">
                                <Button
                                  className="upload-picture-button remove"
                                  onClick={handleRemoveShowImage}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <label className="formLabel">
                              Choose an Intro Audio File
                            </label>
                            <br />
                            <Row>
                              <Col xs={12} md={3} lg={3} xl={3}>
                                <input
                                  type="file"
                                  name="file"
                                  onChange={(event) => {
                                    formik.setFieldValue(
                                      "intro",
                                      event.target.files[0]
                                    );
                                  }}
                                  accept=".mp3,audio/*"
                                />
                                <br />
                                {intorRemoveStatus === false ? (
                                  <>
                                    {apiResponse.intro === null
                                      ? ""
                                      : apiResponse.intro.substr(
                                          apiResponse.intro.lastIndexOf("/") + 1
                                        )}
                                  </>
                                ) : null}
                              </Col>
                              <Col xs={12} md={3} lg={3} xl={3}>
                                <Button
                                  className="upload-picture-button remove"
                                  onClick={() => setIntroRemoveStatus(true)}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <label className="formLabel">
                              Choose an Trailer Audio File
                            </label>
                            <br />
                            <Row>
                              <Col xs={12} md={3} lg={3} xl={3}>
                                <input
                                  type="file"
                                  name="file"
                                  onChange={(event) => {
                                    formik.setFieldValue(
                                      "trailer",
                                      event.target.files[0]
                                    );
                                  }}
                                  accept=".mp3,audio/*"
                                />
                                <br />
                                {trailerRemoveStatus === false ? (
                                  <>
                                    {apiResponse.trailer === null
                                      ? ""
                                      : apiResponse.trailer.substr(
                                          apiResponse.trailer.lastIndexOf("/") +
                                            1
                                        )}
                                  </>
                                ) : null}
                              </Col>
                              <Col xs={12} md={3} lg={3} xl={3}>
                                <Button
                                  className="upload-picture-button remove"
                                  onClick={() => setTrailerRemoveStatus(true)}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <label className="formLabel">Channel</label>
                            <Multiselect
                              options={channels}
                              onSelect={onChannelSelect}
                              onRemove={onChannelRemove}
                              displayValue="name"
                              selectedValues={selectedChannels}
                            />
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <Row>
                              <Col xs={12} md={6} lg={6} xl={6}>
                                <SelectDropdown
                                  control="select"
                                  name="selectLanguage"
                                  data-testid="selectLanguage"
                                  id="selectLanguage"
                                  label="Select Language"
                                  options={preferredLanguage}
                                  preFillValue={apiResponse.language}
                                />
                              </Col>
                              <Col xs={12} md={6} lg={6} xl={6}>
                                <label className="formLabel">Communities</label>
                                <Multiselect
                                  options={allCategories}
                                  onSelect={onCategorySelect}
                                  onRemove={onCategoryRemove}
                                  displayValue="name"
                                  selectedValues={selectedCategories}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <label className="formLabel">Host</label>
                            <Multiselect
                              options={hosts}
                              onSelect={onHostSelect}
                              onRemove={onHostRemove}
                              displayValue="first_name"
                              selectedValues={selecteHosts}
                            />
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <label className="formLabel">Is Unlisted</label>
                            <br />
                            <RadioButton
                              options={isUnlisted}
                              type="isUnlisted"
                            />
                          </Col>
                        </Row>
                      </div>
                      <Row>
                        <Col
                          xs={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="mt-4 chananelButton"
                        >
                          <Button
                            disabled={buttonDraftStatus}
                            variant="primary"
                            type="submit"
                            className="channelDraftButton rounded-2 mx-3"
                            title="ChannelDrafButton"
                            data-testid="signUpBtton"
                            // disabled={formik.isSubmitting}
                            onClick={() => handleEditDraft(formik.values)}
                          >
                            {buttonDraftStatus ? "Saving ..." : "Draft"}
                          </Button>
                          <Button
                            disabled={buttonPublishStatus}
                            variant="primary"
                            type="submit"
                            className="channelPublishButton rounded-2"
                            title="channelPublishButton"
                            data-testid="signUpBtton"
                            // disabled={formik.isSubmitting}
                            onClick={() => handleEditSubmit(formik.values)}
                          >
                            {buttonDraftStatus ? "Saving ..." : "Publish"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
            ) : (
              <div className="loading">
                <img src={sochLogo} alt="Loading" />{" "}
                <span>Loading Sochcasts...</span>
              </div>
            )}
          </Row>
        </div>
      </main>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        data-keyboard="false"
      >
        <Modal.Header>
          <Modal.Title>Please wait</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p>We are creating your Sochcast. Thank you for your patience.</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center"></Modal.Footer>
      </Modal>
    </div>
  );
};
