import React, { useEffect, useState } from "react";
import { SochCastPro } from "../../components/sochcastPro/SochCastPro";
import { Row, Col, Button } from "react-bootstrap";
import { ShowList } from "./ShowList";
import { ReactComponent as AddIcon } from "../../assets/images/add.svg";
import apiInstance from "../../api";
import { accessToken } from "../../helperFunction";
import { Distribution } from "../../components/distribution/Distribution";

export const ShowMain = (props) => {
  const [distributionFlag, setDistributionFlag] = useState(false);
  useEffect(() => {
    getAllShows();
  }, []);

  async function getAllShows() {
    await apiInstance
      .get("/shows/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.count !== 0) {
            setDistributionFlag(true);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (error.response.data.error_type === "InvalidToken") {
            accessToken();
            getAllShows();
          }
        }
      });
  }

  return (
    <div className="p-5 mainContainerAdd">
      {distributionFlag ? <Distribution /> : /* <SochCastPro /> */ null}

      <Row className="mt-5">
        <Col xs={6} md={8} lg={8} xl={8}>
          <h1 className="dash-text">Show List</h1>
          {/* <p className="channelDescription">
            Selecting Multiple Show Allows You To Capture Embed <br />
            Codes Or Transcripts Across Your Content.
          </p> */}
        </Col>
        <Col xs={6} md={4} lg={4} xl={4} className="add-channel-button">
          <Button
            variant="primary"
            className="addbuttontext"
            onClick={props.handleAddNewChannel}
          >
            <AddIcon style={{ width: "14px", height: "14px" }} /> Add Show
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12} cl={12} className="mt-2">
          <ShowList />
        </Col>
      </Row>
    </div>
  );
};
