import React from "react";
import { Card, Button } from "react-bootstrap";
import "./importExistingShow.css";
import { ReactComponent as CloudUpload } from "../../assets/images/cloud-upload.svg";
import { useHistory } from "react-router-dom";

export const ImportExistingShow = () => {
  const hsitory = useHistory();
  const handleImportShowLink = () => {
    hsitory.push("/import-existing-show");
  };
  return (
    <Card className="mb-5 text-center importShowCard">
      <Card.Body>
        <Card.Title className="importHeading">
          <div class="importBackground"></div>
          Create Sochcast
        </Card.Title>
        <Card.Text className="importText">
          <p>
            You can import all of your episodes within a few minutes. Nothing
            will change with your <br /> existing hosting provider or listeners.
            Once imported, we will give you RSS redirect directions
            <br /> based on your particular hosting provider.
          </p>
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <Button
          variant="primary"
          type="submit"
          className="channelPublishButton rounded-2 importShows mb-3"
          title="channelPublishButton"
          data-testid="signUpBtton"
          onClick={handleImportShowLink}
        >
          <CloudUpload style={{ width: "14px", height: "14px" }} />
          {"   "}
          Import an Existing Sochcast
        </Button>
      </Card.Footer>
    </Card>
  );
};
