import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../listener.css";
import FeatureImage from "../../assets/images/MaskGroup20.png";
import { SochcastOriginals } from "../sochcastoriginals/SochcastOriginals";
import { PopularShows } from "../popularshows/PopularShows";

export const Trending = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
    smallDevices: {
      breakpoint: { max: 320, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className="my-5">
        <SochcastOriginals />
        <PopularShows />
      </div>
    </>
  );
};
