import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { Header } from "../../components/header/Header";
import { TopHeader } from "../../components/header/TopHeader";
import { CommonTitle } from "../../components/CommonTitle";
import { Col, Row, Button } from "react-bootstrap";
import "../css/channel/Channel.css";
import ChannelIcon from "../../assets/images/Group1787.png";
import { Formik, Form } from "formik";
import { InputField } from "../../components/formFields/InputField";
import { RadioButton } from "../../components/formFields/RadioButton";
import { TagsInput } from "../../components/formFields/TagsInput";
import { isUnlisted } from "../../helperFunction";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import apiInstance from "../../api";
import { genderSelect } from "../../helperFunction";
import { accessToken } from "../../helperFunction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";
import sochLogo from "../../assets/images/sochlogo.png";

export const EditHost = () => {
  const [apiResponse, setApiResponse] = useState([]);
  const [userInputTags, setUserInputTags] = useState([]);
  const [tagsFromAPI, setTagsFromAPI] = useState([]);
  const [editorState, setEditorState] = useState();
  const [filepreview, setFilePreview] = useState(null);
  const [buttonPublishStatus, setButtonPublishStatus] = useState(false);
  const [hostDetails, setHostDetails] = useState([]);
  const [genderSel, setGenderSel] = useState("");

  const { id } = useParams();
  const history = useHistory();
  const fileElem = useRef(null);

  useEffect(() => {
    async function hostEdit() {
      await apiInstance
        .patch(
          `/host/${id}/edit/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          }
        )
        .then((response) => {
          return response;
        })
        .then((data) => {
          if (data.status === 200) {
            setApiResponse(data.data.payload);
            let value = data.data.payload.description;

            if (data.data.payload.gender === "1") {
              setGenderSel("Man");
            } else if (data.data.payload.gender === "2") {
              setGenderSel("Woman");
            } else if (data.data.payload.gender === "3") {
              setGenderSel("Non-Binary");
            } else if (data.data.payload.gender === "4") {
              setGenderSel("Other");
            }

            const blocksFromHTML = convertFromHTML(`${value}`);
            const state = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            );
            const states = value
              ? EditorState.createWithContent(state)
              : EditorState.createEmpty();
            setEditorState(states);

            let temp = data.data.payload.tags;
            temp.forEach((item) => {
              delete item.id;
            });

            const finalsTags = temp.map((item) => item.name);
            setTagsFromAPI(finalsTags);
          }
        });
    }

    async function getHostDetails() {
      await apiInstance
        .get(`/host/${id}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setHostDetails(res.data.payload);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    hostEdit();
    getHostDetails();
  }, [id]);

  const selectedTags = (tags) => {
    setUserInputTags(tags);
  };

  let unListed = "";
  if (apiResponse.is_unlisted === true) {
    unListed = "Yes";
  } else {
    unListed = "No";
  }

  const initialValues = {
    firstName: `${hostDetails.first_name}`,
    lastName: `${hostDetails.last_name}`,
    email: `${hostDetails.email}`,
    host_image: `${apiResponse.host_image}`,
    isUnlisted: `${unListed}`,
    picked: `${genderSel}`,
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  const handleSubmit = (values) => {
    var genderSel = "";
    if (values.picked === "Man") {
      genderSel = "1";
    } else if (values.picked === "Woman") {
      genderSel = "2";
    } else if (values.picked === "Non-Binary") {
      genderSel = "3";
    } else if (values.picked === "Other") {
      genderSel = "4";
    }

    let unListed = "";
    let isPublished = false;
    if (values.isUnlisted === "Yes") {
      unListed = "True";
      isPublished = false;
    } else if (values.isUnlisted === "No") {
      unListed = "False";
      isPublished = true;
    }

    if (userInputTags.length < 1) {
      toast.error("Please enter atleast a minimum of 1 tags", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setButtonPublishStatus(true);
      let data = new FormData();
      data.append("first_name", values.firstName);
      data.append("last_name", values.lastName);
      data.append("email", values.email);
      data.append(
        "description",
        `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}`
      );
      data.append("gender", genderSel);
      data.append("is_unlisted", unListed);
      for (var i = 0; i < userInputTags.length; i++) {
        data.append(`tags[${i}]`, userInputTags[i]);
      }

      if (typeof values.host_image === "object") {
        data.append("host_image", values.host_image);
      }

      data.append("is_published", isPublished);

      apiInstance
        .patch(`/host/${id}/edit/`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            history.push("/host");
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (error.response.data.error_type === "InvalidToken") {
              accessToken();
              handleSubmit();
            }
          }
        });
    }
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const handleRemoveHostImage = () => {
    setFilePreview(null);
  };

  return (
    <div className="app">
      <Header />
      <main>
        <TopHeader name="Manage Host" />
        <div className="p-5 mainContainerAdd">
          <CommonTitle
            heading={`Edit Host`}
            text="An If You'd Like To List More Than One Author, Separate Names Using A Comma."
            icons={ChannelIcon}
          />

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <Row>
            {tagsFromAPI.length ? (
              <Col xs={12} md={12} lg={12} xl={12} className="mt-5">
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {(formik) => (
                    <Form onKeyDown={onKeyDown}>
                      <div className="channel-form">
                        <Row className="mx-4 py-5">
                          <Col xs={12} md={10} lg={10} xl={10}>
                            <Row>
                              <Col xs={12} md={6} lg={6} xl={6}>
                                <InputField
                                  label="First Name*"
                                  name="firstName"
                                  type="text"
                                  data-testid="host-first-name"
                                />
                              </Col>
                              <Col xs={12} md={6} lg={6} xl={6}>
                                <InputField
                                  label="Last Name*"
                                  name="lastName"
                                  type="text"
                                  data-testid="host-last-name"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10}>
                            <InputField
                              label="Email"
                              name="email"
                              type="email"
                              data-testid="host-email-id"
                              readOnly
                            />
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10}>
                            <label
                              for="channelDescription"
                              className="formLabel mt-3"
                            >
                              Description*
                            </label>
                            <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="ChannelDesscription"
                              editorClassName="editorClassName"
                              toolbar={{
                                options: ["emoji", "link"],
                                emoji: { className: "emojiClassNAme" },
                                link: {
                                  className: "imageClassName",
                                  options: ["link"],
                                },
                              }}
                              editorState={editorState}
                              defaultEditorState={editorState}
                              onEditorStateChange={onEditorStateChange}
                            />
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <label className="formLabel">
                              What's your gender ?
                            </label>
                            <br />
                            <RadioButton options={genderSelect} type="gender" />
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <label className="formLabel">Tag*</label>
                            {tagsFromAPI.length === 0 ? (
                              <TagsInput
                                selectedTags={selectedTags}
                                tags={[]}
                              />
                            ) : (
                              <TagsInput
                                selectedTags={selectedTags}
                                tags={tagsFromAPI}
                              />
                            )}
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <label className="formLabel">
                              Upload Host image*
                              <p className="text-muted">Only *.png accepted</p>
                            </label>
                            <br />
                            <Row>
                              <Col
                                xs={12}
                                md={3}
                                lg={3}
                                xl={3}
                                className="img-mobile-holder"
                              >
                                <div className="banner-image-holder">
                                  {filepreview !== null ? (
                                    <img
                                      src={filepreview}
                                      alt="Host"
                                      className="img-fluid banner-image"
                                    />
                                  ) : (
                                    <img
                                      src={apiResponse.host_image}
                                      alt="Profile"
                                      className="img-fluid banner-image"
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col xs={6} md={3} lg={3} xl={3} className="mt-2">
                                <input
                                  type="file"
                                  name="file"
                                  ref={fileElem}
                                  accept="image/png"
                                  style={{ display: "none" }}
                                  onChange={(event) => {
                                    formik.setFieldValue(
                                      "host_image",
                                      event.target.files[0]
                                    );
                                    setFilePreview(
                                      URL.createObjectURL(event.target.files[0])
                                    );
                                  }}
                                />
                                <Button
                                  className="upload-picture-button"
                                  onClick={() => {
                                    fileElem.current &&
                                      fileElem.current.click();
                                  }}
                                >
                                  Upload Picture
                                </Button>
                              </Col>
                              <Col xs={6} md={6} lg={6} xl={6} className="mt-2">
                                <Button
                                  className="upload-picture-button remove"
                                  onClick={handleRemoveHostImage}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                            <label className="formLabel">Is Unlisted</label>
                            <br />
                            <RadioButton
                              options={isUnlisted}
                              type="isUnlisted"
                            />
                          </Col>
                        </Row>
                      </div>
                      <Row>
                        <Col
                          xs={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="mt-4 chananelButton"
                        >
                          <Button
                            disabled={buttonPublishStatus}
                            variant="primary"
                            type="submit"
                            className="channelPublishButton rounded-2"
                            title="channelPublishButton"
                            data-testid="signUpBtton"
                          >
                            {buttonPublishStatus ? "Saving ..." : "Publish"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
            ) : (
              <div className="loading">
                <img src={sochLogo} alt="Loading" />{" "}
                <span>Loading Sochcasts...</span>
              </div>
            )}
          </Row>
        </div>
      </main>
    </div>
  );
};
