import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "../css/channel/Channel.css";
import DataTable from "react-data-table-component";
import apiInstance from "../../api";
import { ReactComponent as EditIcon } from "../../assets/images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/DeleteIcon.svg";
import { useHistory } from "react-router";
import { accessToken } from "../../helperFunction";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ChannelList = () => {
  const [channels, setChannels] = useState([]);
  const [deletedRow, setDeletedRow] = useState(false);
  const history = useHistory();

  useEffect(() => {
    accessToken();
    async function getAllChannels() {
      let nextUrl = "/channels/";
      let temp = [];
      let config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      };
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl, config);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFunc() {
      let temp1 = await getAllChannels();
      setChannels(temp1);
    }

    tempFunc();
  }, [deletedRow]);

  channels.forEach((arrayItem) => {
    delete arrayItem.description;
    delete arrayItem.tags;
    delete arrayItem.updated_at;
  });

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "No.",
      // selector: (row) => row.title,
      cell: (row, index) => index + 1,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Channel No.",
      selector: (row) => row.id,
    },
    {
      name: "Date",
      selector: (row) => {
        const myMomentObject = moment(
          `${row.created_at}`,
          "YYYY-MM-DD"
        ).toDate();
        const data = moment(myMomentObject).format("MMM D, YYYY");
        return data;
      },
    },
    {
      name: "Publish Status",
      selector: (row) => {
        if (row.is_published === true) {
          return "Published";
        } else {
          return "Draft";
        }
      },
    },
    {
      name: "Is Unlisted",
      selector: (row) => {
        if (row.is_unlisted === true) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <EditIcon
            onClick={() => handleChannelEdit(row.id)}
            id={row.id}
            className="editIconChannelList"
          />
          <DeleteIcon
            onClick={() => handleChannelDelete(row.id, row.name)}
            id={row.id}
            className="deleteIconChannelList mx-3"
          />
        </>
      ),
    },
  ];

  const handleChannelEdit = (channelUID) => {
    history.push(`/channel/edit/${channelUID}`);
  };

  const handleChannelDelete = (channelUID, channelName) => {
    setDeletedRow(false);
    apiInstance
      .patch(
        `/channels/${channelUID}/delete/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      )
      .then((response) => {
        setDeletedRow(true);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (error.response.data.error_type === "InvalidToken") {
            accessToken();
            handleChannelDelete();
          }
        } else if (error.response.status === 405) {
          toast.warning(`You Cannot delete ${channelName}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const data = channels;
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Card className="ChannelListTable">
        <Card.Body>
          <DataTable
            pagination
            columns={columns}
            data={data}
            customStyles={customStyles}
          />
        </Card.Body>
      </Card>
    </>
  );
};
