import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { keepTheme } from "../utils/listenerTheme/Theme";
import { TopHeaderNavigation } from "./listener/TopHeaderNavigation";
import { FiFacebook, FiInstagram } from "react-icons/fi";
import { BsTwitter } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useLocation } from "react-router-dom";

export const PrivacyPolicy = () => {
  const [themeStatus, setThemeStatus] = useState("theme-light");
  const history = useHistory();
  const year = moment().format("YYYY");
  const { pathname } = useLocation();

  useEffect(() => {
    keepTheme();
    if (localStorage.getItem("theme") === "theme-dark") {
      setThemeStatus("theme-dark");
    } else if (localStorage.getItem("theme") === "theme-light") {
      setThemeStatus("theme-light");
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className={themeStatus}>
        <TopHeaderNavigation
          changeTheme={(themeStatus) => setThemeStatus(themeStatus)}
          upgrade="home"
        />
        <Container>
          <h1 className="termsofUSe">
            PRIVACY POLICY<sup>1</sup>
          </h1>

          <p className="agreecontent">1. DEFINITIONS:</p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>
              1.1. <b>“App”</b> means the mobile application namely “
              <b>[Sochcast]</b>” owned and operated by Sochcast;
            </p>
            <p>
              1.2. <b>“Applicable Law”</b> means the Indian Information
              Technology Act, 2000, as well as the rules made thereunder and all
              applicable statutes, enactments, acts of legislature or
              Parliament, laws, ordinances, rules, by-laws, regulations,
              notifications, guidelines, policies, directions, directives,
              orders etc., of any government authority, regulatory bodies,
              tribunal, board, or court in India;
            </p>
            <p>
              1.3. <b>“Content”</b> means the photographs, text, graphics, user
              interfaces, visual interfaces, trademarks, service marks, logos,
              artwork, information, design, structure, sound recordings,
              podcasts, audio, music, video, video/audio-video and arrangement
              of any other materials and information incorporated into and/or
              made available through the Platform from time to time;
            </p>
            <p>
              1.4. <b>“Personal Information”</b> means any information that
              relates to a natural person, which, either directly or indirectly,
              in combination with other information available or likely to be
              available to a body corporate, which is capable of identifying
              such person;
            </p>
            <p>
              1.5. <b>“Platform”</b> means the App and Website collectively, or
              any part thereof;
            </p>
            <p>
              1.6. <b>“Privacy Policy”</b> means this privacy policy;
            </p>
            <p>
              1.7. <b>“Sensitive personal data or information”</b> of a person
              means such personal information which consist of information
              relating to:-
            </p>
            <div
              style={{
                marginLeft: "40px",
              }}
            >
              <p>1.7.1. Biometric information;</p>
              <p>
                1.7.2. Financial information such as bank account, or credit
                card, or debit card, or other payment instrument details;
              </p>
              <p>1.7.3. Medical records and history;</p>
              <p>1.7.4. Password;</p>
              <p>1.7.5. Physical, psychological and mental health condition;</p>
              <p>1.7.6. Sexual orientation;</p>
              <p>
                1.7.7. Any detail relating to the above clauses as provided to a
                body corporate for providing services; and
              </p>
              <p>
                1.7.8. Any of the information received under above clauses by a
                body corporate for processing, stored or processed under lawful
                contract or otherwise;
              </p>
            </div>
            <p>
              1.8. <b>“Sochcast”</b>, <b>“we”</b>, <b>“us”</b> or <b>“our”</b>{" "}
              shall mean Sochcast Media Private Limited a company incorporated
              under the laws of India bearing CIN# U22300KA2020PTC137956;
            </p>
            <p>
              1.9. <b>“Third Party”</b> means any person or entity other than
              the User, or Sochcast;
            </p>
            <p>
              1.10. <b>“User”</b> means any person who accesses and uses the
              Platform or any part thereof for the purpose of viewing,
              listening, sharing, displaying or uploading Content;
            </p>
            <p>
              1.11. <b>“Website”</b> shall mean the website that Sochcast
              presently owns and operates{" "}
              <a href="http://www.sochcast.com">http://www.sochcast.com</a>.
            </p>
          </div>

          <p className="agreecontent">2. SCOPE OF THIS PRIVACY POLICY:</p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>
              2.1. This Privacy Policy specifies Sochcast’s obligation in
              protecting the privacy of the User. By voluntarily providing us
              with Personal Information, the User expressly represents that
              he/she is either the owner of such Personal Information, or
              otherwise has the requisite consent to provide the same to us.
            </p>
          </div>

          <p className="agreecontent">
            3. HOW DOES SOCHCAST COLLECT INFORMATION?
          </p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>
              3.1. Sochcast does not collect Sensitive personal data or
              information of the User. Sochcast asks for and may collect
              Personal Information about User such as his/her name, age, phone
              number, email, photo, location, gender, birth date, postal
              address, e-mail address, wireless address when the User fills up a
              registration form for uploading his/her Content on the Platform.
            </p>
            <p>
              The text in a cookie often consists of a string of numbers and
              letters that uniquely identifies User’s computer, but it can
              contain other information as well.
            </p>
            <p>
              3.3. In addition to the cookies which Sochcast sets when User
              visits the Platform, a Third Party may also set cookies when a
              User visits the Platform; in some cases that is because Sochcast
              has hired or may hire the Third Party to provide some services on
              its behalf. If User wants to know more about these cookies and
              personal data, User may please refer to the privacy notices
              directly on the respective Third Party’s website.
            </p>
            <p>
              3.4. Sochcast gathers information and stores it in logs when the
              User accesses the Platform. This information includes internet
              protocol [IP] addresses as well as browser type, internet service
              provider, URLs of referring/exit pages, operating system,
              date/time stamp, information User searches for geographies and
              language preferences, identification numbers associated with
              User’s devices, User’s telecommunications provider and system
              configuration information. Sporadically, Sochcast co-relates the
              Personal Information to information gathered in its log files as
              necessary to improve the Platform. In such a case, Sochcast would
              treat the combined information in accordance with this Privacy
              Policy.
            </p>
            <p>
              3.5. Sochcast may also receive reports based on the use of these
              technological tools by its Third Party service providers on an
              individual and/or aggregated basis.
            </p>
            <p>
              3.6. User Control: Users have the right to access, delete, or update their
              collected images at any time by contacting us through the provided support channels.
            </p>
          </div>

          <p className="agreecontent">4. HOW TO CONTROL COOKIES?</p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>
              4.1. User can set the web browser to refuse all cookies, or to
              indicate when a cookie is being sent to the User. However, this
              may prevent the Platform from working properly. User can also set
              the browser to delete cookies every time User finishes browsing.
            </p>
            <p>
              4.2. Most web browsers automatically accept cookies but provide
              controls that allow the User to block or delete them. To find
              information about specific browsers, kindly visit the concerned
              browser developer’s website.
            </p>
          </div>

          <p className="agreecontent">
            5. HOW DOES SOCHCAST USE THE INFORMATION COLLECTED?
          </p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>
              5.1. Sochcast uses cookies and other information gathering
              technological tools for multiple purposes which may include:-
            </p>
            <div
              style={{
                marginLeft: "40px",
              }}
            >
              <p>
                5.1.1. <u>Access</u> – To provide uninterrupted access to the
                Platform, or any part thereof;
              </p>
              <p>
                5.1.2. <u>Analytics</u> - To gather usage and performance data
                such as the number of unique visitors to the Platform and to
                develop other statistics about the operations of the Platform.
                This includes cookies from Sochcast and from Third Party
                analytics providers;
              </p>
              <p>
                5.1.3. <u>Availability</u> – To make available news, or
                information about Sochcast and its related entities, advertisers
                or other Users;
              </p>
              <p>
                5.1.4. <u>Communication</u> – To send User the messages,
                including responses to his/her comments, questions, and requests
                [if any]. Also, to send Users the technical notices, security
                alerts;
              </p>
              <p>
                5.1.5. <u>Login and Verification</u> – When a User logs into the
                Platform using his/her User name and password, Sochcast stores a
                unique ID number and the time the User logged in, in an
                encrypted cookie on the User’s device. This cookie allows the
                User to move seamlessly within the Platform without User having
                to login again multiple times. User can also save his/her login
                information so the User does not have to login each time he/she
                returns to the Platform;
              </p>
              <p>
                5.1.6. <u>Monitoring</u> – To monitor and analyse trends,
                unauthorised usage and undesirable activities in connection with
                the Platform;
              </p>
              <p>
                5.1.7. <u>Personalisation</u> – To personalize the Platform and
                communications by providing Content that matches User’s
                interests and preferences;
              </p>
              <p>
                5.1.8. <u>Performance Enhancement</u> – To ensure that the
                Platform downtime is minimal and that it functions optimally;
              </p>
              <p>
                5.1.9. <u>Promotions</u> – To send the promotional information
                to the User, such as newsletter, marketing campaigns.
              </p>
              <p>
                5.1.10. <u>Security</u> - To detect potential fraud and abuse of
                the Platform, including but not limited to any botnet or Trojan
                attack, or distributed denial of service [DDoS], etc. Also, to
                investigate, or prevent unauthorized access to any part of the
                Platform and any illegal activities;
              </p>
              <p>
                5.1.11. <u>Social Media</u> - the Platform includes social media
                cookies that enable Users who are logged in on a social media
                platform to share Content via that service;
              </p>
              <p>
                5.1.12. For any other purpose for which Sochcast has obtained
                User’s specific consent.
              </p>
            </div>
            <p>
              5.2. Collection of Images: We collect users' images for the purpose
              of improving our app's functionality and providing better user experience.
            </p>
            <p>
              5.3. Use of Images: The collected images will be used only for the purpose
              they were collected for and will not be shared with any third parties without
              the user's consent.
            </p>
          </div>

          <p className="agreecontent">
            6. WHOM DOES SOCHCAST SHARE THE INFORMATION COLLECTED?
          </p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>6.1. Sochcast may disclose Personal Information:-</p>
            <div style={{ marginLeft: "40px" }}>
              <p>
                6.1.1. In response to subpoenas, court summons/ orders, or legal
                process, or to establish or exercise its legal rights, or defend
                against legal claims; or{" "}
              </p>
              <p>
                6.1.2. If Sochcast believes it is necessary in order to
                investigate, prevent, or take action regarding illegal
                activities, suspected fraud, situations involving potential
                threats to the physical safety of any person, violations of the
                Terms of Use , or as otherwise required by Applicable Law.
              </p>
            </div>
            <p>
              6.2. Sochcast shares information, including Personal Information
              with:-
            </p>
            <div style={{ marginLeft: "40px" }}>
              <p>
                6.2.1. A Third Party when Sochcast has User’s consent to do so;{" "}
              </p>
              <p>
                6.2.2. It’s Third Party service provider that Sochcast uses to
                provide hosting for and maintenance of the Platform, backup,
                storage, and other services for it. These Third Party service
                providers may have access to or process User’s Personal
                Information for the purpose of providing certain services to
                Sochcast. Sochcast does not permit its Third Party service
                providers to use the Personal Information that it shares with
                them for their marketing purposes, or for any other purpose than
                in connection with the services they provide to Sochcast.
              </p>
            </div>
            <p>
              6.3. From time to time, Sochcast may post testimonials on the
              Platform that may contain Personal Information. Sochcast obtains
              User’s consent to post his/her name along with his/her testimonial
              prior to posting it. If the User desires to update or delete
              his/her testimonial, User can contact us at{" "}
              <a href="mailto:support@sochcast.com">support@sochcast.com</a>.
            </p>
          </div>

          <p className="agreecontent">
            7. HOW DOES SOCHCAST PROTECT USER’S INFORMATION?
          </p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>
              7.1. Sochcast stores Personal Information about the Users within
              India.
            </p>
            <p>
              7.2. Sochcast is committed to protecting the security of Personal
              Information of all the Users of the Platform and therefore
              Sochcast uses a variety of physical, electronic, and procedural
              safeguards/measures to actively protect the confidentiality,
              security and integrity of User’s Personal Information from
              unauthorised access, use or disclosure.
            </p>
            <p>
              7.3. Sochcast has managerial, technical, operational and physical
              security control measures for protecting User’s Personal
              Information and where appropriate, these steps can include
              technical measures including but not limited to firewalls,
              prevention systems and encryption.
            </p>
            <p>
              7.4. Storage of Images: All collected images will be stored securely on our
              servers and will be protected against unauthorized access, use, or disclosure.
            </p>
          </div>

          <p className="agreecontent">
            8. HOW LONG DOES SOCHCAST RETAIN USER INFORMATION?
          </p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>
              8.1. Sochcast retains User’s Personal Information for as long as
              is needed to fulfil the purposes outlined in this Privacy Policy,
              unless a longer retention period is required or permitted by
              Applicable Law [such as tax, accounting or other legal
              requirements]. When Sochcast has no on-going legitimate business
              requirement to process the Personal Information of any User,
              Sochcast will either delete, or encrypt it, or make it anonymous,
              or, if this is not possible [for example, because User’s Personal
              Information has been stored in backup archives], then Sochcast
              will securely store such User’s Personal Information and isolate
              it from any further processing until deletion is possible
              <sup>8</sup>.
            </p>
            <p>
              8.2. For Personal Information that Sochcast processes on behalf of
              its Users, Sochcast will retain such Personal Information in
              accordance with the Terms of Use on the Platform, or as per
              Sochcast’s separate agreements with them as the case may be,
              subject to Applicable Law.
            </p>
          </div>

          <p className="agreecontent">
            9. INTERNATIONAL TRANSFER OF INFORMATION COLLECTED:
          </p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>
              9.1. To facilitate its operations, Sochcast may access such
              Personal Information from around the world, including from other
              countries in which Sochcast has operations for the purposes
              described in this Privacy Policy. Sochcast may also transfer
              User’s Personal Information to its Third Party vendors, who may be
              located in a different country. Such countries may have laws which
              are different and potentially less protective. When Sochcast does
              the transfer of User’s Personal Information, Sochcast uses a
              variety of legal mechanisms, including contracts, to help ensure
              User’s rights and protections travel with Users data<sup>9</sup>.
            </p>
            <p>
              9.2. If the User is visiting the Platform from other regions with
              laws governing data collection and use, kindly note that by
              providing this Personal Information User is agreeing to the
              transfer of his/her Personal Information to India in accordance
              with this Privacy Policy.
            </p>
          </div>

          <p className="agreecontent">10. COMMUNICATION PREFERENCES:</p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>
              10.1. Sochcast offers those who provide personal contact
              information a means to choose how Sochcast uses the information
              provided.
            </p>
            <p>
              10.2. User may manage his/her receipt of marketing, promotional
              and non-transactional communications by clicking on the
              “unsubscribe” link located on the bottom of Sochcast’s marketing
              emails; or alternatively User can “unsubscribe” by writing an
              e-mail to the email i.d. mentioned in the ‘Contact Us’ section at
              the end of this Policy . However, Sochcast reserves the right to
              send non-promotional emails, such as those about User’s account,
              or the ongoing business relations between the User and Sochcast
              [if any].
            </p>
            <p>
              10.3. Sochcast may also, with the consent of the User, send
              promotional and non-promotional push notifications or alerts to
              Users mobile device. User can deactivate these messages at any
              time by changing the notification settings on his/her mobile
              device.
            </p>
          </div>

          <p className="agreecontent">11. USER’S PRIVACY RIGHTS:</p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>
              11.1. Upon request Sochcast will provide User with information
              about whether Sochcast holds, or processes on behalf of a Third
              Party, any of such User’s Personal Information. To request this
              information kindly contact at{" "}
              <a href="mailto:support@sochcast.com">support@sochcast.com</a>.
              Users may update or change their information by editing their
              profile when logged into the Platform. To make a request to have
              Personal Information maintained by Sochcast returned to the User,
              or removed kindly email{" "}
              <a href="mailto:support@sochcast.com">support@sochcast.com</a>.
              Requests to access, change, or remove User’s information will be
              handled within thirty [30] business days.
            </p>
            <p>
              11.2. An individual who seeks access to, or who seeks to correct
              or, amend inaccuracies in, or delete Personal Information stored
              or processed by Sochcast on behalf of a User should direct his/her
              query to the internal Data Protection Officer [“<b>DPO</b>”]
              appointed by Sochcast whose details are mentioned in Clause 15.1
              of this Privacy Policy. Upon receipt of a request from Users for
              Sochcast to remove the data, Sochcast will respond to their
              request within thirty [30] business days. Sochcast will retain
              Personal Information that it stores and process on behalf of its
              Users for as long as needed.
            </p>
            <p>
              11.3. Security of User’s Personal Information is important to
              Sochcast. Sochcast follows generally accepted standards to protect
              the Personal Information submitted to Sochcast, both during
              transmission and once it is received. Kindly be aware that despite
              Sochcast’s best efforts, no data security measures can guarantee
              complete security. User should take steps to protect against
              unauthorized access to User’s login ID’s, password, phone and
              computer, among other things, logging off after using a shared
              computer, choosing a robust password that nobody else knows, or
              can easily guess and keeping user log-in and password
              confidential. Sochcast is not responsible for any lost, stolen, or
              compromised logins, passwords, or for any activity on User
              account, via unauthorized password activity.
            </p>
            <p>
              11.4. If User provides Sochcast with Personal Information in
              connection with the Content uploaded by him/her on the Platform,
              Sochcast will delete this information upon User’s written request,
              provided that, notwithstanding such request, this information may
              be retained for as long as User maintains an account, or as needed
              to comply with Sochcast’s legal obligations, resolve disputes and
              enforce its agreements.
            </p>
          </div>

          <p className="agreecontent">12. GENERAL DATA PROTECTION RIGHTS:</p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>
              12.1. When Sochcast processes Personal Information about User,
              Sochcast does so with User’s consent and/or as necessary to
              provide access to the Platform to the Users, operate its business,
              meet its contractual and legal obligations, protect the security
              of Sochcast’s systems and its Users, or fulfil other legitimate
              interests of Sochcast as enumerated in this Privacy Policy.
            </p>
            <p>
              12.2. Sochcast adheres to applicable data protection laws in the
              European Union, including the General Data Protection Regulation
              [“<b>GDPR</b>”] , whereby the User has a right to:-
            </p>
            <div style={{ marginLeft: "40px" }}>
              <p>
                12.2.1. Be informed of and request access to the personal data
                Sochcast processes about him/her;
              </p>
              <p>12.2.2. Lodge a complaint with a data protection authority;</p>
              <p>
                12.2.3. Not be subject to a decision based solely on automated
                decision making, where such decision would have a legal effect
                on the User or produce a similarly significant effect;
              </p>
              <p>12.2.4. Object to the processing of his/her personal data;</p>
              <p>
                12.2.5. Request from Sochcast, a “data controller” as defined in
                the Applicable Law, access to and rectification of his/her
                personal data;
              </p>
              <p>
                12.2.6. Request from Sochcast that his/her personal data be
                erased, subject to certain exceptions; and
              </p>
              <p>
                12.2.7. Withdraw his/her consent at any time for future
                processing if the processing of personal data is based on the
                User’s consent.
              </p>
            </div>
          </div>

          <p className="agreecontent">13. BUSINESS TRANSFER:</p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>
              13.1. Sochcast may assign or transfer this Policy, as well as
              Users account and related information and data, including any
              Personal Information, to any person or entity that acquires all or
              substantially all of its business, stock or assets, or with whom
              Sochcast may merge.
            </p>
          </div>

          <p className="agreecontent">14. CHANGES TO THIS PRIVACY POLICY:</p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>
              14.1. If there are any material changes to this Privacy Policy,
              User will be notified by Sochcast posting of a prominent notice on
              the Platform prior to the change becoming effective. Sochcast
              encourages Users to periodically review the latest information on
              Sochcast’s privacy practices. User’s continued use of the Platform
              constitutes his/her agreement to be bound by such changes to this
              Privacy Policy. Users only remedy, if the User does not accept the
              terms of this Policy, is to forthwith discontinue use of the
              Platform.
            </p>
            <p>
              14.2. Changes to Policy: We reserve the right to update this privacy policy at any
              time to reflect changes in our data collection and use practices.
            </p>
          </div>

          <p className="agreecontent">15. CONTACT US:</p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>
              15.1. The DPO can be contacted for any questions, or concerns
              about Sochcast’ Privacy Policy, or practices. User can email the
              DPO at{" "}
              <a href="mailto:support@sochcast.com">support@sochcast.com</a>.
            </p>
            <p>
              15.2. For Unsubscribing from marketing communications, email write
              to:-{" "}
              <a href="mailto:support@sochcast.com">support@sochcast.com</a>.
            </p>
          </div>

          <p className="agreecontent">16. LAST UPDATION:</p>

          <div
            style={{
              marginLeft: "40px",
              color: "#566777",
              fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>16.1. This Privacy Policy was last updated on 08/07/2022</p>
          </div>

          <div
            style={{
              //   marginLeft: "40px",
              color: "#566777",
              //   fontSize: "14px",
              letterSpacing: "0.28px",
            }}
          >
            <p>
              This T&C is an electronic record in terms of the Information
              Technology Act, 2000 (as amended / re-enacted) and rules
              thereunder, and is published in accordance with the provisions of
              Rule 3 (1) of the Information Technology (Intermediaries
              Guidelines and Digital Media Ethics code) Rules, 2021, which
              mandates publishing of rules and regulations, privacy policy and
              terms of use for access or usage of the App. This electronic
              record is generated by a computer system and does not require any
              physical or digital signature.
            </p>
          </div>
        </Container>

        <div className="copy-right text-center p-4">
          <Row>
            <Col md={4}>
              <div className="legaltext">
                {/* <span>Legal</span>
                <span>About</span> */}
                <span onClick={() => history.push("/terms-and-conditions")}>
                  Terms and Conditions
                </span>
                <span onClick={() => history.push("/privacy-policy")}>
                  Privacy Policy
                </span>
              </div>
            </Col>
            <Col md={4}>
              <div className="social-icons-listener">
                <a
                  href="https://www.facebook.com/sochcast"
                  target="_blank"
                  rel="noreferrer"
                  className="sociallinksa"
                >
                  <FiFacebook className="mx-2" />
                </a>

                <a
                  href="https://www.instagram.com/sochcast/"
                  target="_blank"
                  rel="noreferrer"
                  className="sociallinksa"
                >
                  <FiInstagram className="mx-2" />
                </a>

                <a
                  href="https://twitter.com/sochcast"
                  target="_blank"
                  rel="noreferrer"
                  className="sociallinksa"
                >
                  <BsTwitter className="mx-2" />
                </a>
              </div>
            </Col>
            <Col md={4}>
              <div className="listener-copyright-year">
                <span>&copy; {year} Sochcast.com. All rights reserved.</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
