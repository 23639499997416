import React from "react";
import { Helmet } from "react-helmet";

export const HelmetMetaData = ({ sTitle, sDescription }) => {
  return (
    <>
      <Helmet>
        <title>
          {sTitle !== undefined ? sTitle : "Sochcast"}
        </title>

        {/* <meta property="og:url" content={window.location.href} /> */}
        {/* <meta property="og:type" content="website" /> */}
        {/* <meta
          property="og:title"
          content="Sochcast - Apni Soch Duniya ko Sunao aur Suno Duniya Ki Soch"
        /> */}
        <meta
          name="description"
          content={
            sDescription !== undefined
              ? sDescription
              : "Sochcast is a women-centric audio platform with hosting, redistribution & monetisation services. Listen to the soch of today’s woman across 9 languages and 51 categories. Tune in NOW!"
          }
        />
        {/* <meta
          property="og:description"
          content={
            props.sDescription !== undefined
              ? props.sDescription
              : "Sochcast is a women-centric audio platform with hosting, redistribution & monetisation services. Listen to the soch of today’s woman across 9 languages and 51 categories. Tune in NOW!"
          }
        /> */}
        {/* <meta
          property="og:image"
          content="https://app.sochcast.com/sochcastmin.png"
        /> */}

        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={
            sTitle !== undefined
              ? sTitle
              : "Sochcast - Apni Soch Duniya ko Sunao aur Suno Duniya Ki Soch"
          }
        />
        <meta
          property="og:description"
          content={
            sDescription !== undefined
              ? sDescription
              : "Sochcast is a women-centric audio platform with hosting, redistribution & monetisation services. Listen to the soch of today’s woman across 9 languages and 51 categories. Tune in NOW!"
          }
        />
        <meta
          property="og:image"
          itemprop="image"
          content="https://app.sochcast.com/sochcastmin.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="https://app.sochcast.com/" />
        <meta property="twitter:url" content="https://app.sochcast.com/" />
        <meta
          name="twitter:title"
          content={
            sTitle !== undefined
              ? sTitle
              : "Sochcast - Apni Soch Duniya ko Sunao aur Suno Duniya Ki Soch"
          }
        />
        <meta
          name="twitter:description"
          content={
            sDescription !== undefined
              ? sDescription
              : "Sochcast is a women-centric audio platform with hosting, redistribution & monetisation services. Listen to the soch of today’s woman across 9 languages and 51 categories. Tune in NOW!"
          }
        />
        <meta
          name="twitter:image"
          content="https://app.sochcast.com/sochcastmin.png"
        />
      </Helmet>
    </>
  );
};
