import React, { useState } from "react";
import { TopHeader } from "../../components/header/TopHeader";
import { Header } from "../../components/header/Header";
import "../css/channel/Channel.css";
import { ChannelMain } from "./ChannelMain";
import { AddNewChannel } from "./AddNewChannel";
import { LoginContext } from "./Helper/Context";
import { FaBars } from "react-icons/fa";

export const Channel = () => {
  const [formStatus, setFormStatus] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <LoginContext.Provider value={{ formStatus, setFormStatus }}>
      <div className="app">
        <Header handleToggleSidebar={handleToggleSidebar} toggled={toggled} />
        <main>
          <TopHeader name="Manage Channel" />
          <div
            className="btn-toggle mobilemnnu"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars />
          </div>
          {formStatus ? (
            <AddNewChannel />
          ) : (
            <ChannelMain
              handleAddNewChannel={(formStatus) => setFormStatus(true)}
            />
          )}
        </main>
      </div>
    </LoginContext.Provider>
  );
};
