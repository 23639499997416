import React, { useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { RadioButton } from "../../components/formFields/RadioButton";
import { InputField } from "../../components/formFields/InputField";
import { isPublicPrivate } from "../../helperFunction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { accessToken } from "../../helperFunction";
import apiInstance from "../../api";

export const PlaylistPopup = (props) => {
  const [playListDescription, setPlayListDescription] = useState("");

  const initialValues = {
    titile: "",
    isUnlistedPP: "",
  };

  const validate = Yup.object({
    titile: Yup.string().required(),
  });

  const onChangePlaylistDescription = (e) => {
    setPlayListDescription(JSON.stringify(e.target.value));
  };

  const handleSubmit = (values) => {
    if (values.titile === "") {
      toast.error("Please enter playlist titile", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      apiInstance
        .post(
          "/listener/playlists/",
          {
            playlist_name: values.titile,
            playlist_description: playListDescription,
            playlist_privacy: "Private",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            toast.success(`Playlist ${values.titile} created.`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            props.onHide(true);
            let playListID = response.data.payload.id;
            props.setPlayListData((prev) => [response.data.payload, ...prev]);
            apiInstance
              .post(
                `/listener/playlists/${playListID}/episodes/`,
                {
                  playlist: playListID,
                  playlist_episode: props.episodeID,
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("access")}`,
                  },
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  toast.success(`Episode added to Playlist`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  props.onHide(true);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          // if (error.response.status === 401) {
          //   if (error.response.data.error_type === "InvalidToken") {
          //     // accessToken();
          //     // handleSubmit();
          //   }
          // }
        });
    }
  };

  const handleClose = () => {
    props.onHide(true);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="listener-playlist-create-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="termsTitle justify-content-center w-100"
          >
            Create Playlist
          </Modal.Title>
        </Modal.Header>
        <Formik initialValues={initialValues} validationSchema={validate}>
          {({ values }) => (
            <Form>
              <Modal.Body>
                <>
                  <Row>
                    <Col md={12}>
                      <InputField
                        label="Titile*"
                        name="titile"
                        type="text"
                        data-testid="titile-playlist"
                        placeholder="My playlist 01"
                      />
                    </Col>
                    <Col md={12}>
                      <label className="formLabel">Description</label>
                      <br />
                      <textarea
                        style={{
                          width: "100%",
                          maxHeight: "130px",
                          minHeight: "130px",
                        }}
                        onChange={onChangePlaylistDescription}
                      ></textarea>
                    </Col>
                    {/* <Col md={12}>
                      <label className="formLabel">Privacy</label>
                      <br />
                      <RadioButton
                        options={isPublicPrivate}
                        type="isUnlistedPP"
                      />
                    </Col> */}
                  </Row>
                </>
              </Modal.Body>
              <Modal.Footer className="justify-content-center">
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  className="playlist-cancel"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="playlist-save"
                  onClick={() => handleSubmit(values)}
                >
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
