import React, { useState, useEffect } from "react";
import apiInstance from "../../api";
import { Link } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { InputField } from "../../components/formFields/InputField";
import { GoogleSignIn } from "../../components/googleSignIn/GoogleSignIn";
import { SelectDropdown } from "../../components/formFields/SelectDropdown";
import { RadioButton } from "../../components/formFields/RadioButton";
import { TermsAndConditions } from "./TermsAndConditions";
import { useHistory } from "react-router";

const SignUpForm = () => {
  useEffect(() => {
    let auth = localStorage.getItem("isAuthenticated");
    if (auth) {
      history.push("/home");
    } else {
      history.push("/signup");
    }

    apiInstance
      .get("/all-languages/")
      .then((response) => {
        setPreferredLanguage(response.data.results);
      })
      .catch((err) => {
        console.log(err);
      });

    apiInstance
      .get("/main-categories/")
      .then((response) => {
        setAllCategories(response.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const validate = Yup.object({
    firstName: Yup.string().max(15).required(),
    lastName: Yup.string().max(15).required(),
    email: Yup.string().email().required(),
    password: Yup.string().min(6).required(),
    age: Yup.number().min(2).required(),
    preferredLanguage: Yup.string().required(),
    selectCategories: Yup.string().required(),
    picked: Yup.string().required(),
    phonenumber: Yup.number().min(10).required(),
  });

  const [agree, setAgree] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    age: "",
    picked: "",
    preferredLanguage: "",
    selectCategories: "",
    phonenumber: "",
  };

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
  };

  const handleSubmit = async (values) => {
    var genderSel = "";
    if (values.picked === "Man") {
      genderSel = "1";
    } else if (values.picked === "Woman") {
      genderSel = "2";
    } else if (values.picked === "Non-Binary") {
      genderSel = "3";
    } else if (values.picked === "Other") {
      genderSel = "4";
    }

    await apiInstance
      .post(`/signup/`, {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password,
        phone_number: values.phonenumber,
        age: values.age,
        gender: genderSel,
        preferred_languages: JSON.parse("[" + values.preferredLanguage + "]"),
        user_type: "Host",
        categories: JSON.parse("[" + values.selectCategories + "]"),
      })
      .then((response) => {
        if (response.data.status === 403) {
          seterrorMsg(response.data.errors.phone_number[0]);
        } else if (response.status === 200) {
          seterrorMsg("Please check your email for further instructions.");
          setTimeout(() => {
            history.push("/signin");
          }, 3000);
        } else {
          seterrorMsg("Something went wrong!!!");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.error_type === "ValidationError") {
            seterrorMsg(error.response.data.errors[0].message);
          }
        }
      });
  };

  const genderSelect = [
    { key: "1", value: "Man" },
    { key: "2", value: "Woman" },
    { key: "3", value: "Non-Binary" },
    { key: "4", value: "Other" },
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <div>
            <h3 data-testid="signup" className="signup">
              Become a Creator on Sochcast
            </h3>
            <p className="text">
              Sochcast is a premier full-stack audio suite that enables you to
              <br />
              broadcast your सोच • <span>(soch)</span> thoughts & musings.
            </p>
            <Form>
              <Row className="mt-2">
                <Col xs={12} md={6} lg={6} xl={6}>
                  <InputField
                    label="First Name"
                    name="firstName"
                    type="text"
                    data-testid="first-name"
                  />
                </Col>
                <Col xs={12} md={6} lg={6} xl={6}>
                  <InputField
                    label="Last Name"
                    name="lastName"
                    type="text"
                    data-testid="last-name"
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <InputField
                    label="Email"
                    name="email"
                    type="email"
                    data-testid="email-id"
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={6} lg={6} xl={6}>
                  <InputField
                    label="Password"
                    name="password"
                    type="password"
                    data-testid="password"
                  />
                </Col>
                <Col xs={12} md={6} lg={6} xl={6}>
                  <InputField
                    label="Phone Number"
                    name="phonenumber"
                    type="text"
                    data-testid="phonenumber"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4} lg={4} xl={4}>
                  <InputField
                    label="Age"
                    name="age"
                    type="text"
                    data-testid="age"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
                <Col xs={12} md={4} lg={4} xl={4}>
                  <SelectDropdown
                    control="select"
                    name="preferredLanguage"
                    data-testid="preferredLanguage"
                    id="preferredLanguage"
                    label="Preferred Language"
                    options={preferredLanguage}
                  />
                </Col>
                <Col xs={12} md={4} lg={4} xl={4}>
                  <SelectDropdown
                    control="select"
                    name="selectCategories"
                    data-testid="selectCategories"
                    id="selectCategories"
                    label="Select Communities"
                    options={allCategories}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <label className="formLabel">What's your gender ?</label>
                  <br />
                  <RadioButton options={genderSelect} />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xs={12}>
                  <input
                    type="checkbox"
                    id="agree"
                    onChange={checkboxHandler}
                    data-testid="tnc"
                  />
                  <label htmlFor="agree" className="tnccheckbox">
                    I accept all the given{" "}
                    <b>
                      <Button
                        className="termsButton"
                        onClick={() => setModalShow(true)}
                      >
                        Terms and Conditions
                      </Button>
                    </b>
                  </label>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={12} md={12} lg={12} xl={12} className="text-center">
                  <Button
                    disabled={!agree}
                    variant="primary"
                    type="submit"
                    className="signupButton rounded-2"
                    title="SingUpButton"
                    data-testid="signUpBtton"
                  >
                    Sign Up
                  </Button>
                </Col>
              </Row>
              <p className="text-center error mt-2">{errorMsg}</p>
            </Form>

            <h1 className="horizontalLineSiginIn">Sign In with</h1>

            <Row className="mt-2">
              <p className="text-center login">
                Already have an Account ?
                <Link to="/signin" className="signinLink">
                  {" "}
                  login here
                </Link>
              </p>
            </Row>
            <div className="googleBtn">
              <GoogleSignIn />
            </div>
          </div>
        )}
      </Formik>

      <TermsAndConditions
        show={modalShow}
        onHide={() => setModalShow(false)}
        // agree={() => setAgree(false)}
      />
    </>
  );
};

export default SignUpForm;
