import React from "react";
// import { SochCastPro } from "../../components/sochcastPro/SochCastPro";
import { EpisodeList } from "./EpisodeList";
import { Row, Col, Button } from "react-bootstrap";
import { ReactComponent as AddIcon } from "../../assets/images/add.svg";

export const EpisodeMain = (props) => {
  return (
    <div className="p-5 mainContainerAdd">
      {/* <SochCastPro /> */}
      <Row className="mt-5">
        <Col xs={6} md={8} lg={8} xl={8}>
          <h1 className="dash-text">Episode List</h1>
          {/* <p className="channelDescription">
            Selecting Multiple Episodes Allows You To Capture Embed Codes Or
            Transcripts Across Your Content.
          </p> */}
        </Col>
        <Col xs={6} md={4} lg={4} xl={4} className="add-channel-button">
          <Button variant="primary" onClick={props.handleAddNewChannel}>
            <AddIcon style={{ width: "14px", height: "14px" }} /> Add Episode
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12} cl={12} className="mt-2">
          <EpisodeList />
        </Col>
      </Row>
    </div>
  );
};
