import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Container } from "react-bootstrap";
import { accessToken } from "../../../helperFunction";
import apiInstance from "../../../api";
import Exporting from "highcharts/modules/exporting";

export const EpisodePerformance = (props) => {
  Exporting(Highcharts);
  const [episodeListenedData, setEpisodeListenedData] = useState([]);

  useEffect(() => {
    async function getAllListen() {
      apiInstance
        .get(
          `/management/episode-analytics?time_delta=${props.dateNumber}&episode_id=${props.episodeID}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setEpisodeListenedData(res.data.episode_listened_data);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            if (err.response.data.error_type === "InvalidToken") {
              accessToken();
              getAllListen();
            }
          }
        });
    }
    getAllListen();
  }, [props]);

  var finalArray = episodeListenedData.map(function (obj) {
    return obj.times_played;
  });

  var finalArrayCount = episodeListenedData.map(function (obj) {
    return obj.duration_played;
  });

  const options = {
    chart: {
      type: "spline",
    },
    style: {
      fontFamily: "Poppins",
      color: "#566777",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: finalArrayCount,
    },
    yAxis: {
      title: {
        text: "",
        style: {
          fontSize: "14",
          fontFamily: "Poppins",
          color: "#566777",
          fontWeight: "600",
        },
      },
    },
    tooltip: {
      valueSuffix: " ",
      backgroundColor: "#FFFFFF",
      borderRadius: "15",
      borderColor: "#FFFFFF",
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Listens",
        data: finalArray,
      },
    ],
  };

  return (
    <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
