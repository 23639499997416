import React from "react";
import { Header } from "../../components/header/Header";
import { TopHeader } from "../../components/header/TopHeader";
import { UpgradePlans } from "./UpgradePlans";

export const index = () => {
  return (
    <div className="app">
      <Header />
      <main>
        <TopHeader />
        <UpgradePlans />
      </main>
    </div>
  );
};
