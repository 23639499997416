import React, { useState, useEffect, useRef } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { Row, Col } from "react-bootstrap";
import { ReactComponent as HeartIcon } from "../../assets/images/heart.svg";
import { ReactComponent as PlaylistCustomIcon } from "../../assets/images/playlist.svg";
import { PlaylistPopup } from "../episodelist/PlaylistPopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiInstance from "../../api";
import { ReactComponent as LikedHeart } from "../../assets/images/heartLIked.svg";
import { detect } from "detect-browser";

export const LikedCustomAudioPlayer = (props) => {
  const browser = detect();
  const [episodesData, setEpisodesData] = useState(props.item);
  const [currentMusicIndex, setCurrentMusicIndex] = useState(0);
  const [playlistPopupModalShow, setPlaylistPopupModalShow] = useState(false);
  const [episodePlayListID, setEpisodePlayListID] = useState("");
  const [ipAddress, setIPAddress] = useState("");
  const [browserName, setBrowserName] = useState("");
  const [audioType, setAudioType] = useState("intro");
  const [geoLocationData, setGeoLocationData] = useState({});

  const audioRef = useRef(null);

  var apiReportingBaseURL = "";

  const geoIPAPIURL =
    "https://api.ipgeolocation.io/ipgeo?apiKey=83581edfef28455f97fbab53986bb88a";

  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "demo.sochcast.com"
  ) {
    apiReportingBaseURL = "https://beta.sochcast.com/api/reporting";
  } else if (window.location.hostname === "app.sochcast.com") {
    apiReportingBaseURL = "https://backend.sochcast.com/api/reporting";
  }

  useEffect(() => {
    setEpisodesData(props.item);

    if (props.item.length === 1) {
      setAudioType("intro");
      if (props.item[0]?.liked_episode?.shows[0]?.intro !== null) {
        setAudioType("intro");
        audioRef.current.audio.current.src =
          props.item[0]?.liked_episode?.shows[0]?.intro;
      } else if (props.item[0]?.liked_episode?.shows[0]?.trailer !== null) {
        setAudioType("trailer");
        audioRef.current.audio.current.src =
          props.item[0]?.liked_episode?.shows[0]?.trailer;
      } else {
        setAudioType("file");
        audioRef.current.audio.current.src = props.item[0]?.liked_episode?.file;
      }
    }
  }, [props.item]);

  useEffect(() => {
    if (browser) {
      setBrowserName(browser.name);
    }

    apiInstance
      .get("https://api.ipify.org/?format=json")
      .then((res) => {
        setIPAddress(res.data.ip);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [browser]);

  useEffect(() => {
    apiInstance
      .get(geoIPAPIURL)
      .then((res) => {
        setGeoLocationData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleOnPlay = (value) => {
    let userID = localStorage.getItem("user_id");
    let userType = "Auth";

    if (userID === null) {
      userID = "";
      userType = "unAuth";
    }

    apiInstance
      .get("https://api.ipify.org/?format=json")
      .then((res) => {
        setIPAddress(res.data.ip);
        apiInstance
          .get(geoIPAPIURL)
          .then((ress) => {
            let data = {
              ip: res.data.ip,
              user: userID,
              user_type: userType,
              user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              device_type: browserName,
              player_action: "PLAY",
              show_played: value.liked_episode.shows[0].id,
              episode_played: value.liked_episode.id,
              duration_played: Math.round(
                audioRef.current.audio.current.currentTime
              ),
              created_at: new Date().toJSON(),
              updated_at: new Date().toJSON(),
              user_continent: ress.data.continent_name,
              user_country: ress.data.country_name,
              user_region: ress.data.state_prov,
              user_zip_code: ress.data.zipcode,
            };

            apiInstance
              .post(apiReportingBaseURL, data)
              .then((resp) => {})
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnPause = (value) => {
    let userID = localStorage.getItem("user_id");
    let userType = "Auth";

    if (userID === null) {
      userID = "";
      userType = "unAuth";
    }

    let data = {
      ip: ipAddress,
      user: userID,
      user_type: userType,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      device_type: browserName,
      player_action: "PAUSE",
      show_played: value.liked_episode.shows[0].id,
      episode_played: value.liked_episode.id,
      duration_played: Math.round(audioRef.current.audio.current.currentTime),
      created_at: new Date().toJSON(),
      updated_at: new Date().toJSON(),
      user_city: geoLocationData.city,
      user_continent: geoLocationData.continent_name,
      user_country: geoLocationData.country_name,
      user_region: geoLocationData.state_prov,
      user_zip_code: geoLocationData.zipcode,
    };

    apiInstance
      .post(apiReportingBaseURL, data)
      .then((resp) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNextPlay = (value) => {
    setCurrentMusicIndex(currentMusicIndex + 1);
    let userID = localStorage.getItem("user_id");
    let userType = "Auth";

    if (userID === null) {
      userID = "";
      userType = "unAuth";
    }

    let data = {
      ip: ipAddress,
      user: userID,
      user_type: `${userType}`,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      device_type: browserName,
      player_action: "NEXT",
      show_played: value[currentMusicIndex]?.liked_episode?.shows[0]?.id,
      episode_played: value[currentMusicIndex + 1]?.id,
      duration_played: Math.round(audioRef.current.audio.current.currentTime),
      created_at: new Date().toJSON(),
      updated_at: new Date().toJSON(),
      user_city: geoLocationData.city,
      user_continent: geoLocationData.continent_name,
      user_country: geoLocationData.country_name,
      user_region: geoLocationData.state_prov,
      user_zip_code: geoLocationData.zipcode,
    };

    apiInstance
      .post(apiReportingBaseURL, data)
      .then((resp) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePreviousPlay = (value) => {
    setCurrentMusicIndex(currentMusicIndex - 1);

    let userID = localStorage.getItem("user_id");
    let userType = "Auth";

    if (userID === null) {
      userID = "";
      userType = "unAuth";
    }

    let data = {
      ip: ipAddress,
      user: userID,
      user_type: userType,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      device_type: browserName,
      player_action: "PREVIOUS",
      show_played: value[currentMusicIndex]?.liked_episode?.shows[0]?.id,
      episode_played: value[currentMusicIndex + 1]?.id,
      duration_played: Math.round(audioRef.current.audio.current.currentTime),
      created_at: new Date().toJSON(),
      updated_at: new Date().toJSON(),
      user_city: geoLocationData.city,
      user_continent: geoLocationData.continent_name,
      user_country: geoLocationData.country_name,
      user_region: geoLocationData.state_prov,
      user_zip_code: geoLocationData.zipcode,
    };

    apiInstance
      .post(apiReportingBaseURL, data)
      .then((resp) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSingleEpisodeID = (playlistEpiosdeID) => {
    setEpisodePlayListID(playlistEpiosdeID);
  };

  const handleLikedEpisode = (episodeID) => {
    console.log(episodeID);
    apiInstance
      .post(
        "/listener/liked-episodes/",
        { liked_episode: episodeID },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.payload === "Episode liked") {
            toast.success("Episode liked", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (response.data.payload === "Episode liked already") {
            toast.warning("Episode liked already", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (error.response.data.error_type === "InvalidToken") {
            toast.error("Please Login to Like an Episode", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };

  const handleOnEnded = (value, audioType) => {
    if (audioType === "intro") {
      if (
        value[currentMusicIndex].liked_episode.shows[0]?.trailer !== null &&
        value[currentMusicIndex].liked_episode.shows[0]?.intro !== null
      ) {
        audioRef.current.audio.current.src =
          value[currentMusicIndex].liked_episode.shows[0]?.trailer;
        setAudioType("trailer");
      } else {
        audioRef.current.audio.current.src =
          value[currentMusicIndex].liked_episode.file;
        setAudioType("file");
      }
    } else if (audioType === "trailer") {
      audioRef.current.audio.current.src =
        value[currentMusicIndex].liked_episode.file;
      setAudioType("file");
    } else if (audioType === "file") {
      audioRef.current.audio.current.autoPlay = false;
      setAudioType("");
    } else {
      audioRef.current.audio.current.src = "";
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Row>
        <Col md={2} className="my-auto text-center">
          <img
            src={episodesData[currentMusicIndex]?.liked_episode?.episode_image}
            alt={episodesData[currentMusicIndex]?.liked_episode?.name}
            className="img-fluid player-episode-image-listener"
          />
        </Col>
        <Col md={10}>
          <AudioPlayer
            ref={audioRef}
            autoPlay={true}
            autoPlayAfterSrcChange={true}
            src={
              episodesData[currentMusicIndex]?.liked_episode?.shows[0]?.intro ||
              episodesData[currentMusicIndex]?.liked_episode?.shows[0]
                ?.trailer ||
              episodesData[currentMusicIndex]?.liked_episode?.file
            }
            showSkipControls={true}
            volume="0.2"
            customProgressBarSection={[
              RHAP_UI.PROGRESS_BAR,
              RHAP_UI.CURRENT_TIME,
              <div>/</div>,
              RHAP_UI.DURATION,
            ]}
            customAdditionalControls={[
              <button
                className="like-audio-button"
                onClick={() =>
                  handleLikedEpisode(
                    episodesData[currentMusicIndex]?.liked_episode?.id
                  )
                }
              >
                <LikedHeart className="mx-2" />
              </button>,
              <button
                className="audio-playlist-button"
                onClick={() => {
                  setPlaylistPopupModalShow(true);
                  handleSingleEpisodeID(
                    episodesData[currentMusicIndex]?.liked_episode?.id
                  );
                }}
              >
                <PlaylistCustomIcon />
              </button>,
            ]}
            onPlay={(e) => {
              handleOnPlay(episodesData[currentMusicIndex]);
            }}
            onPause={(e) => {
              handleOnPause(episodesData[currentMusicIndex]);
            }}
            onClickNext={() => {
              handleNextPlay(episodesData);
            }}
            onClickPrevious={() => {
              handlePreviousPlay(episodesData);
            }}
            progressJumpSteps={{
              forward: 30000,
              backward: 10000,
            }}
            header={episodesData[currentMusicIndex]?.liked_episode?.name}
            footer={episodesData[0]?.liked_episode?.shows[0]?.hosts?.map(
              (item, index, array) => {
                var hostName = "";
                if (array.length - 1 === index) {
                  hostName = `${item?.first_name} ${item?.last_name}`;
                } else {
                  hostName = `${item?.first_name} ${item?.last_name} & `;
                }

                // let string = hostName;
                return (
                  // <>{hostName.slice(0, hostName.lastIndexOf(" & "))}</>
                  <>{hostName}</>
                );
              }
            )}
            onEnded={(e) => {
              handleOnEnded(episodesData, audioType);
            }}
          />
        </Col>
      </Row>

      <PlaylistPopup
        show={playlistPopupModalShow}
        onHide={() => setPlaylistPopupModalShow(false)}
        episodeID={episodePlayListID}
      />
    </>
  );
};
