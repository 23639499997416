import React, { useState, useEffect, useRef, useMemo } from "react";
import { Header } from "../../components/header/Header";
import { TopHeader } from "../../components/header/TopHeader";
import { Row, Col, Card, Tabs, Tab, Button, Modal } from "react-bootstrap";
// import BrandIcon from "../../assets/images/Group9550.png";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik, Form } from "formik";
import apiInstance from "../../api";
import { useParams } from "react-router";
import Multiselect from "multiselect-react-dropdown";
import { accessToken } from "../../helperFunction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router";
import TickIcon from "../../assets/images/moneicon.png";
import "../css/shows/show.css";

export const MonetizationBrandForm = () => {
  const history = useHistory();
  const { brandID, showID } = useParams();
  const [startDate, setStartDate] = useState(new Date());
  const [navtiveSponsorships, setNavtiveSponsorships] = useState([]);
  const [hostReadSponsorships, setHostReadSponsorships] = useState([]);
  const fileElem = useRef(null);
  const [filepreview, setFilePreview] = useState(null);
  const [data, setData] = useState({
    native_sponsors: [{}],
    host_rolls: [{}],
  });
  const [brandName, setBrandName] = useState();
  const [brandDesc, setBrandDesc] = useState();
  const [brandImage, setBrandImage] = useState();
  const [episodes, setEpisodes] = useState();
  const [episodeArray, setEpisodeArray] = useState([]);
  const [episodeImage, setEpisodeImage] = useState(null);
  const [writePitch, setWritePitch] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    getNativeSponsorships();
    getHostReadSponsorships();
    getBrandData();
    getAllEpisodes();
  }, []);

  async function getHostReadSponsorships() {
    await apiInstance
      .get("/monetization/host-read-sponsorships")
      .then((response) => {
        if (response.status === 200) {
          setHostReadSponsorships(response.data.results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getNativeSponsorships() {
    await apiInstance
      .get("/monetization/native-sponsorships")
      .then((response) => {
        if (response.status === 200) {
          setNavtiveSponsorships(response.data.results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getBrandData() {
    await apiInstance
      .get(`/monetization/brands/${brandID}/`)
      .then((response) => {
        if (response.status === 200) {
          setBrandName(response.data.brand_name);
          setBrandDesc(response.data.brand_description);
          setBrandImage(response.data.brand_image);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllEpisodes() {
    await apiInstance
      .get(`/shows/${showID}/episodes/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        setEpisodes(response.data.results);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (error.response.data.error_type === "InvalidToken") {
            accessToken();
            getAllEpisodes();
          }
        }
      });
  }

  const handleBrandSendProposal = () => {
    if (data.host_rolls.length <= 1) {
      toast.error("Please add Host-Read Sponsorships", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (data.native_sponsors.length <= 1) {
      toast.error("Please add Native Sponsorships", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (writePitch === "" || acceptedFiles[0] === undefined) {
      console.log(acceptedFiles[0]);
      toast.error("Please write an Pitch or upload a Pitch", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let formdata = new FormData();
      formdata.append("chosen_sponsor", brandID);
      formdata.append("monetized_show", showID);
      formdata.append("monetization_pitch_file", acceptedFiles[0]);
      formdata.append("monetization_pitch", writePitch);
      if(episodeImage !== null) {
        formdata.append("listener_stats_pic", episodeImage);
      }
      

      let native = data.native_sponsors.filter(function (element) {
        return element !== undefined;
      });

      var newArrayNative = native.filter(
        (value) => Object.keys(value).length !== 0
      );

      let host = data.host_rolls.filter(function (element) {
        return element !== undefined;
      });

      var newArrayhost = host.filter(
        (value) => Object.keys(value).length !== 0
      );

      const stringNative = JSON.stringify(newArrayNative);
      const stringHost = JSON.stringify(newArrayhost);

      formdata.append(`host_rolls`, stringHost);
      formdata.append(`native_sponsors`, stringNative);

      apiInstance
        .post("/monetization/", formdata, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            handleShow();
            setTimeout(function () {
              handleClose();
              history.push("/monetization");
            }, 2000);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (error.response.data.error_type === "InvalidToken") {
              accessToken();
              handleBrandSendProposal();
            }
          }
        });
    }
  };

  const initialValues = {
    episode_image: "",
  };

  const handleChange = (type, field, value, index, id) => {
    setData((prevState) => {
      const nextState = [...prevState[type]];
      nextState[index] = {
        ...prevState[type][index],
        id,
        [field]: value,
      };
      return {
        ...prevState,
        [type]: nextState,
      };
    });
  };

  const onEpisodelSelect = (selectedList, selectedItem) => {
    setEpisodeArray(selectedList);
  };

  const onChangeWritePitch = (e) => {
    setWritePitch(JSON.stringify(e.target.value));
  };

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 20,
    border: "2px dashed #D6DDE5",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#FFFFFF",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    height: "250px",
    cursor: "pointer",
  };

  const activeStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ accept: ".mp3,audio/*", maxFiles: 1 });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <div className="app">
      <Header />
      <main>
        <TopHeader name="Monetization" />
        <div className=" p-5 mainContainerAdd">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <div className="channel-form mt-4">
            <Row className="mx-4 py-5">
              <Col xs={12} md={9} lg={9} xl={9} className="createChannelTitle">
                <h1 className="text-capitalize">{brandName}</h1>
                <p>{brandDesc}</p>
              </Col>
              <Col
                xs={12}
                md={3}
                lg={3}
                xl={3}
                className="createChannelTitleIcon"
              >
                <div className="icon text-center brand icons">
                  <img
                    src={brandImage}
                    alt="ChannelIcon"
                    style={{
                      width: "74px",
                      height: "74px",
                      margin: "auto",
                      marginTop: "5px",
                      borderRadius: "25px",
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <Formik
            initialValues={initialValues}
            // validationSchema={validate}
            // onSubmit={handleSubmit}
          >
            {(formik) => (
              <>
                <Form>
                  <div className="channel-form mt-4">
                    <Row className="mx-3 py-5">
                      <Col className="createChannelTitle">
                        <h1>Native Sponsorships The Brand Is Interested In:</h1>
                        <p>
                          Native Sponsorships Fit The Content/Topic Of Your
                          Episode, Making Them More Authentic For Brands And
                          Engaging For Listeners
                        </p>
                      </Col>
                    </Row>

                    <Row className="mx-3 pb-5">
                      {navtiveSponsorships?.map((item, index) => {
                        return (
                          <Col xs={12} md={6} lg={6} xl={6} className="mt-4">
                            <Card className="Topical Discussion p-4">
                              <h6 className="sub-tit-form-brand">
                                {item.native_sponsorship_name}
                              </h6>
                              <p className="sub-tit-form-brand-p-tag">
                                {item.native_sponsorship_description}
                              </p>
                              <hr style={{ color: "#46daf0" }} />
                              <Row>
                                <Col xs={12} md={6} lg={6} xl={6}>
                                  <label for={item.id} className="formLabel">
                                    Fee
                                  </label>
                                  <br />
                                  <input
                                    type="text"
                                    name={item.id}
                                    placeholder="Your Fee"
                                    className="fee-form"
                                    onChange={(e) =>
                                      handleChange(
                                        "native_sponsors",
                                        "fee",
                                        e.target.value,
                                        index,
                                        item.id
                                      )
                                    }
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={6}>
                                  <label for="fee" className="formLabel">
                                    Campaign Launch Date
                                  </label>
                                  <input
                                    className="date-pic-brand-form"
                                    type="date"
                                    selected={startDate}
                                    min={disablePastDate()}
                                  />
                                  {/* <DatePicker
                                    selected={startDate}
                                    onChange={(date) =>
                                      handleChange(
                                        "native_sponsors",
                                        "date",
                                        date,
                                        index,
                                        item.id,
                                        setStartDate(date)
                                      )
                                    }
                                    className="date-pic-brand-form"
                                  /> */}
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>

                    <hr className="mx-4 my-3" />

                    <Row className="mx-3 py-5">
                      <Col className="createChannelTitle">
                        <h1>
                          Host-Read Sponsorships The Brand Is Interested In:
                        </h1>
                        <p>
                          Native Host-Read Ads Are A Lot More Engaging And Less
                          Disruptive For Listeners Than Traditional Ad Inserts.
                          Note, You Can Be Hired Up To 3 Times For Each
                          Host-Read Ad Format (Ex: Mid-Roll).
                        </p>
                      </Col>
                    </Row>

                    <Row className="mx-3 pb-5">
                      {hostReadSponsorships?.map((hostSponsor, index) => {
                        return (
                          <Col xs={12} md={6} lg={6} xl={6} className="mt-4">
                            <Card className="Creative Integration p-4">
                              <h6 className="sub-tit-form-brand">
                                {hostSponsor.host_read_name}
                              </h6>
                              <p className="sub-tit-form-brand-p-tag">
                                {hostSponsor.host_read_name_description}
                              </p>
                              <hr style={{ color: "#46daf0" }} />
                              <Row>
                                <Col xs={12} md={12} lg={12} xl={12}>
                                  <label for="fee" className="formLabel">
                                    Number of Episodes
                                  </label>
                                  <Multiselect
                                    options={episodes}
                                    onSelect={onEpisodelSelect}
                                    displayValue="name"
                                    // onChange={(e) =>
                                    //   handleChange(
                                    //     "host_rolls",
                                    //     "episodes",
                                    //     e.target.value,
                                    //     index,
                                    //     hostSponsor.id
                                    //   )
                                    // }
                                  />
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={6}>
                                  <label for="fee" className="formLabel">
                                    Fee
                                  </label>
                                  <br />
                                  <input
                                    type="text"
                                    name="fee"
                                    placeholder="Your Fee"
                                    className="fee-form"
                                    onChange={(e) =>
                                      handleChange(
                                        "host_rolls",
                                        "fee",
                                        e.target.value,
                                        index,
                                        hostSponsor.id
                                      )
                                    }
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={6}>
                                  <label for="fee" className="formLabel">
                                    Campaign Launch Date
                                  </label>
                                  <input
                                    className="date-pic-brand-form"
                                    type="date"
                                    selected={startDate}
                                    min={disablePastDate()}
                                  />
                                  {/* <DatePicker
                                    selected={startDate}
                                    onChange={(date) =>
                                      handleChange(
                                        "host_rolls",
                                        "date",
                                        date,
                                        index,
                                        hostSponsor.id,
                                        setStartDate(date)
                                      )
                                    }
                                    className="date-pic-brand-form"
                                  /> */}
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>

                    <Row className="mx-4 py-5">
                      <Col
                        className="createChannelTitle"
                        xs={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <h1>Episode Download Or Listener Stats</h1>
                        <p>
                          We Are Currently Building Our Automated Analytics
                          System, Meanwhile Please Enter The Number Of Downloads
                          Or Listens That You Have Received In The Past 30 Days
                          And Upload A Screenshot From Your Hosting Provider's
                          Dashboard As A Proof.
                        </p>
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        xl={3}
                        className="img-mobile-holder"
                      >
                        <div className="banner-image-holder">
                          {filepreview !== null ? (
                            <img
                              src={filepreview}
                              alt="Profile"
                              className="img-fluid banner-image"
                            />
                          ) : null}
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        xl={3}
                        className="img-mobile-holder"
                      >
                        <input
                          name="hidden-file-input"
                          type="file"
                          ref={fileElem}
                          accept="image/png"
                          style={{ display: "none" }}
                          onChange={(event) => {
                            // formik.setFieldValue(
                            //   "episode_image"
                            setEpisodeImage(event.target.files[0]);
                            // )
                            setFilePreview(
                              URL.createObjectURL(event.target.files[0])
                            );
                          }}
                        />
                        <Button
                          className="upload-picture-button mt-3"
                          onClick={() => {
                            fileElem.current && fileElem.current.click();
                          }}
                        >
                          Upload Picture
                        </Button>
                      </Col>
                    </Row>

                    <hr className="mx-4 my-3" />

                    <Row className="mx-4 py-5">
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="createChannelTitle"
                      >
                        <h1>Sponsorship Proposal Details</h1>
                        <p>
                          Time To Shine! Write And/Or Record More Details About
                          Yourself And Your Proposal. We Recommend Doing Both
                          For A More Personal Touch.
                        </p>
                      </Col>

                      <Col>
                        <Tabs
                          defaultActiveKey="profile"
                          id="uncontrolled-tab-example"
                          className="mb-3"
                        >
                          <Tab eventKey="home" title="Write Pitch">
                            <Row>
                              <Col xs={12}>
                                <textarea
                                  style={{ width: "100%" }}
                                  onChange={onChangeWritePitch}
                                >
                                  {" "}
                                </textarea>
                              </Col>
                            </Row>
                          </Tab>
                          <Tab eventKey="profile" title="Upload Pitch">
                            <div {...getRootProps({ style })}>
                              <input {...getInputProps()} />
                              <p className="mt-5 dropzonecutonscolr">
                                Drag and Drop Audio File to Upload A New Pitch
                              </p>
                              <div className="button-brose-file">
                                <p className="mt-2">Browse File</p>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </Col>
                    </Row>
                  </div>

                  <Button
                    variant="primary"
                    className="channelPublishButton rounded-2 my-5"
                    title="Send Proposal"
                    data-testid="signUpBtton"
                    type="submit"
                    onClick={() => handleBrandSendProposal(formik.values)}
                  >
                    Send Proposal
                  </Button>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </main>
      <>
        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="episode-popupplayer"
        >
          <Modal.Header closeButton>
            <Modal.Header className="termsModalHeader">
              <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
          </Modal.Header>
          <Modal.Body>
            <div className="episode-player-image mt-2 text-center">
              <img src={TickIcon} alt="success" />
              <h2 className="success-monetization mt-5">
                Proposal Is Successfully Send.
              </h2>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
};
