import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../listener.css";
import apiInstance from "../../api";
import { BiChevronRight } from "react-icons/bi";
import { useHistory } from "react-router-dom";

export const PopularCategories = (props) => {
  const [categories, setCategories] = useState([]);
  const history = useHistory();

  useEffect(() => {
    apiInstance
      .get("/listener/categories")
      .then((response) => {
        setCategories(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleViewAllCategories = () => {
    history.push("/all-categories");
  };

  const handleCategoryShow = (categoryId) => {
    history.push(`/category/${categoryId}`);
  };

  return (
    <>
      <div className="my-5">
        <p className="sub-main-text">For you</p>
        <Row>
          <Col xs={12} sm={12} md={9}>
            <h3 className="listener-main-text">Popular Communities</h3>
          </Col>
          <Col xs={12} sm={12} md={3}>
            <p className="view-all" onClick={handleViewAllCategories}>
              View all <BiChevronRight />
            </p>
          </Col>
        </Row>

        <Row>
          {categories?.slice(0, 10).map((item) => {
            return (
              <Col
                className="mb-3"
                onClick={() => handleCategoryShow(item.id)}
                key={item.id}
              >
                <div className={`common-cat-listener pop-cat-${item.id}`}>
                  <h4 className="cat-text py-4 px-3">{item.name}</h4>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
};
