import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Nav,
  InputGroup,
  FormControl,
  Button,
  Card,
  Col,
  Row,
  Dropdown,
} from "react-bootstrap";
import "../../listener/listener.css";
import sochLogo from "../../assets/images/sochlogo.png";
// import { ReactComponent as DarkIcon } from "../../assets/images/moon.svg";
import { AiOutlineClose } from "react-icons/ai";
import apiInstance from "../../api";
import { useHistory } from "react-router-dom";
// import { ReactComponent as IconsSettings } from "../../assets/images/iconsetting.svg";
import { ReactComponent as SignOutIcon } from "../../assets/images/iconsignout.svg";
import { ReactComponent as PodcastIcon } from "../../assets/images/PodcastsIcon.svg";
// import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { accessToken } from "../../helperFunction";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const TopHeaderNavigation = (props) => {

  const [theme, setTheme] = useState("");
  const [themFlag, setThemeFlag] = useState(false);
  const [classnameHome, setClassnameHome] = useState("");
  const [classnameLib, setClassnameLib] = useState("");
  const [classnameUpgrade, setClassnameUpgrade] = useState("");
  const [classnameExplore, setClassnameExplore] = useState("");
  const [visible, setVisible] = useState(false);
  const [key, setKey] = useState("");
  const [searchResultEpisodes, setSearchResultEpisodes] = useState([]);
  const [searchResulthosts, setSearchResulthosts] = useState([]);
  const [searchResultShows, setSearchResultShows] = useState([]);
  const history = useHistory();
  const [profileImageStatus, setProfileImageStatus] = useState(false);
  // const [sochcastRecommendations, setSochcastRecommendations] = useState([]);
  // const [popularShows, setPopularShows] = useState([]);
  // const [orginalShows, setOriginalShows] = useState([]);
  const [windowLength, setWindowLength] = useState("default");

  const [buttonStatus, setButtonStatus] = useState(false);
  const userName = useSelector((state) => state);

  const [timer, setTimer] = useState(null)

  useEffect(() => {
    if (visible === true) {
      props.changeTheme("search-open");
    } else {
      props.changeTheme("search-close");
    }

    if (props.upgrade === "upgrade") {
      setClassnameUpgrade("upgrade");
    } else if (props.upgrade === "library") {
      setClassnameLib("library");
    } else if (props.upgrade === "home") {
      setClassnameHome("home");
    } else if (props.upgrade === "explore") {
      setClassnameExplore("explore");
    }

    let isAuth = localStorage.getItem("isAuthenticated");

    if (isAuth === true || isAuth === null) {
      setButtonStatus(true);
    } else {
      getProfileData();
    }
  }, [visible]);

  function getProfileData() {
    apiInstance
      .get("/profile/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((resp) => {
        if (resp.status === 200 && resp.data.payload.profile_image !== null) {
          localStorage.setItem(
            "profile_image",
            resp.data.payload.profile_image
          );
          setProfileImageStatus(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          if (err.response.data.error_type === "InvalidToken") {
            accessToken();
            getProfileData();
          }
        }
      });
  }

  // const handleThemeChange = () => {
  //   if (localStorage.getItem("theme") === "theme-light") {
  //     localStorage.setItem("theme", "theme-dark");
  //     setThemeFlag(true);
  //     props.changeTheme("theme-dark");
  //   } else if (localStorage.getItem("theme") === "theme-dark") {
  //     localStorage.setItem("theme", "theme-light");
  //     setThemeFlag(false);
  //     props.changeTheme("theme-light");
  //   }
  // };

  const handleVisibility = () => {
    setVisible((prev) => !prev);
    setKey("");
    if (!visible) {
      setSearchResultEpisodes([]);
      setSearchResulthosts([]);
      setSearchResultShows([]);
      setKey("");
    }
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
    smallDevices: {
      breakpoint: { max: 320, min: 0 },
      items: 2,
    },
  };

  const handleSearchChange = (value) => {
    setKey(value);
    clearTimeout(timer);
    

    const newTimer = setTimeout(() => {
      if (localStorage.getItem("access") !== null) {
        if (!!value) {
          apiInstance
            .get(`/listener/search?q=${value}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access")}`,
              },
            })
            .then((resp) => {
              if (resp.status === 200) {
                setSearchResultEpisodes(resp.data.episodes);
                setSearchResulthosts(resp.data.hosts);
                setSearchResultShows(resp.data.shows);
                setWindowLength("filled");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setSearchResultEpisodes([]);
          setSearchResulthosts([]);
          setSearchResultShows([]);
          setWindowLength("default");
        }
      } else {
        if (!!value) {
          apiInstance
            .get(`/listener/search?q=${value}`)
            .then((resp) => {
              if (resp.status === 200) {
                setSearchResultEpisodes(resp.data.episodes);
                setSearchResulthosts(resp.data.hosts);
                setSearchResultShows(resp.data.shows);
                setWindowLength("filled");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setSearchResultEpisodes([]);
          setSearchResulthosts([]);
          setSearchResultShows([]);
          setWindowLength("default");
        }
      }
    }, 500);
    setTimer(newTimer);
  };

  const handleRecommendedHost = (hostID) => {
    history.push(`/recommended-host/${hostID}`);
    setVisible(false);
  };

  const handleEpsisode = (showSlug, EpisodeSlug) => {
    history.push({
      pathname: `/show/${showSlug}`,
      state: {
        showID: showSlug,
        episodeNumber: EpisodeSlug,
      },
    });
    setVisible(false);
  };

  const handleShowEpisode = (slug) => {
    history.push(`/show/${slug}`);
    setVisible(false);
  };

  const handleLogOut = () => {
    localStorage.clear();
    history.push("/signin");
  };

  useEffect(() => {
    // apiInstance
    //   .get("/listener/sochcast-recommendations")
    //   .then((response) => {
    //     setSochcastRecommendations(response.data.results);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // apiInstance
    //   .get("/listener/popular-shows")
    //   .then((response) => {
    //     setPopularShows(response.data.results);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // apiInstance
    //   .get("/listener/sochcast-originals")
    //   .then((response) => {
    //     setOriginalShows(response.data.results);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    if (
      searchResultEpisodes?.length !== 0 &&
      searchResulthosts?.length !== 0 &&
      searchResultShows?.length !== 0
    ) {
      setWindowLength("filled");
    }
  }, []);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        sticky="top"
        className="lisetener-nav"
      >
        <Container>
          <Navbar.Brand className="brand-logo" href="/">
            <img
              src={sochLogo}
              alt="Soch Logo"
              className="img-fluid expanded"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto order-0 custon-listerne-li">
              <Nav.Link href="/listener" className={classnameHome}>
                Home
              </Nav.Link>
              <Nav.Link href="/your-library" className={classnameLib}>
                Your Library
              </Nav.Link>
              <Nav.Link href="/explore" className={classnameExplore}>
                Explore
              </Nav.Link>
              <Nav.Link onClick={handleVisibility}>Search</Nav.Link>
              <aside className={visible ? "open" : "close"}>
                <div className={`input-search-box ${windowLength}`}>
                  {/* <div className={`input-search-box`}> */}
                  <div className={`main-input-search-holder ${windowLength}`}>
                    {/* <div className={`main-input-search-holder`}> */}
                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder="Search for artists, Episode & …"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        className="input-listener-search"
                        name="search"
                        onChange={(e) => handleSearchChange(e.target.value)}
                        autoComplete="off"
                      />
                      <Button
                        variant="outline-secondary"
                        id="button-addon2"
                        className="input-search-close"
                        onClick={handleVisibility}
                      >
                        <AiOutlineClose className="close-button-search" />
                      </Button>
                    </InputGroup>
                  </div>
                </div>
                <div className={`search-result-content`}>
                  {searchResultEpisodes?.length === 0 &&
                  searchResulthosts?.length === 0 &&
                  searchResultShows?.length === 0 ? (
                    <></>
                  ) : (
                    <>
                      <Row>
                        <h5>Episodes</h5>
                        {searchResultEpisodes?.length !== 0 ? (
                          <>
                            <Carousel
                              responsive={responsive}
                              containerClass="carousel-container"
                              ssr={true}
                              autoPlaySpeed={500}
                            >
                              {searchResultEpisodes?.map((item, index) => {
                                return (
                                  <Col key={item?.id} className="my-3">
                                    <div key={item?.id}>
                                      <Card
                                        className="playcard searchPlaycard"
                                        onClick={() =>
                                          handleEpsisode(
                                            item.shows[0]?.slug,
                                            item.slug
                                          )
                                        }
                                      >
                                        <Card.Img
                                          variant="top"
                                          src={item?.episode_image}
                                          className="f-listen-image-holder search-img-holder"
                                          alt={item?.name}
                                        />
                                        <Card.Body className="card-b-listen">
                                          <Card.Title className="l-title-albumn">
                                            {item?.name}
                                          </Card.Title>
                                          <Card.Text className="l-author-albumn">
                                            {
                                              item?.shows[0]?.hosts[0]
                                                ?.first_name
                                            }
                                          </Card.Text>
                                        </Card.Body>
                                      </Card>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Carousel>
                          </>
                        ) : (
                          <p>No Episodes Found</p>
                        )}
                      </Row>

                      <Row className="mt-4">
                        <h5>Hosts</h5>
                        {searchResulthosts?.length !== 0 ? (
                          <>
                            <Carousel
                              responsive={responsive}
                              containerClass="carousel-container"
                              ssr={true}
                              autoPlaySpeed={500}
                            >
                              {searchResulthosts?.map((item, index) => {
                                return (
                                  <Col key={item?.id} className="my-1">
                                    <div>
                                      <Card
                                        className="playcard searchPlaycard"
                                        onClick={() =>
                                          handleRecommendedHost(item.id)
                                        }
                                      >
                                        <Card.Img
                                          variant="top"
                                          src={item.host_image}
                                          className="f-listen-image-holder artist search-img-holder"
                                          alt={item?.name}
                                        />
                                        <Card.Body className="card-b-listen text-center">
                                          <Card.Title className="l-title-albumn text-capitalize">
                                            {item.first_name +
                                              " " +
                                              item.last_name}
                                          </Card.Title>
                                          {/* <Card.Text className="l-author-albumn">
                                      {item.host_total_shows} Shows
                                    </Card.Text> */}
                                        </Card.Body>
                                      </Card>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Carousel>
                          </>
                        ) : (
                          <p>No Hosts Found</p>
                        )}
                      </Row>

                      <Row>
                        <h5>Shows</h5>
                        {searchResultShows?.length !== 0 ? (
                          <>
                            {/* Carousel */}
                            <Carousel
                              responsive={responsive}
                              containerClass="carousel-container"
                              ssr={true}
                              autoPlaySpeed={500}
                            >
                              {searchResultShows?.map((item, index) => {
                                return (
                                  <Col key={item?.id} className="my-3">
                                    <div>
                                      <Card
                                        className="playcard searchPlaycard"
                                        onClick={() =>
                                          handleShowEpisode(item.slug)
                                        }
                                      >
                                        <Card.Img
                                          variant="top"
                                          src={
                                            item?.show_compressed_image !== null
                                              ? item?.show_compressed_image
                                              : item?.show_image
                                          }
                                          className="f-listen-image-holder search-img-holder"
                                          alt={item?.name}
                                        />
                                        <Card.Body className="card-b-listen">
                                          <Card.Title className="l-title-albumn">
                                            {item?.name}
                                          </Card.Title>
                                          <Card.Text className="l-author-albumn">
                                            {item?.hosts[0]?.first_name +
                                              " " +
                                              item?.hosts[0]?.last_name}
                                          </Card.Text>
                                        </Card.Body>
                                      </Card>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Carousel>
                          </>
                        ) : (
                          <p>No Shows Found</p>
                        )}
                      </Row>
                    </>
                  )}
                </div>
              </aside>
            </Nav>
            <Nav>
              {/* <Nav.Link onClick={handleThemeChange} className="dark-icon">
                <div>
                  {themFlag ? (
                    <DarkIcon className="dark" />
                  ) : (
                    <DarkIcon className="light" />
                  )}
                </div>
              </Nav.Link> */}
              {/* <Nav.Link>Language</Nav.Link> */}
              {buttonStatus ? (
                <>
                  <Nav.Link href="/home" className="my-auto">
                    <Button className="landing-signin listener become-sochcaster">
                      Become a Sochcaster
                    </Button>
                  </Nav.Link>
                  <Nav.Link href="/signup">
                    <Button className="landing-signup listener">Sign Up</Button>
                  </Nav.Link>
                  <Nav.Link href="/signin" className="my-auto">
                    <Button className="landing-signin listener">Sign in</Button>
                  </Nav.Link>
                </>
              ) : (
                <>
                  <Nav.Link href="/home" className="my-auto">
                    <Button className="landing-signin listener become-sochcaster">
                      Become a Sochcaster
                    </Button>
                  </Nav.Link>
                  <Nav.Item className="mx-3">
                    <Dropdown className="profile-settings">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {profileImageStatus ? (
                          <img
                            src={localStorage.getItem("profile_image")}
                            width="40"
                            height="40"
                            class="rounded-circle"
                            alt="avatar"
                          />
                        ) : (
                          <div className="profile-image">
                            <h1>{userName.userNameData.userNameData}</h1>
                          </div>
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="profile-Dropdown">
                        <Dropdown.Item className="profile-clip">
                          <img
                            src={localStorage.getItem("profile_image")}
                            className="img-fluid profile-drop-down-icon-menu"
                            alt="user profile"
                          />
                        </Dropdown.Item>
                        <div className="user-name-email text-center mt-5">
                          <p className="drop-name mb-2 text-capitalize">
                            {localStorage.getItem("first_name") +
                              " " +
                              localStorage.getItem("last_name")}
                          </p>
                          <p className="drop-email mb-0">
                            {localStorage.getItem("email")}
                          </p>
                        </div>
                        <Dropdown.Item>
                          <PodcastIcon className="mx-2" />
                          Sochcast
                        </Dropdown.Item>
                        {/* <Dropdown.Item onClick={handleProfileSettings}>
                          <IconsSettings className="mx-2" />
                          Settings
                        </Dropdown.Item> */}
                        <Dropdown.Item onClick={handleLogOut}>
                          <SignOutIcon className="mx-2" />
                          Sign Out
                        </Dropdown.Item>
                        {/* <hr
                          style={{
                            width: "90%",
                            margin: "0 auto",
                            marginTop: "5px",
                          }}
                        />
                        <Link to="/upgrade" style={{ textDecoration: "none" }}>
                          <Row className="mt-2" style={{ cursor: "pointer" }}>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                              <div className="pro-profile-drop">
                                <div className="pro">
                                  <div className="pro-icon"></div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                              <p className="mb-0 pro-small">Sochcast</p>
                              <span className="mb-0 more-small">more</span>
                            </Col>
                          </Row>
                        </Link> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Item>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
