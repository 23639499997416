import React from "react";
import { Row, Col } from "react-bootstrap";

export const CommonTitle = ({ heading, text, type, ...props }) => {
  return (
    <Row>
      <Col xs={9} md={9} lg={9} xl={9} className="createChannelTitle">
        <h1>{heading}</h1>
        <p>{text}</p>
      </Col>
      <Col xs={3} md={3} lg={3} xl={3} className="createChannelTitleIcon">
        <div className="icon text-center">
          <img src={props.icons} alt="ChannelIcon" className="my-3" />
        </div>
      </Col>
    </Row>
  );
};
