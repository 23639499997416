import React, { useState } from "react";
import "./notfound.css";
import { TopHeaderNavigation } from "../components/listener/TopHeaderNavigation";
import { Container } from "react-bootstrap";

export const NotFound = () => {
  const [themeStatus, setThemeStatus] = useState("theme-light");

  return (
    <>
      <TopHeaderNavigation
        changeTheme={(themeStatus) => setThemeStatus(themeStatus)}
        upgrade="home"
      />
      <Container>
        <div class="middle">
          <h1>Page Not Found</h1>
          <p>The page you are looking for is not found.</p>
        </div>
      </Container>
    </>
  );
};
