import React, { useState, useContext, useRef } from "react";
import { Col, Row, Button } from "react-bootstrap";
import "../css/channel/Channel.css";
import HostIcon from "../../assets/images/people-outlineHost.svg";
import { Formik, Form } from "formik";
import { InputField } from "../../components/formFields/InputField";
import * as Yup from "yup";
import { CommonTitle } from "../../components/CommonTitle";
import { TagsInput } from "../../components/formFields/TagsInput";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { isUnlisted } from "../../helperFunction";
import { RadioButton } from "../../components/formFields/RadioButton";
import apiInstance from "../../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginContext } from "../channel/Helper/Context";
import { accessToken } from "../../helperFunction";
import ReactTooltip from "react-tooltip";

export const AddNewHost = () => {
  const fileElem = useRef(null);
  const { setFormStatus } = useContext(LoginContext);
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    host_image: "",
    isUnlisted: "",
    picked: "",
  };

  const validate = Yup.object({
    firstName: Yup.string().required(),
    // lastName: Yup.string().required(),
    email: Yup.string().email().required(),
  });

  const [buttonPublishStatus, setButtonPublishStatus] = useState(false);
  const [userInputTags, setUserInputTags] = useState([]);
  const [filepreview, setFilePreview] = useState(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const selectedTags = (tags) => {
    setUserInputTags(tags);
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  const handleSubmit = async (values) => {
    var genderSel = "";
    if (values.picked === "Man") {
      genderSel = "1";
    } else if (values.picked === "Woman") {
      genderSel = "2";
    } else if (values.picked === "Non-Binary") {
      genderSel = "3";
    } else if (values.picked === "Other") {
      genderSel = "4";
    }

    let unListed = "";
    let isPublished = false;
    if (values.isUnlisted === "Yes") {
      unListed = "True";
      isPublished = false;
    } else if (values.isUnlisted === "No") {
      unListed = "False";
      isPublished = true;
    }

    if (values.picked === "" || values.picked === undefined) {
      toast.error("Please pick your Gender", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInputTags.length < 1) {
      toast.error("Please enter atleast a minimum of 1 tags", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (unListed === "" || unListed === null) {
      toast.error("Please select an option for listed or unlisted", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (values.host_image === null || values.host_image === "") {
      toast.error("Please upload host imgae", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setButtonPublishStatus(true);
      let data = new FormData();
      data.append("first_name", values.firstName);
      data.append("last_name", values.lastName);
      data.append("email", values.email);
      data.append(
        "description",
        `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}`
      );
      data.append("gender", genderSel);
      data.append("is_unlisted", unListed);
      data.append("is_published", isPublished);
      for (var i = 0; i < userInputTags.length; i++) {
        data.append(`tags[${i}]`, userInputTags[i]);
      }
      data.append("host_image", values.host_image);
      apiInstance
        .post("/host/create/", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setFormStatus(false);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (error.response.data.error_type === "InvalidToken") {
              accessToken();
              handleSubmit();
            }
          }
        });
    }
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const genderSelect = [
    { key: "1", value: "Man" },
    { key: "2", value: "Woman" },
    { key: "3", value: "Non-Binary" },
    { key: "4", value: "Other" },
  ];

  const handleRemoveHostImage = () => {
    setFilePreview(null);
  };

  return (
    <div className="p-5 mainContainerAdd">
      <CommonTitle heading="Create An Host" text="" icons={HostIcon} />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Row>
        <Col xs={12} md={12} lg={12} xl={12} className="mt-5">
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form onKeyDown={onKeyDown}>
                <div className="channel-form">
                  <Row className="mx-4 py-5">
                    <Col xs={12} md={10} lg={10} xl={10}>
                      <Row>
                        <Col
                          xs={12}
                          md={6}
                          lg={6}
                          xl={6}
                          data-tip
                          data-for="fname"
                        >
                          <InputField
                            label="First Name*"
                            name="firstName"
                            type="text"
                            data-testid="host-first-name"
                          />
                          <ReactTooltip id="fname" place="top" effect="solid">
                            Your first name
                          </ReactTooltip>
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          lg={6}
                          xl={6}
                          data-tip
                          data-for="lname"
                        >
                          <InputField
                            label="Last Name*"
                            name="lastName"
                            type="text"
                            data-testid="host-last-name"
                          />
                          <ReactTooltip id="lname" place="top" effect="solid">
                            Your Last name
                          </ReactTooltip>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      md={10}
                      lg={10}
                      xl={10}
                      data-tip
                      data-for="hemail"
                    >
                      <InputField
                        label="Email"
                        name="email"
                        type="email"
                        data-testid="host-email-id"
                      />
                      <ReactTooltip id="lname" place="top" effect="solid">
                        Your Email Id where we can send your account information
                      </ReactTooltip>
                    </Col>
                    <Col
                      xs={12}
                      md={10}
                      lg={10}
                      xl={10}
                      data-tip
                      data-for="hostDescription"
                    >
                      <label
                        for="channelDescription"
                        className="formLabel mt-3"
                      >
                        Description*
                      </label>
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="ChannelDesscription"
                        editorClassName="editorClassName"
                        toolbar={{
                          options: ["emoji", "link"],
                          emoji: { className: "emojiClassNAme" },
                          link: {
                            className: "imageClassName",
                            options: ["link"],
                          },
                        }}
                        editorState={editorState}
                        defaultEditorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                      />
                      <ReactTooltip
                        id="hostDescription"
                        place="top"
                        effect="solid"
                      >
                        A brief bio of Host. Get discovered
                      </ReactTooltip>
                    </Col>
                    <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                      <label className="formLabel">What's your gender ?</label>
                      <br />
                      <RadioButton options={genderSelect} type="gender" />
                    </Col>
                    <Col
                      xs={12}
                      md={10}
                      lg={10}
                      xl={10}
                      className="mt-3"
                      data-tip
                      data-for="hostTag"
                    >
                      <label className="formLabel">Tag*</label>
                      <TagsInput selectedTags={selectedTags} tags={[]} />
                      <ReactTooltip id="hostTag" place="top" effect="solid">
                        Use relevant tags to get discovered
                      </ReactTooltip>
                    </Col>
                    <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                      <label className="formLabel">
                        Upload Host image*{" "}
                        <p className="text-muted">Only *.png accepted</p>
                      </label>
                      <br />
                      <Row>
                        <Col
                          xs={12}
                          md={3}
                          lg={3}
                          xl={3}
                          className="img-mobile-holder"
                        >
                          <div className="banner-image-holder">
                            {filepreview !== null ? (
                              <img
                                src={filepreview}
                                alt="Profile"
                                className="img-fluid banner-image"
                              />
                            ) : (
                              <div className="upload-image-text-holder">
                                <p>Add Image</p>
                                <Button
                                  className="add-image-icon"
                                  onClick={() => {
                                    fileElem.current &&
                                      fileElem.current.click();
                                  }}
                                >
                                  +
                                </Button>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xs={6} md={3} lg={3} xl={3} className="mt-2">
                          <input
                            name="hidden-file-input"
                            type="file"
                            ref={fileElem}
                            accept="image/png"
                            style={{ display: "none" }}
                            onChange={(event) => {
                              formik.setFieldValue(
                                "host_image",
                                event.target.files[0]
                              );
                              setFilePreview(
                                URL.createObjectURL(event.target.files[0])
                              );
                            }}
                          />
                          <Button
                            className="upload-picture-button"
                            onClick={() => {
                              fileElem.current && fileElem.current.click();
                            }}
                          >
                            Upload Picture
                          </Button>
                        </Col>
                        <Col xs={6} md={6} lg={6} xl={6} className="mt-2">
                          <Button
                            className="upload-picture-button remove"
                            onClick={handleRemoveHostImage}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={10} lg={10} xl={10} className="mt-3">
                      <label className="formLabel">Is Unlisted</label>
                      <br />
                      <RadioButton options={isUnlisted} type="isUnlisted" />
                    </Col>
                    <Col xs={12} md={10} lg={10} xl={10}>
                      {formik.values.isUnlisted === "Yes"
                        ? "The Host is not published and will not be shown in the RSS."
                        : formik.values.isUnlisted === "No"
                        ? "The Host is published and will be shown in the RSS."
                        : null}
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="mt-4 chananelButton"
                  >
                    <Button
                      disabled={buttonPublishStatus}
                      variant="primary"
                      type="submit"
                      className="channelPublishButton rounded-2"
                      title="channelPublishButton"
                      data-testid="signUpBtton"
                    >
                      {buttonPublishStatus ? "Saving ..." : "Publish"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
