import React from "react";
import { useField } from "formik";

export const InputField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={field.name} className="formLabel">
        {label}
      </label>
      <input
        className={`form-control shadow-none rounded-2 mb-1 customInput ${
          props.styleClass && "profileEdit"
        } ${meta.touched && meta.error && "is-invalid"}`}
        autoComplete="off"
        {...field}
        {...props}
        title="textField"
      />
      {/* <ErrorMessage name={field.name} component="div" className="error" /> */}
    </>
  );
};
