import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../listener.css";
import apiInstance from "../../api";
import { BiChevronRight } from "react-icons/bi";
import { useHistory } from "react-router-dom";

export const TopEpisode = () => {
  const [topEpisodes, setTopEpisodes] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function getTopEpisodes() {
      let nextUrl = "/listener/top-episodes";
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFunc() {
      let temp1 = await getTopEpisodes();
      setTopEpisodes(temp1);
    }

    tempFunc();
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
    smallDevices: {
      breakpoint: { max: 320, min: 0 },
      items: 1,
    },
  };

  const handleShowEpisode = (eSlug, sSlug) => {
    history.push(`/show/${sSlug}/episode/${eSlug}`);
  };

  const handleViewAllTopEpisodes = () => {
    history.push("/top-episodes");
  };

  return (
    <>
      <div className="my-5">
        <p className="sub-main-text">For you</p>
        <Row>
          <Col xs={12} sm={12} md={9}>
            <h3 className="listener-main-text">Top Episodes</h3>
          </Col>
          <Col xs={12} sm={12} md={3}>
            <p className="view-all" onClick={handleViewAllTopEpisodes}>
              View all
              <BiChevronRight />
            </p>
          </Col>
        </Row>

        <Carousel responsive={responsive}>
          {topEpisodes?.map((item) => {
            return (
              <div key={item.id}>
                <Card
                  className="playcard"
                  onClick={() =>
                    handleShowEpisode(item.slug, item.shows[0].slug)
                  }
                >
                  <Card.Img
                    variant="top"
                    src={item.episode_image}
                    className="f-listen-image-holder"
                    alt={item.name}
                  />
                  <Card.Body className="card-b-listen">
                    <Card.Title className="l-title-albumn text-capitalize">
                      {item.name}
                    </Card.Title>
                    <Card.Text className="l-author-albumn">
                      By{" "}
                      {item.shows[0]?.hosts[0]?.first_name +
                        " " +
                        item.shows[0]?.hosts[0]?.last_name}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};
