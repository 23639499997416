import React, { useEffect, useState } from "react";
import { TopHeaderNavigation } from "../../components/listener/TopHeaderNavigation";
import { keepTheme } from "../../utils/listenerTheme/Theme";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../listener.css";
import EpisodePlay from "../../assets/images/episodeplay.png";
import EpisodeFollow from "../../assets/images/add-circle-outline.png";
import FollowMore from "../../assets/images/FollowMore.png";
import { Trending } from "../library/Trending";
import { PopularArtist } from "../popularartist/PopularArtist";
import apiInstance from "../../api";
import moment from "moment";
import { LikedCustomAudioPlayer } from "../audioplayer/LikedCustomAudioPlayer";
import sochLogo from "../../assets/images/sochlogo.png";
import LikedHeart from "../../assets/images/heartLIked.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { accessToken } from "../../helperFunction";
import { PlaylistPopup } from "./PlaylistPopup";

export const LikedEpisodeList = () => {
  const [themeStatus, setThemeStatus] = useState("theme-light");
  const [episodeData, setEpisodeData] = useState([]);
  const [selectedEpisodeToPlay, setSelectedEpisodeToPlay] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [likedIconStatus, setLikedIconStatus] = useState(true);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [sinlgeEpisodeStatus, setSinlgeEpisodeStatus] = useState(false);
  const [selectedSingleEpisodeToPlay, setSelectedSingleEpisodeToPlay] =
    useState([]);
  const [playlistPopupModalShow, setPlaylistPopupModalShow] = useState(false);
  const [episodePlayListID, setEpisodePlayListID] = useState("");

  useEffect(() => {
    keepTheme();
    if (localStorage.getItem("theme") === "theme-dark") {
      setThemeStatus("theme-dark");
    } else if (localStorage.getItem("theme") === "theme-light") {
      setThemeStatus("theme-light");
    }

    let config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    };

    async function getAllSochLikedEpisodes() {
      let nextUrl = "/listener/liked-episodes/";
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl, config);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFunc() {
      let temp1 = await getAllSochLikedEpisodes();
      setEpisodeData(temp1);
    }

    // async function getLikedEpisodes() {
    //   await apiInstance
    //     .get("/listener/liked-episodes/", {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem("access")}`,
    //       },
    //     })
    //     .then((response) => {
    //       if (response.status === 200) {
    //         setEpisodeData(response.data.results);
    //       }
    //     })
    //     .catch((error) => {
    //       if (error.response.status === 401) {
    //         if (error.response.data.error_type === "InvalidToken") {
    //           // accessToken();
    //           // getLikedEpisodes();
    //         }
    //       }
    //     });
    // }
    tempFunc();
  }, [refreshStatus]);

  const handlePlayEpisode = (episodeIndex) => {
    setSinlgeEpisodeStatus(true);
    var arr = [];
    arr.push(episodeData[episodeIndex]);
    setSelectedSingleEpisodeToPlay(arr);
  };

  const handleLikedEpisode = (likedEpisodeID, e) => {
    e.stopPropagation();
    setRefreshStatus(false);

    apiInstance
      .delete(`/listener/liked-episodes/${likedEpisodeID}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success(`Episode removed from likes.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRefreshStatus(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (error.response.data.error_type === "InvalidToken") {
            handleLikedEpisode();
          }
        }
      });
  };

  const myEpisodesData = episodeData.sort(
    (a, b) => parseFloat(a.episode_number) - parseFloat(b.episode_number)
  );

  // Play Now
  const handlePlayNow = () => {
    setSelectedEpisodeToPlay(myEpisodesData);
  };

  // Play List
  const handleSingleEpisodeID = (playlistEpiosdeID) => {
    console.log(playlistEpiosdeID);
    setEpisodePlayListID(playlistEpiosdeID);
  };

  // calculation Duration Starts
  var total = 0;
  myEpisodesData.forEach((item) => {
    total += item.liked_episode.duration;
  });

  var date = new Date(0);
  date.setSeconds(total);
  var timeString = date.toISOString().substr(11, 8);
  // calculation Duration Ends

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className={themeStatus}>
        <TopHeaderNavigation
          changeTheme={(themeStatus) => setThemeStatus(themeStatus)}
          upgrade=""
        />
        {episodeData?.length !== 0 ? (
          <Container className="my-5">
            <Row>
              <Col md={3}>
                {/* <div>
                  <img
                    src={episodeData[0]?.shows[0].show_image}
                    alt="epsidoe"
                    className="img-fluid"
                    style={{ borderRadius: "25px" }}
                  />
                </div> */}
              </Col>
              <Col md={12} className="mt-5">
                <div className="episodelist-listener">
                  <h4>Your likes</h4>
                  <p>
                    An archived talk show with interesting snippets from the
                    lives of your favorite Bollywood celebs on an.
                  </p>
                  <p className="created-epiode-by">
                    {/* <span>Private : Paresh solanki</span> */}
                    <span>{episodeData?.length} Episode</span>
                    <span className="mx-4">{timeString} Duration</span>
                  </p>
                </div>
                <Row>
                  <Col md={2} sm={4} xs={4}>
                    <Button className="episode-playnow" onClick={handlePlayNow}>
                      <img
                        src={EpisodePlay}
                        alt="Play Now"
                        className="img-fluid playnowEpisode"
                      />
                      Play Now
                    </Button>
                  </Col>
                  <Col md={1} sm={2} xs={2}>
                    <div className="f-m">
                      <img
                        src={FollowMore}
                        alt="Follow and More"
                        className="img-fluid like-heart"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <h5 className="episode-all-title mt-5 mb-4">All Episodes</h5>
            {myEpisodesData?.map((item, index) => {
              return (
                <Row
                  className={`mb-3 episode-row-play-${
                    index === activeIndex ? "active" : "inactive"
                  }`}
                  key={item?.liked_episode.id}
                  onClick={() => {
                    handlePlayEpisode(index);
                    setActiveIndex(index);
                  }}
                >
                  <Col md={5} sm={12} xs={12}>
                    <div className="episode-list-all">
                      <p>
                        <span>{item?.liked_episode?.episode_number}.</span>
                        <img
                          src={item?.liked_episode?.episode_image}
                          alt="Episode"
                          className="img-fluid mx-3"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "10px",
                          }}
                        />
                        <span>{item?.liked_episode?.name}</span>
                      </p>
                    </div>
                  </Col>
                  <Col md={2} sm={6} xs={6} className="my-auto">
                    <div className="episode-list-all artist-name my-auto">
                      <p>
                        By{" "}
                        {item?.liked_episode?.shows[0]?.hosts[0]?.first_name +
                          " " +
                          item?.liked_episode?.shows[0]?.hosts[0]?.last_name}
                      </p>
                    </div>
                  </Col>
                  <Col md={2} sm={6} xs={6} className="my-auto">
                    <div className="episode-list-all artist-name my-auto">
                      <p>
                        {moment(
                          moment(item?.liked_episode?.created_at).toDate()
                        ).format("D MMMM YYYY")}
                      </p>
                    </div>
                  </Col>
                  <Col md={3} sm={12} xs={12} className="my-auto">
                    <div className="episode-list-all artist-name my-auto float-end">
                      <p>
                        <span className="durationofepisode mx-5">
                          {Math.floor(item?.liked_episode?.duration / 60)} min
                        </span>
                        <span
                          className="mx-3"
                          onClick={(e) => handleLikedEpisode(item?.id, e)}
                        >
                          {likedIconStatus ? (
                            <img
                              src={LikedHeart}
                              alt="Follow and More"
                              className="img-fluid like-heart"
                            />
                          ) : (
                            <img
                              src={FollowMore}
                              alt="Follow and More"
                              className="img-fluid like-heart"
                            />
                          )}
                        </span>
                        <span
                          onClick={() => {
                            setPlaylistPopupModalShow(true);
                            handleSingleEpisodeID(item.liked_episode.id);
                          }}
                        >
                          <img
                            src={EpisodeFollow}
                            alt="Follow and More"
                            className="img-fluid like-heart"
                          />
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>
              );
            })}

            <div className="my-5">
              {/* <PopularArtist /> */}
              <Trending />
            </div>
            <div className="audio-player-holder container">
              {sinlgeEpisodeStatus ? (
                <LikedCustomAudioPlayer item={selectedSingleEpisodeToPlay} />
              ) : (
                <LikedCustomAudioPlayer item={selectedEpisodeToPlay} />
              )}
            </div>
          </Container>
        ) : (
          <div className="loading">
            <img src={sochLogo} alt="Loading" />{" "}
            <span>Loading Sochcasts...</span>
          </div>
        )}
      </div>

      <PlaylistPopup
        show={playlistPopupModalShow}
        onHide={() => setPlaylistPopupModalShow(false)}
        episodeID={episodePlayListID}
      />
    </>
  );
};
