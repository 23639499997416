import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { Col, Row, Button, Card } from "react-bootstrap";
import "../css/channel/Channel.css";
import "../css/episode/Episode.css";
// import { Formik, Form } from "formik";
import { InputField } from "../../components/formFields/InputField";
import * as Yup from "yup";
import { isUnlisted } from "../../helperFunction";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Multiselect from "multiselect-react-dropdown";
import { SelectDropdown } from "../../components/formFields/SelectDropdown";
import { RadioButton } from "../../components/formFields/RadioButton";
import { episodeTypeDropDown } from "../../helperFunction";
import { Form, Formik } from "formik";
import { useDropzone } from "react-dropzone";
import apiInstance from "../../api";
import { accessToken } from "../../helperFunction";
import { TagsInput } from "../../components/formFields/TagsInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginContext } from "../channel/Helper/Context";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";

export const AddNewEpisode = () => {
  const [data, setData] = useState({
    episodeDescription: "",
    episodeName: "",
    episodeNo: "",
    episodeType: "",
    isUnlisted: "",
    showEpisode: [],
  });
  const [currentStep, setCurrentStep] = useState(0);

  const submitEpisode = (formData) => {
    // let unListed = "";
    // let eType = "";
    // if (formData.isUnlisted === "Yes") {
    //   unListed = "True";
    // } else if (formData.isUnlisted === "No") {
    //   unListed = "False";
    // }
    // if (formData.episodeType === "0") {
    //   eType = "Full";
    // } else if (formData.episodeType === "1") {
    //   eType = "Bonus";
    // } else if (formData.episodeType === "2") {
    //   eType = "Trailer";
    // }
    // let data = new FormData();
    // data.append("episode_image", formData.episode_image);
    // data.append("name", formData.episodeName);
    // data.append("description", formData.episodeDescription);
    // data.append("episode_type", `${eType}`);
    // data.append("episode_number", formData.episodeNo);
    // data.append("is_unlisted", `${unListed}`);
    // data.append("file", formData.file);
    // for (var i = 0; i < formData.tags.length; i++) {
    //   data.append(`tags[${[i]}]`, formData.tags[i]);
    // }
    // const showList = formData.showEpisode.map((e) => e.id);
    // for (var showA = 0; showA < showList.length; showA++) {
    //   data.append("shows", showList[showA]);
    // }
    // const config = {
    //   onUploadProgress: (progressEvent) => {
    //     const { loaded, total } = progressEvent;
    //     let percentage = Math.floor((loaded * 100) / total);
    //     setProgess(percentage);
    //   },
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("access")}`,
    //   },
    // };
    // apiInstance
    //   .post(`/shows/${formData.showEpisode[0].id}/episodes/`, data, config)
    //   .then((response) => {
    //     if (response.status === 200) {
    //       setFormStatus(false);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const handleNextStep = (newData, final = false) => {
    setData((prev) => ({ ...prev, ...newData }));
    if (final) {
      submitEpisode(newData);
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handleCancelStep = () => {
    setData({});
    setCurrentStep(0);
  };

  const steps = [
    <StepOne next={handleNextStep} cancel={handleCancelStep} data={data} />,
    <StepTwo next={handleNextStep} cancel={handleCancelStep} data={data} />,
    // <StepThree next={handleNextStep} cancel={handleCancelStep} data={data} />,
  ];

  return (
    <div className="p-5 mainContainerAdd">
      <div className="box">
        <Card>
          <Card.Body>
            <Card.Title>Create Your Episode</Card.Title>
            <Card.Text>
              Manage All Of The Episodes You Have Uploaded <br /> Or Published.
            </Card.Text>
          </Card.Body>
        </Card>
        <div className="box overlay">
          <Row>
            <Col xs={12} md={12} lg={12} xl={12} className="mt-5">
              {steps[currentStep]}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const stepOneValidationSchema = Yup.object({
  episodeName: Yup.string().required(),
  selectepisodetype: Yup.string().required(),
  episodeNo: Yup.string().required(),
});

const stepOneInitialValues = {
  episodeName: "",
  episodeType: "",
  episodeNo: "",
  isUnlisted: "",
  episode_image: "",
  tags: "",
};

function onKeyDown(keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

const StepOne = (props) => {
  const history = useHistory();
  useEffect(() => {
    async function getAllShows() {
      await apiInstance
        .get("/shows/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setShows(response.data.results);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (error.response.data.error_type === "InvalidToken") {
              accessToken();
              getAllShows();
            }
          }
        });
    }
    getAllShows();
  }, []);
  const [shows, setShows] = useState([]);
  const [userInputTags, setUserInputTags] = useState([]);
  const [filepreview, setFilePreview] = useState(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [showArray, setShowArray] = useState([]);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  const onShowSelect = (selectedList, selectedItem) => {
    setShowArray(selectedList);
  };

  const selectedTags = (tags) => {
    setUserInputTags(tags);
  };

  shows.forEach((item) => {
    delete item.abusive_content;
    delete item.categories;
    delete item.channels;
    delete item.created_at;
    delete item.description;
    delete item.explicit_content;
    delete item.hosts;
    delete item.intro;
    delete item.is_published;
    delete item.is_unlisted;
    delete item.language;
    delete item.pre_roll;
    delete item.rating;
    delete item.show_image;
    delete item.tags;
    delete item.trailer;
    delete item.updated_at;
    delete item.user;
    delete item.banner_image;
  });

  const handleNextSumit = (values) => {
    const dataFromStepOne = {
      episodeName: values.episodeName,
      episodeDescription: `${draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      )}`,
      episodeType: values.episodeType,
      episodeNo: values.episodeNo,
      showEpisode: showArray,
      isUnlisted: values.isUnlisted,
      episode_image: values.episode_image,
      tags: userInputTags,
    };

    if (
      values.episodeName === "" ||
      values.episodeName === undefined ||
      values.episodeName === null
    ) {
      toast.error("Enter your Episode Name", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.episodeType === "" ||
      values.episodeType === undefined ||
      values.episodeType === null
    ) {
      toast.error("Please select an Episode Type", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.episodeNo === "" ||
      values.episodeNo === undefined ||
      values.episodeNo === null
    ) {
      toast.error("Please enter an Episode Number", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.episode_image === "" ||
      values.episode_image === undefined ||
      values.episode_image === null
    ) {
      toast.error("Please upload an Episode Image", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      values.isUnlisted === "" ||
      values.isUnlisted === undefined ||
      values.isUnlisted === null
    ) {
      toast.error("Please select an option for is Unlisted", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInputTags.length < 3) {
      toast.error("Please enter atleast a minimum of 3 tags", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (showArray.length === 0) {
      toast.error("Please select a show", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      props.next(dataFromStepOne);
    }
  };

  const fileElem = useRef(null);
  const { setFormStatus } = useContext(LoginContext);

  return (
    <Formik
      initialValues={stepOneInitialValues}
      validationSchema={stepOneValidationSchema}
    >
      {({ values, setFieldValue }) => (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Form onKeyDown={onKeyDown}>
            <div className="channel-form">
              <Row className="mx-4 py-5">
                <Col xs={12} md={12} lg={12} xl={12} className="text-center">
                  <div
                    className="banner-image-holder episdoe-image-holder"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      fileElem.current && fileElem.current.click();
                    }}
                  >
                    {filepreview !== null ? (
                      <img
                        src={filepreview}
                        alt="Profile"
                        className="img-fluid banner-image"
                      />
                    ) : (
                      <div className="upload-image-text-holder">
                        <p>Add Image</p>
                        <div>
                          <span>+</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <br />
                  <label
                    for="channelDescription"
                    className="formLabel text-center"
                  >
                    Upload cover picture
                    <p className="text-muted">Only *.png accepted</p>
                  </label>
                  <input
                    type="file"
                    name="file"
                    ref={fileElem}
                    accept="image/png"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setFieldValue("episode_image", event.target.files[0]);
                      setFilePreview(
                        URL.createObjectURL(event.target.files[0])
                      );
                    }}
                  />
                </Col>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <InputField
                    label="Episode Name*"
                    name="episodeName"
                    type="text"
                    data-testid="channel-name"
                    data-tip
                    data-for="episName"
                  />
                  <ReactTooltip id="episName" place="top" effect="solid">
                    Make it unique and teasing but accurate
                  </ReactTooltip>
                </Col>
                <Col xs={12} md={12} lg={12} xl={12} data-tip data-for="epDes">
                  <label for="channelDescription" className="formLabel mt-3">
                    Episode Description*
                  </label>
                  <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="ChannelDesscription"
                    editorClassName="editorClassName"
                    toolbar={{
                      options: ["emoji", "link"],
                      emoji: { className: "emojiClassNAme" },
                      link: {
                        className: "imageClassName",
                        options: ["link"],
                      },
                    }}
                    editorState={editorState}
                    defaultEditorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                  />
                  <ReactTooltip id="epDes" place="top" effect="solid">
                    Add popular keywords, be accurate. Get discovered
                  </ReactTooltip>
                </Col>
                <Col xs={12} md={6} lg={6} xl={6} className="mt-3">
                  <SelectDropdown
                    control="select"
                    name="episodeType"
                    data-testid="episodeType"
                    id="episodeType"
                    label="Episode Type"
                    options={episodeTypeDropDown}
                  />
                </Col>
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="mt-3"
                  data-tip
                  data-for="epiNo"
                >
                  <InputField
                    label="Episode No."
                    name="episodeNo"
                    type="text"
                    data-testid="episodeNo"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <ReactTooltip id="epiNo" place="top" effect="solid">
                    Helps with following you
                  </ReactTooltip>
                </Col>
                <Col xs={12} md={12} lg={12} xl={12} data-tip data-for="epiTag">
                  <label className="formLabel">Show</label>
                  <Multiselect
                    options={shows}
                    onSelect={onShowSelect}
                    displayValue="name"
                  />
                  <ReactTooltip id="epiTag" place="top" effect="solid">
                    Use relevant tags to get discovered
                  </ReactTooltip>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  data-tip
                  data-for="epiShow"
                >
                  <label className="formLabel">Tag*</label>
                  <TagsInput selectedTags={selectedTags} tags={[]} />
                  <ReactTooltip id="epiShow" place="top" effect="solid">
                    What show is this a part of?
                  </ReactTooltip>
                </Col>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <label className="formLabel">Is Unlisted</label>
                  <br />
                  <RadioButton options={isUnlisted} type="isUnlisted" />
                </Col>
                {values.isUnlisted === "Yes"
                  ? "The episode is not published and will not be show in the RSS."
                  : values.isUnlisted === "No"
                  ? "The episode is published and will be show in the RSS."
                  : null}
                <hr className="mt-4" />
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="mt-4 chananelButton episode-override"
                >
                  <Button
                    variant="primary"
                    type="reset"
                    className="channelDraftButton rounded-2 mx-3"
                    title="ChannelDrafButton"
                    data-testid="signUpBtton"
                    onClick={() => setFormStatus(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    className="channelPublishButton rounded-2"
                    title="channelPublishButton"
                    data-testid="signUpBtton"
                    onClick={() => handleNextSumit(values)}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

const StepTwo = (props) => {
  const history = useHistory();
  const [uploadProgressStatuts, setUploadProgressStatus] = useState(false);
  const [progess, setProgess] = useState(0);
  const { setFormStatus } = useContext(LoginContext);
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 20,
    border: "2px dashed #D6DDE5",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#FFFFFF",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    height: "250px",
    cursor: "pointer",
  };

  const activeStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ accept: ".mp3,audio/*", maxFiles: 1 });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleUploadSubmit = () => {
    if (acceptedFiles[0] === undefined || acceptedFiles[0] === null) {
      toast.error("Please upload your Episode.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setUploadProgressStatus(true);
      const dataFromStepTwo = {
        file: acceptedFiles[0],
      };

      let unListed = "";
      let eType = "";
      let isPublished = false;
      if (props.data.isUnlisted === "Yes") {
        unListed = "True";
        isPublished = false;
      } else if (props.data.isUnlisted === "No") {
        unListed = "False";
        isPublished = true;
      }
      if (props.data.episodeType === "0") {
        eType = "Full";
      } else if (props.data.episodeType === "1") {
        eType = "Bonus";
      } else if (props.data.episodeType === "2") {
        eType = "Trailer";
      }
      let data = new FormData();
      data.append("episode_image", props.data.episode_image);
      data.append("name", props.data.episodeName);
      data.append("description", props.data.episodeDescription);
      data.append("episode_type", `${eType}`);
      data.append("episode_number", props.data.episodeNo);
      data.append("is_unlisted", `${unListed}`);
      data.append("file", dataFromStepTwo.file);
      data.append("is_published", isPublished);
      for (var i = 0; i < props.data.tags.length; i++) {
        data.append(`tags[${[i]}]`, props.data.tags[i]);
      }
      const showList = props.data.showEpisode.map((e) => e.id);
      for (var showA = 0; showA < showList.length; showA++) {
        data.append("shows", showList[showA]);
      }
      const config = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percentage = Math.floor((loaded * 100) / total);
          setProgess(percentage);
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      };
      apiInstance
        .post(`/shows/${props.data.showEpisode[0].id}/episodes/`, data, config)
        .then((response) => {
          if (response.status === 200) {
            history.push("/episode");
            setFormStatus(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            accessToken();
            handleUploadSubmit();
          }
        });
    }
  };

  const initialValues = {};

  return (
    <Formik initialValues={initialValues}>
      {({ values }) => (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Form>
            <div className="channel-form">
              <Row className="mx-4 py-5">
                {uploadProgressStatuts ? (
                  <>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <h1 className="episode-step2-title">Uploading</h1>
                      <p className="episode-step2-subtitle">
                        Manage all of the episodes you have uploaded or
                        published.
                      </p>
                      <div className="loading-percentage text-center my-5">
                        <p>{progess}%</p>
                      </div>
                      <div className="text-center my-2 episode-file-name">
                        <p>{acceptedFileItems}</p>
                      </div>
                    </Col>
                    <hr className="mt-4" />
                    <div className="text-center episode-step2-subtitle">
                      <p>Please wait</p>
                    </div>
                  </>
                ) : (
                  <>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <h1 className="episode-step2-title">
                        Upload a New Episode
                      </h1>
                      <p className="episode-step2-subtitle">
                        Manage all of the episodes you have uploaded or
                        published.
                      </p>
                    </Col>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p className="mt-5 dropzonecutonscolr">
                          Drag and Drop Audio File to Upload A New Episode
                        </p>
                        <div className="button-brose-file">
                          <p className="mt-2">Browse File</p>
                        </div>
                      </div>
                      <aside>
                        <ul className="mt-3">{acceptedFileItems}</ul>
                      </aside>
                    </Col>
                    <hr className="mt-4" />
                    <Col
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="mt-4 chananelButton episode-override"
                    >
                      <Button
                        variant="primary"
                        type="reset"
                        className="channelDraftButton rounded-2 mx-3"
                        title="ChannelDrafButton"
                        data-testid="signUpBtton"
                        onClick={() => setFormStatus(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        className="channelPublishButton rounded-2"
                        title="channelPublishButton"
                        data-testid="signUpBtton"
                        onClick={() => handleUploadSubmit()}
                      >
                        Upload & Publish
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

// const StepThree = (props) => {
//   const handleFinalSubmit = (values) => {
//     props.next(values, true);
//   };

//   return (
//     <Formik
//       initialValues={props.data}
//       // validationSchema={stepOneValidationSchema}
//       // onSubmit={handleNext}
//     >
//       {({ values }) => (
//         <>
//           <Form>
//             <div className="channel-form">
//               <Row className="mx-4 py-5">
//                 <Col xs={12} md={12} lg={12} xl={12}>
//                   <h1 className="episode-step2-title">Uploading</h1>
//                   <p className="episode-step2-subtitle">
//                     Manage all of the episodes you have uploaded or published.
//                   </p>
//                 </Col>

//                 <hr className="mt-4" />
//                 <Col
//                   xs={12}
//                   md={12}
//                   lg={12}
//                   xl={12}
//                   className="mt-4 chananelButton episode-override"
//                 >
//                   <Button
//                     variant="primary"
//                     type="reset"
//                     className="channelDraftButton rounded-2 mx-3"
//                     title="ChannelDrafButton"
//                     data-testid="signUpBtton"
//                     onClick={() => props.cancel(values)}
//                   >
//                     Cancel
//                   </Button>
//                   <Button
//                     variant="primary"
//                     type="submit"
//                     className="channelPublishButton rounded-2"
//                     title="channelPublishButton"
//                     data-testid="signUpBtton"
//                     onClick={() => handleFinalSubmit(values)}
//                   >
//                     Publish
//                   </Button>
//                 </Col>
//               </Row>
//             </div>
//           </Form>
//         </>
//       )}
//     </Formik>
//   );
// };
