import React, { useState, useEffect } from "react";
import { Field, useField } from "formik";

export const SelectDropdown = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [v, Setv] = useState();

  useEffect(() => {
    Setv(props.preFillValue);
  }, []);

  // console.log(props.preFillValue);

  return (
    <>
      <label htmlFor={field.name} className="formLabel">
        {label}
      </label>
      <Field
        as="select"
        id={field.name}
        className={`form-control shadow-none rounded-2 mb-1 ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
        defualtValue={v}
      >
        <option>Select an option</option>;
        {props.options.map((option) => {
          return (
            <option key={option.id} value={option.id} title={option.title}>
              {option.name}
            </option>
          );
        })}
      </Field>
    </>
  );
};
