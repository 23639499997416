import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { keepTheme } from "../../utils/listenerTheme/Theme";
import apiInstance from "../../api";
import { TopHeaderNavigation } from "../../components/listener/TopHeaderNavigation";
import { Container, Row, Col, Card } from "react-bootstrap";
import { RecommendedHost } from "./RecommendedHost";
import { PopularCategories } from "../popularcategories/PopularCategories";
import { useHistory } from "react-router-dom";
import LazyLoad from "react-lazyload";

export const HostListen = () => {
  const { hostId } = useParams();
  const history = useHistory();
  const [themeStatus, setThemeStatus] = useState("theme-light");
  const [hostShows, setHostShows] = useState([]);
  useEffect(() => {
    keepTheme();
    if (localStorage.getItem("theme") === "theme-dark") {
      setThemeStatus("theme-dark");
    } else if (localStorage.getItem("theme") === "theme-light") {
      setThemeStatus("theme-light");
    }

    async function getAllHostShows() {
      let nextUrl = `/listener/hosts/${hostId}`;
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFunc() {
      let temp1 = await getAllHostShows();
      setHostShows(temp1);
    }

    tempFunc();
  }, []);

  const handleRecommendedHostShows = (slug) => {
    history.push(`/show/${slug}`);
  };

  return (
    <div className={themeStatus}>
      <TopHeaderNavigation
        changeTheme={(themeStatus) => setThemeStatus(themeStatus)}
        upgrade=""
      />
      <Container>
        <div className="my-5">
          <p className="sub-main-text">Top</p>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h3 className="listener-main-text">Shows</h3>
            </Col>
          </Row>

          <LazyLoad height={100} offset={100} once>
            <Row>
              {hostShows?.map((item) => {
                return (
                  <Col md={2}>
                    <div>
                      <Card
                        className="playcard"
                        onClick={() => handleRecommendedHostShows(item.slug)}
                      >
                        <Card.Img
                          variant="top"
                          src={item.show_image}
                          className="f-listen-image-holder"
                          alt={item.hosts[0]?.first_name}
                        />
                        <Card.Body className="card-b-listen">
                          <Card.Title className="l-title-albumn text-capitalize">
                            {item.name}
                          </Card.Title>
                          <Card.Text className="l-author-albumn text-capitalize">
                            By{" "}
                            {item.hosts[0]?.first_name +
                              " " +
                              item.hosts[0]?.last_name}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </LazyLoad>
        </div>

        <RecommendedHost />
        <PopularCategories />
      </Container>
    </div>
  );
};
