import React from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import { InputField } from "../../components/formFields/InputField";

export const ReportAnIssue = (props) => {
  const initialValues = {};
  const validate = {};

  return (
    <>
      {/* Report an issue modal STARTS */}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="report-issue-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="termsTitle justify-content-center w-100"
          >
            Report an Issue
          </Modal.Title>
        </Modal.Header>
        <Formik initialValues={initialValues} validationSchema={validate}>
          {({ values }) => (
            <Form>
              <Modal.Body>
                <div className="report-issue-main">
                  <p className="text-center">Please share your concern</p>
                  <span className="detail-report-issue">
                    Thank you so much for listening to us. Kindly fill the
                    details below to send us your comments. We read your
                    comments and feedback very carefully. We will try to resolve
                    your concern asap. Thank you!
                  </span>
                </div>
                <div className="issue-form">
                  <Row>
                    <Col md={12}>
                      <InputField
                        label="Titile*"
                        name="titile"
                        type="text"
                        data-testid="titile-playlist"
                        placeholder="My playlist 01"
                      />
                    </Col>

                    <Col md={12}>
                      <br />
                      <textarea
                        style={{
                          width: "100%",
                          maxHeight: "80px",
                          minHeight: "80px",
                        }}
                        placeholder="Comments"
                        // onChange={onChangePlaylistDescription}
                      ></textarea>
                    </Col>
                  </Row>
                </div>
              </Modal.Body>
              <Modal.Footer className="justify-content-center">
                <Button
                  variant="secondary"
                  //   onClick={handleClose}
                  className="playlist-cancel"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="playlist-save"
                  // onClick={() => handleSubmit(values)}
                  //   onClick={handleClose}
                >
                  Report
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      {/* Report an issue modal STARTS */}
    </>
  );
};
