import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import apiInstance from "../../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PlaylistPopup } from "./PlaylistPopup";

export const AddToPlaylist = ({ episodeID, ...props }) => {
  const [playListData, setPlayListData] = useState([]);
  const [playlistPopupModalShow, setPlaylistPopupModalShow] = useState(false);
  const [episodePlayListID, setEpisodePlayListID] = useState("");

  useEffect(() => {
    async function getAllPlayList() {
      await apiInstance
        .get("/listener/playlists/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setPlayListData(response.data.results);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (error.response.data.error_type === "InvalidToken") {
              // accessToken();
              // getAllPlayList();
            }
          }
        });
    }

    getAllPlayList();
  }, []);

  const addEpisodeToPlayList = (playListID) => {
    apiInstance
      .post(
        `/listener/playlists/${playListID}/episodes/`,
        {
          playlist: playListID,
          playlist_episode: episodeID,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success(`Episode added to Playlist`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          props.onHide(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCreatePlaylist = (playlistEpiosdeID) => {
    // e.stopPropagation();
    if (localStorage.getItem("access") !== null) {
      props.onHide(true);
      setPlaylistPopupModalShow(true);
      setEpisodePlayListID(playlistEpiosdeID);
    } else {
      toast.warning("Please Login to add to playlist.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="share-modal"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="termsTitle justify-content-center w-100"
          >
            Add to your Playlist
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="share-mod-listener text-left mx-5">
            <Row className="mb-4">
              <Col className="playlistName text-capitalize m-1" md={12}>
                <p
                  onClick={() => {
                    handleCreatePlaylist(episodeID);
                  }}
                >
                  Create new PlayList
                  <span>+</span>
                </p>
              </Col>
              {playListData?.map((item) => {
                return (
                  <Col
                    className="playlistName text-capitalize m-1"
                    md={12}
                    onClick={() => addEpisodeToPlayList(item.id)}
                  >
                    <p>
                      {item.playlist_name}
                      <span>+</span>
                    </p>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <PlaylistPopup
        show={playlistPopupModalShow}
        setPlayListData={setPlayListData}
        onHide={() => setPlaylistPopupModalShow(false)}
        episodeID={episodePlayListID}
      />
    </>
  );
};
