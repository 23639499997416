import React, { useEffect, useState } from "react";
import { keepTheme } from "../../utils/listenerTheme/Theme";
import { PopularCategories } from "../popularcategories/PopularCategories";
import { Container } from "react-bootstrap";
import { TopHeaderNavigation } from "../../components/listener/TopHeaderNavigation";
import { Row, Col, Card } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import { useHistory } from "react-router-dom";
import apiInstance from "../../api";

export const AllRecommendedHost = () => {
  const [themeStatus, setThemeStatus] = useState("theme-light");
  const [recommendedHost, setRecommendedHost] = useState([]);

  const history = useHistory();

  useEffect(() => {
    keepTheme();
    if (localStorage.getItem("theme") === "theme-dark") {
      setThemeStatus("theme-dark");
    } else if (localStorage.getItem("theme") === "theme-light") {
      setThemeStatus("theme-light");
    }

    async function getAllRecommendedHost() {
      let nextUrl = "/listener/recommended-host";
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFunc() {
      let temp1 = await getAllRecommendedHost();
      setRecommendedHost(temp1);
    }

    tempFunc();
  }, []);

  const handleRecommendedHost = (hostID) => {
    history.push(`/recommended-host/${hostID}`);
  };

  return (
    <div className={themeStatus}>
      <TopHeaderNavigation
        changeTheme={(themeStatus) => setThemeStatus(themeStatus)}
        upgrade=""
      />
      <Container>
        <div className="my-5">
          <p className="sub-main-text">For you</p>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h3 className="listener-main-text">Recommended Host Sochcast</h3>
            </Col>
          </Row>

          <Row>
            {recommendedHost?.map((item) => {
              return (
                <Col md={2}>
                  <div>
                    <Card
                      className="playcard"
                      onClick={() => handleRecommendedHost(item.id)}
                    >
                      <Card.Img
                        variant="top"
                        src={item.host_image}
                        className="f-listen-image-holder artist"
                        alt={item.first_name}
                      />
                      <Card.Body className="card-b-listen text-center">
                        <Card.Title className="l-title-albumn text-capitalize">
                          {item.first_name + " " + item.last_name}
                        </Card.Title>
                        <Card.Text className="l-author-albumn">
                          {item.host_total_shows} Shows
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>

        <PopularCategories />
      </Container>
    </div>
  );
};
