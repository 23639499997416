import React, { useContext } from "react";
// import { SochCastPro } from "../../components/sochcastPro/SochCastPro";
import { Row, Col, Button } from "react-bootstrap";
import { ChannelList } from "./ChannelList";
import { LoginContext } from "./Helper/Context";
import { ReactComponent as AddIcon } from "../../assets/images/add.svg";

export const ChannelMain = () => {
  const { setFormStatus } = useContext(LoginContext);
  return (
    <div className="p-5 mainContainerAdd">
      {/* <SochCastPro /> */}
      <Row className="mt-5">
        <Col xs={6} md={8} lg={8} xl={8}>
          <h1 className="dash-text">Channel List</h1>
          {/* <p className="channelDescription">
            Selecting Multiple Episodes Allows You To Capture Embed Codes Or
            Transcripts Across Your Content.
          </p> */}
        </Col>
        <Col xs={6} md={4} lg={4} xl={4} className="add-channel-button">
          <Button
            variant="primary"
            className="addbuttontext"
            onClick={() => setFormStatus(true)}
          >
            <AddIcon style={{ width: "14px", height: "14px" }} /> Add channel
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12} cl={12} className="mt-2">
          <ChannelList />
        </Col>
      </Row>
    </div>
  );
};
