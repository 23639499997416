import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Signup from "./pages/signup/Signup";
import GlobalStyle from "./theme/globalStyles";
import { Signin } from "./pages/signin/Signin";
import { Home } from "./pages/home/Home";
import { ForgotPassword } from "./pages/forgotPassword/ForgotPassword";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import { Channel } from "./pages/channel/Channel";
import { Show } from "./pages/show/Show";
import { index } from "./pages/upgrade";
import { ProfileSettings } from "./pages/profileSettings/ProfileSettings";
import { Episode } from "./pages/episode/Episode";
import { Host } from "./pages/host/Host";
import { Monetization } from "./pages/monetization/Monetization";
import { NotFound } from "./components/NotFound";
import { ChannelEdit } from "./pages/channel/ChannelEdit";
import { EditHost } from "./pages/host/EditHost";
import { ShowEdit } from "./pages/show/ShowEdit";
import { EpisodeEdit } from "./pages/episode/EpisodeEdit";
import { Brand } from "./pages/monetization/Brand";
import { MonetizationBrandForm } from "./pages/monetization/MonetizationBrandForm";
import { Analytics } from "./pages/episode/Analytics";
import { ShowDistribution } from "./pages/show/ShowDistribution";
import { ImportExistingShow } from "./pages/show/ImportExistingShow";
import { Listener } from "./listener/Listener";
import { LandingPage } from "./landingpage/LandingPage";
import { Library } from "./listener/library/Library";
// import { UpgradePlans } from "./listener/upgradeplans/UpgradePlans";
import { EpisodeList } from "./listener/episodelist/EpisodeList";
import { AllCategories } from "./listener/popularcategories/AllCategories";
import { CategoryShow } from "./listener/popularcategories/CategoryShow";
import { AllRecommendedHost } from "./listener/recommendedhost/AllRecommendedHost";
import { HostListen } from "./listener/recommendedhost/HostListen";
import { AllOriginalSochcast } from "./listener/sochcastoriginals/AllOriginalSochcast";
import { AllPopularShows } from "./listener/popularshows/AllPopularShows";
import { AllNewRelease } from "./listener/newrelease/AllNewRelease";
import { AllFethure } from "./listener/fethure/AllFethure";
import { LikedEpisodeList } from "./listener/episodelist/LikedEpisodeList";
import { MyPlayList } from "./listener/library/MyPlayList";
import { ShowAnalytics } from "./pages/show/reports/ShowAnalytics";
import { AllTopChart } from "./listener/topchart/AllTopChart";
import { TermsAndConditions } from "./components/TermsAndConditions";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import ReactGA from "react-ga4";
import { Explore } from "./listener/explore/Explore";
import { AllTopEpisodes } from "./listener/topepisodes/AllTopEpisodes";
import { getBuildDate } from "./utils/utils";
import withClearCache from "./ClearCache";
import packageJson from "../package.json";
import ReactPixel from "react-facebook-pixel";

const GA_TRACKING_CODE = process.env.REACT_APP_GA_TRACKING_CODE;
const FB_PIXEL_CODE = process.env.REACT_APP_FB_PIXEL_CODE;
const ClearCacheComponent = withClearCache(MainApp);
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};

ReactPixel.init(`${FB_PIXEL_CODE}`, options);
ReactPixel.pageView(); // For tracking page view

ReactGA.initialize(`${GA_TRACKING_CODE}`);
ReactGA.send("pageview");

function MainApp(props) {
  return <></>;
}

function App() {
  return (
    <Router>
      <ClearCacheComponent />
      <GlobalStyle />
      <Switch>
        <Route exact path="/" component={LandingPage} />
        {/* Listener Routes Starts */}
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/listener" component={Listener} />
        <Route exact path="/your-library" component={Library} />
        {/* <Route exact path="/upgrade-plans" component={UpgradePlans} /> */}
        <Route exact path="/show/:showId" component={EpisodeList} />
        <Route exact path="/explore" component={Explore} />
        <Route
          exact
          path="/show/:showId/episode/:episodeID"
          component={EpisodeList}
        />
        <Route exact path="/all-categories" component={AllCategories} />
        <Route exact path="/category/:id" component={CategoryShow} />
        Host
        <Route
          exact
          path="/all-recommended-host"
          component={AllRecommendedHost}
        />
        <Route exact path="/recommended-host/:hostId" component={HostListen} />
        <Route exact path="/all-originals" component={AllOriginalSochcast} />
        <Route exact path="/all-popular-shows" component={AllPopularShows} />
        <Route exact path="/all-new-release" component={AllNewRelease} />
        <Route exact path="/sochcast-recommendations" component={AllFethure} />
        <Route exact path="/top-episodes" component={AllTopEpisodes} />
        <Route exact path="/top-charts" component={AllTopChart} />
        <Route exact path="/your-likes" component={LikedEpisodeList} />
        <Route exact path="/playlist/:playListID" component={MyPlayList} />
        {/* Listener Routes Ends */}
        {/* Creator Routes Starts */}
        <Route exact path="/signup" component={Signup} />
        <Route path="/signin" component={Signin} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <ProtectedRoute exact path="/home" component={Home} />
        <ProtectedRoute exact path="/channel" component={Channel} />
        <ProtectedRoute
          exact
          path="/channel/edit/:id"
          component={ChannelEdit}
        />
        <ProtectedRoute exact path="/show" component={Show} />
        <ProtectedRoute exact path="/show/edit/:id" component={ShowEdit} />
        <ProtectedRoute
          exact
          path="/show-distribution"
          component={ShowDistribution}
        />
        <ProtectedRoute
          exact
          path="/import-existing-show"
          component={ImportExistingShow}
        />
        <ProtectedRoute exact path="/upgrade" component={index} />
        <ProtectedRoute
          exact
          path="/profile-settings"
          component={ProfileSettings}
        />
        <ProtectedRoute exact path="/episode" component={Episode} />
        <ProtectedRoute exact path="/episode-analytics" component={Analytics} />
        <ProtectedRoute
          exact
          path="/shows/:showId/episodes/:episodeId"
          component={EpisodeEdit}
        />
        <ProtectedRoute exact path="/host" component={Host} />
        <ProtectedRoute exact path="/host/:id/edit" component={EditHost} />
        <ProtectedRoute exact path="/monetization" component={Monetization} />
        <ProtectedRoute
          exact
          path="/brand/:brandID/show/:showID"
          component={Brand}
        />
        <ProtectedRoute
          exact
          path="/brand-form/:brandID/show/:showID"
          component={MonetizationBrandForm}
        />
        <ProtectedRoute
          exact
          path="/show/:showID/analytics"
          component={ShowAnalytics}
        />
        {/* Creator Routes Ends */}
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
