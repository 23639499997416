import React from "react";
import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";

export const AboutSochcast = ({ data, hostData, ...props }) => {
  return (
    <>
      <div className="about-episode">
        <Row className="m-4">
          {data?.length !== 0 ? (
            <>
              <Col md={2}>
                <img
                  src={data[0]?.shows[0].show_image}
                  alt="About Artist"
                  className="img-fluid episode-image-about"
                  style={{ borderRadius: "25px" }}
                />
              </Col>
              <Col md={10} className="my-auto">
                <p>{parse(data[0]?.description)}</p>
              </Col>
            </>
          ) : (
            <>
              {hostData[0]?.shows[0]?.hosts[0]?.host_image === null ? (
                <>
                  <Col md={12} className="my-auto">
                    <p>{parse(hostData[0]?.shows[0]?.hosts[0]?.description)}</p>
                  </Col>
                </>
              ) : (
                <>
                  <Col md={2}>
                    <img
                      src={hostData[0]?.shows[0]?.hosts[0]?.host_image}
                      alt={
                        hostData[0]?.shows[0]?.hosts[0]?.first_name +
                        " " +
                        hostData[0]?.shows[0]?.hosts[0]?.last_name
                      }
                      className="img-fluid episode-image-about"
                      style={{ borderRadius: "25px" }}
                    />
                  </Col>
                  <Col md={10} className="my-auto">
                    <p>{parse(hostData[0]?.shows[0]?.hosts[0]?.description)}</p>
                  </Col>
                </>
              )}
            </>
          )}
        </Row>
      </div>
    </>
  );
};
