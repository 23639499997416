import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import Ellipse72 from "../../assets/images/Ellipse72.png";
import { CommonButton } from "../../components/formFields/CommonButton";

export const DisableMonetization = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
      style={{ innerWidth: 100 }}
    >
      <Modal.Header closeButton className="termsModalHeader">
        <Modal.Title
          className="termsTitle justify-content-center w-100"
          id="contained-modal-title-vcenter"
        >
          Disable Monetization
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="text-center">
          <Col>
            <div className="dis-monit-popup">
              <img
                src={Ellipse72}
                alt="Disable Monetization"
                className="img-fluid elipse"
              />
            </div>
            <p className="m-text mt-5">
              Are you sure you want to disable Monetization for this podcast?
              This will stop all ads from running and will stop you from
              generating additional revenue.
            </p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <CommonButton className="mr-auto" name="Cancel" type="normalButton" />
        <CommonButton
          className="mr-auto"
          name="Deactivate"
          type="noBackgroundButton"
        />
      </Modal.Footer>
    </Modal>
  );
};
