import React, { useState } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { InputField } from "../../components/formFields/InputField.js";
import { Link } from "react-router-dom";
import "../css/forgotPassword/forgotPassword.css";
import apiInstance from "../../api";
import { useHistory } from "react-router-dom";
import sochLogo from "../../assets/images/sochlogo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ForgotPassword = () => {
  const [errorMsg, seterrorMsg] = useState("");

  const history = useHistory();

  const initialValues = {
    email: "",
  };

  const validate = Yup.object({
    email: Yup.string().email().required(),
  });

  const handleForgotGotPasswordSubmit = async (values) => {
    await apiInstance
      .post(`/password-reset/`, {
        email: values.email,
      })
      .then((response) => {
        if (response.status === 200) {
          seterrorMsg(response.data.payload.message);
          setTimeout(() => {
            history.push("/signin");
          }, 3000);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error(`${error.response.data.errors[0].message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  return (
    <Container>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Row>
        <Col md={7} className="forgot-left"></Col>
        <Col md={5}>
          <div className="sochlogo m-5 p-3 text-center">
            <img src={sochLogo} alt="SochCast Logo" />
          </div>
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validate}
              onSubmit={handleForgotGotPasswordSubmit}
            >
              {(formik) => (
                <div>
                  <h3 className="forgot-text">Forget Password</h3>
                  <p className="text subtext-forgetpassword">
                    Sochcast is a premier full-stack audio suite that enables
                    you to
                    <br />
                    broadcast your सोच • <span>(soch)</span> f thoughts &
                    musings.
                  </p>
                  <Form>
                    <Row className="mt-5">
                      <Col xs={12} md={12} lg={12} xl={12}>
                        <InputField
                          label="Email"
                          name="email"
                          type="email"
                          data-testid="signin-email"
                        />
                      </Col>
                    </Row>

                    {/* <p className="mt-2 error text-center">{errorMsg}</p> */}

                    <Row className="mt-4">
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="text-center"
                      >
                        <Button
                          variant="primary"
                          type="submit"
                          className="forgot rounded-2 buttonColor"
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </Form>

                  <p className="mt-2 error text-center">{errorMsg}</p>

                  <span className="mt-4 horizontalLineSiginIn" />

                  <Row className="mt-2">
                    <p className="text-center login">
                      Already have an Account ?{" "}
                      <Link to="/signin" className="signinLink">
                        Login here
                      </Link>
                    </p>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
