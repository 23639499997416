import React, { useState } from "react";
// import { useSelector } from "react-redux";
import { TopHeader } from "../../components/header/TopHeader";
import { Dashboard } from "../dashboard/Dashboard";
import { Header } from "../../components/header/Header";
import { FaBars } from "react-icons/fa";

export const Home = () => {
  // const userData = useSelector((state) => state.signUp.userData);
  // const { id, username, email } = userData;
  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <div className="app">
      <Header handleToggleSidebar={handleToggleSidebar} toggled={toggled} />
      <main>
        <TopHeader name="Dashboard" />
        <div
          className="btn-toggle mobilemnnu"
          onClick={() => handleToggleSidebar(true)}
        >
          <FaBars />
        </div>
        <div className="p-5">
          <Dashboard />
        </div>
      </main>
    </div>
  );
};
