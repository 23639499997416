import React, { useState, useEffect, useRef } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { Row, Col } from "react-bootstrap";
import { ReactComponent as HeartIcon } from "../../assets/images/heart.svg";
import { ReactComponent as PlaylistCustomIcon } from "../../assets/images/playlist.svg";
import LikedHeart from "../../assets/images/heartLIked.svg";
import { PlaylistPopup } from "../episodelist/PlaylistPopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiInstance from "../../api";
import { detect } from "detect-browser";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AddToPlaylist } from "../episodelist/AddToPlaylist";
import FollowMore from "../../assets/images/FollowMore.png";

export const CustomAudioPlayer = (props) => {
  const browser = detect();
  const [episodesData, setEpisodesData] = useState(props.item);
  const [currentMusicIndex, setCurrentMusicIndex] = useState(0);
  const [episodePlayListID, setEpisodePlayListID] = useState("");
  const [ipAddress, setIPAddress] = useState("");
  const [browserName, setBrowserName] = useState("");
  const [audioType, setAudioType] = useState("intro");
  const [geoLocationData, setGeoLocationData] = useState({});
  const [episodeIDForPlayList, setEpisodeIDForPlayList] = useState("");
  const [playListModalShow, setPlayListModalShow] = useState(false);

  const history = useHistory();
  const { showId, episodeID } = useParams();
  let location = useLocation();

  const audioRef = useRef(null);

  const geoIPAPIURL =
    "https://api.ipgeolocation.io/ipgeo?apiKey=83581edfef28455f97fbab53986bb88a";

  var apiReportingBaseURL = "";

  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "demo.sochcast.com"
  ) {
    apiReportingBaseURL = "https://beta.sochcast.com/api/reporting";
  } else if (window.location.hostname === "app.sochcast.com") {
    apiReportingBaseURL = "https://backend.sochcast.com/api/reporting";
  }

  episodesData.sort(function (a, b) {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  useEffect(() => {
    if(showId !== undefined && episodeID !== undefined) {
      apiInstance.get(`/listener/show/${showId}/episode/${episodeID}`).then((res) => {
        setEpisodesData([res.data.payload]);
      }).catch((err) => {
        console.log(err);
      })
    }
  }, []);

  useEffect(() => {
    console.log(props.item);
    setEpisodesData(props.item);

    if (props.item.length === 1) {
      setAudioType("intro");
      if (props.item[0]?.shows[0]?.intro !== null) {
        setAudioType("intro");
        audioRef.current.audio.current.src = props.item[0]?.shows[0]?.intro;
      } else if (props.item[0]?.shows[0]?.trailer !== null) {
        setAudioType("trailer");
        audioRef.current.audio.current.src = props.item[0]?.shows[0]?.trailer;
      } else {
        setAudioType("file");
        audioRef.current.audio.current.src = props.item[0]?.file;
      }
    }
  }, [props.item]);

  useEffect(() => {
    if (browser) {
      setBrowserName(browser.name);
    }

    apiInstance
      .get("https://api.ipify.org/?format=json")
      .then((res) => {
        setIPAddress(res.data.ip);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [browser]);

  useEffect(() => {
    apiInstance
      .get(geoIPAPIURL)
      .then((res) => {
        setGeoLocationData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleOnPlay = (value) => {
    let userID = localStorage.getItem("user_id");
    let userType = "Auth";

    if (userID === null) {
      userID = "";
      userType = "unAuth";
    }

    // if (
    //   window.location.hostname !== "localhost" &&
    //   window.location.hostname !== "demo.sochcast.com"
    // ) {
    apiInstance
      .get("https://api.ipify.org/?format=json")
      .then((res) => {
        setIPAddress(res.data.ip);
        apiInstance
          .get(geoIPAPIURL)
          .then((ress) => {
            setGeoLocationData(res.data);
            let data = {
              ip: res.data.ip,
              user: userID,
              user_type: userType,
              user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              device_type: browserName,
              player_action: "PLAY",
              show_played: value.shows[0].id,
              episode_played: value.id,
              duration_played: Math.round(
                audioRef.current.audio.current.currentTime
              ),
              created_at: new Date().toJSON(),
              updated_at: new Date().toJSON(),
              user_city: ress.data.city,
              user_continent: ress.data.continent_name,
              user_country: ress.data.country_name,
              user_region: ress.data.state_prov,
              user_zip_code: ress.data.zipcode,
            };

            apiInstance
              .post(apiReportingBaseURL, data)
              .then((resp) => {})
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  const handleOnPause = (event, value) => {
    let userID = localStorage.getItem("user_id");
    let userType = "Auth";

    if (userID === null) {
      userID = "";
      userType = "unAuth";
    }

    let data = {
      ip: ipAddress,
      user: userID,
      user_type: userType,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      device_type: browserName,
      player_action: "PAUSE",
      show_played: value.shows[0].id,
      episode_played: value.id,
      duration_played: Math.round(audioRef.current.audio.current.currentTime),
      created_at: new Date().toJSON(),
      updated_at: new Date().toJSON(),
      user_city: geoLocationData.city,
      user_continent: geoLocationData.continent_name,
      user_country: geoLocationData.country_name,
      user_region: geoLocationData.state_prov,
      user_zip_code: geoLocationData.zipcode,
    };

    // if (
    //   window.location.hostname !== "localhost" &&
    //   window.location.hostname !== "demo.sochcast.com"
    // ) {
    apiInstance
      .post(apiReportingBaseURL, data)
      .then((resp) => {})
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  const handleNextPlay = (value) => {
    setCurrentMusicIndex(
      currentMusicIndex < value.length - 1 ? currentMusicIndex + 1 : 0
    );

    let userID = localStorage.getItem("user_id");
    let userType = "Auth";

    if (userID === null) {
      userID = "";
      userType = "unAuth";
    }

    let data = {
      ip: ipAddress,
      user: userID,
      user_type: `${userType}`,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      device_type: browserName,
      player_action: "NEXT",
      show_played: value[currentMusicIndex]?.shows[0]?.id,
      episode_played: value[currentMusicIndex + 1]?.id,
      duration_played: Math.round(audioRef.current.audio.current.currentTime),
      created_at: new Date().toJSON(),
      updated_at: new Date().toJSON(),
      user_city: geoLocationData.city,
      user_continent: geoLocationData.continent_name,
      user_country: geoLocationData.country_name,
      user_region: geoLocationData.state_prov,
      user_zip_code: geoLocationData.zipcode,
    };

    // if (
    //   window.location.hostname !== "localhost" &&
    //   window.location.hostname !== "demo.sochcast.com"
    // ) {
    apiInstance
      .post(apiReportingBaseURL, data)
      .then((resp) => {})
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  const handlePreviousPlay = (value) => {
    setCurrentMusicIndex(
      currentMusicIndex === 0 ? value.length - 1 : currentMusicIndex - 1
    );

    let userID = localStorage.getItem("user_id");
    let userType = "Auth";

    if (userID === null) {
      userID = "";
      userType = "unAuth";
    }

    let data = {
      ip: ipAddress,
      user: userID,
      user_type: userType,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      device_type: browserName,
      player_action: "PREVIOUS",
      show_played: value[currentMusicIndex]?.shows[0]?.id,
      episode_played: value[currentMusicIndex + 1]?.id,
      duration_played: Math.round(audioRef.current.audio.current.currentTime),
      created_at: new Date().toJSON(),
      updated_at: new Date().toJSON(),
      user_city: geoLocationData.city,
      user_continent: geoLocationData.continent_name,
      user_country: geoLocationData.country_name,
      user_region: geoLocationData.state_prov,
      user_zip_code: geoLocationData.zipcode,
    };

    // if (
    //   window.location.hostname !== "localhost" &&
    //   window.location.hostname !== "demo.sochcast.com"
    // ) {
    apiInstance
      .post(apiReportingBaseURL, data)
      .then((resp) => {})
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  const handleSingleEpisodeID = (episodeID) => {
    // e.stopPropagation();
    if (localStorage.getItem("access") !== null) {
      setPlayListModalShow(true);
      setEpisodeIDForPlayList(episodeID);
    } else {
      toast.warning("Please Login to add to playlist.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleOnEnded = (value, audioType) => {
    if (audioType === "intro") {
      if (
        value[currentMusicIndex].shows[0]?.trailer !== null &&
        value[currentMusicIndex].shows[0]?.intro !== null
      ) {
        audioRef.current.audio.current.src =
          value[currentMusicIndex].shows[0]?.trailer;
        setAudioType("trailer");
      } else {
        audioRef.current.audio.current.src = value[currentMusicIndex].file;
        setAudioType("file");
      }
    } else if (audioType === "trailer") {
      audioRef.current.audio.current.src = value[currentMusicIndex].file;
      setAudioType("file");
    } else if (audioType === "file") {
      audioRef.current.audio.current.autoPlay = false;
      setAudioType("");
    } else {
      audioRef.current.audio.current.src = "";
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className="customToast"
      />

      <Row>
        <Col md={2} xs={3} className="my-auto text-center">
          <img
            src={episodesData[currentMusicIndex]?.episode_image}
            alt={episodesData[currentMusicIndex]?.name}
            className="img-fluid player-episode-image-listener"
          />
        </Col>
        <Col md={10} xs={7}>
          {/* {episodesData?.length !== 0 ? ( */}
          <AudioPlayer
            ref={audioRef}
            autoPlay={true}
            autoPlayAfterSrcChange={true}
            src={
              episodesData[currentMusicIndex]?.shows[0]?.intro ||
              episodesData[currentMusicIndex]?.shows[0]?.trailer ||
              episodesData[currentMusicIndex]?.file
            }
            showSkipControls={true}
            volume="0.2"
            customProgressBarSection={[
              RHAP_UI.PROGRESS_BAR,
              RHAP_UI.CURRENT_TIME,
              <div>/</div>,
              RHAP_UI.DURATION,
            ]}
            customAdditionalControls={[
              <button className="like-audio-button">
                {episodesData[currentMusicIndex]?.is_liked === true ? (
                  <span
                    onClick={(e) => {
                      props.handleRemoveLikedEpisode(
                        episodesData[currentMusicIndex].id,
                        e
                      );
                    }}
                    onAnimationEnd={() => props.setClicked(false)}
                  >
                    <img
                      src={LikedHeart}
                      alt="Follow and More"
                      className="img-fluid like-heart"
                    />
                  </span>
                ) : (
                  <span
                    onClick={(e) =>
                      props.handleLikedEpisode(
                        episodesData[currentMusicIndex].id,
                        e
                      )
                    }
                  >
                    <img
                      src={FollowMore}
                      alt="Follow and More"
                      className="img-fluid like-heart"
                    />
                  </span>
                )}
              </button>,
              <button
                className="audio-playlist-button"
                onClick={(e) => {
                  handleSingleEpisodeID(episodesData[currentMusicIndex]?.id);
                }}
              >
                <PlaylistCustomIcon />
              </button>,
            ]}
            onPlay={(e) => {
              handleOnPlay(episodesData[currentMusicIndex]);
            }}
            onPause={(e) => {
              handleOnPause(e, episodesData[currentMusicIndex]);
            }}
            onClickNext={(e) => {
              handleNextPlay(episodesData);
            }}
            onClickPrevious={(e) => {
              handlePreviousPlay(episodesData);
            }}
            progressJumpSteps={{
              forward: 30000,
              backward: 10000,
            }}
            header={episodesData[currentMusicIndex]?.name}
            footer={episodesData[0]?.shows[0]?.hosts?.map(
              (item, index, array) => {
                var hostName = "";
                if (array.length - 1 === index) {
                  hostName = `${item?.first_name} ${item?.last_name}`;
                } else {
                  hostName = `${item?.first_name} ${item?.last_name} & `;
                }

                // let string = hostName;
                return (
                  // <>{hostName.slice(0, hostName.lastIndexOf(" & "))}</>
                  <>{hostName}</>
                );
              }
            )}
            onEnded={(e) => {
              handleOnEnded(episodesData, audioType);
            }}
          />
        </Col>
      </Row>

      <AddToPlaylist
        show={playListModalShow}
        onHide={() => setPlayListModalShow(false)}
        episodeID={episodeIDForPlayList}
      />
    </>
  );
};
