import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../listener.css";
import { BiChevronRight } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import apiInstance from "../../api";

export const Fethure = () => {
  const history = useHistory();
  const [sochcastRecommendations, setSochcastRecommendations] = useState([]);

  useEffect(() => {
    apiInstance
      .get("/listener/sochcast-recommendations")
      .then((response) => {
        setSochcastRecommendations(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
    smallDevices: {
      breakpoint: { max: 320, min: 0 },
      items: 1,
    },
  };

  const handleViewAllNewRelease = () => {
    history.push("/sochcast-recommendations");
  };

  const handleRecommededSochcast = (slug) => {
    history.push(`/show/${slug}`);
  };

  return (
    <>
      <div className="my-5">
        <p className="sub-main-text">For you</p>
        <Row>
          <Col xs={12} sm={12} md={9}>
            <h3 className="listener-main-text">Sochcast Recommendations</h3>
          </Col>
          <Col xs={12} sm={12} md={3}>
            <p className="view-all" onClick={handleViewAllNewRelease}>
              View all
              <BiChevronRight />
            </p>
          </Col>
        </Row>

        <Carousel responsive={responsive}>
          {sochcastRecommendations?.map((item) => {
            return (
              <div key={item.id}>
                <Card
                  className="playcard"
                  onClick={() => handleRecommededSochcast(item.slug)}
                >
                  <Card.Img
                    variant="top"
                    src={
                      item.show_compressed_image !== null
                        ? item.show_compressed_image
                        : item.show_image
                    }
                    className="f-listen-image-holder"
                    alt={item.name}
                  />
                  <Card.Body className="card-b-listen">
                    <Card.Title className="l-title-albumn">
                      {item.name}
                    </Card.Title>
                    <Card.Text className="l-author-albumn">
                      {item.hosts[0]?.first_name +
                        " " +
                        item.hosts[0]?.last_name}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};
