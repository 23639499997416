import React, { useState, useEffect } from "react";
import { Col, Row, Card, Modal, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../listener.css";
import FeatureImage from "../../assets/images/women.png";
import apiInstance from "../../api";
import { useHistory } from "react-router-dom";
// import sochLogo from "../../assets/images/sochlogo.png";
import { InputField } from "../../components/formFields/InputField";
import * as Yup from "yup";
import { Formik, Form } from "formik";
// import { RadioButton } from "../../components/formFields/RadioButton";
// import { isPublicPrivate } from "../../helperFunction";
// import { accessToken } from "../../helperFunction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const PlayList = () => {
  const [viewSavedDatas, setViewSavedDatas] = useState(false);
  const [likedData, setLikedData] = useState([]);
  const [playListData, setPlayListData] = useState([]);
  const [show, setShow] = useState(false);
  const [playListDescription, setPlayListDescription] = useState("");
  const [refreshStatus, setRefreshStatus] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const accessTokens = localStorage.getItem("access");
    if (accessTokens === null || accessTokens === "") {
      setViewSavedDatas(false);
    } else {
      setViewSavedDatas(true);
    }

    async function getAllPlayList() {
      await apiInstance
        .get("/listener/playlists/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setPlayListData(response.data.results);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (error.response.data.error_type === "InvalidToken") {
              console.log("InvalidToken");
            }
          }
        });
    }

    let config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    };

    async function getLikedEpisodes() {
      let nextUrl = "/listener/liked-episodes/";
      let temp = [];
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl, config);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFunc() {
      let temp1 = await getLikedEpisodes();
      setLikedData(temp1);
    }

    tempFunc();
    getAllPlayList();
  }, [refreshStatus]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
    smallDevices: {
      breakpoint: { max: 320, min: 0 },
      items: 1,
    },
  };
  const handleLikeEpisodeList = () => {
    history.push("/your-likes");
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialValues = {
    titile: "",
    isUnlistedPP: "",
  };

  const validate = Yup.object({
    titile: Yup.string().required(),
  });

  const onChangePlaylistDescription = (e) => {
    setPlayListDescription(JSON.stringify(e.target.value));
  };

  const handleSubmit = (values) => {
    setRefreshStatus(false);
    if (values.titile === "") {
      toast.error("Please enter playlist titile", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // else if (values.isUnlistedPP === "") {
    //   toast.error("Please select public or private", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
    // else if (playListDescription === "") {
    //   toast.error("Please enter playlist description", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
    else {
      // console.log(values.isUnlistedPP);
      apiInstance
        .post(
          "/listener/playlists/",
          {
            playlist_name: values.titile,
            playlist_description: playListDescription,
            playlist_privacy: "Private",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            toast.success(`Playlist ${values.titile} created.`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            handleClose();
            setRefreshStatus(true);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (error.response.data.error_type === "InvalidToken") {
              // accessToken();
              // handleSubmit();
            }
          }
        });
    }
  };

  const handleSelectPlaylist = (playlistId) => {
    history.push(`/playlist/${playlistId}`);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="my-5">
        {viewSavedDatas ? (
          <>
            {likedData.length === 0 && playListData.length === 0 ? (
              <div>
                <Card
                  className="playcard create playllist"
                  onClick={handleShow}
                >
                  <Card.Body className="card-b-listen playlist-center">
                    <p>Create Playlist</p>
                  </Card.Body>
                </Card>
              </div>
            ) : null}

            {likedData.length !== 0 || playListData.length !== 0 ? (
              <>
                <p className="sub-main-text">For you</p>
                <Row>
                  <Col xs={12} sm={12} md={9}>
                    <h3 className="listener-main-text">My Playlists</h3>
                  </Col>
                  {/* <Col xs={12} sm={12} md={3}>
                    <p className="view-all">View all</p>
                  </Col> */}
                </Row>

                <Carousel responsive={responsive}>
                  <div>
                    <Card
                      className="playcard create playllist"
                      onClick={handleShow}
                    >
                      <Card.Body className="card-b-listen playlist-center">
                        <p>Create Playlist</p>
                      </Card.Body>
                    </Card>
                  </div>

                  {likedData.length !== 0 ? (
                    <div>
                      <Card
                        className="playcard"
                        onClick={handleLikeEpisodeList}
                      >
                        <Card.Img
                          variant="top"
                          src={likedData[0]?.liked_episode?.episode_image}
                          className="f-listen-image-holder"
                          alt={likedData[0]?.liked_episode?.name}
                        />
                        <Card.Body className="card-b-listen">
                          <Card.Title className="l-title-albumn">
                            Liked Sochcast
                          </Card.Title>
                          <Card.Text className="l-author-albumn">
                            {likedData.length} Liked
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  ) : null}

                  {playListData?.map((item) => {
                    return (
                      <div
                        key={item.id}
                        onClick={() => handleSelectPlaylist(item.id)}
                      >
                        <Card className="playcard" key={item.id}>
                          <Card.Img
                            variant="top"
                            src={FeatureImage}
                            className="f-listen-image-holder women images"
                            alt={item.playlist_name}
                          />
                          <Card.Body className="card-b-listen">
                            <Card.Title className="l-title-albumn">
                              {item.playlist_name}
                            </Card.Title>
                            <Card.Text className="l-author-albumn text-capitalize">
                              By{" "}
                              {localStorage.getItem("first_name") +
                                " " +
                                localStorage.getItem("last_name")}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })}
                  {/* </>
                  ) : null} */}
                </Carousel>
              </>
            ) : null}
          </>
        ) : null}
      </div>

      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="listener-playlist-create-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="termsTitle justify-content-center w-100"
          >
            Create Playlist
          </Modal.Title>
        </Modal.Header>
        <Formik initialValues={initialValues} validationSchema={validate}>
          {({ values }) => (
            <Form>
              <Modal.Body>
                <>
                  <Row>
                    <Col md={12}>
                      <InputField
                        label="Titile*"
                        name="titile"
                        type="text"
                        data-testid="titile-playlist"
                        placeholder="My playlist 01"
                      />
                    </Col>
                    <Col md={12}>
                      <label className="formLabel">Description</label>
                      <br />
                      <textarea
                        style={{
                          width: "100%",
                          maxHeight: "130px",
                          minHeight: "130px",
                        }}
                        onChange={onChangePlaylistDescription}
                      ></textarea>
                    </Col>
                    {/* <Col md={12}>
                      <label className="formLabel">Privacy</label>
                      <br />
                      <RadioButton
                        options={isPublicPrivate}
                        type="isUnlistedPP"
                      />
                    </Col> */}
                  </Row>
                </>
              </Modal.Body>
              <Modal.Footer className="justify-content-center">
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  className="playlist-cancel"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="playlist-save"
                  onClick={() => handleSubmit(values)}
                >
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
