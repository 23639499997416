import React, { useEffect, useState } from "react";
import { ReactComponent as Copy } from "../../assets/images/copy-outline.svg";
import { ReactComponent as Outline } from "../../assets/images/link-outline.svg";
import { Modal, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import { TwitterButton } from "react-social";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import TwitterIconD from "../../assets/socialIcons/twitter.png";

export const SocialShare = (props) => {
  const [shareEpisodeData, setShareEpisodeData] = useState(
    props.socialShareData
  );
  const [copySuccess, setCopySuccess] = useState("");

  const handleOnCopyLink = async (episodeID, showID) => {
    navigator.clipboard.writeText(
      `${window.location.hostname}/show/${showID}/episode/${episodeID}`
    );
    setCopySuccess("copied");
  };

  useEffect(() => {
    setShareEpisodeData(props.socialShareData);
  }, [props.socialShareData]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${props.socialShareData[0]?.name}`}</title>

        <meta
          property="og:url"
          content={`https://${window.location.hostname}/show/${props.socialShareData[0]?.shows[0]?.id}/episode/${props.socialShareData[0]?.id}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={props.socialShareData[0]?.name} />
        <meta
          property="og:description"
          content={props.socialShareData[0]?.description}
        />
        <meta
          property="og:image"
          content={props.socialShareData[0]?.episode_image}
        />

        <meta
          property="og:url"
          content={`https://${window.location.hostname}/show/${props.socialShareData[0]?.shows[0]?.id}/episode/${props.socialShareData[0]?.id}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={props.socialShareData[0]?.name} />
        <meta
          property="og:description"
          content={props.socialShareData[0]?.description}
        />
        <meta
          property="og:image"
          itemprop="image"
          content={props.socialShareData[0]?.episode_image}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="https://app.sochcast.com/" />
        <meta property="twitter:url" content="https://app.sochcast.com/" />
        <meta name="twitter:title" content={props.socialShareData[0]?.name} />
        <meta
          name="twitter:description"
          content={props.socialShareData[0]?.description}
        />
        <meta
          name="twitter:image"
          content={props.socialShareData[0]?.episode_image}
        />
      </Helmet>

      {/* Share modal STARTS */}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="termsTitle justify-content-center w-100"
          >
            Share
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="share-mod-listener">
            <Row>
              <Col md={3} xs={4}>
                <div className="share-img-holder">
                  <WhatsappShareButton
                    url={`https://${window.location.hostname}/show/${props.socialShareData[0]?.shows[0]?.slug}/episode/${props.socialShareData[0]?.slug}`}
                    title={`Hi there, Here’s an amazing episode for you to listen now!`}
                    separator={` - `}
                    media={props.socialShareData[0]?.episode_image}
                  >
                    <WhatsappIcon size={45} round={true} />
                  </WhatsappShareButton>
                </div>
                <p className="share-text">Whatsapp</p>
              </Col>

              <Col md={3} xs={4}>
                <div className="share-img-holder">
                  <FacebookShareButton
                    url={`https://${window.location.hostname}/show/${props.socialShareData[0]?.shows[0]?.slug}/episode/${props.socialShareData[0]?.slug}`}
                    quote={
                      props.socialShareData[0]?.name +
                      " - " +
                      parse(`${props.socialShareData[0]?.description}`)?.props
                        ?.children
                    }
                    hashtag="#sochcast"
                  >
                    <FacebookIcon size={45} round={true} />
                  </FacebookShareButton>
                </div>
                <p className="share-text">Facebook</p>
              </Col>

              <Col md={3} xs={4}>
                <div className="share-img-holder">
                  <EmailShareButton
                    url={`https://${window.location.hostname}/show/${props.socialShareData[0]?.shows[0]?.slug}/episode/${props.socialShareData[0]?.slug}`}
                    subject={props.socialShareData[0]?.name}
                    body={props.socialShareData[0]?.description}
                  >
                    <EmailIcon size={45} round={true} />
                  </EmailShareButton>
                </div>
                <p className="share-text">Email</p>
              </Col>
            </Row>

            <Row>
              <Col md={3} xs={4}>
                <div className="share-img-holder">
                  <LinkedinShareButton
                    url={`https://${window.location.hostname}/show/${props.socialShareData[0]?.shows[0]?.slug}/episode/${props.socialShareData[0]?.slug}`}
                    title={`Hi there, Here’s an amazing show for you to listen now!`}
                    summary={props.socialShareData[0]?.description}
                    source="Sochcast"
                  >
                    <LinkedinIcon size={45} round={true} />
                  </LinkedinShareButton>
                </div>
                <p className="share-text">LinkedIn</p>
              </Col>

              <Col md={3} xs={4}>
                <div className="share-img-holder">
                  <TwitterButton
                    url={`https://${window.location.hostname}/show/${props.socialShareData[0]?.shows[0]?.slug}/episode/${props.socialShareData[0]?.slug}`}
                    style={{ background: "none", border: "none" }}
                  >
                    <img src={TwitterIconD} alt="Twitter" />
                  </TwitterButton>
                </div>
                <p className="share-text">Twitter</p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div>
            <label className="formLabel text-center">Episode Link</label>
            <InputGroup className="mb-3">
              <InputGroup.Text>
                <Outline />
              </InputGroup.Text>
              <FormControl
                type="text"
                defaultValue={`${window.location.hostname}/show/${props.socialShareData[0]?.shows[0]?.slug}/episode/${props.socialShareData[0]?.slug}`}
                readOnly
              />
              <InputGroup.Text
                onClick={() => {
                  handleOnCopyLink(
                    shareEpisodeData[0]?.slug,
                    shareEpisodeData[0]?.shows[0]?.slug
                  );
                }}
                style={{ cursor: "pointer" }}
              >
                <Copy />
              </InputGroup.Text>
            </InputGroup>
            <div className="text-center">{copySuccess}</div>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Share modal Ends */}
    </>
  );
};
