import React from "react";
import "../css/profileSettings/profile.css";
import { ProfileForm } from "./ProfileForm";
import { ChooseDomain } from "./ChooseDomain";

export const ProfileUpdate = () => {
  return (
    <div className="p-5 mainContainerAdd">
      {/* <ChooseDomain /> */}
      <ProfileForm />
    </div>
  );
};
