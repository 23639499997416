import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import "../css/channel/Channel.css";
import { ReactComponent as EditIcon } from "../../assets/images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/DeleteIcon.svg";
import { useHistory } from "react-router";
import apiInstance from "../../api";
import DataTable from "react-data-table-component";
import { accessToken } from "../../helperFunction";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const HostList = () => {
  const history = useHistory();
  const [deletedRow, setDeletedRow] = useState(false);
  const [hosts, setHosts] = useState([]);

  useEffect(() => {
    async function getAllHosts() {
      let nextUrl = "/host/";
      let temp = [];
      let config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      };
      while (nextUrl !== null) {
        const response = await apiInstance.get(nextUrl, config);
        nextUrl = response.data.next;
        temp.push(...response.data.results);
      }
      return temp;
    }

    async function tempFunc() {
      let temp1 = await getAllHosts();
      setHosts(temp1);
    }

    tempFunc();
  }, [deletedRow]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "No.",
      cell: (row, index) => index + 1,
    },
    {
      name: "Name",
      selector: (row) => row.first_name + " " + row.last_name,
    },
    {
      name: "Host No.",
      selector: (row) => row.id,
    },
    {
      name: "Date",
      selector: (row) => {
        const myMomentObject = moment(
          `${row.created_at}`,
          "YYYY-MM-DD"
        ).toDate();
        const data = moment(myMomentObject).format("MMM D, YYYY");
        return data;
      },
    },
    {
      name: "Publish Status",
      selector: (row) => {
        if (row.is_published === true) {
          return "Published";
        } else if (row.is_published === false) {
          return "Draft";
        } else {
          return "N/A";
        }
      },
    },
    {
      name: "Is Unlisted",
      selector: (row) => {
        if (row.is_unlisted === true) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <EditIcon
            onClick={() => handleHostEdit(row.id)}
            id={row.id}
            className="editIconChannelList"
          />
          <DeleteIcon
            onClick={() =>
              handleHostnelDelete(row.id, row.first_name, row.last_name)
            }
            id={row.id}
            className="deleteIconChannelList mx-3"
          />
        </>
      ),
    },
  ];

  const handleHostEdit = (hostUID) => {
    history.push(`/host/${hostUID}/edit/`);
  };

  const handleHostnelDelete = (hostUID, hostFname, hostLname) => {
    setDeletedRow(false);
    apiInstance
      .patch(
        `/host/${hostUID}/delete/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      )
      .then((response) => {
        setDeletedRow(true);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (error.response.data.error_type === "InvalidToken") {
            accessToken();
            handleHostnelDelete();
          }
        } else if (error.response.status === 405) {
          toast.warning(`You Cannot delete ${hostFname} ${hostLname}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const data = hosts;
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Card className="ChannelListTable">
        <Card.Body>
          <Card.Text>
            <DataTable
              pagination
              columns={columns}
              data={data}
              customStyles={customStyles}
            />
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};
