import React from "react";
import { Modal } from "react-bootstrap";
import { CommonButton } from "../../components/formFields/CommonButton";

export const TermsAndConditions = (props) => {
  const handleAcceptClick = () => {
    props.onHide(true);
    // props.agree(true);
  };

  const handleDeclineClick = () => {
    props.onHide(true);
    // props.agree(false);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <Modal.Header closeButton className="termsModalHeader">
        <Modal.Title
          className="termsTitle justify-content-center w-100"
          id="contained-modal-title-vcenter"
        >
          Terms and Conditions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1 className="termsofUSe">Terms of Use</h1>
        <h1 className="agreecontent">
          You agree that you will not submit any content to Sochcast that -
        </h1>
        <ol className="termsModelUL">
          <li>
            belongs to another person and to which the user does not have any
            right to;
          </li>
          <li>
            is grossly harmful, harassing, blasphemous defamatory, obscene,
            pornographic, paedophilic, libellous, invasive of another's privacy,
            hateful, or racially, ethnically objectionable, disparaging,
            relating or encouraging money laundering or gambling, or otherwise
            unlawful in any manner whatsoever;
          </li>
          <li>harm minors in any way;</li>
          <li>
            infringes any patent, trademark, copyright or other proprietary
            rights; (e) violates any law for the time being in force;
          </li>
          <li>
            deceives or misleads the addressee about the origin of such messages
            or communicates any information which is grossly offensive or
            menacing in nature;{" "}
          </li>
          <li>impersonate another person; </li>
          <li>
            contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource;
          </li>
          <li>
            threatens the unity, integrity, defence, security or sovereignty of
            India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognisable offence or
            prevents investigation of any offence or is insulting any other
            nation
          </li>
        </ol>
        <p>
          You further agree that Content you submit to Sochcast will not contain
          third party copyrighted material, or material that is subject to other
          third party proprietary rights, unless you have permission from the
          rightful owner of the material or you are otherwise legally entitled
          to post the material and to grant Sochcast all of the license rights
          granted herein.
        </p>
        <p>
          Sochcast does not endorse any Content submitted on its Platform by any
          user or other licensor, or any opinion, recommendation, or advice
          expressed therein, and Sochcast expressly disclaims any and all
          liability in connection with Content. Sochcast does not permit
          copyright infringing activities and infringement of intellectual
          property rights on the Service, and Sochcast will remove all Content
          if properly notified that such Content infringes on another's
          intellectual property rights. Sochcast reserves the right to remove
          Content without prior notice.
        </p>
        <p>
          Sochcast will terminate a user's access to the Service if, under
          appropriate circumstances, the user is determined to be a repeat
          infringer.
        </p>
        <p>
          Sochcast reserves the right to decide whether Content violates these
          Terms of Service for reasons other than copyright infringement, such
          as, but not limited to, pornography, obscenity, or excessive length.
          Sochcast may at any time, without prior notice and in its sole
          discretion, remove such Content and/or terminate a user's account for
          submitting such material in violation of these Terms of Service.
        </p>
        <p>
          Sochcast reserves the right to offer and share the Content as you may
          submit, across all the products, software, data feeds and services
          offered by Sochcast, including by way of embeddable players and
          progressive web apps, and you, as Content uploader, shall be bound by
          the terms and conditions specified herein, with respect to such
          Content offered through such products, software, data feeds and
          services.
        </p>
        <p>
          By uploading the Content, including making available the Content
          through RSS feed (XML structured content) links, you direct and permit
          Sochcast to initiate the process of transcoding such Content to the
          Sochcast servers, from where such Content shall be streamed,
          transmitted, distributed, shared, modified or otherwise made available
          to the Service, subject to the terms and conditions hereof.
        </p>
        <h1 className="termsofUSe">Indemnity</h1>
        <p>
          To the extent permitted by applicable law, you agree to defend,
          indemnify and hold harmless Sochcast, its parent corporation,
          officers, directors, employees and agents, from and against any and
          all claims, damages, obligations, losses, liabilities, costs or debt,
          and expenses (including but not limited to attorney's fees) arising
          from: (i) your use of and access to the Service; (ii) your violation
          of any term of these Terms of Service; (iii) your violation of any
          third party right, including without limitation any copyright,
          property, or privacy right; or (iv) any claim that your Content caused
          damage to a third party. This defence and indemnification obligation
          will survive these Terms of Service and your use of the Service.
        </p>
        <h1 className="termsofUSe">Your acceptance of these terms</h1>
        <p>
          You affirm that you are either more than 18 years of age, or possess
          legal parental or guardian consent, and are fully able and competent
          to enter into the terms, conditions, obligations, affirmations,
          representations, and warranties set forth in these Terms of Service,
          and to abide by and comply with these Terms of Service. In any case,
          you affirm that you are over the age of 13, as the Service is not
          intended for children under 13. If you are under 13 years of age, then
          please do not use the Service. We provide these Terms of Service with
          our Service so that you know what terms apply to your use. You
          acknowledge that we have given you a reasonable opportunity to review
          these Terms of Service and that you have agreed to them.
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <CommonButton
          className="mr-auto"
          name="Accept"
          type="normalButton"
          onClick={handleAcceptClick}
        />
        <CommonButton
          className="mr-auto"
          name="Decline"
          type="noBackgroundButton"
          onClick={handleDeclineClick}
        />
      </Modal.Footer>
    </Modal>
  );
};
