import React, { useEffect, useState } from "react";
import { Nav, Dropdown, Navbar, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { CommonButton } from "../../components/formFields/CommonButton";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { ReactComponent as IconsSettings } from "../../assets/images/iconsetting.svg";
import { ReactComponent as SignOutIcon } from "../../assets/images/iconsignout.svg";
import { ReactComponent as PodcastIcon } from "../../assets/images/PodcastsIcon.svg";
import apiInstance from "../../api";

export const TopHeader = (props) => {
  const userName = useSelector((state) => state);
  const [profileImageStatus, setProfileImageStatus] = useState(false);
  const history = useHistory();

  useEffect(() => {
    apiInstance
      .get("/profile/", {
        headers: { Authorization: `Bearer ${localStorage.getItem("access")}` },
      })
      .then((resp) => {
        if (resp.status === 200 && resp.data.payload.profile_image !== null) {
          localStorage.setItem(
            "profile_image",
            resp.data.payload.profile_image
          );
          setProfileImageStatus(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleLogOut = () => {
    localStorage.clear();
    history.push("/signin");
  };

  const handleProfileSettings = () => {
    history.push("/profile-settings");
  };

  return (
    <>
      <Navbar className="topnav" expand="sm" sticky="top">
        <Container>
          <Navbar.Brand>{props.name}</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="justify-content-end">
              {/* <Nav.Item className="mx-3">
                <Link to="/upgrade">
                  <CommonButton
                    className="mr-auto"
                    name="Upgrade"
                    type="normalButton"
                  />
                </Link>
              </Nav.Item> */}
              {/* <Nav.Item className="mx-3">
                <Dropdown className="language">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Eng
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Hindi</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Kannada</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">English</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Gujarati</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Item> */}
              <Nav.Link href="/listener" className="my-auto">
                <Button className="landing-signin listener">Listener</Button>
              </Nav.Link>
              <Nav.Item className="mx-3">
                <Dropdown className="profile-settings">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {profileImageStatus ? (
                      <img
                        src={localStorage.getItem("profile_image")}
                        width="40"
                        height="40"
                        class="rounded-circle"
                        alt="Your Profile"
                      />
                    ) : (
                      <div className="profile-image">
                        <h1>{userName.userNameData.userNameData}</h1>
                      </div>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="profile-Dropdown">
                    <Dropdown.Item className="profile-clip">
                      <img
                        src={localStorage.getItem("profile_image")}
                        className="img-fluid profile-drop-down-icon-menu"
                        alt="Avatar"
                      />
                    </Dropdown.Item>
                    <div className="user-name-email text-center mt-5">
                      <p className="drop-name mb-2 text-capitalize">
                        {localStorage.getItem("first_name") +
                          " " +
                          localStorage.getItem("last_name")}
                      </p>
                      <p className="drop-email mb-0">
                        {localStorage.getItem("email")}
                      </p>
                    </div>
                    <Dropdown.Item>
                      <PodcastIcon className="mx-2" />
                      Sochcast
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleProfileSettings}>
                      <IconsSettings className="mx-2" />
                      Settings
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleLogOut}>
                      <SignOutIcon className="mx-2" />
                      Sign Out
                    </Dropdown.Item>
                    {/* <hr
                      style={{
                        width: "90%",
                        margin: "0 auto",
                        marginTop: "5px",
                      }}
                    />
                    <Link to="/upgrade" style={{ textDecoration: "none" }}>
                      <Row className="mt-2" style={{ cursor: "pointer" }}>
                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                          <div className="pro-profile-drop">
                            <div className="pro">
                              <div className="pro-icon"></div>
                            </div>
                          </div>
                        </Col>
                        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                          <p className="mb-0 pro-small">Sochcast</p>
                          <span className="mb-0 more-small">more</span>
                        </Col>
                      </Row>
                    </Link> */}
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
