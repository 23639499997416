import React, { useEffect, useState } from "react";
import { Header } from "../../components/header/Header";
import { TopHeader } from "../../components/header/TopHeader";
import { Row, Col, Card, Button } from "react-bootstrap";
import "../css/monetization/monetization.css";
import BrandIcon from "../../assets/images/Group9550.png";
import { useHistory, useParams } from "react-router";
import apiInstance from "../../api";

export const Brand = () => {
  const history = useHistory();
  const { brandID, showID } = useParams();
  const [brandData, setBrandData] = useState();

  useEffect(() => {
    apiInstance
      .get(`/monetization/brands/${brandID}/`)
      .then((response) => {
        return response;
      })
      .then((data) => {
        if (data.status === 200) {
          setBrandData(data.data);
        }
      });
  }, [brandID]);

  const handleBrandSendProposal = () => {
    history.push(`/brand-form/${brandID}/show/${showID}`);
  };

  return (
    <div className="app">
      <Header />
      <main>
        <TopHeader name="Monetization - Brand" />
        <div className=" p-5 mainContainerAdd">
          {brandData ? (
            <>
              <Row>
                <Col className="createChannelTitle">
                  <h1 className="text-capitalize">{brandData.brand_name}</h1>
                </Col>
              </Row>

              <div className="channel-form mt-4">
                <Row className="mx-4 py-5">
                  <Col>
                    <Card className="brand-sponsor">
                      <Card.Img
                        variant="top"
                        src={brandData.brand_image}
                        style={{
                          width: "74px",
                          height: "74px",
                          margin: "auto",
                          marginTop: "30px",
                        }}
                        alt={brandData.brand_name}
                      />
                      <Card.Body className="brand-name-footer text-center">
                        <p className="m-0 text-capitalize">
                          {brandData.brand_name}
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mx-4 py-5">
                  <Col>
                    <h4 className="brand-proposal-title">Budget</h4>
                    <p className="brand-proposal-sub-title">
                      {brandData.budget}
                    </p>
                  </Col>
                  <Col>
                    <h4 className="brand-proposal-title">Category</h4>
                    <p className="brand-proposal-sub-title">
                      {brandData.brand_category.name}
                    </p>
                  </Col>
                  <Col>
                    <h4 className="brand-proposal-title">Duration</h4>
                    <p className="brand-proposal-sub-title">
                      {brandData.campaign_duration}
                    </p>
                  </Col>
                  <Col>
                    <h4 className="brand-proposal-title">Ad Delivery:</h4>
                    <p className="brand-proposal-sub-title">
                      {brandData.monetization_ad_delivery}
                    </p>
                  </Col>
                  <Col>
                    <h4 className="brand-proposal-title">Rolls:</h4>
                    <p className="brand-proposal-sub-title">
                      {brandData.host_read_rolls}
                    </p>
                  </Col>
                  <hr className="my-4" />
                </Row>
                <Row className="mx-4 pb-5">
                  <Col>
                    <h4 className="brand-proposal-title">
                      Sponsor Description
                    </h4>
                    <p className="brand-proposal-sub-title mb-5">
                      {brandData.brand_description}
                    </p>
                    {/* <div className="blue-back-brand mt-5 text-center">
                      <p className="mt-2">
                        <span className="text-capitalize">
                          {brandData.brand_name}
                        </span>{" "}
                        wants to push for audio
                      </p>
                    </div> */}
                  </Col>
                </Row>
              </div>
            </>
          ) : null}
          <Button
            variant="primary"
            className="channelPublishButton rounded-2 my-5"
            title="Send Proposal"
            data-testid="signUpBtton"
            type="submit"
            onClick={handleBrandSendProposal}
          >
            Send Proposal
          </Button>
        </div>
      </main>
    </div>
  );
};
