import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router";
import { accessToken } from "../../helperFunction";
import apiInstance from "../../api";
import { ReactComponent as EditIcon } from "../../assets/images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/DeleteIcon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Button } from "react-bootstrap";

export const ShowList = () => {
  const [shows, setShows] = useState([]);
  const [deletedRow, setDeletedRow] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function getAllShows() {
      await apiInstance
        .get("/shows/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setShows(response.data.results);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (error.response.data.error_type === "InvalidToken") {
              accessToken();
              getAllShows();
            }
          }
        });
    }
    getAllShows();
  }, [deletedRow]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "No.",
      cell: (row, index) => index + 1,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Show ID",
      selector: (row) => row.id,
    },
    {
      name: "Date",
      selector: (row) => {
        const myMomentObject = moment(
          `${row.created_at}`,
          "YYYY-MM-DD"
        ).toDate();
        const data = moment(myMomentObject).format("MMM D, YYYY");
        return data;
      },
    },
    {
      name: "Publish Status",
      selector: (row) => {
        if (row.is_published === true) {
          return "Published";
        } else {
          return "Draft";
        }
      },
    },
    {
      name: "Is Unlisted",
      selector: (row) => {
        if (row.is_unlisted === true) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <EditIcon
            onClick={() => handleShowEdit(row.id)}
            id={row.id}
            className="editIconChannelList"
          />
          <DeleteIcon
            onClick={() => handleShowDelete(row.id, row.name)}
            id={row.id}
            className="deleteIconChannelList mx-3"
          />
        </>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <>
          <Button
            className="landing-signin"
            onClick={() => handleViewAnalytics(row.id)}
          >
            Analytics
          </Button>
        </>
      ),
    },
  ];

  const handleViewAnalytics = (showID) => {
    history.push(`show/${showID}/analytics`);
  };

  const handleShowEdit = (showID) => {
    history.push(`/show/edit/${showID}`);
  };

  const handleShowDelete = (showID, showName) => {
    setDeletedRow(false);
    apiInstance
      .delete(`/shows/${showID}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        setDeletedRow(true);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (error.response.data.error_type === "InvalidToken") {
            accessToken();
            handleShowDelete();
          }
        } else if (error.response.status === 405) {
          toast.warning(`You Cannot delete ${showName}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const data = shows;

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Card className="ChannelListTable">
        <Card.Body>
          <DataTable
            pagination
            columns={columns}
            data={data}
            customStyles={customStyles}
          />
        </Card.Body>
      </Card>
    </>
  );
};
